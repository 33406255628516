const Table = require('../../Misc/Table');
const PieChart = require('../../Misc/PieChart');

export const timebudget = (app, report, evaluation, group, assessor) => {

    let slide = report.pptx.addSlide('Slide master');
    let slide2;
    const language = self.app.$store.state.i18n.locale;

    let pieObj = [{
        name: '',
        labels: [
            app.$t('Lying (') + assessor.getLyingHours() + ')',
            app.$t('Eating* (') + assessor.getEatingHours() + ')',
            app.$t('Drinking* (') + assessor.getDrinkingHours() + ')',
            app.$t('Milking (') + assessor.getMilkingHours() + ')',
        ],
        values: [
            assessor.getLyingHours(),
            assessor.getEatingHours(),
            assessor.getDrinkingHours(),
            assessor.getMilkingHours(),
        ],
    }];

    if (assessor.getProductionType() === 'confined_cattle') {
        pieObj[0].labels.push(...[
            `${app.$t('Standing in Stall (')}${assessor.getStallStandingHours()})`,
            `${app.$t('Standing in Alley (')}${assessor.getAlleyStandingHours()})`,
        ]);
        pieObj[0].values.push(...[
            assessor.getStallStandingHours(),
            assessor.getAlleyStandingHours()
        ]);
    } else {
        pieObj[0].labels.push(...[
            `${app.$t('Walking to Paddock (')}${assessor.getWalkingToPaddock()})`,
            `${app.$t('Away from Paddock (')}${assessor.getTimeHeldAwayFromPaddocks()})`,
        ]);
        pieObj[0].values.push(...[
            assessor.getWalkingToPaddock(),
            assessor.getTimeHeldAwayFromPaddocks()
        ]);
    }

    slide.addText(
        `${app.$t('Time Budget Assessor')} - ${group.getName()}`, report.styleOptions.slideHeader
    );

    slide.addText(
        app.$t('Calculations'), {
            x: 0.5,
            y: 2.2,
            ...report.styleOptions.subheaderBlue
        }
    );

    slide.addText(
        app.$t("Today's highly productive cows need plenty of rest to deliver maximum results. The chart below shows the results of your herd's budget analysis."), {
            x: 0.5,
            y: 2.7,
            w: 7.5,
            color: '000000',
            fontSize: 12,
            fontFace: 'Arial',
            fontWeight: 500
        }
    );

    // textbox rectangle
    slide.addShape(report.pptx.shapes.RECTANGLE, {
        x: 4.5,
        y: 3.3,
        w: 3.5,
        h: language === null ? 0.7 : 1,
        line: '000000',
        line_size: 1
    });

    slide.addText(
        assessor.getProductionType() !== 'grazing_cattle'
            ? app.$t('Dairy cows need to lie down at least 12 hours per day. Increased time spent standing places the cow at risk for claw horn lesion development.')
            : app.$t('Dairy cows in an intensive grazing system need to lie down at least 7 hours per day. Increased time spend standing places the cow at risk for claw horn lesion development.'), {
            x: assessor.getProductionType() !== 'grazing_cattle' ? 4.55 : 4.48,
            y: language === null ? 3.4 : 3.55,
            w: 3.35,
            h: 0.5,
            color: (assessor.getProductionType() !== 'grazing_cattle' && assessor.getLyingHours() >= 12) || (assessor.getProductionType() === 'grazing_cattle' && assessor.getLyingHours() >= 7) ? '249824' : 'ff0000',
            fontSize: 10,
            fontFace: 'Arial',
            fontWeight: 500,
            align: 'c'
        }
    );

    // pie rectangle
    slide.addShape(report.pptx.shapes.RECTANGLE, {
        x: 0.5,
        y: 3.3,
        w: 3.6,
        h: 4,
        line: '000000',
        line_size: 1
    });

    PieChart.create(report, slide, pieObj, {
        x: 0.3,
        y: 3.4,
        w: 4,
        h: 3.9,
    });

    let obj3 = [];
    let obj4 = [];

    if (assessor.getProductionType() === 'confined_cattle') {

        //this is causing an error message for powerpoints
        obj4.push([{
                text: app.$t('Stall Standing Index (%)'),
                options: report.styleOptions.tableHeaderGray
            },
            {
                text: assessor.getStallStandingIndexPercentage() !== null || assessor.getStallStandingIndexPercentage() !== undefined
                    ? `${app.$numberFormat(assessor.getStallStandingIndexPercentage())}%`
                    : app.$t('N/A'),
            }
        ]);

        // this is causing an error message for powerpoints
        if (assessor.getStockingRatePercentage() !== undefined) {
            obj4.push([{
                text: app.$t('Stocking Rate (%)'),
                options: report.styleOptions.tableHeaderGray
            }, {
                text: (app.$numberFormat(assessor.getStockingRatePercentage())) + '%',
            }]);
        }

    }

    obj4.push([{
        text: app.$t('Approx Time Spent Eating* (hrs)'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: assessor.getEatingHours(),
    }]);
    obj4.push([{
        text: app.$t('Approx Time Spent Drinking* (mins)'),
        options: report.styleOptions.tableHeaderGray,
    }, {
        text: 30.0,
    }]);

    if (assessor.getProductionType() === 'confined_cattle') {

        obj4.push([{
            text: `${app.$t('Time Spent Standing in Alley (hrs)')}:`,
            options: report.styleOptions.tableHeaderGray
        }, {
            text: assessor.getAlleyStandingHours(),
        }]);

    }

    obj4.push([{
        text: app.$t('Calculated Average Time Spent Milking (hrs):'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: assessor.getMilkingHours(),
    }]);

    if (assessor.getProductionType() === 'confined_cattle') {

        obj4.push([{
            text: `${app.$t('Time Spent Standing in Stall (hrs)')}:`,
            options: report.styleOptions.tableHeaderGray
        }, {
            text: assessor.getStallStandingHours(),
        }]);

    }

    obj4.push([{
        text: `* ${app.$t('Constant')}`,
        options: {
            ...report.styleOptions.tableHeaderGray,
            colspan: 2
        }
    }]);

    Table.create(slide, obj3, obj4, report, group, 'Time Budget Assessor', {
        ...report.styleOptions.tableOptions,
        x: 4.5,
        y: language === null ? 4.25 : 4.5,
        w: 3.55,
        fill: 'ffffff',
        color: '000000',
        border: {
            pt: '1',
            color: '000000'
        },
        colW: [2.45, 1.05]
    });

    slide.addText(
        app.$t('Your Operation'), {
            x: 0.5,
            y: language === null && assessor.getProductionType() === 'confined_cattle'
                ? 7.88
                : language === 'es' && assessor.getProductionType() !== 'confined_cattle'
                    ? 7.58
                    : 8.18,
            ...report.styleOptions.subheaderBlue
        }
    );

    slide.addText(
        [
            {
                text: `${app.$t('Type of Production')}: `,
                options: {
                    bold: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: assessor.getProductionType() === 'confined_cattle'
                    ? app.$t('Confined Cattle')
                    : assessor.getProductionType() === 'grazing_cattle'
                        ? app.$t('Grazing Cattle')
                        : app.$t('Dirt Lot'),
                options: {
                    breakLine: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: `${app.$t('Cows in Group')}: `,
                options: {
                    bold: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: app.$numberFormat(group.getGroupSize()),
                options: {
                    breakLine: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: `${app.$t('Cows Milked per Hour')}: `,
                options: {
                    bold: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: assessor.getCowsMilkedPerHour() !== null
                    ? app.$numberFormat(assessor.getCowsMilkedPerHour())
                    : app.$t('N/A'),
                options: {
                    breakLine: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: `${app.$t('Milkings per Day')}: `,
                options: {
                    bold: true,
                    fontFace: 'Arial'
                }
            },
            {
                text: assessor.getMilkingsPerDay() !== null
                    ? app.$numberFormat(assessor.getMilkingsPerDay())
                    : app.$t('N/A'),
                options: {
                    breakLine: true,
                    fontFace: 'Arial'
                }
            }
        ], {
            x: 0.5,
            y: language === null && assessor.getProductionType() === 'confined_cattle'
                ? 7.7
                : language === 'es' && assessor.getProductionType() !== 'confined_cattle'
                    ? 7.5
                    : 8,
            w: 3,
            h: 1.75,
            fontSize: 12
        }
    );

    let timebudgetOperationListings2 = [];

    if (assessor.getProductionType() === 'confined_cattle') {

        timebudgetOperationListings2.push({
            text: `${app.$t('Stalls Available in a Pen')}: `,
            options: {
                bold: true,
                fontFace: 'Arial'
            }
        }, {
            text: assessor.getStallsAvailableInPen() !== undefined
                ? app.$numberFormat(assessor.getStallsAvailableInPen())
                : app.$t('N/A'),
            options: {
                breakLine: true,
                fontFace: 'Arial'
            }
        });

        timebudgetOperationListings2.push({
            text: `${app.$t('Cows Standing or Lying in Stall')}: `,
            options: {
                bold: true,
                fontFace: 'Arial'
            }
        }, {
            text: assessor.getCowsStandingOrLyingInStall() !== undefined
                ? app.$numberFormat(assessor.getCowsStandingOrLyingInStall())
                : app.$t('N/A'),
            options: {
                breakLine: true,
                fontFace: 'Arial'
            }
        });

        timebudgetOperationListings2.push({
            text: `${app.$t('Cows Standing Only in Stall')}: `,
            options: {
                bold: true,
                fontFace: 'Arial'
            }
        }, {
            text: assessor.getCowsStandingOnlyInStall() !== null
                ? app.$numberFormat(assessor.getCowsStandingOnlyInStall())
                : app.$t('N/A'),
            options: {
                breakLine: true,
                fontFace: 'Arial'
            }
        });

    } else if (assessor.getProductionType() === 'grazing_cattle') {

        timebudgetOperationListings2.push({
            text: `${app.$t('Quantity of Grain Supplement')} (${assessor.app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')}${app.$t('):')} `,
            options: {
                bold: true,
                fontFace: 'Arial'
            }
        }, {
            text: assessor.getQuantityOfGrainSupplements() !== undefined
                ? app.$numberFormat(assessor.getQuantityOfGrainSupplements())
                : app.$t('N/A'),
            options: {
                breakLine: true,
                fontFace: 'Arial'
            }
        });

    }

    if (assessor.getProductionType() === 'dirt_lot' || assessor.getProductionType() === 'grazing_cattle') {

        timebudgetOperationListings2.push({
            text: `${app.$t('Walking Time to/from Paddock per Day (Mins):')} `,
            options: {
                bold: true,
                fontFace: 'Arial',
            }
        }, {
            text: assessor.getWalkingTimePerDay() !== undefined
                ? app.$numberFormat(assessor.getWalkingTimePerDay())
                : app.$t('N/A'),
            options: {
                breakLine: true,
                fontFace: 'Arial',
            }
        });

        timebudgetOperationListings2.push({
            text: `${app.$t('Time Held Away from Paddocks')}: `,
            options: {
                bold: true,
                fontFace: 'Arial',
            }
        }, {
            text: assessor.getTimeHeldAwayFromPaddocks() !== null
                ? app.$numberFormat(assessor.getTimeHeldAwayFromPaddocks())
                : app.$t('N/A'),
            options: {
                breakLine: true,
                fontFace: 'Arial',
            }
        });

    }

    slide.addText(
        timebudgetOperationListings2, {
            x: 3.75,
            y: language === null && assessor.getProductionType() === 'confined_cattle'
                ? 7.7
                : language === 'es' && assessor.getProductionType() !== 'confined_cattle'
                    ? 7.5
                    : 8,
            w: 4,
            h: 1.75,
            fontSize: 12
        }
    );

    let obj5 = [{
        text: app.$t('Question'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Answer'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Comment'),
        options: report.styleOptions.tableHeader
    }];
    let obj6 = [];

    console.log(
      "console",
        typeof assessor.getSoftBedding() === NaN ? 
          ""
       :  assessor.getSoftBedding() === 1
          ? "Yes"
          : "No"
         
    );
    obj6.push([
      {
        // text: `${app.$t('Do freestalls have at least ')}${assessor.getMinSoftBeddingDepth()} ${assessor.app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')} ${app.$t('of soft bedding?')}`,
        text: evaluation.isImperial
          ? app.$t(
              "Do freestalls have at least 8 in of soft bedding?"
            )
          : app.$t(
              "Do freestalls have at least 20 cm of soft bedding?"
            ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          // fontFace: 'Arial',
          bold: false,
        },
      },
      {
        text:
              isNaN(assessor.getSoftBedding()) ? "" :
                  assessor.getSoftBedding() === 1 ? "Yes" : "No",
        options: {
          color: "000000",
          fill: "ffffff",
          // fontFace: 'Arial',
          bold: false,
        },
      },
      {
        // TODO: Check why this doesn't return true when using strict equals operator
        text:
          assessor.getSoftBedding() === 0
            ? `${app.$t(
                "Bedding depth less than "
              )}${assessor.getMinSoftBeddingDepth()}${app.$t(
                assessor.app.$getEquivalentUnit(
                  evaluation.getMeasurements(),
                  "cm"
                )
              )} ${app.$t("may reduce lying time and quality.")}`
            : assessor.getSoftBedding() === 1
            ? app.$t("Good!")
            : "",
        options: {
          color:
            assessor.getSoftBedding() === 0 ? "ff0000" : "249824",
          align: "left",
          fill: "efefef",
          // fontFace: 'Arial',
          bold: false,
        },
      },
    ]);

    if (assessor.getProductionType() === 'confined_cattle' && obj6.length > 0) {

        slide2 = report.pptx.addSlide('Slide master');

        slide2.addText(
          `${app.$t('Time Budget Assessor')} - ${group.getName()}`, report.styleOptions.slideHeader
        );

        Table.create(slide2, obj5, obj6, report, group, 'Time Budget Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.2,
            colW: [3.25, 1.25, 3]
        });

    }
}
