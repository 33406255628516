const Table = require('../../Misc/Table');

export const freestallVentilation = (app, report, evaluation, group, assessor) => {
    let slide;

    let titleY = 2.6;
    let slideY = 3;

    const categoriesObj = [
        {
            text: app.$t('Question'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Answer'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Comment'),
            options: report.styleOptions.tableHeader
        }
    ];
    let questionsObj = [];
    let ventilationObj = [];

    if (assessor.getSummerVentilationSystem() !== null) {
        questionsObj.push([{
                text: app.$t("Summer Ventilation System"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getSummerVentilationSystem() === 'natural' ? app.$t('Natural (open-sided)') : app.$t('Tunnel'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        assessor.getSummerVentilationSystem() === 'natural' ? slideY += 1 : slideY += .8;
        assessor.getSummerVentilationSystem() === 'natural' ? titleY += 1 : titleY += .8;
    }

    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getLongAxisOrientationOfTheBarn() !== null) {
        questionsObj.push([{
                text: app.$t("Long Axis Orientation of the Barn"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getLongAxisOrientationOfTheBarn() === "n_to_s" ? app.$t('N to S') : assessor.getLongAxisOrientationOfTheBarn() === "ne_to_sw" ? app.$t('NE to SW') : assessor.getLongAxisOrientationOfTheBarn() === "e_to_w" ? app.$t('E to W') : app.$t('SE to NW'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getLongAxisOrientationOfTheBarn() !== "e_to_w" ? app.$t('East to West orientation is preferred to maximize wind capture.') : app.$t(''),
                options: {
                    align: 'left',
                    color: assessor.getLongAxisOrientationOfTheBarn() !== "e_to_w" ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        assessor.getLongAxisOrientationOfTheBarn() !== "e_to_w" ? slideY += .6 : slideY += .35;
        assessor.getLongAxisOrientationOfTheBarn() !== "e_to_w" ? titleY += .6 : titleY += .35;
    }

    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getPrevailingWindDirection() !== null) {
        questionsObj.push([{
                text: app.$t("Prevailing Wind Direction"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getPrevailingWindDirection() === "n_to_s" ? app.$t('N to S') : assessor.getPrevailingWindDirection() === "ne_to_sw" ? app.$t('NE to SW') : assessor.getPrevailingWindDirection() === "e_to_w" ? app.$t('E to W') : assessor.getPrevailingWindDirection() === "w_to_e" ? app.$t('W to E') : assessor.getPrevailingWindDirection() === "s_to_n" ? app.$t('S to N') : assessor.getPrevailingWindDirection() === "se_to_nw" ? app.$t('SE to NW') : assessor.getPrevailingWindDirection() === "nw_to_se" ? app.$t('NW to SE') : app.$t('SW to NE'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .35;
        titleY += .35;
    }

    if (assessor.getSummerVentilationSystem() === 'tunnel' && assessor.getBarnLength() !== null) {
        questionsObj.push([{
                text: app.$t("Barn Length") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnLength()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .35;
        titleY += .35;
    }

    if (assessor.getBarnWidth() !== null) {
        questionsObj.push([{
                text: app.$t("Barn Width") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnWidth()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .35;
        titleY += .35;
    }

    if (assessor.getBarnEaveHeight() !== null) {
        questionsObj.push([{
                text: app.$t("Barn Eave Height (") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnEaveHeight()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
        slideY += .35;
        titleY += .35;
    }

    if (assessor.getRoofHeight() !== null) {
        questionsObj.push([{
                text: app.$t("Roof Height (") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getRoofHeight()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .35;
        titleY += .35;
    }

    if (assessor.getSummerVentilationSystem() === 'tunnel' && assessor.getCrossSectionalArea() !== null) {

        questionsObj.push([{
                text: app.$t("Cross-sectional area") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getCrossSectionalArea()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .35;
        titleY += .35;
    }

    if (questionsObj.length > 0) {

        slide = report.pptx.addSlide('Slide master');

        slide.addText(
            app.$t('Freestall Ventilation Assessor') + ' - ' + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('Questions'), {
                x: 0.5,
                y: 2.4,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, questionsObj, report, group, 'Freestall Ventilation Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });

    }

    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getRidgePitch() !== null) {
        ventilationObj.push([{
                text: app.$t("Roof Pitch"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getRidgePitch()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t('The preferred roof pitch is ') + assessor.getTargetRidgePitch() + app.$t('.'),
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getRidgeOpeningWidth() !== null) {
        ventilationObj.push([{
                text: app.$t("Ridge Opening Width") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getRidgeOpeningWidth()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t('Target ridge opening is ') + assessor.getRidgeOpening() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t('.'),
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getSummerVentilationSystem() === 'tunnel' && assessor.getInletArea() !== null) {
        ventilationObj.push([{
                text: app.$t("Inlet area") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getInletArea(0)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getInletArea() !== assessor.getTargetAirMovementCapacity() ? app.$t('Target inlet area for your barn is ') + app.$numberFormat(assessor.getTargetAirMovementCapacity()) + " " + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + '.' : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getInletArea() !== assessor.getTargetAirMovementCapacity() ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }
    
    // we've two types one is "tunnel" and other one is "natural"
    const numberOfFansType = assessor.getSummerVentilationSystem() === 'tunnel' ?  "number_of_fans" : "number_of_fans_natural" 
    const fanWidthType = assessor.getSummerVentilationSystem() === 'tunnel' ?  "fan_width" : "fan_width_natural" 
    
    // fans width 1
// assessor.getSummerVentilationSystem() === 'tunnel'
    if (assessor.getFanWidth(`${fanWidthType}_1`) !== null) {
        ventilationObj.push([{
                text: app.$t("Fan Width 1") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidth(`${fanWidthType}_1`)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    // no fo fans 1
    if (assessor.getNumberOfFans(`${numberOfFansType}_1`) !== null) {
        ventilationObj.push([{
                text: app.$t("Number of Fans 1"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFans(`${numberOfFansType}_1`)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    // fans width 2
// assessor.getSummerVentilationSystem() === 'tunnel'
    if (assessor.getFanWidth(`${fanWidthType}_2`) !== null) {
        ventilationObj.push([{
                text: app.$t("Fan Width 2") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidth(`${fanWidthType}_2`)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    // no fo fans 2
// assessor.getSummerVentilationSystem() === 'tunnel'
    if (assessor.getNumberOfFans(`${numberOfFansType}_2`) !== null) {
        ventilationObj.push([{
                text: app.$t("Number of Fans 2"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFans(`${numberOfFansType}_2`)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    // fans width 3
// assessor.getSummerVentilationSystem() === 'tunnel'
    if (assessor.getFanWidth(`${fanWidthType}_3`) !== null) {
        ventilationObj.push([{
                text: app.$t("Fan Width 3") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidth(`${fanWidthType}_3`)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    // no fo fans 3
// assessor.getSummerVentilationSystem() === 'tunnel'
    if (assessor.getNumberOfFans(`${numberOfFansType}_3`) !== null) {
        ventilationObj.push([{
                text: app.$t("Number of Fans 3"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFans(`${numberOfFansType}_3`)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

// assessor.getSummerVentilationSystem() === 'tunnel'
    if (assessor.getSummerVentilationSystem() === 'tunnel' && assessor.getDisplayedAirMovementCapacity() !== null) {
        ventilationObj.push([{
                text: app.$t("Total air movement capacity") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getDisplayedAirMovementCapacity()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDisplayedAirMovementCapacity() !== assessor.getAirMovementCapacity() ? app.$t('Target air movement capacity ') + app.$numberFormat(assessor.getAirMovementCapacity()) + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + app.$t('.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getDisplayedAirMovementCapacity() !== assessor.getAirMovementCapacity() ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getDisplayedAirMovementCapacityNatural() !== null) {
     const condition = assessor.getDisplayedAirMovementCapacityNatural() !== assessor.getAirMovementCapacityNatural();
        ventilationObj.push([{
                text: app.$t("Total air movement capacity") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getDisplayedAirMovementCapacityNatural()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text:  condition ? app.$t('Target air movement capacity ') + assessor.getAirMovementCapacityNatural() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + app.$t('.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color:  condition ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }


    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getBarnIsFreeOfWindShadows() !== null) {
        ventilationObj.push([{
                text: app.$t("Barn is free of wind shadows"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getBarnIsFreeOfWindShadows() === 1 ? app.$t('Yes') : assessor.getBarnIsFreeOfWindShadows() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getBarnIsFreeOfWindShadows() === 1 ? app.$t('Good!') : assessor.getBarnIsFreeOfWindShadows() === 0 ? app.$t('There should be no obstruction to airflow within 1.5 building widths.') : '',
                options: {
                    align: 'left',
                    color: assessor.getBarnIsFreeOfWindShadows() === 1 ? '249824' : assessor.getBarnIsFreeOfWindShadows() === 0 ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getSummerVentilationSystem() === 'natural' && assessor.getFiftyPercentOfSideWallCanBeOpened() !== null) {
        ventilationObj.push([{
                text: app.$t("50% of side wall can be opened"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFiftyPercentOfSideWallCanBeOpened() === 1 ? app.$t('Yes') : assessor.getFiftyPercentOfSideWallCanBeOpened() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFiftyPercentOfSideWallCanBeOpened() === 1 ? app.$t('Good!') : assessor.getFiftyPercentOfSideWallCanBeOpened() === 0 ? app.$t('Curtain side walls allowing the entire sidewall to be opened is preferred to improve airflow.') : '',
                options: {
                    align: 'left',
                    color: assessor.getFiftyPercentOfSideWallCanBeOpened() === 1 ? '249824' : assessor.getFiftyPercentOfSideWallCanBeOpened() === 0 ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (ventilationObj.length > 0) {

        const slideTitle = assessor.getSummerVentilationSystem() === 'tunnel' ? app.$t('Tunnel Summer Ventilation') : app.$t('Natural Summer Ventilation');

        // slide.addText(
        //     app.$t('Freestall Ventilation Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        // );
        titleY += .5
        slideY += .5

        slide.addText(
            slideTitle, {
                x: 0.5,
                y: titleY,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, ventilationObj, report, group, 'Freestall Ventilation Assessor', {
            y: slideY,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });

    }

}
