<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block mb-lg-4 mr-1">
          {{ $t('Tell Us About Your Walking Surfaces') }}
        </h2>
        <modal-info :value="'nutrition'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ $t('Walking Surface') }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Walking Surfaces, click the link to learn more from'
                  | translate
              }}
              <a
                :href="
                  $t('https://library.zinprofirststep.com/walking-surface/')
                "
                @click="$goToLameness($t('/walking-surface/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ $t('Cancel') }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-4 mt-1 mt-lg-0" />

    <!-- surface risk assessment -->
    <div class="row mx-0 mb-3">
      <div class="col-lg-12">
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Feed Alley') }}</h4>
          <select-surface-risk
            @change="assessor.data.feed_alley = $event.value"
            :initial-value="assessor.data.feed_alley"
            :differentiator="'feed_alley'"
          ></select-surface-risk>
        </div>
        <hr class="hr--n-sm" />
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Crossover Alley') }}</h4>
          <select-surface-risk
            @change="assessor.data.crossover_alley = $event.value"
            :initial-value="assessor.data.crossover_alley"
            :differentiator="'crossover_alley'"
          ></select-surface-risk>
        </div>
        <hr class="hr--n-sm" />
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Holding Area') }}</h4>
          <select-surface-risk
            @change="assessor.data.holding_area = $event.value"
            :initial-value="assessor.data.holding_area"
            :differentiator="'holding_area'"
          ></select-surface-risk>
        </div>
        <hr class="hr--n-sm" />
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Parlor Return Lanes') }}</h4>
          <select-surface-risk
            @change="assessor.data.parlor_return_lanes = $event.value"
            :initial-value="assessor.data.parlor_return_lanes"
            :differentiator="'parlor_return_lanes'"
          ></select-surface-risk>
        </div>
        <hr class="d-block d-lg-none hr--n-sm" />
      </div>
      <!-- end left column -->

      <!-- right column -->
      <div class="offset-lg-1 col-lg-11">
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Stall Alley') }}</h4>
          <select-surface-risk
            @change="assessor.data.stall_alley = $event.value"
            :initial-value="assessor.data.stall_alley"
            :differentiator="'stall_alley'"
          ></select-surface-risk>
        </div>
        <hr class="hr--n-sm" />
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Transfer Lanes') }}</h4>
          <select-surface-risk
            @change="assessor.data.transfer_lanes = $event.value"
            :initial-value="assessor.data.transfer_lanes"
            :differentiator="'transfer_lanes'"
          ></select-surface-risk>
        </div>
        <hr class="hr--n-sm" />
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Parlor') }}</h4>
          <select-surface-risk
            @change="assessor.data.parlor = $event.value"
            :initial-value="assessor.data.parlor"
            :differentiator="'parlor'"
          ></select-surface-risk>
        </div>
        <hr class="hr--n-sm" />
        <div class="row mb-3">
          <h4 class="mb-1">{{ $t('Steps Between Pen and Milking Parlor') }}</h4>
          <select-surface-risk
            @change="
              assessor.data.steps_between_pen_and_milking_parlor = $event.value
            "
            :initial-value="assessor.data.steps_between_pen_and_milking_parlor"
            :differentiator="'steps_between_pen_and_milking_parlor'"
          ></select-surface-risk>
        </div>
      </div>
      <!-- end right column -->
    </div>
    <!-- end surface risk assessment -->

    <hr />

    <question-three
      @change="
        assessor.data.are_steps_in_the_path_of_movement_between_the_pen_and_milking_parlor =
          $event.value
      "
      :initial-value="
        assessor.data
          .are_steps_in_the_path_of_movement_between_the_pen_and_milking_parlor
      "
    >
      <label class="label d-inline-block">{{
        $t(
          'Are stair steps in the path of movement between the pen and milking parlor?'
        )
      }}</label>
    </question-three>

    <hr
      v-show="
        assessor.data
          .are_steps_in_the_path_of_movement_between_the_pen_and_milking_parlor ===
          1
      "
    />

    <question-three
      v-show="
        assessor.data
          .are_steps_in_the_path_of_movement_between_the_pen_and_milking_parlor ===
          1
      "
      @change="
        assessor.data.are_cows_being_excessively_pushed_over_steps =
          $event.value
      "
      :initial-value="
        assessor.data.are_cows_being_excessively_pushed_over_steps
      "
    >
      <label class="label d-inline-block">{{
        $t('Are cows being excessively pushed over steps?')
      }}</label>
    </question-three>

    <hr />

    <question
      @change="
        assessor.data.is_the_distance_from_cow_pens_to_milking_center_greater_than =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_distance_from_cow_pens_to_milking_center_greater_than
      "
    >
      <label class="label d-inline-block">
        {{
          $t('Is the distance from cow pens to milking center greater than ') +
            walkingsurface.getMinIsTheDistanceFromCowPensToMilkingCenterGreaterThan() +
            $t(' ') +
            $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
            $t('?')
        }}
      </label>
    </question>

    <hr />

    <!--        <question-three @change="assessor.data.are_feed_delivery_and_pushups_timed_to_maximize_feed_access = $event.value" :initial-value="assessor.data.are_feed_delivery_and_pushups_timed_to_maximize_feed_access">-->
    <!--            <label class="label d-inline-block">-->
    <!--                {{ $t("Are feed delivery and pushups timed to maximize feed access?") }}-->
    <!--            </label>-->
    <!--        </question-three>-->

    <!--        <question-three @change="assessor.data.is_there_a_highly_fermentable_carbohydrate_feed_delivered_separate_at_any_time = $event.value;" :initial-value="assessor.data.is_there_a_highly_fermentable_carbohydrate_feed_delivered_separate_at_any_time">-->
    <!--            <label class="label d-inline-block">-->
    <!--                {{ $t("Is there a highly fermentable carbohydrate feed delivered separate at any time?") }}-->
    <!--            </label>-->
    <!--        </question-three>-->

    <question-three
      @change="
        assessor.data.does_herdsperson_allow_the_cows_to_walk_at_their_own_pace_to_from_the_milking_parlor =
          $event.value
      "
      :initial-value="
        assessor.data
          .does_herdsperson_allow_the_cows_to_walk_at_their_own_pace_to_from_the_milking_parlor
      "
    >
      <label class="label d-inline-block">{{
        $t(
          'Does herdsperson allow the cows to walk at their own pace to/from the milking parlor?'
        )
      }}</label>
    </question-three>

    <hr class="mb-4" />

    <div class="row">
      <div class="col-lg-12">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{ $t('Number of 90˚ Turns') }}</label>
          <select
            class="form-control"
            v-model="assessor.data.number_of_90_degree_turns"
          >
            <option :value="null" disabled>{{ $t('Select') }}</option>
            <option value="1">{{ $t('1') }}</option>
            <option value="2">{{ $t('2') }}</option>
            <option value="3">{{ $t('3') }}</option>
            <option value="4">{{ $t('4') }}</option>
            <option value="5">{{ $t('5') }}</option>
            <option value="6+">{{ $t('6+') }}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{ $t('Flooring Type') }}</label>
          <select class="form-control" v-model="assessor.data.flooring_type">
            <option :value="null" disabled>{{ $t('Select') }}</option>
            <option value="concrete">{{ $t('Concrete') }}</option>
            <option value="concrete_slat">{{ $t('Concrete Slat') }}</option>
            <option value="rubber">{{ $t('Rubber') }}</option>
            <option value="other">{{ $t('Other') }}</option>
          </select>
        </div>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_rubber_surface_grooved_or_finished_to_minimize_cow_slipping =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_rubber_surface_grooved_or_finished_to_minimize_cow_slipping
      "
    >
      <label class="label d-inline-block">{{
        $t(
          'Is the rubber surface grooved or finished to minimize cow slipping?'
        )
      }}</label>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_any_wire_or_sharp_metal_protruding_from_the_rubber_surface =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_there_any_wire_or_sharp_metal_protruding_from_the_rubber_surface
      "
    >
      <label class="label d-inline-block">{{
        $t(
          'Is there any wire or sharp metal protruding from the rubber surface?'
        )
      }}</label>
    </question-three>

    <hr />
    <div v-show="assessor.data.flooring_type === 'rubber'" class="row">
      <div class="col-lg-12">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Passage Alley Width (') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <currency-input
            v-model.number="converted_passage_alley_width"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Feed Alley Width (') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <currency-input
            v-model.number="converted_feed_alley_width"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
    </div>
    <hr v-show="assessor.data.flooring_type === 'rubber'" />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4"
    >
      <photoUpload :assessorId="18" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <a
          class="btn btn-secondary btn-full--sm mb-2 mt-md-0"
          href="javascript:history.go(-1)"
          >{{ 'Back' | translate }}</a
        >
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
          >{{ 'Reports' | translate }}</router-link
        >
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
  <!-- end container -->
</template>

<script>
import _ from 'lodash';
import PresentationNotes from '@/pages/assessors/assessor_components/presentation-notes';
import PersonalNotes from '@/pages/assessors/assessor_components/personal-notes';
import Question from '@/pages/assessors/assessor_components/question';
import QuestionThree from '@/pages/assessors/assessor_components/question-three';
import SelectSurfaceRisk from '@/pages/assessors/assessor_components/select-surface-risk';
import ModalInfo from '@/components/misc/modal-info';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from '@/pages/assessors/assessor_components/hygiene-header';
import convert from 'convert-units';
import WalkingSurface from '@/libs/Classes/WalkingSurface.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import requestReportMixin from '@/mixins/requestReport.js';
import CurrencyInput from "@/components/common/CurrencyInput.vue";


export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    QuestionThree,
    SelectSurfaceRisk,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    CurrencyInput
  },
  mixins: [requestReportMixin],

  props: ['assessor', 'group', 'evaluation'],
  data() {
    return {
      walkingsurface: null,
    };
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, evaluationClass, this.group);
    this.walkingsurface = new WalkingSurface(
      this,
      evaluationClass,
      groupClass,
      this.assessor
    );
  },
  computed: {
    converted_passage_alley_width: {
      get: function() {
        return this.walkingsurface !== null
          ? this.walkingsurface.getPassageAlleyWidth(false)
          : false;
      },
      set: function(newValue) {
        return this.walkingsurface.setPassageAlleyWidth(newValue);
      },
    },
    converted_feed_alley_width: {
      get: function() {
        return this.walkingsurface !== null
          ? this.walkingsurface.getFeedAlleyWidth(false)
          : false;
      },
      set: function(newValue) {
        return this.walkingsurface.setFeedAlleyWidth(newValue);
      },
    },
  },
};
</script>
