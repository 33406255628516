const Table = require('../../Misc/Table');
const PieChart = require('../../Misc/PieChart');
const BarGraph = require('../../Misc/BarGraph');

export const hygiene = (app, report, evaluation, group, assessor) => {

    let slide = report.pptx.addSlide('Slide master');
    let obj = [{
        text: app.$t('Hygiene Scores'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Number of Cows Scored'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Percentage of Group'),
        options: report.styleOptions.tableHeader
    }];
    let obj2 = [];

    let pieObj = [{
        name: app.$t('Cows Scoring 3 or 4'),
        labels: [app.$t('1') + app.$t(' or ') + app.$t('2'), app.$t('3') + app.$t(' or ') + app.$t('4')],
        values: [
            (assessor.getFirstScore() + assessor.getSecondScore()),
            (assessor.getThirdScore() + assessor.getFourthScore())
        ]
    }];

    let barObj = [{
        name: app.$t('title'),
        labels: [app.$t('Score 1'), app.$t('Score 2'), app.$t('Score 3'), app.$t('Score 4')],
        values: [
            assessor.getPercentageOfTotal(assessor.getFirstScore()),
            assessor.getPercentageOfTotal(assessor.getSecondScore()),
            assessor.getPercentageOfTotal(assessor.getThirdScore()),
            assessor.getPercentageOfTotal(assessor.getFourthScore())
        ]
    }];

    slide.addText(
        app.$t('Hygiene/Footbath Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    ).addText(
        app.$t('Calculations'), {
            x: 0.5,
            y: 2.28,
            ...report.styleOptions.subheaderBlue
        }
    ).addShape(report.pptx.shapes.RECTANGLE, {
        x: 0.5,
        y: 2.7,
        w: 3.6,
        h: 3.25,
        line: '000000',
        line_size: 1
    }).addShape(report.pptx.shapes.RECTANGLE, {
        x: 4.5,
        y: 2.7,
        w: 3.5,
        h: 2.5,
        line: '000000',
        line_size: 1
    }).addText(
        app.$t('Cows Scoring 3 or 4: ') + app.$numberFormat(assessor.getPercentageOfTotal((assessor.getThirdScore() + assessor.getFourthScore()))) + '%', {
            x: 1.25,
            y: 3,
            color: '000000',
            fontSize: 11,
            bold: true,
            fontFace: 'Arial'
        }
    ).addShape(report.pptx.shapes.RECTANGLE, {
        x: 4.5,
        y: 5.45,
        w: 3.5,
        h: 0.5,
        line: '000000',
        line_size: 1
    }).addText(
        app.$t('Hygiene Scores - Group'), {
            x: 5.3,
            y: 3,
            color: '000000',
            fontSize: 11,
            bold: true,
            fontFace: 'Arial'
        }
    ).addText(
        app.$t('By reducing the number of cows scoring 3 and 4, the number of footbaths that are necessary can be significantly reduced.'), {
            x: 4.65,
            y: 5.45,
            w: 3.25,
            h: 0.5,
            align: 'c',
            color: '000000',
            fontSize: 8,
            fontFace: 'Arial'
        }
    ).addShape(report.pptx.shapes.RECTANGLE, {
        x: 0.5,
        y: 6.2,
        w: 7.5,
        h: 0.5,
        line: '000000',
        line_size: 1
    }).addText(
        app.$t('Based upon hygiene scores, your suggested footbath frequency is: ') + app.$numberFormat(assessor.getFrequencySuggested(assessor.getPercentageOfTotal(assessor.getThirdScore()) + assessor.getPercentageOfTotal(assessor.getFourthScore()))) + app.$t(' days per week'), {
            x: 1.35,
            y: 6.48,
            width: 4.5,
            color: '000000',
            fontSize: 11,
            fontFace: 'Arial'
        }
    );

    PieChart.create(report, slide, pieObj, {
        x: 0.75,
        y: 3.3,
        w: 3,
        h: 2.5,
    });

    BarGraph.create(report, slide, barObj, {
        x: 4.65,
        y: 3.1,
        w: 3.25,
        h: 2.1,
        showLegend: false
    });

    obj2.push([{
            text: app.$t('Hygiene Score 1'),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getFirstScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getPercentageOfTotal(assessor.getFirstScore())) + '%',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
                fontFace: 'Arial',
                bold: false
            }
        },
    ]);

    obj2.push([{
            text: app.$t('Hygiene Score 2'),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getSecondScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getPercentageOfTotal(assessor.getSecondScore())) + '%',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
                fontFace: 'Arial',
                bold: false
            }
        },
    ]);

    obj2.push([{
            text: app.$t('Hygiene Score 3'),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getThirdScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getPercentageOfTotal(assessor.getThirdScore())) + '%',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
                fontFace: 'Arial',
                bold: false
            }
        },
    ]);

    obj2.push([{
            text: app.$t('Hygiene Score 4'),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getFourthScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getPercentageOfTotal(assessor.getFourthScore())) + '%',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
                fontFace: 'Arial',
                bold: false
            }
        },
    ]);

    obj2.push([{
            text: app.$t('Total Cows Scored'),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getTotalScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                fontFace: 'Arial',
                bold: false
            }
        },
        {
            text: '100%',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
                fontFace: 'Arial',
                bold: false
            }
        },
    ]);

    Table.create(slide, obj, obj2, report, group, 'Hygiene/Footbath Assessor', {
        y: 6.95,
        ...report.styleOptions.tableOptions
    });

    return slide;
}
