<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ 'Tell Us About Your Holding Yard & Milking Shed' | translate }}
        </h2>
        <modal-info :value="'tell-us'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Holding Yard & Milking Shed' | translate }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Holding Yards & Milking Sheds, click the link to learn more from'
                  | translate
              }}
              <a
                :href="
                  $t(
                    'https://library.zinprofirststep.com/holding-yard-milk-shed/'
                  )
                "
                @click="$goToLameness($t('/holding-yard-milk-shed/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>
    <hr class="mb-lg-4" />
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ $t('Yard Size') }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-24">
        <div class="row">
          <div class="col-lg-24">
            <div class="row">
              <div class="form-group col-lg-12">
                <label class="mb-1 d-block">{{ $t('Yard Shape') }}</label>
                <div class="form-check form-check-inline mr-4">
                  <input
                    v-model="assessor.data.yard_shape"
                    type="radio"
                    id="rectangular"
                    name="rectangular"
                    value="rectangular"
                    class="form-check-input mr-2"
                  />
                  <label class="form-check-label mt-1" for="rectangular">{{
                    'Rectangular' | translate
                  }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="assessor.data.yard_shape"
                    type="radio"
                    id="circular"
                    name="circular"
                    value="circular"
                    class="form-check-input mr-2"
                  />
                  <label class="form-check-label mt-1" for="circular">{{
                    'Circular' | translate
                  }}</label>
                </div>
              </div>
              <div
                class="col-lg-12"
                v-if="assessor.data.yard_shape === 'rectangular'"
              >
                <div class="row">
                  <div class="col-lg-24">
                    <label class="mb-1">{{
                      $t('Yard Size') +
                        ' (' +
                        $t(
                          $getEquivalentUnit(evaluation.data.measurements, 'm')
                        ) +
                        ')'
                    }}</label>
                    <div class="row">
                      <div class="col-lg-11">
                        <currency-input                 
                          v-model="assessor.data.yard_size_ft_wide"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: {min: 0, max :2},
                          }"
                        />
                      </div>
                      <span class="col-lg-2 mt-2 font-14">{{
                        'by' | translate
                      }}</span>
                      <div class="col-lg-11">
                        <currency-input                 
                          v-model="assessor.data.yard_size_ft_long"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: {min: 0, max :2},
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-12"
                v-if="assessor.data.yard_shape === 'circular'"
              >
                <label class="mb-1">{{ $t('Yard Diameter') }}</label>
                <div class="row">
                  <div class="col-lg-24">
                    <currency-input                 
                          v-model="assessor.data.yard_diameter"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: {min: 0, max :2},
                          }"
                        />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-24">
        <div class="row">
          <div class="col-lg-12">
            <label class="mb-1">{{ 'Milking Shed Type' | translate }}</label>
            <select
              v-model="assessor.data.milking_shed_type"
              class="form-control"
            >
              <option :value="null" selected disabled>
                {{ $t('Select') }}
              </option>
              <option value="rotary">{{ 'Rotary' | translate }}</option>
              <option value="herringbone">
                {{ 'Herringbone' | translate }}
              </option>
              <option value="other">{{ 'Other' | translate }}</option>
            </select>
          </div>

          <div class="col-lg-12">
            <label class="mb-1">{{ $t('Friesians') }}</label>
            <currency-input                 
              v-model="assessor.data.fresians"
              :options="{
                currency: 'USD',
                currencyDisplay: 'hidden',
                precision: 0,
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <label class="mb-1">{{ $t('Jerseys') }}</label>
            <currency-input                 
              v-model="assessor.data.jerseys"
              :options="{
                currency: 'USD',
                currencyDisplay: 'hidden',
                precision: 0,
              }"
            />
          </div>
          <div class="col-lg-12">
            <label class="mb-1">{{ $t('J/F Crossbreed') }}</label>
            <currency-input                 
              v-model="assessor.data.jf_rossbreed"
              :options="{
                currency: 'USD',
                currencyDisplay: 'hidden',
                precision: 0,
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Pipework' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.are_there_any_sharp_protruding_parts = $event.value
      "
      :initial-value="assessor.data.are_there_any_sharp_protruding_parts"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are there any sharp/protruding parts?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_there_any_projections_in_hipbone_or_shoulder_range =
          $event.value
      "
      :initial-value="
        assessor.data.are_there_any_projections_in_hipbone_or_shoulder_range
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are there any projections in hipbone or shoulder range?')
        }}</label>
        <modal-info :value="'shoulder-range'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{
                'Are there any projections in hipbone or shoulder range?'
                  | translate
              }}
            </h2>
            <p class="px-3">
              {{
                 evaluation.data.measurements === 'imperial' ? 
                 'Jerseys: 36 in - hipbone, 27 inches - shoulder Fresians: 44 in - hipbone, 30 inches - shoulder'
                 :
                 'Jerseys: 950 mm - hipbone, 700 mm - shoulder Fresians: 1100 mm - hipbone, 760 mm - shoulder' | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_protection_around_solitary_pipes_objects =
          $event.value
      "
      :initial-value="
        assessor.data.is_there_protection_around_solitary_pipes_objects
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is there protection around solitary pipes/objects?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_a_clear_field_of_view_for_cows_exiting =
          $event.value
      "
      :initial-value="
        assessor.data.is_there_a_clear_field_of_view_for_cows_exiting
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is there a clear field of view for cows exiting?')
        }}</label>
        <modal-info :value="'cows-exiting'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{
                'Is there a clear field of view for cows exiting?' | translate
              }}
            </h2>
            <p class="px-3">
              {{
                'Offset gate pillars etc; check by walking cow route'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">
          {{ 'Yard and Shed Entrance' | translate }}
        </h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_yard_entrance_at_least_as_wide_as_the_race_leading_to_the_yard =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_yard_entrance_at_least_as_wide_as_the_race_leading_to_the_yard
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the yard entrance at least as wide as the race leading to the yard?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_a_4_inch_high_curb_installed_in_the_race_to_yard_entrance =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_there_a_4_inch_high_curb_installed_in_the_race_to_yard_entrance
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            evaluation.data.measurements === 'metric' ? 
             "Is there a 15 cm high curb installed in the race-to-yard entrance?"
            : "Is there a 6 inch high curb installed in the race-to-yard entrance?"  | translate
          }} 
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_a_rubber_mat_installed_in_the_yard_entrance =
          $event.value
      "
      :initial-value="
        assessor.data.is_a_rubber_mat_installed_in_the_yard_entrance
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is a rubber mat installed in the yard entrance?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_yard_bail_entrance_straight_lined = $event.value
      "
      :initial-value="assessor.data.is_the_yard_bail_entrance_straight_lined"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the yard-bail entrance straight lined?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_yard_bail_entrance_cow_sized_30_32_inches =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_yard_bail_entrance_cow_sized_30_32_inches
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            `${
              evaluation.data.measurements === 'metric'
                ? $t('Is the yard-bail entrance cow sized (76 – 81 cm)?')
                : $t('Is the yard-bail entrance cow sized (30-32 inches)?')
            }`
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_entrance_funneled_with_an_angle_less_than_45_and_3_4_meters_long =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_entrance_funneled_with_an_angle_less_than_45_and_3_4_meters_long
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            `${
              evaluation.data.measurements === 'metric'
                ? $t(
                    'Is the entrance funneled with an angle less than 45° and 3-4 meters long?'
                  )
                : $t(
                    'Is the entrance funneled with an angle less than 45° and 10-13 feet long?'
                  )
            }`
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_last_10_13_feet_of_surrounding_yard_fence_of_solid_material_55_inches_high =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_last_10_13_feet_of_surrounding_yard_fence_of_solid_material_55_inches_high
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            `${
              evaluation.data.measurements === 'metric'
                ? $t(
                    'Is the last 3-4 meters of surrounding yard fence made of solid material (140 cm high)?'
                  )
                : $t(
                    'Is the last 10-13 feet of surrounding yard fence made of solid material (55 inches high)?'
                  )
            }`
          }}
        </label>
      </div>
    </question-three>

    <hr v-if="assessor.data.milking_shed_type === 'rotary'" />

    <question-three
      v-if="assessor.data.milking_shed_type === 'rotary'"
      @change="
        assessor.data.rotary_only_is_the_anti_jam_gate_installed_on_the_entry_side =
          $event.value
      "
      :initial-value="
        assessor.data
          .rotary_only_is_the_anti_jam_gate_installed_on_the_entry_side
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t('Rotary only: Is the anti-jam gate installed on the entry side?')
          }}
        </label>
      </div>
    </question-three>

    <hr v-if="assessor.data.milking_shed_type === 'rotary'" />

    <question-three
      v-if="assessor.data.milking_shed_type === 'rotary'"
      @change="
        assessor.data.rotary_only_are_hinged_dividers_installed = $event.value
      "
      :initial-value="assessor.data.rotary_only_are_hinged_dividers_installed"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Rotary only: Are hinged dividers installed?') }}
        </label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Milking Platform' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.does_the_breastrail_miss_the_shoulder_joints =
          $event.value
      "
      :initial-value="
        assessor.data.does_the_breastrail_miss_the_shoulder_joints
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Does the breastrail miss the shoulder joints?')
        }}</label>
        <modal-info :value="'breastrail'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Does the breastrail miss the shoulder joints?' | translate }}
            </h2>
            <p class="px-3">
              {{
                `${
                  evaluation.data.measurements === 'metric'
                    ? 'Jerseys - 70 cm - shoulder, Friesians - 76 cm - shoulder'
                    : 'Jerseys - 28 inches - shoulder, Friesians - 30 inches - shoulder'
                }` | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.does_the_rumprail_sit_between_the_top_of_udder_and_bottom_of_the_vulva =
          $event.value
      "
      :initial-value="
        assessor.data
          .does_the_rumprail_sit_between_the_top_of_udder_and_bottom_of_the_vulva
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Does the rumprail sit between the top of udder and bottom of the vulva?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <div class="row">
      <div class="col-md-12 col-lg-11 col-xl-13 d-lg-flex align-self-center">
        <div class="mb-1">
          <label class="label d-inline">{{ $t('Rumprails?') }}</label>
          <modal-info :value="'rumprails'" class="d-inline">
            <template slot="modal-content">
              <h2 class="h2 pb-2 pt-3 px-3 text-center">
                {{ 'Rumprails?' | translate }}
              </h2>
              <p class="px-3">
                {{
                  `Rumprail between top of udder and bottom of the vulva should be approximately ${
                    evaluation.data.measurements === 'metric'
                      ? '97cm and 84cm'
                      : '38 and 33 inches'
                  } (FR/J).` | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </div>
      </div>
      <div
        class="col-md-12 col-lg-13 col-xl-11 d-flex flex-row flex-lg-row justify-content-center justify-content-md-end flex-md-wrap flex-lg-nowrap"
      >
        <div
          class="label_group label_group--width-sm label_group--inline mr-2 mr-sm-2 ml-0"
        >
          <input
            class="label_group__input"
            v-model="assessor.data.rumprails"
            type="radio"
            id="straight"
            value="straight"
          />
          <label class="label_group__label" for="straight">
            {{ 'Straight' | translate }}
          </label>
        </div>
        <div class="label_group label_group--width-sm label_group--inline">
          <input
            class="label_group__input"
            v-model="assessor.data.rumprails"
            type="radio"
            id="zigzag"
            value="zigzag"
          />
          <label class="label_group__label" for="zigzag">
            {{ 'Zigzag' | translate }}
          </label>
        </div>
      </div>
    </div>

    <hr />
    <div v-if="assessor.data.rumprails === 'straight'">
      <question-three
        @change="assessor.data.are_rumprails_set_in_by_25_50_mm = $event.value"
        :initial-value="assessor.data.are_rumprails_set_in_by_25_50_mm"
      >
        <div class="mb-1">
          <label class="label d-inline">
            {{
              $t(
                `Are rumprails set in by ${
                  evaluation.data.measurements.toLowerCase() === 'metric'
                    ? '25-50 mm'
                    : '1-2 inches'
                }?`
              )
            }}
          </label>
        </div>
      </question-three>
      <hr />
    </div>

    <div v-else>
      <question-three
        @change="
          assessor.data.are_the_inside_corners_of_the_rumprails_over_the_pit_edge =
            $event.value
        "
        :initial-value="
          assessor.data
            .are_the_inside_corners_of_the_rumprails_over_the_pit_edge
        "
      >
        <div class="mb-1">
          <label class="label d-inline">{{
            $t('Are the inside corners of the rumprails over the pit edge?')
          }}</label>
        </div>
      </question-three>
      <hr />
    </div>

    <question-three
      @change="
        assessor.data.do_the_cows_fit_comfortably_into_the_bail = $event.value
      "
      :initial-value="assessor.data.do_the_cows_fit_comfortably_into_the_bail"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Do the cows fit comfortably into the bail?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.do_cows_fit_well_into_the_first_bail = $event.value
      "
      :initial-value="assessor.data.do_cows_fit_well_into_the_first_bail"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Do cows fit well into the first bail?')
        }}</label>
      </div>
    </question-three>
    <hr />

    <question-three
      @change="assessor.data.is_an_anti_jumpbar_mounted = $event.value"
      :initial-value="assessor.data.is_an_anti_jumpbar_mounted"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is an anti-jumpbar mounted?')
        }}</label>
      </div>
    </question-three>
    <hr />

    <question-three
      @change="
        assessor.data.is_the_anti_jumpbar_in_the_correct_location = $event.value
      "
      :initial-value="assessor.data.is_the_anti_jumpbar_in_the_correct_location"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the anti-jumpbar in the correct location?')
        }}</label>
        <modal-info :value="'anti-jumpbar'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Is the anti-jumpbar in the correct location?' | translate }}
            </h2>
            <p class="px-3">
              {{
                `The anti-jumpbar should be hung directly over the breastrail at ${
                  evaluation.data.measurements === 'metric'
                    ? '1350-1400 mm'
                    : '53-55 inches'
                } high. If an adjustable breastrail is used, the anti-jumpbar should be mounted ${
                  evaluation.data.measurements === 'metric'
                    ? '200 mm'
                    : '8 inches'
                } inside the breastrail.` | translate
              }}
            </p>

            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_a_steel_bar_or_concrete_curb_along_the_pit_edge =
          $event.value
      "
      :initial-value="
        assessor.data.is_a_steel_bar_or_concrete_curb_along_the_pit_edge
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is a steel bar or concrete curb along the pit edge?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_surface_made_of_non_slip_material = $event.value
      "
      :initial-value="assessor.data.is_the_surface_made_of_non_slip_material"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the surface made of non-slip material?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_an_excessive_amount_of_dung_in_the_milking_shed =
          $event.value
      "
      :initial-value="
        assessor.data.is_there_an_excessive_amount_of_dung_in_the_milking_shed
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is there an excessive amount of dung in the milking shed?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_surface_kept_clean_free_of_stones_and_debris_during_milking =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_surface_kept_clean_free_of_stones_and_debris_during_milking
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the surface kept clean, free of stones and debris during milking?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">
          {{ 'Platform Exit/Exit Race' | translate }}
        </h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.exit_race_is_the_surface_made_of_non_slip_material =
          $event.value
      "
      :initial-value="
        assessor.data.exit_race_is_the_surface_made_of_non_slip_material
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the surface made of non-slip material?')
        }}</label>
      </div>
    </question-three>

    <hr v-if="assessor.data.milking_shed_type === 'herringbone'" />
    <question-three
      v-if="assessor.data.milking_shed_type === 'herringbone'"
      @change="
        assessor.data.herringbone_do_cows_exit_in_a_straight_line_from_bails =
          $event.value
      "
      :initial-value="
        assessor.data.herringbone_do_cows_exit_in_a_straight_line_from_bails
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Herringbone: Do cows exit in a straight line from bails?')
        }}</label>
      </div>
    </question-three>

    <hr v-if="assessor.data.milking_shed_type === 'herringbone'" />
    <question-three
      v-if="assessor.data.milking_shed_type === 'herringbone'"
      @change="
        assessor.data.herringbone_can_the_exit_race_contain_a_full_row_of_cows =
          $event.value
      "
      :initial-value="
        assessor.data.herringbone_can_the_exit_race_contain_a_full_row_of_cows
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Herringbone: Can the exit race contain a full row of cows?')
        }}</label>
      </div>
    </question-three>

    <hr v-if="assessor.data.milking_shed_type === 'rotary'" />
    <question-three
      v-if="assessor.data.milking_shed_type === 'rotary'"
      @change="
        assessor.data.rotary_is_a_rubber_mat_installed_at_the_cows_turning_point =
          $event.value
      "
      :initial-value="
        assessor.data.rotary_is_a_rubber_mat_installed_at_the_cows_turning_point
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Rotary: Is a rubber mat installed at the cows turning point?')
        }}</label>
      </div>
    </question-three>

    <hr v-if="assessor.data.milking_shed_type === 'rotary'" />
    <question-three
      v-if="assessor.data.milking_shed_type === 'rotary'"
      @change="
        assessor.data.rotary_do_cows_turn_freely_without_bending_their_backbone_or_neck =
          $event.value
      "
      :initial-value="
        assessor.data
          .rotary_do_cows_turn_freely_without_bending_their_backbone_or_neck
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t(
            'Rotary: Do cows turn freely without bending their backbone or neck?'
          )
        }}</label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Slopes/Steps' | translate }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-24">
        <div class="row">
          <div class="col-lg-8">
            <label class="mb-1">{{ $t('Approximate yard slope') }}</label>
            <input
              :disabled="(assessor.data.drop || assessor.data.length) > 0"
              v-model="assessor.data.approximate_yard_slope"
              type="text"
              class="form-control"
              @keypress="$isRatio"
            />
          </div>
          <div class="col-lg-8">
            <label class="mb-1">{{ $t('Drop') }}</label>
            <currency-input
            :disabled="
                assessor.data.approximate_yard_slope !== null &&
                  assessor.data.approximate_yard_slope.length > 0
              "                 
              v-model="assessor.data.drop"
              :options="{
                currency: 'USD',
                currencyDisplay: 'hidden',
                precision: {min: 0, max :2},
              }"
            />
          </div>
          <div class="col-lg-8">
            <label class="mb-1">{{ $t('Length') }}</label>
            <currency-input
            :disabled="
                assessor.data.approximate_yard_slope !== null &&
                  assessor.data.approximate_yard_slope.length > 0
              "               
              v-model="assessor.data.length"
              :options="{
                currency: 'USD',
                currencyDisplay: 'hidden',
                precision: {min: 0, max :2},
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />

    <question-three
      @change="assessor.data.are_there_cow_ramps = $event.value"
      :initial-value="assessor.data.are_there_cow_ramps"
    >
      <div class="mb-1">
        <label class="label d-inline">{{ $t('Are there cow ramps?') }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.are_there_steps = $event.value"
      :initial-value="assessor.data.are_there_steps"
    >
      <div class="mb-1">
        <label class="label d-inline">{{ $t('Are there steps?') }}</label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">
          {{ 'Concrete Composition, Surface' | translate }}
        </h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_surface_rough_with_a_pronounced_aggregate =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_surface_rough_with_a_pronounced_aggregate
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the surface rough with a pronounced aggregate?')
        }}</label>
        <modal-info
          value="is_the_surface_rough_with_a_pronounced_aggregate"
          class="d-inline"
        >
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{
                'Is the surface rough with a pronounced aggregate?' | translate
              }}
            </h2>
            <p class="px-3">
              {{
                'Rub your knuckles over the surface. Do you lose skin? Is it painful?'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ 'Cancel' | translate }}
              </a>
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.is_the_surface_slippery = $event.value"
      :initial-value="assessor.data.is_the_surface_slippery"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the surface slippery?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.is_algae_present_on_any_surface = $event.value"
      :initial-value="assessor.data.is_algae_present_on_any_surface"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is algae present on any surface?')
        }}</label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Cleanliness' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_yard_clean_when_not_in_use_especially_free_of_stones =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_yard_clean_when_not_in_use_especially_free_of_stones
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t('Is the yard clean when not in use (especially free of stones)?')
          }}
        </label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Feedbins in Shed' | translate }}</h2>
      </div>
    </div>

    <hr />
    <question-three
      @change="assessor.data.are_cows_fed_in_the_shed = $event.value"
      :initial-value="assessor.data.are_cows_fed_in_the_shed"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are cows fed in the shed?')
        }}</label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Bulls/Dogs/Social' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.are_bulls_kept_out_of_the_yard_at_all_times = $event.value
      "
      :initial-value="assessor.data.are_bulls_kept_out_of_the_yard_at_all_times"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are bulls kept out of the yard at all times?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.are_any_biting_dogs_present = $event.value"
      :initial-value="assessor.data.are_any_biting_dogs_present"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are any biting dogs present?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_non_biting_dogs_kept_at_a_no_flight_distance_from_cow =
          $event.value
      "
      :initial-value="
        assessor.data.are_non_biting_dogs_kept_at_a_no_flight_distance_from_cow
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t('Are non-biting dogs kept at a no-flight distance from cows?')
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.do_cows_have_low_flight_distance = $event.value"
      :initial-value="assessor.data.do_cows_have_low_flight_distance"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Do cows have a low flight distance?')
        }}</label>
        <modal-info value="do_cows_have_low_flight_distance" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Do cows have a low flight distance?' | translate }}
            </h2>
            <p class="px-3">
              {{ 'In the yard' | translate }}
              {{
                convert(2.3)
                  .from('m')
                  .to($getEquivalentUnit(evaluation.data.measurements, 'm'))
                  .toFixed(1)
              }}
              {{
                $getEquivalentUnit(evaluation.data.measurements, 'meters')
                  | translate
              }};
              {{ 'in paddock' | translate }}
              {{
                convert(3.2)
                  .from('m')
                  .to($getEquivalentUnit(evaluation.data.measurements, 'm'))
                  .toFixed(1)
              }}
              {{
                $getEquivalentUnit(evaluation.data.measurements, 'meters')
                  | translate
              }}
              {{ 'for low fear herds. Average is' | translate }}
              {{
                convert(4.4)
                  .from('m')
                  .to($getEquivalentUnit(evaluation.data.measurements, 'm'))
                  .toFixed(1)
              }}-{{
                parseFloat(
                  convert(5.3)
                    .from('m')
                    .to($getEquivalentUnit(evaluation.data.measurements, 'm'))
                ).toFixed(1)
              }}
              {{
                $getEquivalentUnit(evaluation.data.measurements, 'meters')
                  | translate
              }}
              {{ 'with a high of' | translate }}
              {{
                parseFloat(
                  convert(5.7)
                    .from('m')
                    .to($getEquivalentUnit(evaluation.data.measurements, 'm'))
                ).toFixed(1)
              }}-{{
                parseFloat(
                  convert(12)
                    .from('m')
                    .to($getEquivalentUnit(evaluation.data.measurements, 'm'))
                ).toFixed(0)
              }}
              {{
                $getEquivalentUnit(evaluation.data.measurements, 'meters')
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_young_cows_separated_from_mature_cows = $event.value
      "
      :initial-value="assessor.data.are_young_cows_separated_from_mature_cows"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are young cows separated from mature cows?')
        }}</label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Milker Behavior' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="assessor.data.does_the_milker_hit_the_cows = $event.value"
      :initial-value="assessor.data.does_the_milker_hit_the_cows"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Does the milker hit the cows?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.does_the_milker_make_sudden_unexpected_movements =
          $event.value
      "
      :initial-value="
        assessor.data.does_the_milker_make_sudden_unexpected_movements
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Does the milker make sudden, unexpected movements?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.are_there_any_sudden_noises = $event.value"
      :initial-value="assessor.data.are_there_any_sudden_noises"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t('Are there any sudden noises (voice, radio, equipment, etc.)?')
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.does_the_milker_leave_the_shed_to_get_cows = $event.value
      "
      :initial-value="assessor.data.does_the_milker_leave_the_shed_to_get_cows"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Does the milker leave the shed to get cows?')
        }}</label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Light' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_shed_well_lit_with_roof_panels_windows_and_or_an_artificial_light_source =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_shed_well_lit_with_roof_panels_windows_and_or_an_artificial_light_source
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the shed well lit with roof panels, windows and/or an artificial light source?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_exit_path_poorly_lit_or_shaded_by_a_wall_shrubs_or_other_objects =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_exit_path_poorly_lit_or_shaded_by_a_wall_shrubs_or_other_objects
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the exit path poorly lit or shaded by a wall, shrubs or other objects?'
            )
          }}
        </label>
      </div>
    </question-three>
    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">{{ 'Stray Voltage' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_fence_unit_switched_off_during_milking =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_fence_unit_switched_off_during_milking
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the fence unit switched off during milking?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.the_shed_has_been_checked_for_stray_voltage_by_a_competent_operator_and_remedied_where_necessary =
          $event.value
      "
      :initial-value="
        assessor.data
          .the_shed_has_been_checked_for_stray_voltage_by_a_competent_operator_and_remedied_where_necessary
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'The shed has been checked for stray voltage by a competent operator and remedied where necessary?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_there_any_signs_of_cows_that_are_reluctant_to_enter_certain_areas =
          $event.value
      "
      :initial-value="
        assessor.data
          .are_there_any_signs_of_cows_that_are_reluctant_to_enter_certain_areas
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Are there any signs of cows that are reluctant to enter certain areas?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24 mt-4">
        <h2 class="h2 d-inline-block">
          {{ 'Backing Gate/Top Gate' | translate }}
        </h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="assessor.data.is_the_backing_gate_electrified = $event.value"
      :initial-value="assessor.data.is_the_backing_gate_electrified"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the backing gate electrified?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_backing_gate_used_to_fill_up_space_behind_the_cows_it_is_not_pushing_on_them_and_the_cows_are_not_leaning_back =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_backing_gate_used_to_fill_up_space_behind_the_cows_it_is_not_pushing_on_them_and_the_cows_are_not_leaning_back
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the backing gate used to fill up space behind the cows? It is not pushing on them, and the cows are not leaning back?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.is_the_backing_gate_speed_excessive = $event.value"
      :initial-value="assessor.data.is_the_backing_gate_speed_excessive"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the backing gate speed excessive?')
        }}</label>
        <modal-info
          value="is_the_backing_gate_speed_excessive"
          class="d-inline"
        >
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Is the backing gate speed excessive?' | translate }}
            </h2>
            <p class="px-3">
              {{
                'Does the gate move too fast so that cows can not move forward and out of the way before being hit?'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_backing_gate_used_in_the_first_15_minutes_of_milking =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_backing_gate_used_in_the_first_15_minutes_of_milking
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t('Is the backing gate used in the first 15 minutes of milking?')
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.are_the_cows_heads_up = $event.value"
      :initial-value="assessor.data.are_the_cows_heads_up"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Are the cows’ heads up?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_buzzer_bell_working_in_conjunction_with_backing_top_gate =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_buzzer_bell_working_in_conjunction_with_backing_top_gate
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the buzzer/bell working in conjunction with backing/top gate?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_top_gate_used_to_bring_up_subgroups_of_cows =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_top_gate_used_to_bring_up_subgroups_of_cows
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Is the top gate used to bring up subgroups of cows?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="assessor.data.do_cows_escape_through_top_gate = $event.value"
      :initial-value="assessor.data.do_cows_escape_through_top_gate"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t('Do cows escape through top gate?')
        }}</label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.do_milkers_have_an_excellent_view_of_the_yard_from_the_platform_enabling_milkers_to_be_aware_of_cow_behavior_and_positions_of_the_backing_and_top_gates =
          $event.value
      "
      :initial-value="
        assessor.data
          .do_milkers_have_an_excellent_view_of_the_yard_from_the_platform_enabling_milkers_to_be_aware_of_cow_behavior_and_positions_of_the_backing_and_top_gates
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Do milkers have an excellent view of the yard from the platform, enabling milkers to be aware of cow behavior and positions of the backing and top gates?'
            )
          }}
        </label>
      </div>
    </question-three>

    <div class="row">
      <div class="col-lg-24">
        <hr />
      </div>
    </div>
    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>
    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="20" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-24">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
          >{{ 'Reports' | translate }}</router-link
        >
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>
    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import QuestionThree from './assessor_components/question-three';
import ModalInfo from '@/components/misc/modal-info.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import HoldingYardMilkShed from '@/libs/Classes/HoldingYardMilkShed.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import requestReportMixin from '@/mixins/requestReport.js';
import CurrencyInput from "@/components/common/CurrencyInput.vue";


export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    QuestionThree,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    CurrencyInput
  },
  mixins: [requestReportMixin],
  props: ['assessor', 'group', 'evaluation'],
  data() {
    return {
      holdingYardMilkShed: null,
    };
  },
  methods: {
    convert,
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, evaluationClass, this.group);
    this.holdingYardMilkShed = new HoldingYardMilkShed(
      this,
      evaluationClass,
      groupClass,
      this.assessor
    );
  },
};
</script>
