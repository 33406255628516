import convert from 'convert-units';
import { comparativeValues, transitionConstants } from '../../../Classes/constants/transition.js';

import { create, createRowHeading, createRowValues, createComparedRow, colors, comparedModes } from '@/libs/ReportPptx/Misc/Table';

import { filterGroupsByFieldValue, filterGroupsByType } from '@/mixins/animal.mixin.js';
import { changeToMilesPerHr } from '@/libs/Classes/utils.js';

export const group = (app, report, evaluation, scoringType) => {
  let assessorType = '19';
  let assessorData = {};
  let groups = [];

  const selectedGroups = evaluation.getGroups().filter(item => {
    item = item.group.id;
    return evaluation.getReport().groups.includes(item);
  });

  for (let i = 0; i < selectedGroups.length; i++) {
    let group = selectedGroups[i];
    let transition = group.getAssessors().find(assessor => {
      if (assessor) {
        return assessor.getType() === assessorType;
      }
    });

    if (transition !== undefined && transition.isStarted()) {
      groups.push(group);
    }
  }

  // if there are is less than 2 groups with transitions, do not add this slide
  // if (groups.length < 1) return false;

  let slide = report.pptx.addNewSlide('Slide master');

  slide.addText(app.$t('Transition Assessor'), report.styleOptions.slideHeader);

  slide.addText(app.$t('Facilities & Management'), {
    x: 0.5,
    y: 2.38,
    ...report.styleOptions.subheaderBlue,
  });

  let tableBody = [];
  let tableHeader = [
    {
      text: app.$t('Estimated Risk'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t('High Risk'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t('Moderate'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t('Low Risk'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t('Comments'),
      options: report.styleOptions.tableHeader,
    },
  ];
  let rowData = [];

  // Bedded Pack Area
  let beddedPackHeadings = [
    app.$t('Bedded Pack Area'),
    app.$t('< 65 sq ft (6m²) /') + app.$t('cow'),
    app.$t('65 sq ft (6m²) – 120 sq ft (11m²)/') + app.$t('cow'),
    app.$t('> 120 sq ft (11m²) /') + app.$t('cow'),
    '',
  ];
  tableBody.push(createRowHeading(beddedPackHeadings));

  groups.forEach(group => {
    assessorData = group.getAssessorByType(assessorType);

    let fieldValue = assessorData.getDataField('lying_space');
    let value = fieldValue / group.getGroupSize();

    const values = [
      {
        text: group.getName(),
      },
      ...createComparedRow('lying_space', value),
      {},
    ];

    tableBody.push(createRowValues(values));
  });

  // Drinking Space
  tableBody.push([
    {
      text: app.$t('Drinking Space'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('< 3.2” (8cm)/') + app.$t('cow'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('3.2” - 4” (8-10cm)/') + app.$t('cow'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('> 4” (10cm)/') + app.$t('cow'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    let isDrinkingSpaceAtHighRisk =
      assessorData.getDrinkingSpacePerCow() <=
      convert(transitionConstants['feed_drink_space_min'])
        .from('cm')
        .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm'));
    let isDrinkingSpaceAtModerateRisk =
      assessorData.getDrinkingSpacePerCow() >
        convert(transitionConstants['feed_drink_space_min'])
          .from('cm')
          .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) &&
      assessorData.getDrinkingSpacePerCow() <
        convert(transitionConstants['feed_drink_space_max'])
          .from('cm')
          .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm'));
    let isDrinkingSpaceAtLowRisk =
      assessorData.getDrinkingSpacePerCow() >=
      convert(transitionConstants['feed_drink_space_max'])
        .from('cm')
        .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm'));

    rowData = [
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          bold: false,
        },
      },
      {
        text: isDrinkingSpaceAtHighRisk ? app.$numberFormat(assessorData.getDrinkingSpacePerCow()) + app.$t(` ${app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')}/cow`) : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: isDrinkingSpaceAtHighRisk ? 'CC0000' : 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: true,
        },
      },
      {
        text: isDrinkingSpaceAtModerateRisk ? app.$numberFormat(assessorData.getDrinkingSpacePerCow()) + app.$t(` ${app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')}/cow`) : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: isDrinkingSpaceAtModerateRisk ? 'FFCC66' : 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: true,
        },
      },
      {
        text: isDrinkingSpaceAtLowRisk ? app.$numberFormat(assessorData.getDrinkingSpacePerCow()) + app.$t(` ${app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')}/cow`) : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: isDrinkingSpaceAtLowRisk ? '00cc00' : 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: true,
        },
      },
    ];
    if (i === 0) {
      rowData.push({
        text: '', // app.$t('Evaluation is based on calculated max capacity of 22 cows for far-off dry and 8 cows for close-up pen.'),
        options: {
          rowspan: groups.length,
          color: '000000',
          fill: 'FFFFFF',
          bold: false,
        },
      });
    }
    tableBody.push(rowData);
  }

  // Feeding Space
  tableBody.push([
    {
      text: app.$t('Feeding Space'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('≤ 18" (46 cm)/') + app.$t('cow'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('>18” (46 cm)/')+ app.$t('cow') + app.$t(' – <30” (75 cm)/') + app.$t('cow'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('≥30" (75cm)/')+ app.$t('cow'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);

  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    let isFeedingSpaceAtHighRisk =
      assessorData.getFeedingSpacePerCow() <=
      convert(transitionConstants['feed_bunk_space_min'])
        .from('cm')
        .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm'));
    let isFeedingSpaceAtModerateRisk =
      assessorData.getFeedingSpacePerCow() >
        convert(transitionConstants['feed_bunk_space_min'])
          .from('cm')
          .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) &&
      assessorData.getFeedingSpacePerCow() <
        convert(transitionConstants['feed_bunk_space_max'])
          .from('cm')
          .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm'));
    let isFeedingSpaceAtLowRisk =
      assessorData.getFeedingSpacePerCow() >=
      convert(transitionConstants['feed_bunk_space_max'])
        .from('cm')
        .to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm'));

    rowData = [
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          bold: false,
        },
      },
      {
        text: isFeedingSpaceAtHighRisk ? app.$numberFormat(assessorData.getFeedingSpacePerCow()) + app.$t(` ${app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')}/cow`) : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: isFeedingSpaceAtHighRisk ? 'CC0000' : 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: true,
        },
      },
      {
        text: isFeedingSpaceAtModerateRisk ? app.$numberFormat(assessorData.getFeedingSpacePerCow()) + app.$t(` ${app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')}/cow`) : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: isFeedingSpaceAtModerateRisk ? 'FFCC66' : 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: true,
        },
      },
      {
        text: isFeedingSpaceAtLowRisk ? app.$numberFormat(assessorData.getFeedingSpacePerCow()) + app.$t(` ${app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')}/cow`) : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: isFeedingSpaceAtLowRisk ? '00cc00' : 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: true,
        },
      },
    ];
    if (i === 0) {
      rowData.push({
        text: '', // app.$t('Evaluation is based on calculated max capacity of 22 cows for far-off dry and 8 cows for close-up pen.'),
        options: {
          rowspan: groups.length,
          color: '000000',
          fill: 'FFFFFF',
          bold: false,
        },
      });
    }
    tableBody.push(rowData);
  }

  // Feeding Space Divider Type
  tableBody.push([
    {
      text: app.$t('Feeding Space Divider Type'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Headlocks'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Slant Bar Dividers'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Feed Rail'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('bunk_divider_type', 'cm', 'select'),
      { text: '' },
    ]);
  }

  const filteredByMaternityPen = filterGroupsByFieldValue(groups, '19', 'is_maternity_pen', 'yes');

  if (filteredByMaternityPen.length) {
    // Maternity Undisturbed?
    let maternityHeadings = ['Maternity Undisturbed?', 'No', '', 'Yes', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(maternityHeadings));

    filteredByMaternityPen.forEach(group => {
      assessorData = group.getAssessorByType(assessorType);

      const values = [
        {
          text: group.getName(),
          options: {
            color: '000000',
            fill: 'FFFFFF',
          },
        },
        {
          text: assessorData.getDataField('maternity_undisturbed') === 'no' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: assessorData.getDataField('maternity_undisturbed') === 'no' ? 'CC0000' : 'FFFFFF',
          },
        },
        {},
        {
          text: assessorData.getDataField('maternity_undisturbed') === 'yes' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: assessorData.getDataField('maternity_undisturbed') === 'yes' ? '00cc00' : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });

    // Are Cows Hydrated Immediately Post Calving?
    let hydratedHeadings = ['Are Cows Hydrated Immediately Post Calving?', 'No', '', 'Yes', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(hydratedHeadings));

    filteredByMaternityPen.forEach(group => {
      assessorData = group.getAssessorByType(assessorType);

      const values = [
        {
          text: group.getName(),
          options: {
            color: '000000',
            fill: 'FFFFFF',
          },
        },
        {
          text: assessorData.getDataField('cows_hydrated_immediately') === 'no' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: assessorData.getDataField('cows_hydrated_immediately') === 'no' ? 'CC0000' : 'FFFFFF',
          },
        },
        {},
        {
          text: assessorData.getDataField('cows_hydrated_immediately') === 'yes' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: assessorData.getDataField('cows_hydrated_immediately') === 'yes' ? '00cc00' : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });

    // Feed Available in Maternity Pen?
    let feedHeadings = ['Feed Available in Maternity Pen?', 'No', '', 'Yes', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(feedHeadings));

    filteredByMaternityPen.forEach(group => {
      assessorData = group.getAssessorByType(assessorType);

      const values = [
        {
          text: group.getName(),
          options: {
            color: '000000',
            fill: 'FFFFFF',
          },
        },
        {
          text: assessorData.getDataField('is_feed_available') === 'no' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: assessorData.getDataField('is_feed_available') === 'no' ? 'CC0000' : 'FFFFFF',
          },
        },
        {},
        {
          text: assessorData.getDataField('is_feed_available') === 'yes' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: assessorData.getDataField('is_feed_available') === 'yes' ? '00cc00' : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });
  }

  // Freestall Surface Cushion
  tableBody.push([
    {
      text: app.$t('Freestall Surface Cushion'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Packed or Firm Bed'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Cushioned Mattress with Thin Bedding'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Deep Loose Bedding >3"/8 cm Depth'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('freestall_surface_cushion', '', 'select', 'rtl'),
      { text: '' },
    ]);
  }

  // Freestall Occupation Area
  tableBody.push([
    {
      text: app.$t('Freestall Occupation Area'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Significant Overlap in Imprint Area (>3"/8 cm)'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Small Overlap in Imprint Area (1-3"/3-8 cm)'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('No Overlap of Imprint Area with Neighbor'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('freestall_occupation_area', '', 'select', 'rtl'),
      { text: '' },
    ]);
  }

  // Freestall - Lunge Obstruction
  tableBody.push([
    {
      text: app.$t('Freestall Lunge Obstruction'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Front and side lunge obstruction'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Front Lunge Obstruction Only'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('No Front or Side Lunge Obstruction'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('freestalls_lunge_obstruction', '', 'select', 'rtl'),
      { text: '' },
    ]);
  }

  // Heat Abatement
  tableBody.push([
    {
      text: app.$t('Heat Abatement - Freestall'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('No Fans or Water Cooling'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Fans Over Stalls, No Water Cooling'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Fans Over Freestalls, Water Cooling'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('heat_abatement', '', 'select', 'rtl'),
      { text: '' },
    ]);
  }

  // Avg Air Speed at Cow Level
  let avgAirAtCowLevelHeadings = [
    app.$t('Avg Air Speed at Cow Level'),
    app.$t(`No ventilation available or`) +
      comparativeValues.airSpeedAllCows(
        'less-5',
        evaluation.getMeasurements()
      ) +
      app.$t(
        changeToMilesPerHr(
          app.$getEquivalentUnit(evaluation.getMeasurements(), 'km/h')
        )
      ),
    app.$t(
      comparativeValues.airSpeedAllCows('5-8', evaluation.getMeasurements())
    ) +
      app.$t(
        changeToMilesPerHr(
          app.$getEquivalentUnit(evaluation.getMeasurements(), 'km/h')
        )
      ),
    app.$t(
      comparativeValues.airSpeedAllCows('more-5', evaluation.getMeasurements())
    ) +
      app.$t(
        changeToMilesPerHr(
          app.$getEquivalentUnit(evaluation.getMeasurements(), 'km/h')
        )
      ),
    '',
  ];
  tableBody.push(createRowHeading(avgAirAtCowLevelHeadings));

  groups.forEach(group => {
    assessorData = group.getAssessorByType(assessorType);
    let fieldValue = assessorData.getDataField('air_speed_all_cows');

    const values = [
      {
        text: group.getName(),
      },
      ...createComparedRow('air_speed_all_cows', fieldValue),
      {},
    ];

    tableBody.push(createRowValues(values));
  });

  // Avg Air Volume per Cow
  let avgAirVolumePerCowHeadings = [
    app.$t('Avg Air Volume per Cow'),
    app.$t(`No ventilation available or`) +
      comparativeValues.airSpeedCow(
        'less-300',
        evaluation.getMeasurements(),
        app.$t
      ),
    app.$t(
      comparativeValues.airSpeedCow(
        '300-1000',
        evaluation.getMeasurements(),
        app.$t
      )
    ),
    app.$t(
      comparativeValues.airSpeedCow(
        'more-1000',
        evaluation.getMeasurements(),
        app.$t
      )
    ),
    '',
  ];
  tableBody.push(createRowHeading(avgAirVolumePerCowHeadings));

  groups.forEach(group => {
    assessorData = group.getAssessorByType(assessorType);
    let fieldValue = assessorData.getDataField('air_speed_cow');

    const values = [
      {
        text: group.getName(),
      },
      ...createComparedRow('air_speed_cow', fieldValue),
      {},
    ];

    tableBody.push(createRowValues(values));
  });

  // Are close-up heifers mixed with cows?
  let heifersMixedWithCowsHeadings = ['Are Close-Up Heifers Mixed with Cows?', 'Yes', '', 'No', ''].map(heading => app.$t(heading));
  tableBody.push(createRowHeading(heifersMixedWithCowsHeadings));

  const data = evaluation.evaluation.data;
  tableBody.push([
    {
      text: '',
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: data.close_up_heifers_mixed_with_cows === 'yes' ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.close_up_heifers_mixed_with_cows === 'yes' ? 'CC0000' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: data.close_up_heifers_mixed_with_cows === 'no' ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.close_up_heifers_mixed_with_cows === 'no' ? '00cc00' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  // }

  // How many moves into close-up pen?
  tableBody.push([
    {
      text: app.$t('Frequency Animals are Moved to Close-Up Pen'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Multiple Times per Week'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Weekly filling of group pen'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Cows remain with same group of cows >14 days'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  tableBody.push([
    {
      text: app.$t('Pen move(s) into close-up'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: evaluation.evaluation.data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_min'] ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: evaluation.evaluation.data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_min'] ? 'CC0000' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_mid'] ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_mid'] ? 'FFCC66' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_max'] ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_max'] ? '00cc00' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    { text: '' },
  ]);

  // Move into calving pen (if separate from pre-fresh
  if (data.move_into_calving_pen) {
    tableBody.push([
      {
        text: app.$t('When are Cows Moved into Calving Pen (If Separate from Close-Up)?'),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'dddddd',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: app.$t('3-7 Days Before Due Date'),
        options: {
          color: '000000',
          valign: 'center',
          align: 'center',
          fill: 'dddddd',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: app.$t(''),
        options: {
          color: '000000',
          valign: 'center',
          align: 'center',
          fill: 'dddddd',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: app.$t('< 2 Days or > 7 Days Before Due Date'),
        options: {
          color: '000000',
          valign: 'center',
          align: 'center',
          fill: 'dddddd',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: app.$t(''),
        options: {
          color: '000000',
          valign: 'center',
          align: 'center',
          fill: 'dddddd',
          fontFace: 'Arial',
          bold: true,
        },
      },
    ]);
    tableBody.push([
      {
        text: app.$t('Entry into calving pens'),
        options: {
          color: '000000',
          valign: 'center',
          align: 'center',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: data.move_into_calving_pen === transitionConstants['move_into_calving_pen_min'] ? 'X' : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: data.move_into_calving_pen === transitionConstants['move_into_calving_pen_min'] ? 'CC0000' : 'FFFFFF',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: data.move_into_calving_pen === transitionConstants['move_into_calving_pen_mid'] ? 'X' : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: data.move_into_calving_pen === transitionConstants['move_into_calving_pen_mid'] ? 'FFCC66' : 'FFFFFF',
          fontFace: 'Arial',
          bold: true,
        },
      },
      {
        text: data.move_into_calving_pen === transitionConstants['move_into_calving_pen_max'] ? 'X' : '',
        options: {
          color: 'FFFFFF',
          valign: 'center',
          align: 'center',
          fill: data.move_into_calving_pen === transitionConstants['move_into_calving_pen_max'] ? '00cc00' : 'FFFFFF',
          fontFace: 'Arial',
          bold: true,
        },
      },
      { text: '' },
    ]);
  }
  // How are Sick Cows Managed?
  tableBody.push([
    {
      text: app.$t('How are Sick Cows Managed?'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Mixed at All Times'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Segregated at Milking'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t('Segregated at All Times'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: app.$t(''),
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        bold: true,
      },
    },
  ]);
  tableBody.push([
    {
      text: app.$t(''),
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_min'] ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_min'] ? 'CC0000' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_mid'] ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_mid'] ? 'FFCC66' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    {
      text: data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_max'] ? 'X' : '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_max'] ? '00cc00' : 'FFFFFF',
        fontFace: 'Arial',
        bold: true,
      },
    },
    { text: '' },
  ]);

  // Dry Off Protocol
  let dryOffProtocolHeadings = ['Dry Off Protocol', 'Daily', 'Multiple Times per Week', 'Weekly', ''].map(heading => app.$t(heading));

  const values = [
    {
      text: app.$t('Frequency'),
    },
    ...createComparedRow('dry_off_protocol', data.dry_off_protocol),
    {},
  ];

  tableBody.push(createRowHeading(dryOffProtocolHeadings));
  tableBody.push(createRowValues(values));

  // Create table
  create(slide, tableHeader, tableBody, report, { app }, 'Transition Assessor', {
    ...report.styleOptions.tableOptions,
    y: 2.7,
    autoPageLineWeight: .5,
    colW: [1.8, 1.5, 1.5, 1.5, 1.2],
  });

  let slide2 = report.pptx.addNewSlide('Slide master');

  slide2.addText(app.$t('Transition Assessor'), report.styleOptions.slideHeader);

  slide2.addText(app.$t('Feeding & Body Condition'), {
    x: 0.5,
    y: 2.38,
    ...report.styleOptions.subheaderBlue,
  });

  tableBody = [];
  tableHeader = [
    {
      text: app.$t('Estimated Risk'),
      options: {
        ...report.styleOptions.tableHeader,
        align: 'left',
      },
    },
    {
      text: app.$t('High Risk'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t('Moderate'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t('Low Risk'),
      options: report.styleOptions.tableHeader,
    },
    {
      text: '',
      options: report.styleOptions.tableHeader,
    },
  ];

  // % Cows Rumen Fill < 3
  if (filterGroupsByType('fresh', groups).length) {
    let rumenFill3 = [app.$t('% Cows Rumen Fill < 3'), '> 25%', '15-25%', '< 15%', ''];
    tableBody.push(createRowHeading(rumenFill3));

    filterGroupsByType('fresh', groups).forEach(group => {
      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('cows_rumen_fill');

      const values = [
        {
          text: group.getName(),
        },
        ...createComparedRow('cows_rumen_fill', parseFloat(fieldValue), { reverse: true }),
        {},
      ];

      tableBody.push(createRowValues(values));
    });
  }

  groups.forEach(group => {
    assessorData = group.getAssessorByType(assessorType);
    let fieldValue = assessorData.getDataField('cows_rumen_fill');
    // console.log('cows_rumen_fill', fieldValue);
  });

  // % Cows Rumen Fill < 4
  if (groups.length - filterGroupsByType('fresh', groups).length) {
    let rumenFill4 = [app.$t('% Cows Rumen Fill < 4'), '> 25%', '15-25%', '< 15%', ''];
    tableBody.push(createRowHeading(rumenFill4));

    groups.forEach(group => {
      if (group.getTypeOfAnimal() === 'fresh') return;

      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('cows_rumen_fill');
      
      const values = [
        {
          text: group.getName(),
        },
        ...createComparedRow('cows_rumen_fill', parseFloat(fieldValue), { reverse: true }),
        {},
      ];

      tableBody.push(createRowValues(values));
    });
  }

  // % of Cows with Manure Score < 3
  let cowsWithManureHeadings = [app.$t('% of Cows with Manure Score < 3'), '> 25%', '15-25%', '< 15%', ''];
  tableBody.push(createRowHeading(cowsWithManureHeadings));

  groups.forEach(group => {
    assessorData = group.getAssessorByType(assessorType);
    let fieldValue = assessorData.getDataField('cows_manure_score'); 
  
    const values = [
      {
        text: group.getName(),
      },
      ...createComparedRow('cows_manure_score', parseFloat(fieldValue), { reverse: true }),
      {},
    ];

    tableBody.push(createRowValues(values));
  });

  // Body condition 1
  tableBody.push([
    {
      text: app.$t('% of Cows with BCS ≤2.5 or ≥3.75, 1st lact'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '>30%',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '30-10%',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '<10%',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('body_condition_score_1', '%', 'number'),
    ]);
  }

  // Body condition 2
  tableBody.push([
    {
      text: app.$t('% of Cows with BCS ≤2.5 or ≥3.75, ≥2nd lact'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '>30%',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '30-10%',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '<10%',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('body_condition_score_2', '%', 'number',),
    ]);
  }

  // Refusal %
  let refusalHeadings = ['Refusal %', '< 3%', '3-5%', '> 5%', ''].map(heading => app.$t(heading));
  tableBody.push(createRowHeading(refusalHeadings));

  groups.forEach(group => {
    assessorData = group.getAssessorByType(assessorType);
    let fieldValue = assessorData.getDataField('refusal');
    
    const values = [
      {
        text: group.getName(),
      },
      ...createComparedRow('refusal', parseFloat(fieldValue), { reverse: false, mode: comparedModes.M_EQUAL }),
      {},
    ];

    tableBody.push(createRowValues(values));
  });

  // TMR Sorting difference (% points)
  tableBody.push([
    {
      text: app.$t('TMR sorting difference (% points)'),
      options: {
        color: '000000',
        valign: 'center',
        align: 'left',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '≥10',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '5-9',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '≤5',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
    {
      text: '',
      options: {
        color: '000000',
        valign: 'center',
        align: 'center',
        fill: 'dddddd',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    },
  ]);
  for (let i = 0; i < groups.length; i++) {
    assessorData = groups[i].getAssessorByType(assessorType);
    tableBody.push([
      {
        text: groups[i].getName(),
        options: {
          color: '000000',
          valign: 'center',
          align: 'left',
          fill: 'FFFFFF',
          fontFace: 'Arial',
          margin: 5,
          bold: false,
        },
      },
      ...assessorData.generate3ValueRow_LEGACY('tmr_sorting', '', 'select', 'rtl'),
    ]);
  }

  // Screening Method Used
  if (filterGroupsByType('fresh', groups).length) {
    let screeningMethodUsedHeadings = [
      'Screening Method Used',
      'No Program',
      'Routine Temping, Multiple Staff',
      'Daily Milk wt, Rumination Activity',
      'Dedicated Staff, Appetite, Front & Rear Visual Exam',
    ].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(screeningMethodUsedHeadings));

    filterGroupsByType('fresh', groups).forEach(group => {
      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('screening_method');

      const values = [
        {
          text: group.getName(),
        },
        {
          text: fieldValue === transitionConstants['screening_method_min'] ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === transitionConstants['screening_method_min'] ? colors.red : 'FFFFFF',
          },
        },
        {
          text: fieldValue === transitionConstants['screening_method_mid'] ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === transitionConstants['screening_method_mid'] ? colors.orange : 'FFFFFF',
          },
        },
        {
          text: fieldValue === transitionConstants['screening_method_mid2'] ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === transitionConstants['screening_method_mid2'] ? colors.orange : 'FFFFFF',
          },
        },
        {
          text: fieldValue === transitionConstants['screening_method_max'] ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === transitionConstants['screening_method_max'] ? colors.green : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });

    // Time Spent Checking Fresh Cows (hr)
    let timeSpentCheckingFreshHeadings = ['Time Spent Checking Fresh Cows (hr)', '≥3 hrs', '1-3 hrs', '≤1 hr', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(timeSpentCheckingFreshHeadings));

    filterGroupsByType('fresh', groups).forEach(group => {
      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('time_checking_hr');

      const values = [
        {
          text: group.getName(),
        },
        ...createComparedRow('time_checking_hr', fieldValue),
        {},
      ];

      tableBody.push(createRowValues(values));
    });

    // Feed Available after Milking?
    let feedAvailableHeadings = ['Feed Available after Milking?', 'No', '', 'Yes', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(feedAvailableHeadings));

    filterGroupsByType('fresh', groups).forEach(group => {
      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('is_feed_available_af_milking');

      const values = [
        {
          text: group.getName(),
        },
        {
          text: fieldValue === 'no' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === 'no' ? colors.red : 'FFFFFF',
          },
        },
        {},
        {
          text: fieldValue === 'yes' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === 'yes' ? colors.green : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });

    // Checks Done After Milking?
    let checkAfMilkingHeadings = ['Checks Done After Milking?', 'No', '', 'Yes', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(checkAfMilkingHeadings));

    filterGroupsByType('fresh', groups).forEach(group => {
      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('checks_af_milking');

      const values = [
        {
          text: group.getName(),
        },
        {
          text: fieldValue === 'no' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === 'no' ? colors.red : 'FFFFFF',
          },
        },
        {},
        {
          text: fieldValue === 'yes' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === 'yes' ? colors.green : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });

    // Check Appetite?
    let checkAppetiteHeadings = ['Check Appetite?', 'No', '', 'Yes', ''].map(heading => app.$t(heading));
    tableBody.push(createRowHeading(checkAppetiteHeadings));

    filterGroupsByType('fresh', groups).forEach(group => {
      assessorData = group.getAssessorByType(assessorType);
      let fieldValue = assessorData.getDataField('is_appetite_checked');

      const values = [
        {
          text: group.getName(),
        },
        {
          text: fieldValue === 'no' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === 'no' ? colors.red : 'FFFFFF',
          },
        },
        {},
        {
          text: fieldValue === 'yes' ? 'X' : '',
          options: {
            color: 'FFFFFF',
            fill: fieldValue === 'yes' ? colors.green : 'FFFFFF',
          },
        },
        {},
      ];

      tableBody.push(createRowValues(values));
    });
  }

  create(slide2, tableHeader, tableBody, report, { app }, 'Transition Assessor', {
    ...report.styleOptions.tableOptions,
    y: 2.7,
    autoPageLineWeight: 0.6,
    colW: [1.8, 1.4, 1.4, 1.4, 1.4],
  });

  // tableBody = [];
  // // Screening method used
  // tableBody.push([
  //   {
  //     text: app.$t('Screening method used'),
  //     options: {
  //       color: '000000',
  //       valign: 'center',
  //       align: 'left',
  //       fill: 'dddddd',
  //       fontFace: 'Arial',
  //       margin: 5,
  //       bold: true,
  //     },
  //   },
  //   {
  //     text: app.$t('No program'),
  //     options: {
  //       color: '000000',
  //       valign: 'center',
  //       align: 'center',
  //       fill: 'dddddd',
  //       fontFace: 'Arial',
  //       margin: 5,
  //       bold: true,
  //     },
  //   },
  //   {
  //     text: app.$t('Routine temping, multiple staff'),
  //     options: {
  //       color: '000000',
  //       valign: 'center',
  //       align: 'center',
  //       fill: 'dddddd',
  //       fontFace: 'Arial',
  //       margin: 5,
  //       bold: true,
  //     },
  //   },
  //   {
  //     text: app.$t('Daily milk wt, rumination activity'),
  //     options: {
  //       color: '000000',
  //       valign: 'center',
  //       align: 'center',
  //       fill: 'dddddd',
  //       fontFace: 'Arial',
  //       margin: 5,
  //       bold: true,
  //     },
  //   },
  //   {
  //     text: app.$t('Dedicated staff, appetite, front & rear visual exam'),
  //     options: {
  //       color: '000000',
  //       valign: 'center',
  //       align: 'center',
  //       fill: 'dddddd',
  //       fontFace: 'Arial',
  //       margin: 5,
  //       bold: true,
  //     },
  //   },
  // ]);
  // for (let i = 0; i < groups.length; i++) {
  //   assessorData = groups[i].getAssessorByType(assessorType);
  //   tableBody.push([
  //     {
  //       text: groups[i].getName(),
  //       options: {
  //         color: '000000',
  //         valign: 'center',
  //         align: 'left',
  //         fill: 'FFFFFF',
  //         fontFace: 'Arial',
  //         margin: 5,
  //         bold: false,
  //       },
  //     },
  //     ...assessorData.generate4ValueRow('screening_method', '', 'select'),
  //   ]);
  // }

  // let slide3 = report.pptx.addNewSlide('Slide master');

  // slide3.addText(app.$t('Transition Assessor'), report.styleOptions.slideHeader);

  // create(slide3, null, tableBody, report, { app }, 'Transition Assessor', {
  //   ...report.styleOptions.tableOptions,
  //   y: 2.2,
  //   colW: [1.8, 1.4, 1.4, 1.4, 1.4],
  //   fontSize: 9.3,
  // });
};
