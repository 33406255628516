var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container main-container main-container--hygiene view"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(" "+_vm._s(_vm._f("translate")('Tell Us About Your Holding Area'))+" ")]),_c('modal-info',{staticClass:"d-inline",attrs:{"value":'tell-us'}},[_c('template',{slot:"modal-content"},[_c('h2',{staticClass:"h2 pb-2 pt-3 px-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("translate")('Holding Area'))+" ")]),_c('p',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm._f("translate")('For more information about Holding Areas, click the link to learn more from'))+" "),_c('a',{attrs:{"href":_vm.$t(
                  'https://library.zinprofirststep.com/holding-areas-freestalls/'
                ),"target":"_blank"},on:{"click":function($event){_vm.$goToLameness(_vm.$t('/holding-areas-freestalls/'), $event)}}},[_vm._v(_vm._s(_vm._f("translate")('FirstStep Resources')))]),_vm._v(". ")]),_c('div',{staticClass:"row modal-bottom pb-3"},[_c('a',{staticClass:"btn-cancel-modal d-flex justify-content-center",attrs:{"href":"#","data-dismiss":"modal"}},[_vm._v(_vm._s(_vm._f("translate")('Cancel')))])])])],2)],1)]),_c('hr',{staticClass:"mb-lg-4"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(_vm._s(_vm.$t('General')))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Holding Area Size') + ' (' + _vm.$t( _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'square meters')) ) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.converted_holding_area_size),callback:function ($$v) {_vm.converted_holding_area_size=$$v},expression:"converted_holding_area_size"}})],1)]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data
        .do_cows_spend_more_than_3_hours_day_on_average_in_the_holding_area},on:{"change":function($event){_vm.assessor.data.do_cows_spend_more_than_3_hours_day_on_average_in_the_holding_area =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Do cows spend more than 3 hours/day, on average, in the holding area?' ))+" ")])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data
        .are_first_lactation_heifers_mixed_with_mature_cows_in_the_holding_area},on:{"change":function($event){_vm.assessor.data.are_first_lactation_heifers_mixed_with_mature_cows_in_the_holding_area =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Are first lactation heifers mixed with mature cows in the holding area?' ))+" ")])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data.is_the_crowd_gate_used_appropriately},on:{"change":function($event){_vm.assessor.data.is_the_crowd_gate_used_appropriately = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Is the crowd gate used appropriately (used to fill up space behind cows, not used to push cows)?' ))+" ")])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data
        .is_a_large_proportion_more_than_30_of_cows_with_their_heads_up_rather_than_down_looking_at_the_ground},on:{"change":function($event){_vm.assessor.data.is_a_large_proportion_more_than_30_of_cows_with_their_heads_up_rather_than_down_looking_at_the_ground =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Is a large proportion (more than 30%) of cows with their heads up, rather than down looking at the ground?' ))+" ")])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data.is_the_holding_area_floor_excessively_sloped},on:{"change":function($event){_vm.assessor.data.is_the_holding_area_floor_excessively_sloped =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Is the holding area floor excessively sloped (greater than 2%)?' ))+" ")])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data
        .is_there_any_slipping_as_the_cows_move_between_each_other_that_may_traumatize_the_hoof},on:{"change":function($event){_vm.assessor.data.is_there_any_slipping_as_the_cows_move_between_each_other_that_may_traumatize_the_hoof =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Is there any slipping as the cows move between each other that may traumatize the hoof?' ))+" ")])])]),_c('hr',{staticClass:"mb-lg-4"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(_vm._s(_vm._f("translate")('Fans')))])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data.are_recirculating_fans_in_the_holding_area},on:{"change":function($event){_vm.assessor.data.are_recirculating_fans_in_the_holding_area = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Are recirculating fans in the holding area?'))+" ")])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.assessor.data.are_recirculating_fans_in_the_holding_area === 1),expression:"assessor.data.are_recirculating_fans_in_the_holding_area === 1"}],staticClass:"col-lg-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Temperature that the fans activate (°') + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'C')) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }},model:{value:(_vm.converted_temperature_that_the_fans_activate),callback:function ($$v) {_vm.converted_temperature_that_the_fans_activate=$$v},expression:"converted_temperature_that_the_fans_activate"}})],1),_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Group/Pen Size in Holding Area')))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }},model:{value:(_vm.assessor.data.group_pen_size_in_holding_area),callback:function ($$v) {_vm.$set(_vm.assessor.data, "group_pen_size_in_holding_area", $$v)},expression:"assessor.data.group_pen_size_in_holding_area"}})],1),(_vm.assessor.data.are_recirculating_fans_in_the_holding_area === 1)?[_c('Fans',{attrs:{"fan-width":_vm.converted_fan_width_1,"no-of-fan":"number_of_fans_1","assessor":_vm.assessor,"evaluation":_vm.evaluation},on:{"update:fanWidth":function($event){_vm.converted_fan_width_1=$event},"update:fan-width":function($event){_vm.converted_fan_width_1=$event}}}),_c('Fans',{attrs:{"fan-width":_vm.converted_fan_width_2,"no-of-fan":"number_of_fans_2","assessor":_vm.assessor,"evaluation":_vm.evaluation},on:{"update:fanWidth":function($event){_vm.converted_fan_width_2=$event},"update:fan-width":function($event){_vm.converted_fan_width_2=$event}}}),_c('Fans',{attrs:{"fan-width":_vm.converted_fan_width_3,"no-of-fan":"number_of_fans_3","assessor":_vm.assessor,"evaluation":_vm.evaluation},on:{"update:fanWidth":function($event){_vm.converted_fan_width_3=$event},"update:fan-width":function($event){_vm.converted_fan_width_3=$event}}}),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(`${_vm.$t('Total air movement capacity')} (${_vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'cmh'))}${_vm.$t('/cow')})`))]),_c('currency-input',{attrs:{"readonly":"","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }},model:{value:(_vm.computed_air_movement_capacity),callback:function ($$v) {_vm.computed_air_movement_capacity=$$v},expression:"computed_air_movement_capacity"}})],1)])]:_vm._e()],2),_c('hr',{staticClass:"mb-lg-4"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(_vm._s(_vm._f("translate")('Soakers/Misters')))])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data.are_soakers_misters_in_the_holding_area},on:{"change":function($event){_vm.assessor.data.are_soakers_misters_in_the_holding_area = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Are soakers/misters in the holding area?'))+" ")])])]),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.assessor.data.are_soakers_misters_in_the_holding_area === 1),expression:"assessor.data.are_soakers_misters_in_the_holding_area === 1"}]}),_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.assessor.data.are_soakers_misters_in_the_holding_area === 1),expression:"assessor.data.are_soakers_misters_in_the_holding_area === 1"}],staticClass:"col-lg-8"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Temperature that the soakers activate (°') + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'C')) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.converted_temperature_that_the_soakers_activate),callback:function ($$v) {_vm.converted_temperature_that_the_soakers_activate=$$v},expression:"converted_temperature_that_the_soakers_activate"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.assessor.data.are_soakers_misters_in_the_holding_area === 1),expression:"assessor.data.are_soakers_misters_in_the_holding_area === 1"}],staticClass:"col-lg-8"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Duration (minutes)?')))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.assessor.data.soakers_duration),callback:function ($$v) {_vm.$set(_vm.assessor.data, "soakers_duration", $$v)},expression:"assessor.data.soakers_duration"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.assessor.data.are_soakers_misters_in_the_holding_area === 1),expression:"assessor.data.are_soakers_misters_in_the_holding_area === 1"}],staticClass:"col-lg-8"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Frequency (minutes)?')))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.assessor.data.soakers_frequency),callback:function ($$v) {_vm.$set(_vm.assessor.data, "soakers_frequency", $$v)},expression:"assessor.data.soakers_frequency"}})],1)]),_c('hr',{staticClass:"mb-lg-4"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(_vm._s(_vm._f("translate")('Shade')))])])]),_c('hr'),_c('question-three',{attrs:{"initial-value":_vm.assessor.data.is_there_shade_over_the_holding_area},on:{"change":function($event){_vm.assessor.data.is_there_shade_over_the_holding_area = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Is there shade over the holding area?'))+" ")])])]),_vm._m(0),_c('div',{staticClass:"row my-0"},[_c('div',{staticClass:"col-md-12"},[_c('personal-notes',{model:{value:(_vm.assessor.data.personal_notes),callback:function ($$v) {_vm.$set(_vm.assessor.data, "personal_notes", $$v)},expression:"assessor.data.personal_notes"}})],1),_c('div',{staticClass:"col-md-12"},[_c('presentation-notes',{model:{value:(_vm.assessor.data.presentation_notes),callback:function ($$v) {_vm.$set(_vm.assessor.data, "presentation_notes", $$v)},expression:"assessor.data.presentation_notes"}})],1)]),_c('div',{staticClass:"row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"},[_c('photoUpload',{attrs:{"assessorId":11,"assessor":_vm.assessor}})],1),_c('hr',{staticClass:"mb-3 mb-md-5 mt-3 mt-md-5"}),_c('div',{staticClass:"row misc-bottom"},[_c('div',{staticClass:"col-md-24"},[(_vm.user && _vm.user.role !== 'data_entry')?_c('router-link',{staticClass:"btn btn-primary btn-full--sm pull-right",attrs:{"to":'/reports'}},[_vm._v(_vm._s(_vm._f("translate")('Reports')))]):(
          _vm.user && _vm.user.role === 'data_entry' && !_vm.$store.state.offline
        )?_c('button',{staticClass:"btn btn-primary btn-full--sm pull-right",attrs:{"data-toggle":"modal","data-target":['#requestSent-' + _vm.user.id]},on:{"click":function($event){return _vm.requestReport(_vm.evaluation.name)}}},[_vm._v(" "+_vm._s(_vm._f("translate")('Request Report'))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.requestReportLoader),expression:"requestReportLoader"}],staticClass:"loader ml-1"})]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.successMessage),expression:"successMessage"}],staticClass:"alert alert-primary mt-3 mb-0",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Your request has been sent!'))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],staticClass:"alert alert-danger mt-3 mb-0",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm._f("translate")('An error has occurred during your request!'))+" ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('hr')])])
}]

export { render, staticRenderFns }