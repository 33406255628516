var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-24"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Fan Width") + " (" + _vm.$t(_vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, "cm"))) + ")")+" "),(_vm.showModal)?[_c('modal-info',{staticClass:"d-inline",attrs:{"value":'base-layer'}},[_c('template',{slot:"modal-content"},[_c('h2',{staticClass:"h2 pb-2 pt-3 px-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("translate")("Number of Fans"))+" ")]),_c('p',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm._f("translate")("Enter up to three fan sizes and quantities below"))+" ")]),_c('div',{staticClass:"row modal-bottom pb-3"},[_c('a',{staticClass:"btn-cancel-modal d-flex justify-content-center",attrs:{"href":"#","data-dismiss":"modal"}},[_vm._v(_vm._s(_vm._f("translate")("Cancel")))])])])],2)]:_vm._e()],2),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
            
          }},model:{value:(_vm.localFanWidth),callback:function ($$v) {_vm.localFanWidth=$$v},expression:"localFanWidth"}})],1),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Number of Fans")))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
            
          }},model:{value:(_vm.assessor.data[_vm.noOfFan]),callback:function ($$v) {_vm.$set(_vm.assessor.data, _vm.noOfFan, $$v)},expression:"assessor.data[noOfFan]"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }