<template>
  <input
    ref="inputRef"
    type="text"
    class="form-control"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue'

export default {
  name: 'CurrencyInput',
  props: {
    value: Number,
    options: Object,
  },
  setup(props) {
    const { inputRef, setOptions } = useCurrencyInput(props.options)
    
    watch(
      () => props.options,
      (options) => {
        // Ensure the currency code is present
        const code = self.app.$store.state.i18n.locale;
        const locale = [
          {
          code: 'en',
          numberFormat: 'en-US'
        },
        {
          code: 'es',
          numberFormat: 'es-ES'
        },
        {
          code: 'fr',
          numberFormat: 'fr-FR'
        },
        {
          code: 'tr',
          numberFormat: 'de-DE'
        },
        {
          code: 'de',
          numberFormat: 'de-DE'
        },
        {
          code: 'pt',
          numberFormat: 'pt-PT'
        },
        {
          code: 'ch',
          numberFormat: 'zh-ZH'
        },
        {
          code: 'jp',
          numberFormat: 'ja-JP'
        },
        {
          code: 'pl',
          numberFormat: 'pt-PT'
        },
        {
          code: 'it',
          numberFormat: 'de-DE'
        },
        {
          code: 'nl',
          numberFormat: 'nl-NL'
        },
        {
          code: 'ru',
          numberFormat: 'sv-SE'
        },
    ].find((lang)=> lang.code === code).numberFormat
        const newOptions = {
          ...options,
          locale : locale || 'en-US',
          hideCurrencySymbolOnFocus: true,
          hideGroupingSeparatorOnFocus: true,
          autoDecimalDigits: false,
          useGrouping: true,
          accountingSign: false,
          allowNegative: false,
          currency: "USD", // Default to USD if not provided
          currencyDisplay: "hidden",
          // hideGroupingSeparator: true,
          hideNegligibleDecimalDigitsOnFocus: true,
          // valueRange: { min: 0 },
}
        if (newOptions.currency) {
          setOptions(newOptions)
        } else {
          console.error("Currency code is required but missing.")
        }
      },
      { deep: true } // Ensure deep watch for nested object changes
    )
        

    return { inputRef }
  }
}
</script>