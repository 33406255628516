const BarGraph = require('../../Misc/BarGraph');
const Table = require('../../Misc/Table');

export const locomotion = (app, report, evaluation, group, assessor) => {

    if (assessor === undefined) return false;

    let slide = report.pptx.addSlide('Slide master');
    let slide2;

    slide.addText(
        app.$t('Locomotion Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    );

    slide.addText(
        app.$t('Economic Results'), {
            x: 0.5,
            y: 2.4,
            ...report.styleOptions.subheaderBlue
        }
    );

    slide.addText(
        app.$t('The prevalence of lameness in a herd is revealed through locomotion scoring. Even seemingly minor changes in the average locomotion score result in a significant profit potential.'), {
            x: 0.5,
            y: 2.9,
            w: 7.5,
            color: '000000',
            fontSize: 12,
            fontFace: 'Arial',
        }
    );

    let obj3 = [];
    let obj4 = [];

    obj4.push([{
            text: app.$t('Name of Operation'),
            options: report.styleOptions.tableHeaderGray
        },
        {
            text: evaluation.getName()
        }
    ]);
    obj4.push([{
        text: app.$t('Name of Group'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: group.getName()
    }]);
    obj4.push([{
        text: app.$t('Evaluation Date'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: evaluation.getDate()
    }]);
    obj4.push([{
        text: app.$t('Local Currency'),
        options: report.styleOptions.tableHeaderGray
    }, {
        // still need to include more currencies
        text: evaluation.getCurrency()
    }]);
    obj4.push([{
        text: app.$t('Milk Prod. (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + app.$t('/day)'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text:  app.$numberFormat(group.getShowMilkProduction()),
    }]);
    obj4.push([{
        // need to set up currency
        text: app.$t('Milk Price') + ' (' + evaluation.getCurrency() + app.$t('/') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + ')',
        options: report.styleOptions.tableHeaderGray
    }, {
        text: app.$numberFormat(evaluation.getMilkPrice(false, false)) 
    }]);
    obj4.push([{
        text: app.$t('Animals in Group'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: app.$numberFormat(group.getGroupSize())
    }]);
    obj4.push([{
        text: app.$t('Sugg. Sample Size'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: app.$numberFormat(group.getSampleSize())
    }]);
    obj4.push([{
        text: app.$t('Economics On'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: Number(assessor.getEconomics()) === 1 ? app.$t('On') : app.$t('Off')
    }]);
    if (assessor.getProductionType() !== null) {
        obj4.push([{
            text: app.$t('Production Type'),
            options: report.styleOptions.tableHeaderGray
        }, {
            text: assessor.getProductionType() === 'confined_cattle' ? app.$t('Confined') : app.$t('Grazing'),
        }]);
    }
    obj4.push([{
        text: app.$t('Scoring System'),
        options: report.styleOptions.tableHeaderGray
    }, {
        text: assessor.getScoringSystem() === 0 ? app.$t('4 Point Scoring') : app.$t('5 Point Scoring'),
    }]);

    Table.create(slide,null, obj4, report, group, 'Locomotion Assessor', {
        ...report.styleOptions.tableOptions,
        fill: 'ffffff',
        color: '000000',
        x: Number(assessor.getEconomics()) === 0 ? .5 : 4.3,
        y: 3.3,
        w: Number(assessor.getEconomics()) === 0 ? 7.5 : 3.7,
        fontSize: 9,
        margin: Number(assessor.getEconomics()) === 0 ? [5.25, .5, 5.25, .5] : [7, .5, 7, .5]
    });

    let obj = [{
        text: app.$t('Locomotion Scores'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Number of Cows Scored'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('% of Group'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Target'),
        options: report.styleOptions.tableHeader
    }];
    let obj2 = [];
    obj2.push([{
            text: app.$t('Locomotion Score 1'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false,
                align: 'left'
            }
        },
        {
            text: app.$numberFormat(assessor.getScore(1)),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: assessor.getPercentageOfTotalScore(1) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getTarget(1)) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ]);
    obj2.push([{
            text: app.$t('Locomotion Score 2'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false,
                align: 'left'
            }
        },
        {
            text: app.$numberFormat(assessor.getScore(2)),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getPercentageOfTotalScore(2)) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getTarget(2)) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ]);
    obj2.push([{
            text: app.$t('Locomotion Score 3'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false,
                align: 'left'
            }
        },
        {
            text: app.$numberFormat(assessor.getScore(3)),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getPercentageOfTotalScore(3)) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getTarget(3)) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ]);

    if (assessor.getScoringSystem() === 1) {

        obj2.push([{
                text: app.$t('Locomotion Score 4'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: app.$numberFormat(assessor.getScore(4)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getPercentageOfTotalScore(4)) + '%',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTarget(4)) + '%',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);
        obj2.push([{
                text: app.$t('Locomotion Score 5'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: app.$numberFormat(assessor.getScore(5)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getPercentageOfTotalScore(5)) + '%',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTarget(5)) + '%',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);

    } else {

             obj2.push([{
                text: app.$t('Locomotion Score 4'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: app.$numberFormat(assessor.getScore(45)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getPercentageOfTotalScore(45)) + '%',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTarget(45)) + '%',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);

    }

    obj2.push([{
            text: app.$t('Total Cows Scored'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false,
                align: 'left'
            }
        },
        {
            text: app.$numberFormat(assessor.getTotalScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: '',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: '',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ]);
    obj2.push([{
            text: app.$t('Average Locomotion Score'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false,
                align: 'left'
            }
        },
        {
            text: '',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getAverageLocomotionScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(assessor.getTargetAverageLocomotionScore()),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ]);

    if (Number(assessor.getEconomics()) === 1) {
        obj2.push([{
                text: app.$t('Average Daily Milk Loss (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + app.$t('/cow/day)*'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getAverageDailyMilkLoss()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);
        obj2.push([{
                text: app.$t('Average Daily Milk Loss (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + app.$t('/group/day)*'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getAverageDailyMilkLossPerHerd()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);
        obj2.push([{
                //currency conversions
                text: app.$t('Lost Revenue (') + evaluation.getCurrency() + app.$t('/group/day)*'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getLostRevenueDaily()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);
        obj2.push([{
                //currency conversions
                text: app.$t('Lost Revenue (') + evaluation.getCurrency() + app.$t('/group/yr)*'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getLostRevenueYearly()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);

    }

    obj2.push([{
        text: '*' + app.$t('Based off milking cows in herd'),
        options: {
            color: 'ffffff',
            fill: '000000',
            fontFace: 'Arial',
            bold: false,
            align: 'c',
            colspan: 4,
            colW: 6
        }
    }]);

    if (Number(assessor.getEconomics()) === 1) {

        slide.addShape(report.pptx.shapes.RECTANGLE, {
            x: 0.5,
            y: 3.3,
            w: 3.65,
            h: 5.25,
            line: '000000',
            line_size: 1
        });

        let barObj = [{
            name: app.$t(''),
            labels: [app.$t('')],
            values: [assessor.getYearlyProfitPotential()]
        }];

        BarGraph.create(report, slide, barObj, {
            x: .6,
            y: 3.5,
            w: 3.25,
            h: 4.35,
            showLegend: false,
            showLabel: false,
            showTitle: false,
            showValue: false,
            showCatAxisTitle: false,
            legendFontSize: 12,
            showValAxisTitle: false,
            valAxisMajorUnit: assessor.getValAxisMajorUnit(),
            valAxisTitle: evaluation.getCurrencyLogo(evaluation.getCurrency()),
            valAxisHidden: false,
            valAxisLabelFontSize: 9,
            catAxisLabelFontSize: 12,
            barGapWidthPct: 20
        });

        slide.addText(
            app.$t('Yearly Profit Potential'), {
                x: 0.5,
                y: 7.8,
                w: 3.65,
                align: 'center',
                color: '000000',
                fontSize: 14,
                fontFace: 'Arial',
                bold: true
            }
        );

        slide.addText(
            app.$t('(if targets are achieved)'), {
                x: 0.5,
                y: 8,
                w: 3.65,
                align: 'center',
                color: '000000',
                fontSize: 12,
                fontFace: 'Arial',
            }
        );


        slide.addText(
            evaluation.getCurrencyLogo(evaluation.getCurrency()) + app.$t(app.$store.getters.commaFormatted(parseFloat(assessor.getYearlyProfitPotential()))), {
                x: 0.5,
                y: 8.2,
                w: 3.65,
                align: 'center',
                color: '000000',
                fontSize: 12,
                fontFace: 'Arial',
            }
        );


        slide2 = report.pptx.addSlide('Slide master');

        slide2.addText(
            app.$t('Locomotion Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );
    }

    Table.create(Number(assessor.getEconomics()) === 1 ? slide2 : slide, obj, obj2, report, group, 'Locomotion Assessor', {
        ...report.styleOptions.tableOptions,
        y: Number(assessor.getEconomics()) === 1 ? 2.2 : 7,
        colW: [3, 2, 1.25, 1.25]
    });

    return true;

};
