const Table = require('../../Misc/Table');

export const TieStallStanchionHeatAbatement = (app, report, evaluation, group, assessor) => {
    let slide;

    const categoriesObj = [{
        text: app.$t('Question'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Answer'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Comment'),
        options: report.styleOptions.tableHeader
    }];
    let contentObj = [];

    if (assessor.getSummerVentilationSystem() !== null) {
        contentObj.push([{
                text: app.$t("Summer Ventilation System"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getSummerVentilationSystem() === 'cross' ? app.$t('Cross') : app.$t('Tunnel'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAreCowsAllowedOutOfTheBarnAtNightToCoolOff() !== null) {
        contentObj.push([{
                text: app.$t("Are cows allowed out of the barn at night to cool off?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreCowsAllowedOutOfTheBarnAtNightToCoolOff() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreCowsAllowedOutOfTheBarnAtNightToCoolOff() === 1 ? app.$t('Good!') : app.$t('If your current barn does not quickly cool down at night, consider creating an outside lot for cows at night.'),
                options: {
                    align: 'left',
                    color: assessor.getAreCowsAllowedOutOfTheBarnAtNightToCoolOff() === 1 ? '249824' : assessor.getAreCowsAllowedOutOfTheBarnAtNightToCoolOff() === 0 ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAreSoakersMistersUsedToHelpCoolCowsOff() !== null) {
        contentObj.push([{
                text: app.$t("Are soakers/misters used to help cool cows off?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreSoakersMistersUsedToHelpCoolCowsOff() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreSoakersMistersUsedToHelpCoolCowsOff() === 1 ? app.$t('Good!') : app.$t('Water soaking can help reduce heat stress.'),
                options: {
                    align: 'left',
                    color: assessor.getAreSoakersMistersUsedToHelpCoolCowsOff() === 1 ? '249824' : assessor.getAreSoakersMistersUsedToHelpCoolCowsOff() === 0 ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsThereShadeOverOutsideFeedArea() !== null) {
        contentObj.push([{
                text: app.$t("Is there shade over outside feed area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverOutsideFeedArea() === 1 ? app.$t('Yes') : assessor.getIsThereShadeOverOutsideFeedArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverOutsideFeedArea() === 1 ? app.$t('Good!') : assessor.getIsThereShadeOverOutsideFeedArea() === 0 ? app.$t('Shade will reduce heat stress by eliminating the effects of direct sunlight.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsThereShadeOverOutsideFeedArea() === 1 ? '249824' : assessor.getIsThereShadeOverOutsideFeedArea() === 0 ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getSummerVentilationSystem() === 'cross' && assessor.getBarnLength() !== null) {
        contentObj.push([{
                text: app.$t("Barn Length") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnLength()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getBarnWidth() !== null) {
        contentObj.push([{
                text: app.$t("Barn Width") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnWidth()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);

    }

    if (assessor.getBarnCeilingHeight() !== null) {
        contentObj.push([{
                text: app.$t("Barn Ceiling Height") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnCeilingHeight()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getInletArea() !== null) {
        contentObj.push([{
                text: app.$t("Inlet area") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getInletArea()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getInletArea() !== assessor.calculateTargetInletArea() ? app.$t('Target inlet area for your barn is ') + assessor.calculateTargetInletArea() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + app.$t('.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getInletArea() !== assessor.calculateTargetInletArea() ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getCrossSectionalArea() !== null) {
        contentObj.push([{
                text: app.$t("Cross-sectional area") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getCrossSectionalArea()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getSummerVentilationSystem() === 'cross' && assessor.getBarnVolume() !== null) {
        contentObj.push([{
                text: app.$t("Barn Volume") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cu meters')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getBarnVolume()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getFanWidthOne()) {
        contentObj.push([{
                text: app.$t("Fan Width") + ' 1 (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidthOne()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getNumberOfFansOne()) {
        contentObj.push([{
                text: app.$t("Number of Fans") + " 1",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFansOne()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getFanWidthTwo()) {
        contentObj.push([{
                text: app.$t("Fan Width") + ' 2 (' +  app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidthTwo()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getNumberOfFansTwo()) {
        contentObj.push([{
                text: app.$t("Number of Fans") + " 2",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFansTwo()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getFanWidthThree()) {
        contentObj.push([{
                text: app.$t("Fan Width") + ' 3 (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidthThree()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getNumberOfFansThree()) {
        contentObj.push([{
                text: app.$t("Number of Fans") + " 3",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFansThree()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.calculateTotalAirMovementCapacity() !== null) {
        contentObj.push([{
                text: app.$t("Total air movement capacity") + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.calculateTotalAirMovementCapacity()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.calculateTotalAirMovementCapacity() !== assessor.calculateTargetAirMovementCapacity() ? app.$t('Target air movement capacity for your barn is ') + assessor.calculateTargetAirMovementCapacity() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + app.$t(' at 40 air changes per hour.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.calculateTotalAirMovementCapacity() !== assessor.calculateTargetAirMovementCapacity() ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (contentObj.length > 0) {

        slide = report.pptx.addSlide('Slide master');

        slide.addText(
            app.$t('Tiestall/Stanchion Heat Abatement Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('Your Operation'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, contentObj, report, group, 'Tiestall/Stanchion Heat Abatement Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [3.25, 1.25, 3]
        });
    }
}
