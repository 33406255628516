const Table = require('../../Misc/Table');

export const heatAbatement = (app, report, evaluation, group, assessor) => {

    let slide;
    let slide2;
    let slide3;

    let titleY = 2.2;
    let slideY = 2.7;

    const categoriesObj = [
        {
            text: app.$t('Question'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Answer'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Comment'),
            options: report.styleOptions.tableHeader
        }
    ]
    let waterObj = [];
    let fanObj = [];
    let restingAreaObj = [];
    let soakerObj = [];
    let shadeObj = [];


    if (group.getGroupSize() !== null) {
        waterObj.push([{
                text: app.$t("Group/Pen Size"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(group.getGroupSize()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        titleY += .4;
        slideY += .4;
    }
    console.log(assessor.getAccessibleWaterTroughPerimeterInPen(), assessor.getMinAccessibleWaterTroughPerimeterInPen())
    if (assessor.getAccessibleWaterTroughPerimeterInPen() !== null) {
        waterObj.push([{
                text: app.$t('Accessible Water Trough Perimeter in Pen') + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getAccessibleWaterTroughPerimeterInPen()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t("In hot weather, there should be at least ") + assessor.getMinAccessibleWaterTroughPerimeterInPen() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(" of linear trough perimeter per cow. In your herd there are ") + assessor.waterTroughPerimeterCalc() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(" per cow."),
                options: {
                    align: 'left',
                    color: assessor.getMinAccessibleWaterTroughPerimeterInPen() <= assessor.waterTroughPerimeterCalc() ? '000000' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        titleY += .65;
        slideY += .65;
    }

    if (assessor.getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() !== null) {
        waterObj.push([{
                text: app.$t("Is drinking water readily available to cows exiting the milking parlor?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() === 1 ? app.$t('Yes') : assessor.getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() === 0 ? app.$t('All cows should have ready access to water when exiting the parlor.') : assessor.getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() === 1 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() === 0 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        titleY += .5;
        slideY += .5;
    }

    if (assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() !== null) {
        waterObj.push([{
                text: app.$t("Is the area around the water trough free of mud, stones, etc?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 1 ? app.$t('Yes') : assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? app.$t('Mud, stones and other obstacles may limit water access for cows. Furthermore, mud, stones and other obstacles around the water trough can increase the risk of cows becoming lame.') : assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 1 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? titleY += 1.1 : titleY += .6
        assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? slideY += 1.1 : slideY += .6
    }

    if (waterObj.length > 0) {

        titleY += 1;
        slideY += 1;

        slide = report.pptx.addSlide('Slide master');

        slide.addText(
            app.$t('Heat Abatement Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('Water'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, waterObj, report, group, 'Heat Abatement Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });

    }

    if (assessor.getAreFansLocatedInTheFeedBunkArea() !== null) {
        fanObj.push([{
                text: app.$t("Are fans located in the Feed Bunk Area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreFansLocatedInTheFeedBunkArea() === 1 ? app.$t('Yes') : assessor.getAreFansLocatedInTheFeedBunkArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreFansLocatedInTheFeedBunkArea() === 0 ? app.$t('Air movement over cows at the feed bunk assists soaker cooling.') : assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 1 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreFansLocatedInTheFeedBunkArea() === 0 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getFeedBunkAreaAreHvlsFansUsed() !== null && assessor.getAreFansLocatedInTheFeedBunkArea() === 1) {
        fanObj.push([{
                text: app.$t("Are HVLS Fans used (high volume, low speed)?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFeedBunkAreaAreHvlsFansUsed() === 1 ? app.$t('Yes') : assessor.getFeedBunkAreaAreHvlsFansUsed() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFeedBunkAreaAreHvlsFansUsed() === 1 ? app.$t('Make sure there is adequate air movement in the barn periphery.') : assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getFeedBunkAreaAreHvlsFansUsed() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getFeedBunkAreaTemperatureFansActivate() !== null && assessor.getAreFansLocatedInTheFeedBunkArea() === 1) {
        fanObj.push([{
                text: app.$t("Temperature Fans Activate (°") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFeedBunkAreaTemperatureFansActivate()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t('Target activation temperature is ') + assessor.temperatureThatFansActivateRecommendation() + app.$t('°') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + app.$t('.'),
                options: {
                    align: 'left',
                    color: assessor.getFeedBunkAreaTemperatureFansActivate() <= assessor.temperatureThatFansActivateRecommendation() ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getFeedBunkAreaFanDiameter() !== null && assessor.getAreFansLocatedInTheFeedBunkArea() === 1) {
        fanObj.push([{
                text: app.$t("Fan Diameter (") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFeedBunkAreaFanDiameter()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }
    if (assessor.getFeedBunkAreaSpacing() !== null && assessor.getAreFansLocatedInTheFeedBunkArea() === 1) {
        let spacingComment = ''
        if (app.user.lang === 'es') {
            spacingComment = 'Los ventiladores con un diámetro de '
        }
        fanObj.push([{
                text: app.$t("Spacing (") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFeedBunkAreaSpacing()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: spacingComment + assessor.getFeedBunkAreaFanDiameter() + ' ' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(" diameter fans should be spaced every ") + assessor.idealFeedBunkFanSpacing() + app.$t(" ") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + app.$t('.'),
                options: {
                    align: 'left',
                    color: assessor.getFeedBunkAreaSpacing() > assessor.idealFeedBunkFanSpacing() ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }
    if (fanObj.length > 0) {

        slide.addText(
            app.$t('Heat Abatement Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('Fans'), {
                x: 0.5,
                y: titleY + .2,
                ...report.styleOptions.subheaderBlue
            }
        );

        slide.addText(
            app.$t('Feed Bunk Area'), {
                x: 0.5,
                y: titleY + .6,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, fanObj, report, group, 'Heat Abatement Assessor', {
            y: slideY + .5,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
        //reset titleY & slideY
        titleY = 2.2;
        slideY = 2.7;
    }

    if (assessor.getAreFansLocatedInTheRestingAreaPaddock() !== null) {
        restingAreaObj.push([{
                text: app.$t("Are fans located in the resting area/paddock?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreFansLocatedInTheRestingAreaPaddock() === 1 ? app.$t('Yes') : assessor.getAreFansLocatedInTheRestingAreaPaddock() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreFansLocatedInTheRestingAreaPaddock() === 0 ? app.$t('Air movement over cows in the resting area encourages cows to stand in the resting area or lie down.') : assessor.getAreFansLocatedInTheRestingAreaPaddock() === 1 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreFansLocatedInTheRestingAreaPaddock() === 0 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        assessor.getAreFansLocatedInTheRestingAreaPaddock() === 0 ? slideY += .9 : slideY += .5;
        assessor.getAreFansLocatedInTheRestingAreaPaddock() === 0 ? titleY += .9 : titleY += .5;
    }

    if (assessor.getRestingAreaPaddockAreHvlsFansUsed() !== null && assessor.getAreFansLocatedInTheRestingAreaPaddock() === 1) {
        restingAreaObj.push([{
                text: app.$t("Are HVLS Fans used (high volume, low speed)?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getRestingAreaPaddockAreHvlsFansUsed() === 1 ? app.$t('Yes') : assessor.getRestingAreaPaddockAreHvlsFansUsed() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getRestingAreaPaddockAreHvlsFansUsed() === 1 ? app.$t('Make sure there is adequate air movement in the barn periphery.') : assessor.getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getRestingAreaPaddockAreHvlsFansUsed() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .5;
        titleY += .5;
    }

    if (assessor.getRestingAreaPaddockTemperatureFansActivate() !== null && assessor.getAreFansLocatedInTheRestingAreaPaddock() === 1) {
        restingAreaObj.push([{
                text: app.$t("Temperature Fans Activate (°") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getRestingAreaPaddockTemperatureFansActivate()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t('Target activation temperature is ') + app.$numberFormat(assessor.temperatureThatFansActivateRecommendation()) + app.$t('°') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + app.$t('.'),
                options: {
                    align: 'left',
                    color: assessor.getRestingAreaPaddockTemperatureFansActivate() <= assessor.temperatureThatFansActivateRecommendation() ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .4;
        titleY += .4;
    }

    if (assessor.getRestingAreaPaddockFanDiameter() !== null && assessor.getAreFansLocatedInTheRestingAreaPaddock() === 1) {
        restingAreaObj.push([{
                text: app.$t("Fan Diameter (") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getRestingAreaPaddockFanDiameter()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .4;
        titleY += .4;
    }

    if (assessor.getRestingAreaPaddockSpacing() !== null && assessor.getAreFansLocatedInTheRestingAreaPaddock() === 1) {
        let spacingComment2 = ''
        if (app.user.lang === 'es') {
            spacingComment2 = 'Los ventiladores con un diámetro de '
        }
        restingAreaObj.push([{
                text: app.$t("Spacing (") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getRestingAreaPaddockSpacing()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: spacingComment2 + app.$numberFormat(assessor.getRestingAreaPaddockFanDiameter()) + app.$t(" ") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(" diameter fans should be spaced every ") + app.$numberFormat(assessor.idealRestingAreaFanSpacing()) + app.$t(" ") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + app.$t("."),
                options: {
                    align: 'left',
                    color: assessor.getRestingAreaPaddockSpacing() > assessor.idealRestingAreaFanSpacing() ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .5;
        titleY += .5;
    }

    if (restingAreaObj.length > 0) {

        slide2 = report.pptx.addSlide('Slide master');

        titleY += .8;
        slideY += .8;

        slide2.addText(
            app.$t('Heat Abatement Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide2.addText(
            app.$t('Resting Area/Paddock'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide2, categoriesObj, restingAreaObj, report, group, 'Heat Abatement Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    if (assessor.getAreSoakersMistersInTheHoldingArea() !== null) {
        soakerObj.push([{
                text: app.$t("Are soakers/misters located in the Feed Bunk Area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreSoakersMistersInTheHoldingArea() === 1 ? app.$t('Yes') : assessor.getAreSoakersMistersInTheHoldingArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreSoakersMistersInTheHoldingArea() === 1 ? app.$t('Good!') : assessor.getAreSoakersMistersInTheHoldingArea() === 0 ? app.$t('If production declines in the summer, feed bunk soaking may be a worthwhile investment.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreSoakersMistersInTheHoldingArea() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() !== null) {
        soakerObj.push([{
                text: app.$t("Are there soakers/misters located in the Resting Area/Paddock?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1 ? app.$t('Yes') : assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1 ? app.$t('Good! Make sure bedding is not excessively wet.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getDoesWaterDropletEffectivelySoakCows() !== null && assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1) {
        soakerObj.push([{
                text: app.$t("Does water droplet effectively soak cows?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesWaterDropletEffectivelySoakCows() === 1 ? app.$t('Yes') : assessor.getDoesWaterDropletEffectivelySoakCows() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesWaterDropletEffectivelySoakCows() === 1 ? app.$t('Good!') : assessor.getDoesWaterDropletEffectivelySoakCows() === 0 ? app.$t('Water droplets that are too small do not cool cows effectively.') : '',
                options: {
                    align: 'left',
                    color: assessor.getDoesWaterDropletEffectivelySoakCows() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getTemperatureSoakersMistersActivate() !== null && assessor.getAreFansLocatedInTheFeedBunkArea() === 1) {
        soakerObj.push([{
                text: app.$t("Temperature Soakers/Misters Activate (°") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTemperatureSoakersMistersActivate()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getTemperatureSoakersMistersActivate() > assessor.temperatureThatFansActivateRecommendation() ? app.$t('Target activation temperature is ') + assessor.temperatureThatFansActivateRecommendation() + app.$t('°') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + app.$t('.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getTemperatureSoakersMistersActivate() > assessor.temperatureThatFansActivateRecommendation() ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getDuration() !== null && assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1) {
        soakerObj.push([{
                text: app.$t("Duration (min)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getDuration()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getFrequency() !== null && assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1) {
        soakerObj.push([{
                text: app.$t("Frequency (min)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFrequency()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: evaluation.getMeasurements() === 'metric' ? app.$t('For Temperatures between 21˚ - 26˚, the duration should be 1-2 min, with a frequency of every 15 min. For Temperatures between 27˚ - 32˚, the duration should be 1-2 min, with a frequency of every 10 min. For Temperatures > 32˚, the duration should be 1-2 min, with a frequency of every 5 min.') : app.$t('For Temperatures between 70˚ - 80˚, the duration should be 1-2 min, with a frequency of every 15 min. For Temperatures between 81˚ - 90˚, the duration should be 1 - 2 min, with a frequency of every 10 min. For Temperatures > 90˚, the duration should be 1 - 2 min, with a frequency of every 5 min.'),
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() !== null && assessor.getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() === 1) {
        soakerObj.push([{
                text: app.$t("Does the frequency of soaking vary depending upon temperature?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() === 1 ? app.$t('Yes') : assessor.getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() === 1 ? app.$t('Good!') : assessor.getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() === 0 ? assessor.frequencyVariedByTempRecommendation() : '',
                options: {
                    align: 'left',
                    color: assessor.getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (soakerObj.length > 0) {
        slide2.addText(
            app.$t('Soakers/Misters'), {
                x: 0.5,
                y: titleY + .7,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide2, categoriesObj, soakerObj, report, group, 'Heat Abatement Assessor', {
            y: slideY + .5,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    if (assessor.getIsThereShadeOverTheFeedBunkArea() !== null) {
        shadeObj.push([{
                text: app.$t("Is there shade over the Feed Bunk Area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverTheFeedBunkArea() === 1 ? app.$t('Yes') : assessor.getIsThereShadeOverTheFeedBunkArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverTheFeedBunkArea() === 1 ? app.$t('Good!') : assessor.getIsThereShadeOverTheFeedBunkArea() === 0 ? app.$t('Consider constructing a shade.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsThereShadeOverTheFeedBunkArea() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsThereShadeOverTheRestingAreaPaddock() !== null) {
        shadeObj.push([{
                text: app.$t("Is there shade over the Resting Area/Paddock?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverTheRestingAreaPaddock() === 1 ? app.$t('Yes') : assessor.getIsThereShadeOverTheRestingAreaPaddock() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverTheRestingAreaPaddock() === 1 ? app.$t('Good!') : assessor.getIsThereShadeOverTheRestingAreaPaddock() === 0 ? app.$t('Consider constructing a shade.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsThereShadeOverTheRestingAreaPaddock() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() !== null && assessor.getIsThereShadeOverTheRestingAreaPaddock() === 1) {
        shadeObj.push([{
                text: app.$t("Are air cooling units such as Shade-Tracker or Koral-Kool used below shade?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() === 1 ? app.$t('Yes') : assessor.getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() === 1 ? app.$t('Good!') : assessor.getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() === 0 ? app.$t('Air cooling units such as Shade-Tracker and Koral-Kool have been shown to effectively cool the air, reducing level of heat stress.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (shadeObj.length > 0) {
        slide3 = report.pptx.addSlide('Slide master');

        slide3.addText(
            app.$t('Heat Abatement Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide3.addText(
            app.$t('Shade'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide3, categoriesObj, shadeObj, report, group, 'Heat Abatement Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

}
