var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card--measurements card--measurements--small"},[_c('div',{staticClass:"row card--measurements__first-row"},[_c('div',{staticClass:"col-md-24 measurements_heading"},[_c('p',{staticClass:"p--measurements-heading mb-0 d-inline-block"},[_vm._v(" "+_vm._s(_vm._f("translate")("Measurements"))+" ")]),_c('modal-info',{staticClass:"d-inline-block",attrs:{"value":'measurements',"icon-color":"white"}},[_c('template',{slot:"modal-content"},[_c('h2',{staticClass:"h2 pb-2 pt-3 px-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("translate")("Measurements"))+" ")]),_c('p',{staticClass:"p--measurements px-3 pt-0"},[_vm._v(" "+_vm._s(_vm._f("translate")(_vm.evaluation.data.measurements === "imperial" ? "Dimensions of the footbath are required to calculate capacity. The bath should be at least 10 ft long to allow each rear leg to have at least two dunks per pass, and deep enough 4 in to allow the product to reach in between the claws." : "Dimensions of the footbath are required to calculate capacity. The bath should be at least 3 m long to allow each rear leg to have at least two dunks per pass, and deep enough 10 cm to allow the product to reach in between the claws."))+" ")]),_c('div',{staticClass:"row modal-bottom pb-3"},[_c('a',{staticClass:"btn-cancel-modal d-flex justify-content-center",attrs:{"href":"#","data-dismiss":"modal"}},[_vm._v(_vm._s(_vm._f("translate")("Cancel")))])])])],2)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$t("Length (") + _vm.$t( _vm.$getEquivalentUnit(_vm.evaluation.data.measurements, "cm") ) + ")"))]),_c('currency-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:'form-control',attrs:{"name":"length","data-vv-as":"Length","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_length),callback:function ($$v) {_vm.computed_length=_vm._n($$v)},expression:"computed_length"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('length')),expression:"errors.has('length')"}],staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(_vm._f("translate")("The Length field is required.")))])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Width (") + _vm.$t( _vm.$getEquivalentUnit(_vm.evaluation.data.measurements, "cm") ) + ")"))]),_c('currency-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:'form-control',attrs:{"name":"width","data-vv-as":"Width","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_width),callback:function ($$v) {_vm.computed_width=_vm._n($$v)},expression:"computed_width"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('width')),expression:"errors.has('width')"}],staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(_vm._f("translate")("The Width field is required.")))])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm._f("translate")(_vm.evaluationClass.isImperial ? "Depth (in) of Solution at Step In" : "Depth (cm) of Solution at Step In")))]),_c('currency-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:'form-control',attrs:{"name":"step_in_depth","data-vv-as":"Step in Depth","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_step_in),callback:function ($$v) {_vm.computed_step_in=_vm._n($$v)},expression:"computed_step_in"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('step_in_depth')),expression:"errors.has('step_in_depth')"}],staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(_vm._f("translate")("The Step In Depth field is required.")))])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm._f("translate")(_vm.evaluationClass.isImperial ? "Depth (in) of Solution at Step Out" : "Depth (cm) of Solution at Step Out")))]),_c('currency-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:'form-control',attrs:{"name":"depth","data-vv-as":"Step Out Depth","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_step_out),callback:function ($$v) {_vm.computed_step_out=_vm._n($$v)},expression:"computed_step_out"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('depth')),expression:"errors.has('depth')"}],staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(_vm._f("translate")("The Step Out Depth field is required.")))])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[(_vm.evaluation.data.measurements === 'metric')?_c('label',[_vm._v(_vm._s(_vm._f("translate")("Volume (L)")))]):_c('label',[_vm._v(_vm._s(_vm._f("translate")("Volume (gal)")))]),_c('currency-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:'form-control',attrs:{"name":"volume","data-vv-as":"Volume","disabled":"","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=_vm._n($$v)},expression:"volume"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('volume')),expression:"errors.has('volume')"}],staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(_vm._f("translate")("The Volume field is required.")))])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }