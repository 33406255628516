<!-- @format -->

<template>
  <div class="card--measurements card--measurements--small">
    <div class="row card--measurements__first-row">
      <div class="col-md-24 measurements_heading">
        <p class="p--measurements-heading mb-0 d-inline-block">
          {{ "Measurements" | translate }}
        </p>
        <modal-info
          :value="'measurements'"
          class="d-inline-block"
          icon-color="white"
        >
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Measurements" | translate }}
            </h2>
            <p class="p--measurements px-3 pt-0">
              {{
             
           
             evaluation.data.measurements === "imperial"
                  ? "Dimensions of the footbath are required to calculate capacity. The bath should be at least 10 ft long to allow each rear leg to have at least two dunks per pass, and deep enough 4 in to allow the product to reach in between the claws."
                  : "Dimensions of the footbath are required to calculate capacity. The bath should be at least 3 m long to allow each rear leg to have at least two dunks per pass, and deep enough 10 cm to allow the product to reach in between the claws." | translate


              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group pt-4">
          <label>{{
            $t("Length (") +
            $t(
              $getEquivalentUnit(evaluation.data.measurements, "cm")
            ) +
            ")"
          }}</label>
          <currency-input
            v-model.number="computed_length"
            name="length"
            data-vv-as="Length"
            v-validate="'required'"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
          <span v-show="errors.has('length')" class="text-danger">
            <small>{{
              "The Length field is required." | translate
            }}</small>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            $t("Width (") +
            $t(
              $getEquivalentUnit(evaluation.data.measurements, "cm")
            ) +
            ")"
          }}</label>
          <currency-input
            v-model.number="computed_width"
            name="width"
            data-vv-as="Width"
            v-validate="'required'"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
          <span v-show="errors.has('width')" class="text-danger">
            <small>{{
              "The Width field is required." | translate
            }}</small>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            evaluationClass.isImperial
              ? "Depth (in) of Solution at Step In"
              : "Depth (cm) of Solution at Step In" | translate
          }}</label>
          <currency-input
            v-model.number="computed_step_in"
            name="step_in_depth"
            data-vv-as="Step in Depth"
            v-validate="'required'"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
          <span
            v-show="errors.has('step_in_depth')"
            class="text-danger"
          >
            <small>{{
              "The Step In Depth field is required." | translate
            }}</small>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            evaluationClass.isImperial
              ? "Depth (in) of Solution at Step Out"
              : "Depth (cm) of Solution at Step Out" | translate
          }}</label>
          <currency-input
            v-model.number="computed_step_out"
            name="depth"
            data-vv-as="Step Out Depth"
            v-validate="'required'"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
          <span v-show="errors.has('depth')" class="text-danger">
            <small>{{
              "The Step Out Depth field is required." | translate
            }}</small>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label v-if="evaluation.data.measurements === 'metric'">{{
            "Volume (L)" | translate
          }}</label>
          <label v-else>{{ "Volume (gal)" | translate }}</label>
          <currency-input
            v-model.number="volume"
            name="volume"
            data-vv-as="Volume"
            v-validate="'required'"
            :class="'form-control'"
            disabled
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
          <span v-show="errors.has('volume')" class="text-danger">
            <small>{{
              "The Volume field is required." | translate
            }}</small>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfo from "@/components/misc/modal-info.vue";
import Footbath from "@/libs/Classes/Footbath.js";
import Evaluation from "@/libs/Classes/Evaluation.js";
import convert from "convert-units";
import { mapState } from "vuex";
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    ModalInfo,
    CurrencyInput,
  },
  props: ["value", "footbath"],
  inject: ["$validator"],
  data() {
    return {
      // evaluation: null,
      evaluationClass: null,
      group: null,
      assessor: null,
    };
  },
  methods: {
    getMeasurements(measurement, unit) {
      return convert(measurement)
        .from(unit)
        .to(
          this.$getEquivalentUnit(
            this.evaluationClass.getMeasurements(),
            unit
          )
        )
        .toDecimal(0);
    },
  },
  created() {
    // this.evaluation = this.$store.getters.getData(
    //   'users/' +
    //     this.$route.params.userId +
    //     '/evaluations/' +
    //     this.$route.params.evaluationId
    // );
    this.evaluationClass = new Evaluation(this, this.evaluation);
    // this.group = this.$store.getters.getData(
    //   'users/' +
    //     this.$route.params.userId +
    //     '/evaluations/' +
    //     this.$route.params.evaluationId +
    //     '/groups/' +
    //     this.$route.params.groupId
    // );

    this.group = this.evaluation.groups[this.$route.params.groupId];

    let assessorType = this.$route.params.assessorType;
    if (this.group.assessors !== undefined) {
      if (Array.isArray(this.group.assessors)) {
        this.assessor = this.group.assessors.find((assessor) => {
          return Number(assessor.type) === Number(assessorType);
        });
      } else {
        for (let assessorId in this.group.assessors) {
          if (
            Number(this.group.assessors[assessorId].type) ===
            Number(assessorType)
          ) {
            this.assessor = this.group.assessors[assessorId];
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluations.evaluation,
    }),
    computed_length: {
      get: function () {
        return this.footbath.getLength(false);
      },
      set: function (newValue) {
        return this.footbath.setLength(newValue);
      },
    },
    computed_width: {
      get: function () {
        return this.footbath.getWidth(false);
      },
      set: function (newValue) {
        return this.footbath.setWidth(newValue);
      },
    },
    computed_step_in: {
      get: function () {
        return this.footbath.getStepIn(false);
      },
      set: function (newValue) {
        return this.footbath.setStepIn(newValue);
      },
    },
    computed_step_out: {
      get: function () {
        return this.footbath.getStepOut(false);
      },
      set: function (newValue) {
        return this.footbath.setStepOut(newValue);
      },
    },
    volume: {
      get: function () {
        return this.footbath.getVolume();
      },
    },
  },
  watch: {
    value: function (newValue) {
      if (
        newValue.measurements_length === null &&
        newValue.measurements_width === null &&
        newValue.measurements_volume === null &&
        newValue.measurements_step_in_depth === null &&
        newValue.measurements_step_out_depth === null
      ) {
        this.$validator.reset();
      }
    },
  },
};
</script>
