<template>
	<div class="container main-container main-container--hygiene view">
		<div class="row">
			<div class="col-lg-24">
				<h2 class="h2 d-inline-block mb-lg-4 mr-1">{{ $t("Tell Us About Your Herd's Lesion Prevalence") }}</h2>
				<modal-info :value="'nutrition'" class="d-inline">
					<template slot="modal-content">
						<h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Lesion') }}</h2>
						<p class="px-3">{{'For more information about Lesions, click the link to learn more from' | translate}}
							<a :href="$t('https://library.zinprofirststep.com/lesions/')" @click="$goToLameness($t('/lesions/'), $event);" target="_blank">{{'FirstStep Resources' | translate}}</a>.
						</p>
						<div class="row modal-bottom pb-3">
							<a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
						</div>
					</template>
				</modal-info>
			</div>
		</div>

		<hr class="mb-4 mt-1 mt-lg-0">

		<div class="row">
			<div class="col-lg-8">
				<label class="mb-1">{{ $t('Select when lesions counted') }}</label>
                <modal-info :value="'what-type-of-lesions'" class="d-inline" v-if="['lame','trim', 'both'].includes(assessor.data.what_type_of_lesions_do_you_want_to_enter)">
                    <template slot="modal-content">
                        <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Select when lesions counted') }}</h2>
                        <p class="px-3">{{ $t('Lame - Pulled lame cows for treatment.') }}</p>
                        <p class="px-3">{{ $t('Trim - Found during routine trimming.') }}</p>
                        <p class="px-3">{{ $t('Both - Both Lame and Trim.') }}</p>
                        <!-- v-if="['trim', 'both'].includes(assessor.data.what_type_of_lesions_do_you_want_to_enter)">-->
                        <div class="row modal-bottom pb-3">
                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                        </div>
                    </template>
                </modal-info>
                <select class="form-control" v-model="assessor.data.what_type_of_lesions_do_you_want_to_enter">
                    <option :value="null" disabled>{{ $t("Select") }}</option>
                    <option value="lame">{{ $t("Lame") }}</option>
                    <option value="trim">{{ $t("Trim") }}</option>
                    <option value="both">{{ $t("Both") }}</option>
                </select>
			</div>
			<div class="col-lg-8">
				<label class="mb-1">{{ $t('Number of Blocks Applied') }}</label>
				<currency-input
				  v-model="assessor.data.number_of_blocks_applied"
				  :options="{
					currency: 'USD',
					currencyDisplay: 'hidden',
					precision: 0,
				  }"
				/>
			</div>
			<div class="col-lg-8">
				<label class="mb-1">{{ $t('Number of Wraps Applied') }}</label>
				<currency-input
				  v-model="assessor.data.number_of_wraps_applied"
				  :options="{
					currency: 'USD',
					currencyDisplay: 'hidden',
					precision: 0,
				  }"
				/>
			</div>
		</div>

        <hr class="mb-4">

		<div class="row mb-3">
			<div class="col-lg-12">
				<div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Sole Ulcer (U)') + $t(':') }}</h5>
                        <modal-info :value="'sole-ulcer'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Sole Ulcer (U)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/U.png" alt="Sole Ulcer (U)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Pododermatitis Circumscripta, Rusterholz Disease') }}</p>
                                <p class="px-3"><b>{{ $t('Zone Affected')}}: 4</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                 <ul class="ul">
                                     <li>{{ $t('Raw sore (horn erosive defect) occurring at sole-heel junction on inner side of outside hind claw') }}</li>
                                     <li>{{ $t('Often occurs in both outside hind claws (when present)') }}</li>
                                 </ul>
                                <p class="px-3">{{ $t('These ulcers occur in the typical site, below the flexor tuberosity of the pedal bone.') }}</p>
<!--                                <a target="_blank" href="/pdf/zcontent_AABP-White-Line.pdf" class="d-inline-block px-3 mb-3">-->
<!--                                    {{ $t('AABP Fact Sheet – Sole Ulcer (PDF)') }}-->
<!--                                </a>-->
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.sole_ulcer_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.sole_ulcer_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Sole Fracture/Heel Ulcer (S)') + $t(':') }}</h5>
                        <modal-info :value="'sole-fracture'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Sole Fracture/Heel Ulcer (S)') }}</h2>
                                <p class="px-3">{{ $t('Changes at calving time, such as hormonal concentrations of relaxin, trigger enzyme activity within the claw which loosens the bond between the pedal bone and the claw horn capsule. As the cow bears weight, sinking of the pedal bone occurs. The sinking damages the tissues of the sole and triggers lesions at the sole identical to those associated with SARA.') }}</p>
                                <label class="text-dark px-3">{{ $t('Calving Associated Changes Predispose Cows to Lameness') }}</label>
                                <img class="w-100 px-3 mb-3" src="/images/zcontent_calving-in-maternity.jpg" alt="Calving Associated Changes Predispose Cows to Lameness">
                                <p class="px-3">{{ $t('Research at the University of Bristol in the UK clearly shows that there is an inevitable increase in claw horn lesions (such as sole hemorrhage) around the time of calving (see figure below). Lesions may be exacerbated by feeding wet diets and housing heifers in compromised environments where they spend a long time standing.') }}</p>
                                <label class="text-dark px-3">{{ $t('Sole Hemorrhage Scores for a Group of Maiden Heifers from 4 Weeks Before to 24 Weeks After First Calving, Exposed to Two Different Diet (Wet Vs Dry) and Housing (Free Stall vs Straw Bedded Pack) Treatments.') }}</label>
                                <img class="w-100 px-3 mb-3" src="/images/zcontent_calvinglame.gif" alt="Sole Hemorrhage Scores">
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.sole_fracture_heel_ulcer_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.sole_fracture_heel_ulcer_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Sole Hemorrhage/Bruise (H)') + $t(':') }}</h5>
                        <modal-info :value="'sole-hemorrhage'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Sole Hemorrhage/Bruise (H)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center">
                                    <img src="@/assets/img/lesion/H.png" alt="Sole Hemorrhage/Bruise (H)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Sole Bruising') }}</p>
                                <p class="px-3">{{ $t('Zones Affected') }}: 4, 5, 6</p>
                                <p class="px-3">{{ $t('Common signs') }}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Slight to significant red (or blue) coloration of the sole') }}</li>
                                    <li>{{ $t('Not to be confused with natural black pigmentation of claw horn') }}</li>
                                </ul>

                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.sole_hemorrhage_bruise_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.sole_hemorrhage_bruise_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Axial Wall Crack (X)') + $t(':') }}</h5>
                        <modal-info :value="'axial-wall'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Axial Wall Crack (X)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/X.png" alt="Axial Wall Crack (X)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called:  Axial Wall Fissure') }}</p>
                                <p class="px-3"><b>{{ $t('Zones Affected')}}: 11, 12</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Deep groove on interior surface of claw wall parallel to front claw surface') }}</li>
                                    <li>{{ $t('Bleeding may indicate lesion presence') }}</li>
                                    <li>{{ $t('Mild to severe lameness') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.axial_wall_crack_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.axial_wall_crack_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Horizontal Fissure (G)') + $t(':') }}</h5>
                        <modal-info :value="'horizontal-fisure'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Horizontal Fissure (G)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center">
                                    <img src="@/assets/img/lesion/G.png" alt="Horizontal Fissure (G)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Horizontal Wall Fissure, Fissura Ungulae Transversalis') }}</p>
                                <p class="px-3">{{ $t('Zones Affected') }}: 7, 8</p>
                                <p class="px-3">{{ $t('Common signs') }}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Claw wall parallel to the hair-line cracks and eventually breaks off') }}</li>
                                    <li>{{ $t('Caused by nutritional or metabolic stress') }}</li>
                                </ul>

                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.horizontal_fissure_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.horizontal_fissure_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Corkscrew Claw (C)') + $t(':') }}</h5>
                         <modal-info :value="'corkscrew'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Corkscrew Claw (C)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/C.png" alt="Corkscrew Claw (C)" width="200px">
                                </div>
                                <p class="px-3"><b>{{ $t('Zone Affected')}}: 7</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Rapid irregular growth of the claw with rotation') }}</li>
                                    <li>{{ $t('Sole displaced inward and rear') }}</li>
                                    <li>{{ $t('Causes difficulty walking') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.corkscrew_claw_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.corkscrew_claw_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Deep Digital Sepsis') }}:</h5>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.deep_digital_sepsis_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.deep_digital_sepsis_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Heel Horn Erosion (E)') + $t(':') }}</h5>
                         <modal-info :value="'heel-horn'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Heel Horn Erosion (E)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/E.png" alt="Heel Horn Erosion (E)" width="200px">
                                </div>
                                <p class="px-3"><b>{{ $t('Zone Affected')}}: 6</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Severe erosion of heel in irregular pit-like depressions or “v” shaped grooves causing lameness') }}</li>
                                    <li>{{ $t('Instability of the claw due to lost or damaged horn resulting in uneven weight bearing') }}</li>
                                    <li>{{ $t('Heel becomes sore as erosion progresses') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.heel_horn_erosion_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.heel_horn_erosion_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Interdigital Phlegmon/Foot Rot (F)') + $t(':') }}</h5>
                        <modal-info :value="'foot-rot'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Interdigital Phlegmon/Foot Rot (F)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/F.png" alt="Interdigital Phlegmon/Foot Rot (F)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Interdigital Phlegmon, Interdigital Necrobacillosis') }}</p>
                                <p class="px-3"><b>{{ $t('Zone Affected')}}: 9</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Swelling of the entire foot (equally) including the dew claws') }}</li>
                                    <li>{{ $t('Separation of digits, infection produces a noticeable foul odor') }}</li>
                                    <li>{{ $t('Animals will likely have a fever') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.interdigital_phlegmon_foot_rot_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.interdigital_phlegmon_foot_rot_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
            </div>
            <!-- end left column -->

            <!-- right column -->
			<div class="offset-lg-1 col-lg-11">
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Toe Ulcer (T)') + $t(':') }}</h5>
                        <modal-info :value="'toe-ulcer'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Toe Ulcer (T)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/T.png" alt="Toe Ulcer (T)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Toe Necrosis, Apicalis Necrotica') }}</p>
                                <p class="px-3"><b>{{ $t('Zone Affected')}}: 1</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Black mark, blood stain and/or rupture in white line or sole at the toe') }}</li>
                                    <li>{{ $t('Caused by rotation of pedal bone within the claw pressing down on the sole or thin sole') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.toe_ulcer_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.toe_ulcer_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Thin Sole (Z)') + $t(':') }}</h5>
                        <modal-info :value="'thin-sole'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Thin Sole (Z)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/Z.png" alt="Thin Sole (Z)" width="200px">
                                </div>
                                <p class="px-3"><b>{{ $t('Zones Affected')}}: 4, 5</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Sole is thin and flexible when pressure is applied') }}</li>
                                    <li>{{ $t('Caused by insufficient length of toe, excessive wear or over trimming') }}</li>
                                    <li>{{ $t('Minimum claw length of 3.25 inches (8.25 cm) does not apply to heifers or animals that weigh less than 900 lb (400 kg)') }}</li>
                                </ul><div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.thin_sole_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.thin_sole_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('White Line Disease (W)') + $t(':') }}</h5>
                        <modal-info :value="'white-line'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('White Line Disease (W)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/W.png" alt="White Line Disease (W)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: White Line Separation, White Line Disease') }}</p>
                                <p class="px-3"><b>{{ $t('Zones Affected')}}: 1, 2, 3</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('In mild cases, a void occurs in the junction between the sole and the wall') }}</li>
                                    <li>{{ $t('In severe cases, abscesses form, generally at the heel-sole-wall juncture (zone 3)') }}</li>
                                </ul>
                                <a target="_blank" href="/pdf/zcontent_AABP-White-Line.pdf" class="d-inline-block px-3 mb-3">
                                    {{ $t('AABP Fact Sheet – White Line (PDF)') }}
                                </a>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.white_line_disease_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.white_line_disease_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Interdigital Hyperplasia (K)') + $t(':') }}</h5>
                        <modal-info :value="'interdigital-hyperplasia'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Interdigital Hyperplasia (K)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/K.png" alt="Interdigital Hyperplasia (K)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Corn, Interdigital Fibroma, Interdigital Growth') }}</p>
                                <p class="px-3"><b>{{ $t('Zone Affected')}}: 0</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Rapid growth of skin and/or tissue between the digits, forming a firm mass') }}</li>
                                    <li>{{ $t('Secondary infection likely with severe (large) lesion') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.interdigital_hyperplasia_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.interdigital_hyperplasia_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Vertical Fissure (V)') + $t(':') }}</h5>
                        <modal-info :value="'vertical-fissure'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Vertical Fissure (V)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center">
                                    <img src="@/assets/img/lesion/V.png" alt="Vertical Fissure (V)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Sandcrack, Fissura Ungulae Longitudinalis') }}</p>
                                <p class="px-3"><b>{{ $t('Zones Affected')}}: 7, 8</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Vertical split in front or side of claw') }}</li>
                                    <li>{{ $t('Occurs primarily on outside front claws') }}</li>
                                    <li>{{ $t('Often the most painful cause of lameness') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.vertical_fissure_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.vertical_fissure_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Injury (J)') + $t(':') }}</h5>
                            <modal-info :value="'injury'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Injury (J)') }}</h2>
                                <p class="px-3">{{ $t('Two causes of fissure development at the white line junction (especially in the toe region) are excessive hoof wear and sole removal during hoof trimming. This lesion may be referred to as a white line fissure or a toe ulcer, depending on severity.') }}</p>
                                <p class="px-3">{{ $t('Making cows walk down slopes greater than 2 to 4%, long transfer lanes made of concrete between the free stall or dirt lot pens and the milking center or standing in overcrowded (excessively sloped holding areas) are risk factors for excessive hoof wear. Cows will lose heel height and develop thin soles. This makes them more susceptible to claw horn lesions.') }}</p>
                                <p class="px-3">{{ $t('Poor hoof trimming which fails to balance the inner and outer claw or removes too much of the sole increases the risk of an animal becoming lame.') }}</p>
                                <p class="px-3">{{ $t('Pushing cows to move quickly on rough cow tracks between the pasture and the milking center may result in sole penetration by a rock, a white line lesion or axial wall crack.') }}</p>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.injury_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.injury_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row mb-3">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Digital Dermatitis (D)') + $t(':') }}</h5>
                        <modal-info :value="'digital-dermatitis'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Digital Dermatitis (D)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/D.png" alt="Digital Dermatitis (D)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Hairy Heel Warts, Mortellaro Disease') }}</p>
                                <p class="px-3"><b>{{ $t('Zones Affected')}}: 9, 10</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Raw, bright-red or black circular growth above the heel bulbs, with edges forming a white opaque ring or hard, thin, hairy, wart-like growths or sores') }}</li>
                                    <li>{{ $t('Affected cattle are reluctant to walk or are lame') }}</li>
                                </ul>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.digital_dermatitis_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.digital_dermatitis_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
                <div class="row">
                    <div class="col-24">
                        <h5 class="mb-1 d-inline-block">{{ $t('Interdigital Dermatitis (I)') + $t(':') }}</h5>
                         <modal-info :value="'interdigital-dermatitis'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Interdigital Dermatitis (I)') }}</h2>
                                <div class="d-flex align-items-center justify-content-center mb-3">
                                    <img src="@/assets/img/lesion/I.png" alt="Interdigital Dermatitis (I)" width="200px">
                                </div>
                                <p class="px-3">{{ $t('Also called: Stable Foot Rot, Scald') }}</p>
                                <p class="px-3"><b>{{ $t('Zones Affected')}}: 0, 10</b></p>
                                <p class="px-3">{{ $t('Common signs')}}:</p>
                                <ul class="ul">
                                    <li>{{ $t('Discharge and destruction of skin between the claws') }}</li>
                                    <li>{{ $t('Bulb horn clefts leading to contusion of the corium and ulceration') }}</li>
                                </ul><div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'trim'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Lame') }}</label>
                        <currency-input
                          v-model="assessor.data.interdigital_dermatitis_lame"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                    <div v-show="assessor.data.what_type_of_lesions_do_you_want_to_enter !== 'lame'" :class="columnBasedOnLesionSelected">
                        <label class="mb-1">{{ $t('Trim') }}</label>
                        <currency-input
                          v-model="assessor.data.interdigital_dermatitis_trim"
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
				</div>
			</div>
            <!-- end right column -->
		</div>
        <!-- end nutrition supply -->

        <hr class="mt-4">

		<div class="row my-0">
			<div class="col-md-12">
				<personal-notes v-model="assessor.data.personal_notes"></personal-notes>
			</div>
			<div class="col-md-12">
				<presentation-notes v-model="assessor.data.presentation_notes"></presentation-notes>
			</div>
		</div>

		<div class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4">
			<photoUpload :assessorId="17"  :assessor="assessor" ></photoUpload>
		</div>

		<hr class="mb-3 mb-md-5 mt-3 mt-md-5">

		<div class="row misc-bottom">
			<div class="col-md-12 flex-sm-row-reverse">
				<a class="btn btn-secondary btn-full--sm mb-2 mt-md-0" href="javascript:history.go(-1)">{{ "Back" | translate }}</a>
			</div>
			<div class="col-md-12">
				<router-link v-if="user && user.role !== 'data_entry'" :to="'/reports'" class="btn btn-primary btn-full--sm pull-right">
					{{"Reports" | translate}}
				</router-link>
				<button v-else-if="user && user.role === 'data_entry' && !$store.state.offline" data-toggle="modal" @click="requestReport(evaluation.name)" :data-target="['#requestSent-' + user.id]" class="btn btn-primary btn-full--sm pull-right">
					{{ 'Request Report' | translate }}
					<span v-show="requestReportLoader" class="loader ml-1"></span>
				</button>
			</div>
		</div>

		<div v-show="successMessage" class="alert alert-primary mt-3 mb-0" role="alert">
			{{ 'Your request has been sent!' | translate }}
		</div>

		<div v-show="errorMessage" class="alert alert-danger mt-3 mb-0" role="alert">
			{{ 'An error has occurred during your request!' | translate }}
		</div>
	</div>
	<!-- end container -->
</template>

<script>
	import _ from 'lodash';
	import PresentationNotes from '@/pages/assessors/assessor_components/presentation-notes';
	import PersonalNotes from '@/pages/assessors/assessor_components/personal-notes';
    import Question from '@/pages/assessors/assessor_components/question';
	import QuestionThree from '@/pages/assessors/assessor_components/question-three';
    import ModalInfo from '@/components/misc/modal-info';
	import PhotoUpload from '@/components/misc/photoUpload';
	import HygieneHeader from '@/pages/assessors/assessor_components/hygiene-header';
	import convert from 'convert-units';
    import Lesion from '@/libs/Classes/Lesion.js';
	import Evaluation from '@/libs/Classes/Evaluation.js';
	import Group from '@/libs/Classes/Group.js';
    import requestReportMixin from '@/mixins/requestReport.js';
    import CurrencyInput from "@/components/common/CurrencyInput.vue";

	export default {
		components: {
			PersonalNotes,
			PresentationNotes,
            Question,
			QuestionThree,
			HygieneHeader,
			PhotoUpload,
			ModalInfo,
			CurrencyInput,
		},
          mixins: [requestReportMixin],
		props: ['assessor', 'group', 'evaluation'],
		data() {
			return {
				lesion: null
			}
		},
		created() {
            let evaluationClass = new Evaluation(this, this.evaluation);
			let groupClass = new Group(this, evaluationClass, this.group);
            // this.user = this.$store.getters.getData("user");
            this.lesion = new Lesion(this, evaluationClass, groupClass, this.assessor);
		},
		computed: {
            columnBasedOnLesionSelected() {
                let column = 'col-24';
                if(this.assessor.data.what_type_of_lesions_do_you_want_to_enter === 'both') {
                    return column = 'col-lg-12';
                }
                return column;
            }
		},
		methods: {
            updateValue(e) {
			  let output = ''
			  if (e.target.type === 'number' || e.target.type === 'tel') {
                output = parseInt(e.target.value) ? parseInt(e.target.value) : 0
              } else {
                output = e.target.value
              }
              this.assessor.data[e.target.name] = output
              this.$forceUpdate()
            }
		}
	}
</script>
