const Table = require('../../Misc/Table');

export const dirtLot = (app, report, evaluation, group, assessor) => {
  let slide;

  const categoriesObj = [
    {
      text: app.$t('Question'),
      options: report.styleOptions.tableHeader
    },
    {
      text: app.$t('Answer'),
      options: report.styleOptions.tableHeader
    },
    {
      text: app.$t('Comment'),
      options: report.styleOptions.tableHeader
    }
  ]

  let contentObj = [];

  if (assessor.getCorralAreaPerCow() !== null) {
    contentObj.push([
      {
        text:
          app.$t('What is the corral area per cow ') + '(' +
          app.$t(app.$getEquivalentUnit(
            evaluation.getMeasurements(),
            'square meters'
          )) +
          ')' +
          app.$t('?'),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text: app.$numberFormat(assessor.getCorralAreaPerCow()),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getCorralAreaPerCow() < assessor.getTargetCorralAreaPerCow()
            ? app.$t('Target area per cow should be at least ') +
              app.$numberFormat(assessor.getTargetCorralAreaPerCow()) + ' ' +
              app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(),'square meters')) +
              app.$t('. Consider enlarging the corral area.')
            : app.$t('Good! Target area is at least ') +
              app.$numberFormat(assessor.getTargetCorralAreaPerCow()) +
              app.$t(' ') +
              app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) +  '.',
        options: {
          align: 'left',
          fill: 'efefef',
          color:
            assessor.getCorralAreaPerCow() <
            assessor.getTargetCorralAreaPerCow()
              ? 'a40000'
              : '249824',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getAtLeastOneHeadlockPerCow() !== null) {
    contentObj.push([
      {
        text: app.$t('Is there at least one headlock (feed space) per cow?'),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAtLeastOneHeadlockPerCow() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        // TODO: Check why this doesn't return true when using strict equals operator
        text:
          assessor.getAtLeastOneHeadlockPerCow() === 1
            ? app.$t('Good!')
            : app.$t('There should be at least one headlock/feed space per cow.'),
        options: {
          align: 'left',
          color:
            assessor.getAtLeastOneHeadlockPerCow() === 1 ? '249824' : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getConcreteFeedingAreaScrapedAtLeastOncePerDay() !== null) {
    contentObj.push([
      {
        text: app.$t(
          'Is the concrete feeding area scraped at least once a day?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getConcreteFeedingAreaScrapedAtLeastOncePerDay() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        // TODO: Check why this doesn't return true when using strict equals operator
        text:
          assessor.getConcreteFeedingAreaScrapedAtLeastOncePerDay() === 1
            ? app.$t('Good!')
            : app.$t('Manure accumulation at the bunk increases the risk of infectious hoof disease.'),
        options: {
          align: 'left',
          color:
            assessor.getConcreteFeedingAreaScrapedAtLeastOncePerDay() === 1
              ? '249824'
              : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getBeddingBeneathFreeOfWetManureContamination() !== null) {
    contentObj.push([
      {
        text: app.$t(
          'Is the bedding beneath the shade dry and free of wet manure contamination?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getBeddingBeneathFreeOfWetManureContamination() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        // TODO: Check why this doesn't return true when using strict equals operator
        text:
          assessor.getBeddingBeneathFreeOfWetManureContamination() === 1
            ? app.$t('Good!')
            : app.$t(
                'Wet areas must be removed each milking and replaced with dry bedding material.'
              ),
        options: {
          align: 'left',
          color:
            assessor.getBeddingBeneathFreeOfWetManureContamination() === 1
              ? '249824'
              : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getAtLeast3InchesOfLooseManureInLyingArea() !== null) {
    contentObj.push([
      {
        text:
          evaluation.getMeasurements() === "metric"
            ? app.$t(
                "Is there at least 7.5 cm of loose dirt/dried manure in the lying area?"
              )
            : app.$t(
                "Is there at least 3 in of loose dirt/dried manure in the lying area?"
              ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          assessor.getAtLeast3InchesOfLooseManureInLyingArea() === 1
            ? app.$t("Yes")
            : app.$t("No"),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        // TODO: Check why this doesn't return true when using strict equals operator
        text:
          assessor.getAtLeast3InchesOfLooseManureInLyingArea() === 1
            ? app.$t("Good!")
            : app.$t(
                "Surface cushion is one of the most important aspects of the lying surface, and the amount of cushioning affects lying times."
              ),
        options: {
          align: "left",
          color:
            assessor.getAtLeast3InchesOfLooseManureInLyingArea() === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getLyingAreaPassKneeDropTest() !== null) {
    contentObj.push([
      {
        text: app.$t('Does the lying area pass the knee drop test?'),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getLyingAreaPassKneeDropTest() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getLyingAreaPassKneeDropTest() === 1
            ? app.$t('Good!')
            : app.$t(
                'Surface cushion is one of the most important aspects of the lying surface, and the amount of cushioning affects lying times.'
              ),
        options: {
          align: 'left',
          color:
            assessor.getLyingAreaPassKneeDropTest() === 1 ? '249824' : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (contentObj.length > 0) {
    slide = report.pptx.addSlide('Slide master');

    slide.addText(
      app.$t('Dirt Lot Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    );

    slide.addText(app.$t('Your Operation'), {
      x: 0.5,
      y: 2.4,
      ...report.styleOptions.subheaderBlue
    });

    Table.create(
      slide,
      categoriesObj,
      contentObj,
      report,
      group,
      'Dirt Lot Assessor',
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions
      }
    );
  }
};
