import { getPageSize } from "@/constants";

const Table = require("../../Misc/Table");
export const beddedPack = (app, report, evaluation, group, assessor) => {

  try {

    const lang  = app.user.lang
    console.log({lang: app.user.lang});
    // let slide = report.pptx.addSlide("Slide master");
  const TITLE_Y_START = 2.4;
  const TABLE_Y_START = 2.7;

  const PAGE_SIZE = getPageSize(app.user.lang); // we'll create slide after this 
  const breakpoints = [0];
  const defaultValues = {tableY:TABLE_Y_START, titleY: TITLE_Y_START}

  let titleY = TITLE_Y_START;
  let tableY  = TABLE_Y_START;


 function makePageBreak({tableY, tableValue, titleY, titleValue}, defaultVal) {
  if(tableY + tableValue > PAGE_SIZE){
    const pageBreak = contentObj.length -1 /* because this value is greater the available space, 
    if break after this row our content get overflow, that's why need to break the slide before add this row the page */
    breakpoints.push(pageBreak);
    return {tableY: defaultVal.tableY  + tableValue, titleY: defaultVal.titleY + titleValue}
  }
    return {tableY: tableY+ tableValue , titleY: titleY + titleValue}
}

  const categoriesObj = [
    {
      text: app.$t("Question"),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t("Answer"),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t("Comment"),
      options: report.styleOptions.tableHeader,
    },
  ];

  let contentObj = [];

  if (group.getCowSizeLabel() !== null) {
    contentObj.push([
      {
        text:
          app.$t("Average Cow Size on Bedded Pack? (") +
          app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "kg")) +
          ")",
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: group.getCowSizeLabel(),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    /* if the current row is bigger than available space avaiable, this func add the rowNum in the array, so we create the new slide, before to add this row,
     and reset out table and slide values. */

    ({tableY, titleY} = makePageBreak({tableY, tableValue: 0.4, titleY,titleValue: 0.4 }, defaultValues) ) 


    console.log(tableY, titleY);
  }

  // needs recommended calculation implemented
  // second row
  if (assessor.getWhatIsTheBeddingAreaPerCow() !== null) {
    contentObj.push([
      {
        text:
          app.$t("Bedding/Resting Area Per Cow (") +
          app.$t(assessor.getSquareMeasurements()) +
          ")?",
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(assessor.getWhatIsTheBeddingAreaPerCow()),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          app.$t("For ") +
          group.getCowSizeLabel() +
          " " +
          app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "kg")) +
          app.$t(" cows, the target bedded area is ") +
          assessor.getTargetWhatIsTheBeddingAreaPerCow() +
          " " +
          app.$t(
            app.$getEquivalentUnit(
              evaluation.getMeasurements(),
              "square meters"
            )
          ) +
          app.$t(" per cow."),
        options: {
          align: "left",
          color:
            Number(assessor.getWhatIsTheBeddingAreaPerCow()) <
            Number(assessor.getTargetWhatIsTheBeddingAreaPerCow())
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    ({tableY, titleY} = makePageBreak({tableY, tableValue: 0.55, titleY,titleValue: 0.55 }, defaultValues) ) 

    // tableY += 0.55;
    // titleY += 0.55;
    console.log(tableY, titleY);
  }

  //  third row
  if (assessor.getIsThePackManagedAerobicallyOrAnaerobically() !== null) {
    const isAerobic =
      assessor.getIsThePackManagedAerobicallyOrAnaerobically() === "aerobic";
    contentObj.push([
      {
        text: app.$t("How is Pack Managed?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isAerobic ? app.$t("Aerobic (Compost)") : app.$t("Anaerobic"),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isAerobic ? 0.55 : 0.4;
    ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
    // tableY += isAerobic ? 0.55 : 0.4;
    // titleY += isAerobic ? 0.55 : 0.4;
    console.log(tableY, titleY);
  }

  // fourth row
  if (assessor.getLongAxisOrientationOfTheBarn() !== null) {
    const isE_to_w = assessor.getLongAxisOrientationOfTheBarn() === "e_to_w";
    contentObj.push([
      {
        text: app.$t("Long Axis Orientation of the Barn"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: assessor.getLongAxisOrientationOfTheBarnText(),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: assessor.getLongAxisOrientationOfTheBarnComment(),
        options: {
          align: "left",
          color: isE_to_w ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isE_to_w ? 0.55 : 1.25;
    ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

    // tableY += isE_to_w ? 0.55 : 1.25;
    // titleY += isE_to_w ? 0.55 : 1.25;
    console.log(tableY, titleY);
  }

  if (
    assessor.getInAerobicBarnsHowFrequentlyIsThePackStirred() !== null &&
    assessor.getIsThePackManagedAerobicallyOrAnaerobically() === "aerobic"
  ) {

    // fifth row
    if (assessor.getHowFrequentlyIsFreshBeddingAdded() !== null) {
      const isTrue =
        assessor.getHowFrequentlyIsFreshBeddingAdded() >= 18 &&
        assessor.getHowFrequentlyIsFreshBeddingAdded() <= 40;
      contentObj.push([
        {
          text: app.$t("How Frequently is Fresh Bedding Added (days)?"),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getHowFrequentlyIsFreshBeddingAdded()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t(
                "Good! Add fresh bedding as needed to increase particle size or reduce pack moisture."
              )
            : app.$t(
                "Fresh bedding should be added every 18 to 40 days or more frequently, depending on weather conditions, stocking rate, production level and udder hygiene score."
              ),
          options: {
            align: "left",
            color:
              assessor.getHowFrequentlyIsFreshBeddingAdded() >= 18 &&
              assessor.getHowFrequentlyIsFreshBeddingAdded() <= 40
                ? "249824"
                : "a40000",
            fill: "efefef",
            bold: false,
          },
        },
      ]);

      const value = isTrue ? 0.7 : 0.95;
      ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  
      // tableY += isTrue ? 0.7 : 0.95;
      // titleY += isTrue ? 0.7 : 0.95;
      console.log(tableY, titleY);
    }

    // sixth row
    if (assessor.getHowMuchFreshBeddingIsAdded()) {
      const isTrue =
        assessor.getHowMuchFreshBeddingIsAdded() <
        assessor.getTargetHowMuchFreshBeddingIsAddedAerobicMin();
      contentObj.push([
        {
          text:
            app.$t("Amount of Fresh Bedding Added per Cow per Day (") +
            app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "kg")) +
            app.$t(")?"),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getHowMuchFreshBeddingIsAdded()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t(
                "Fresh bedding must be added to correct the moisture and particle size of the bedding."
              )
            : app.$t("Good! The amount of fresh bedding added can vary from") +
              " " +
              assessor.getTargetHowMuchFreshBeddingIsAddedAerobicMin() +
              " " +
              app.$t("to") +
              " " +
              assessor.getTargetHowMuchFreshBeddingIsAddedAerobicMax() +
              " " +
              app.$t(
                app.$getEquivalentUnit(evaluation.getMeasurements(), "kg")
              ) +
              " " +
              app.$t(
                "daily per cow depending on humidity, stocking rate, milk production and udder hygiene score."
              ),
          options: {
            align: "left",
            color: isTrue ? "a40000" : "249824",
            fill: "efefef",
            bold: false,
          },
        },
      ]);

      const value = isTrue ? 0.7 : 1.1;
      ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  

      // tableY += isTrue ? 0.7 : 1.1;
      // titleY += isTrue ? 0.7 : 1.1;
      // console.log(tableY, titleY);
    }

    // seventh row
    if (assessor.getInAerobicBarnsHowFrequentlyIsThePackStirred() !== null) {
      const isTrue =
        assessor.getInAerobicBarnsHowFrequentlyIsThePackStirred() >= 2;
      contentObj.push([
        {
          text: app.$t("Frequency Pack is Stirred (Times/Day)?"),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getInAerobicBarnsHowFrequentlyIsThePackStirred()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t(
                "Good! During times of high humidity, increase the frequency of stirring during the driest time of the day."
              )
            : app.$t(
                "For adequate composting the pack should be stirred at least twice a day."
              ),
          options: {
            align: "left",
            color: isTrue ? "249824" : "a40000",
            fill: "efefef",
            bold: false,
          },
        },
      ]);
      const value =  0.7;
      ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  
      // tableY += 0.7;
      // titleY += 0.7;
      console.log(tableY, titleY);
    }

    // eighth row
    if (assessor.getPercentageOfCowsHaveScoreOfThreeOrFour() !== null) {
      const isTrue = assessor.getPercentageOfCowsHaveScoreOfThreeOrFour() <= 20;
      contentObj.push([
        {
          text: app.$t(
            "What Percentage of Cows have an Udder Hygiene Score of 3 or 4?"
          ),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getPercentageOfCowsHaveScoreOfThreeOrFour()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t("Good!")
            : app.$t(
                "Bedding frequency should be determined by the udder hygiene score. If greater than 20% of cows have a hygiene score of 3 or 4, increase bedding frequency."
              ),
          options: {
            align: "left",
            color: isTrue ? "249824" : "a40000",
            fill: "efefef",
            bold: false,
          },
        },
      ]);
      const value =   isTrue ? 0.5 : 0.95;
      ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  
      // tableY += isTrue ? 0.5 : 0.95;
      // titleY += isTrue ? 0.5 : 0.95;
      console.log(tableY, titleY);
    }

    // ninth row
    if (assessor.getTemperatureOfBeddedPack()) {
      const isTrue =
        assessor.getWhatIsTheTemperatureOfTheBeddedPack() <=
        assessor.getTemperatureOfBeddedPackMin();
      contentObj.push([
        {
          text: app.$t("What is the Temperature of the Bedded Pack?"),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.formatTemperature(
            assessor.getWhatIsTheTemperatureOfTheBeddedPack()
          )),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t("Compost temperature should remain above") +
              " " +
              assessor.formatTemperature(
                assessor.getTemperatureOfBeddedPackMin()
              ) +
              " " +
              app.$t(
                "to effectively kill pathogens and improve hygiene. Add additional bedding to maintain a higher pack temperature."
              )
            : app.$t("Good!"),
          options: {
            align: "left",
            color: isTrue ? "a40000" : "249824",
            fill: "efefef",
            bold: false,
          },
        },
      ]);

      const value =   isTrue ? 1.1 : 0.55;
      ({tableY, titleY} = makePageBreak({tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  
      // tableY += isTrue ? 1.1 : 0.55;
      // titleY += isTrue ? 1.1 : 0.55;
    }

    if (assessor.getHowFrequentlyIsTheWholeOrPartialBedRemoved()) {
      const isTrue =
        assessor.getHowFrequentlyIsTheWholeOrPartialBedRemoved() > 12;
      contentObj.push([
        {
          text: app.$t(
            "How Frequently is the Whole or Partial Bed Removed (months)?"
          ),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getHowFrequentlyIsTheWholeOrPartialBedRemoved()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t(
                "A majority of the pack should be removed if it affects the management of the pack. This includes the ability to effectively stir the pack, cow flow and movement and hygiene."
              )
            : app.$t(
                "Good! Removing at least some of the pack once per year is acceptable, twice per year (spring and fall) is optimal."
              ),
          options: {
            align: "left",
            color: isTrue ? "a40000" : "249824",
            fill: "efefef",
            bold: false,
          },
        },
      ]);

      const value =   isTrue ? 1.1 : 1.3;
      ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
 
      // tableY += isTrue ? 1.1 : 0.95;
      // titleY += isTrue ? 1.1 : 0.95;
    }
  }
  if (
    assessor.getHowMuchFreshBeddingIsAdded() !== null &&
    assessor.getIsThePackManagedAerobicallyOrAnaerobically() === "anaerobic"
  ) {
    const isTrue =
      assessor.getHowMuchFreshBeddingIsAdded() <
      assessor.getTargetHowMuchFreshBeddingIsAdded();
    contentObj.push([
      {
        text:
          app.$t("Amount of Fresh Bedding Added per Cow per Day (") +
          app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "kg")) +
          app.$t(")?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(assessor.getHowMuchFreshBeddingIsAdded()),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Target application is ") +
            app.$numberFormat(assessor.getTargetHowMuchFreshBeddingIsAdded()) +
            app.$t(" ") +
            app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "kg")) +
            app.$t(" of fresh bedding per cow per day.")
          : app.$t("Good!"),
        options: {
          align: "left",
          color:
            assessor.getHowMuchFreshBeddingIsAdded() <
            assessor.getTargetHowMuchFreshBeddingIsAdded()
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
 
    const value =  isTrue ?  0.70 : 1.10;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

    // tableY += isTrue ? 0.5 : 0.5;
    // titleY += isTrue ? 0.5 : 0.5;
    console.log(tableY, titleY);

    if (assessor.getPercentageOfCowsHaveScoreOfThreeOrFour() !== null) {
      const isTrue = assessor.getPercentageOfCowsHaveScoreOfThreeOrFour() <= 20;
      contentObj.push([
        {
          text: app.$t(
            "What Percentage of Cows have an Udder Hygiene Score of 3 or 4?"
          ),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getPercentageOfCowsHaveScoreOfThreeOrFour()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t("Good!")
            : app.$t(
                "Bedding frequency should be determined by the udder hygiene score. If greater than 20% of cows have a hygiene score of 3 or 4, increase bedding frequency."
              ),
          options: {
            align: "left",
            color: isTrue ? "249824" : "a40000",
            fill: "efefef",
            bold: false,
          },
        },
      ]);

      const value =   isTrue ? 0.55 : 0.95;
      ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
 
      // tableY += isTrue ? 0.55 : 0.95;
      // titleY += isTrue ? 0.55 : 0.95;
      console.log(tableY, titleY);
    }

    if (assessor.getHowFrequentlyIsTheWholeBedRemoved() !== null) {
      const isTrue = assessor.getHowFrequentlyIsTheWholeBedRemoved() <= 4;
      contentObj.push([
        {
          text: app.$t("How Frequently is the Whole Bed Removed (weeks)?"),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: app.$numberFormat(assessor.getHowFrequentlyIsTheWholeBedRemoved()),
          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t("Good!")
            : app.$t(
                "For anaerobic beds, the whole bed should be removed every 4 weeks."
              ),
          options: {
            align: "left",
            color: isTrue ? "249824" : "a40000",
            fill: "efefef",
            bold: false,
          },
        },
      ]);

      const value = 0.55;
      ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
      console.log(tableY, titleY);

    }

  }

  if (assessor.getWhatIsTheWidthOfTheFeedAlley() !== null) {
    const isTrue =
      assessor.getWhatIsTheWidthOfTheFeedAlley() >=
        assessor.getWhatIsTheWidthOfTheFeedAlleyMin() &&
      assessor.getWhatIsTheWidthOfTheFeedAlley() <=
        assessor.getWhatIsTheWidthOfTheFeedAlleyMax();
    contentObj.push([
      {
        text:
          app.$t("What is the Width of the Feed Alley (") +
          app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "m")) +
          app.$t(")?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(assessor.getWhatIsTheWidthOfTheFeedAlley()),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t("Feed alleyways should be between") +
            " " +
            app.$numberFormat(assessor.getWhatIsTheWidthOfTheFeedAlleyMin()) +
            " " +
            app.$t("and") +
            " " +
            app.$numberFormat(assessor.getWhatIsTheWidthOfTheFeedAlleyMax()) +
            " " +
            app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), "m")) +
            " " +
            app.$t(
              "to help reduce the transfer of manure from alleyways to beds and the depth of accumulated manure."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ? 0.4 : 0.95;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

  }

  ///questions block
  if (
    assessor.getInAerobicBarnsHowFrequentlyIsThePackStirred() !== null &&
    assessor.getIsThePackManagedAerobicallyOrAnaerobically() === "aerobic"
  ) {
    if (assessor.getIsTheBedStirredToTheProperDepth() !== null) {
      const isTrue = assessor.getIsTheBedStirredToTheProperDepth() === 1;
      contentObj.push([
        {
          text: app.$t("Is the bed stirred to the proper depth?"),
          options: {
            color: "000000",
            align: "left",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue ? app.$t("Yes") : app.$t("No"),

          options: {
            color: "000000",
            fill: "ffffff",
            bold: false,
          },
        },
        {
          text: isTrue
            ? app.$t("Good!")
            : app.$t("The bed should be stirred to within") +
              " " +
              assessor.getProperDepthFirstValue() +
              " " +
              app.$t(assessor.getProperDepthMeasurements()) +
              " " +
              app.$t(
                "of the pack base. If it is not possible to achieve the depth on very deep packs aim for"
              ) +
              " " +
              assessor.getProperDepthSecondValue() +
              " " +
              app.$t(assessor.getProperDepthMeasurements()) +
              " " +
              app.$t("depth."),
          options: {
            align: "left",
            color:
              assessor.getIsTheBedStirredToTheProperDepth() === 1
                ? "249824"
                : "a40000",
            fill: "efefef",
            bold: false,
          },
        },
      ]);
      const value =  isTrue ? 0.4 : 0.95;
      ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  
    }
  }

  if (
    assessor.getIsTheBeddedAreaDemarcatedFromTheConcreteFeedingAreaByABeddingRetainer() !==
    null
  ) {
    const isTrue =
      assessor.getIsTheBeddedAreaDemarcatedFromTheConcreteFeedingAreaByABeddingRetainer() ===
      1;
    contentObj.push([
      {
        text: app.$t(
          "Is the bedded area separated from the concrete feeding area by a bedding retainer or curb?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue ? app.$t("Yes") : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t(
              "A bedding retainer will prevent spill-over of bedding on the concrete area and helps to keep bedding and slurry separate."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ? 0.7 : 0.95;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

    // tableY += isTrue ? 0.7 : 0.95;
    // titleY += isTrue ? 0.7 : 0.95;
    console.log(tableY, titleY);
  }
  if (assessor.getIsTheConcreteFeedingAreScrapedAtLeastTwiceADay() !== null) {
    const isTrue =
      assessor.getIsTheConcreteFeedingAreScrapedAtLeastTwiceADay() === 1;

    contentObj.push([
      {
        text: app.$t(
          "Is the concrete feeding area scraped at least twice a day?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue ? app.$t("Yes") : app.$t("No"),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t(
              "A build up of manure in the feed alley will increase risk of infectious hoof conditions."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ?  0.55 : 0.7;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

    // tableY += isTrue ? 0.55 : 0.7;
    // titleY += isTrue ? 0.55 : 0.7;
    console.log(tableY, titleY);
  }

  if (assessor.getIsPackExcessivelyWetAroundAccessPoints() !== null) {
    const isTrue = assessor.getIsPackExcessivelyWetAroundAccessPoints() !== 1;
    contentObj.push([
      {
        text: app.$t(
          "Is the pack excessively wet and sloppy around the access points from alleyways and the feed bunk?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          assessor.getIsPackExcessivelyWetAroundAccessPoints() === 1
            ? app.$t("Yes")
            : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : assessor.getIsThePackManagedAerobicallyOrAnaerobically() ===
            "anaerobic"
          ? app.$t("Access points should be created every") +
            " " +
            assessor.getAccessPointsDistance() +
            " " +
            app.$t(
              "or less with no fewer than two access points. Increase access points and/or bedding application."
            )
          : app.$t("Access points should be created every") +
            " " +
            assessor.getAccessPointsDistance() +
            " " +
            app.$t(
              "or less with no fewer than two access points. Increase access points or frequency of stirring and/or bedding application."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ?   0.95 : 1.1;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 


    // tableY += isTrue ? 0.7 : 1.1;
    // titleY += isTrue ? 0.7 : 1.1;
    console.log(tableY, titleY);
  }

  if (assessor.getIsTheAreaWellVentilatedWithFansOverTheBeddedArea() !== null) {
    const isTrue =
      assessor.getIsTheAreaWellVentilatedWithFansOverTheBeddedArea() === 1;
    contentObj.push([
      {
        text: app.$t("Is the area ventilated with fans over the bedded area?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue ? app.$t("Yes") : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t(
              "Adequate ventilation is required to reduce humidity and risk of respiratory disease and heat stress."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ?  0.55 : 0.7;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 


    // tableY += isTrue ? 0.55 : 0.7;
    // titleY += isTrue ? 0.55 : 0.7;
    console.log(tableY, titleY);
  }

  if (
    assessor.getWhatIsTheWindsSpeedCreatedByTheFans() !== null &&
    assessor.getIsTheAreaWellVentilatedWithFansOverTheBeddedArea() === 1
  ) {
    const isTrue =
      assessor.getWhatIsTheWindsSpeedCreatedByTheFans() === "more_two_m_s";
    contentObj.push([
      {
        text: app.$t("What is the wind speed created by the fans?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? "≥ " + assessor.getFanWentSpeedThresholdText()
          : "< " + assessor.getFanWentSpeedThresholdText(),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!") +
            " " +
            assessor.getFanWentSpeedThresholdText() +
            " " +
            app.$t("wind speed is good for ventilation and") +
            " " +
            assessor.getFanWentPerfectSpeedThresholdText() +
            " " +
            app.$t("should be the ideal wind speed to reduce heat stress.")
          : app.$t(
              "Inadequate wind speed will result in higher ammonia levels and increased humidity of the bedding. Increased risk of heat stress results in poor animal performance."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ?  0.95 : 1.25;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

    // tableY += isTrue ? 0.95 : 1.1;
    // titleY += isTrue ? 0.95 : 1.1;
    console.log(tableY, titleY);
  }

  if (assessor.getIsThereSmellOfAmmoniaPresentInTheBarn() !== null) {
    const isTrue = assessor.getIsThereSmellOfAmmoniaPresentInTheBarn() !== 1;
    contentObj.push([
      {
        text: app.$t("Is there a smell of ammonia present in the barn?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          assessor.getIsThereSmellOfAmmoniaPresentInTheBarn() === 1
            ? app.$t("Yes")
            : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t(
              "A high ammonia smell indicates inadequate ventilation. Consider adding or improving mechanical ventilation."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ? 0.55 : 0.95;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

    console.log(tableY, titleY);
  }

  if (assessor.getDoesThePackHaveTheCorrectMoistureConsistency() !== null) {
    const isTrue =
      assessor.getDoesThePackHaveTheCorrectMoistureConsistency() === 1;
    contentObj.push([
      {
        text: app.$t("Does the pack have the correct moisture consistency?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue ? app.$t("Yes") : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t(
              "For aerobic (compost) packs, moisture levels must be maintained between 40 and 65%. For anaerobic, the bedding surface should be dry."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  isTrue ? 0.55 : 0.95;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
  }

  if (
    assessor.getInAerobicBarnsHowFrequentlyIsThePackStirred() !== null &&
    assessor.getIsThePackManagedAerobicallyOrAnaerobically() === "aerobic"
  ) {
    const isTrue = assessor.getDoesThePackRaiseDustWhenTheAnimalsWalk() !== 1;
    contentObj.push([
      {
        text: app.$t("Does the pack raise dust when the animals walk?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          assessor.getDoesThePackRaiseDustWhenTheAnimalsWalk() === 1
            ? app.$t("Yes")
            : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t("Add water to the pack before it is stirred."),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value =  0.55;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 
    // tableY += 0.55;
    // titleY += 0.55;
  }

  if (assessor.getIsThereEvidenceOfLiquidPoolingInTheBarn() !== null) {
    const isTrue = assessor.getIsThereEvidenceOfLiquidPoolingInTheBarn() !== 1;
    contentObj.push([
      {
        text: app.$t("Is there evidence of liquid “pooling” in the barn?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          assessor.getIsThereEvidenceOfLiquidPoolingInTheBarn() === 1
            ? app.$t("Yes")
            : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : app.$t(
              "Poor drainage will increase bedding moisture levels and put cows at risk of infectious hoof disease and/or mastitis."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isTrue ? 0.55 : 0.95;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

  }

  if (assessor.getIsTheBeddingSoftAndComfortable() !== null) {
    const isTrue = assessor.getIsTheBeddingSoftAndComfortable() === 1;
    contentObj.push([
      {
        text: app.$t("Is the bedding soft and comfortable?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue ? app.$t("Yes") : app.$t("No"),

        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t("Good!")
          : assessor.getIsThePackManagedAerobicallyOrAnaerobically() ===
            "aerobic"
          ? app.$t(
              "For aerobic packs, scarify the surface and increase the frequency that the pack is stirred. If necessary, add fresh bedding."
            )
          : app.$t(
              "Add fresh bedding or increase the frequency of adding fresh bedding, especially in wet areas."
            ),
        options: {
          align: "left",
          color: isTrue ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isTrue ? 0.4 : 0.95;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

  }

  if (assessor.getCanCowsAccessWaterTroughsFromTheBeddedArea() !== null) {
    const isTrue =
      assessor.getCanCowsAccessWaterTroughsFromTheBeddedArea() === 1;
    contentObj.push([
      {
        text: app.$t("Can cows access water troughs from the bedded area?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue ? app.$t("Yes") : app.$t("No"),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: isTrue
          ? app.$t(
              "Cows should not be able to drink from the bedded area as the bedding will become spoiled by splashed water."
            )
          : app.$t("Good!"),
        options: {
          align: "left",
          color: isTrue ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isTrue ? 0.7 : 0.55;
    ({tableY, titleY} = makePageBreak({ tableY, tableValue: value, titleY,titleValue: value }, defaultValues) ) 

  }

  // [0, 1, 2, 3]
  writeTableOnSlide({app,Table, breakpoints, report, contentObj, group, assessorName: "Bedded Pack Assessor", TITLE_Y_START, TABLE_Y_START, categoriesObj, tableTitle: "Your Operation"})

 
} catch (error) {
    console.log(error);
  }
};


export function writeTableOnSlide({
  app,
  Table,
  breakpoints,
  report,
  contentObj,
  categoriesObj,
  group,
  assessorName,
  TITLE_Y_START,
  TABLE_Y_START,
  tableTitle,
  prevSlide,
  prevTableEndAt,
  prevTitleEndAt
}) {
  for (let i = 0; i < breakpoints.length; i++) {
    const currentBreakPoint = breakpoints[i];
    const nextBreakPoint = breakpoints[i + 1];
    let cloneContent = []

    let tableStartFrom = TABLE_Y_START // page top after header
    let titleStartFrom = TITLE_Y_START

    let slide; // we assign it the prev remaining slide or we'll assign it new slide 

 
    // if last slide had enough space to start table from, so we start from there.
    if(prevTableEndAt && prevTitleEndAt && i === 0) {
      tableStartFrom = prevTableEndAt;
      titleStartFrom = prevTitleEndAt;
      slide = prevSlide;
  } else {
    slide = report.pptx.addSlide("Slide master");
  }

     // add slide title
     slide.addText(
      app.$t(assessorName) + app.$t(" - ") + group.getName(),
      report.styleOptions.slideHeader
    );


    slide.addText(app.$t(tableTitle), {
      x: 0.5,
      y: titleStartFrom,
      ...report.styleOptions.subheaderBlue,
    });

    if(currentBreakPoint === 0){
      cloneContent = contentObj.slice(i, nextBreakPoint)
    } else if((breakpoints.length - 1) === currentBreakPoint){
      cloneContent = contentObj.slice(currentBreakPoint)
    } else {
      cloneContent = contentObj.slice(currentBreakPoint, nextBreakPoint)
    }
    
    Table.create(
      slide,
      categoriesObj,
      cloneContent,
      report,
      group,
      assessorName,
      {
        y: tableStartFrom,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }
}