<template>
  <!-- Get image upload back up -->
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-md-12">
        <h2 class="h2 mb-2 d-inline-block mr-1">
          {{ "Tell Us About Your Cows' Hygiene" | translate }}
        </h2>
        <modal-info :value="'score-cow'" class="d-inline">
          <template slot="modal-content">
            <div class="p-3">
              <h2 class="h2 pb-2 text-center">
                {{ 'Score Your Cows For Hygiene' | translate }}
              </h2>
              <p>
                {{
                  'It is recommended to complete the Hygiene assessor before completing the Footbath assessor.'
                    | translate
                }}
              </p>
              <p>
                {{
                  'Poor leg hygiene is a key factor in determining risk for infectious hoof lesions. The greater the contamination, the more digital dermatitis and foot rot is typically observed.'
                    | translate
                }}
              </p>
              <p>
                {{
                  'Score a representative group of cows using the leg hygiene scoring system; aim for scoring at least 20 percent of cows in a given group.'
                    | translate
                }}
              </p>
              <p>
                {{
                  'For more information about Hygiene, click the link to learn more from'
                    | translate
                }}
                <a
                  :href="'https://library.zinprofirststep.com/footbath/'"
                  @click="$goToLameness($t('/footbath/'), $event)"
                  target="_blank"
                  >{{ 'FirstStep Resources' | translate }}</a
                >.
              </p>
              <div class="text-center">
                <button class="btn-cancel-modal" data-dismiss="modal">
                  {{ 'Cancel' | translate }}
                </button>
              </div>
            </div>
          </template>
        </modal-info>
      </div>
      <div class="col-md-12">
        <button
          data-toggle="modal"
          data-target="#skip_footbath_modal"
          class="btn btn-primary--thin btn-full--sm float-right mb-3 mb-md-0"
        >
          {{ 'Skip To Footbath' | translate }}
        </button>
      </div>
    </div>

    <hr class="mt-2 mb-md-4 mt-md-3" />

    <div class="row mx-0 d-md-none d-flex justify-content-end">
      <a
        href="#"
        class="hygiene-score__link"
        v-if="showGuide === false"
        @click.prevent="showGuide = true"
      >
        {{ 'View Scoring Guide' | translate }}
      </a>
      <a
        href="#"
        class="hygiene-score__link"
        v-if="showGuide === true"
        @click.prevent="showGuide = false"
      >
        {{ 'Hide Scoring Guide' | translate }}
      </a>
    </div>

    <div class="row">
      <!-- I was not able to figure out how to change a data property depending on the screen size, so I created a mobile version -->
      <div class="col-xl-14 hygiene-score-images">
        <div class="row">
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <transition name="fade">
              <modal-score
                :value="1"
                class="d-md-none"
                v-if="showGuide === true"
              ></modal-score>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                {{ 'Score 1' | translate }}
              </p>
            </transition>
            <!-- dektop -->
            <modal-score
              :value="1"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
            ></modal-score>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ 'Score 1' | translate }}
            </p>
          </div>
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <transition name="fade">
              <modal-score
                :value="2"
                class="d-md-none"
                v-if="showGuide === true"
              ></modal-score>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                {{ 'Score 2' | translate }}
              </p>
            </transition>
            <!-- dektop -->
            <modal-score
              :value="2"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
            ></modal-score>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ 'Score 2' | translate }}
            </p>
          </div>
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <transition name="fade">
              <modal-score
                :value="3"
                class="d-md-none"
                v-if="showGuide === true"
              ></modal-score>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                {{ 'Score 3' | translate }}
              </p>
            </transition>
            <!-- dektop -->
            <modal-score
              :value="3"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
            ></modal-score>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ 'Score 3' | translate }}
            </p>
          </div>
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <transition name="fade">
              <modal-score
                :value="4"
                class="d-md-none"
                v-if="showGuide === true"
              ></modal-score>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                {{ 'Score 4' | translate }}
              </p>
            </transition>
            <!-- dektop -->
            <modal-score
              :value="4"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
            ></modal-score>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ 'Score 4' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-xl-10 hygiene-score">
        <div class="counter d-flex justify-content-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            :disabled="assessor.data.hygiene_score_1 <= 0"
            @click="assessor.data.hygiene_score_1--"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">1</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            min="0"
            pattern="\d*"
            class="score-count"
            v-model.number="assessor.data.hygiene_score_1"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.hygiene_score_1++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div class="counter d-flex justify-content-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.hygiene_score_2--"
            :disabled="assessor.data.hygiene_score_2 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">2</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            min="0"
            pattern="\d*"
            class="score-count"
            v-model.number="assessor.data.hygiene_score_2"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.hygiene_score_2++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div class="counter d-flex justify-content-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.hygiene_score_3--"
            :disabled="assessor.data.hygiene_score_3 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">3</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            min="0"
            pattern="\d*"
            class="score-count"
            v-model.number="assessor.data.hygiene_score_3"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.hygiene_score_3++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div class="counter d-flex justify-content-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.hygiene_score_4--"
            :disabled="assessor.data.hygiene_score_4 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">4</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            min="0"
            pattern="\d*"
            class="score-count"
            v-model.number="assessor.data.hygiene_score_4"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.hygiene_score_4++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div class="counter-total">
          <span class="total-title mr-3">{{ 'Total' | translate }}</span>
          <input
            type="text"
            class="total-hygiene-score"
            v-model="total_hygiene_score"
            readonly
          />
        </div>
      </div>
    </div>

    <hr class="mt-2 mb-4 mb-md-5 mt-md-4" />

    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 mb-3 mb-sm-2">
          {{ 'Tell Us About Your Operation' | translate }}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8" v-if="evaluation.data.consultation_date">
        <div class="form-group">
          <label class="mb-1">{{ 'Evaluation Date' | translate }}</label>
          <input
            type="text"
            class="form-control date-picker date-picker--disabled"
            :value="getDate(evaluation.data.consultation_date)"
            disabled
          />
        </div>
      </div>
      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1">{{ 'Group/Pen Size' | translate }}</label>
          <currency-input                 
            :v-model="groupClass.getGroupSize()"
            disabled
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1">{{ 'Suggested Sample Size' | translate }}</label>
          <currency-input
          disabled                 
            :v-model="groupClass.getSampleSize()"
            pattern="\d*"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label class="mb-1"
            >{{ 'Frequency of Manure Removal' | translate }}
            <span class="times-day">{{ '(times per day)' | translate }}</span>
          </label>
          <input
            type="number"
            inputmode="decimal"
            class="form-control"
            v-model.number="assessor.data.frequency_of_manure_removal"
          />
        </div>
      </div>
      <div class="col-lg-6 d-flex justify-content-end flex-column">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            'Summer Manure Removal Method' | translate
          }}</label>
          <select
            class="form-control"
            v-model="assessor.data.summer_manure_removal_method"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option value="Flush">{{ 'Flush' | translate }}</option>
            <option value="Scrape (Automatic)">{{
              'Scrape (Automatic)' | translate
            }}</option>
            <option value="Scrape (Manual)">{{
              'Scrape (Manual)' | translate
            }}</option>
            <option value="Slats">{{ 'Slats' | translate }}</option>
            <option value="Vacuum">{{ 'Vacuum' | translate }}</option>
            
          </select>
        </div>
      </div>
      <div class="col-lg-6 d-flex justify-content-end flex-column">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            'Winter Manure Removal Method' | translate
          }}</label>
          <select
            class="form-control"
            v-model="assessor.data.winter_manure_removal_method"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option value="Flush">{{ 'Flush' | translate }}</option>
            <option value="Scrape (Automatic)">{{
              'Scrape (Automatic)' | translate
            }}</option>
            <option value="Scrape (Manual)">{{
              'Scrape (Manual)' | translate
            }}</option>
            <option value="Slats">{{ 'Slats' | translate }}</option>
            <option value="Vacuum">{{ 'Vacuum' | translate }}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-6 d-flex justify-content-end flex-column">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{ 'Bedding Type' | translate }}</label>
          <select class="form-control" v-model="assessor.data.bedding_type">
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option value="Concrete">{{ 'Concrete' | translate }}</option>
            <option value="Foam Mattress">{{'Foam Mattress' | translate}}</option>
            <option value="Manure Solids">{{ 'Manure Solids' | translate }}</option>
            <option value="Mats">{{ 'Mats' | translate }}</option>
            <option value="Rubber Mattress">{{'Rubber Mattress' | translate}}</option>
            <option value="Sand">{{ 'Sand' | translate }}</option>
            <option value="sawdust">{{ 'Sawdust' | translate }}</option>
            <option value="Straw">{{ 'Straw' | translate }}</option>
            <option value="Waterbed">{{ 'Waterbed' | translate }}</option>
            <option value="Other">{{ 'Other' | translate }}</option>
          </select>
        </div>
      </div>
    </div>

    <hr />

    <question
      @change="assessor.data.cows_in_pen = $event.value"
      :initial-value="assessor.data.cows_in_pen"
    >
      <div class="mb-1 mb-md-0">
        <label class="mb-1 d-inline">
          {{
            'Are cows in the pen when manure is scraped / flushed?' | translate
          }}
        </label>
        <modal-info :value="'cow-in-pen'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{
                'Are cows in the pen when manure is scraped / flushed?'
                  | translate
              }}
            </h2>
            <p class="px-3">
              {{
                'If the cows are in the pen when an automatic scraper pushes a wave of manure, leg hygiene may be poor and risk for digital dermatitis may be very high. Removal of manure when cows are in the parlor is preferred; this should occur at least three times per day.'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <hr />

    <question
      @change="updateQuestion($event.value, 'cows_dry_lot')"
      :initial-value="assessor.data.cows_dry_lot"
    >
      <label class="mb-1 d-inline-block">
        {{ 'Do cows have access to a dry lot?' | translate }}
      </label>
    </question>

    <hr />

    <question
      v-if="assessor.data.cows_dry_lot === 1"
      @change="assessor.data.is_the_lot_dry = $event.value"
      :initial-value="assessor.data.is_the_lot_dry"
    >
      <label class="mb-1 d-inline-block">
        {{ 'Is the lot dry and regularly groomed?' | translate }}
      </label>
    </question>

    <hr v-if="assessor.data.cows_dry_lot === 1" />

    <div class="row mb-3">
      <div class="col-lg-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-lg-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-2 pb-md-3"
    >
      <photoUpload :assessorId="1" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-4 mb-md-5 mt-md-4" />

    <div class="row misc-bottom mt-3">
      <div class="col-lg-16 ">
        <router-link
          :to="
            '/users/' +
              $route.params.userId +
              '/evaluations/' +
              $route.params.evaluationId +
              '/groups/' +
              $route.params.groupId +
              '/assessors/' +
              $route.params.assessorType +
              '/footbaths/existing/'
          "
          class="btn btn-primary btn-full--sm mr-0 mt-0 mt-md-2 mt-lg-0 mr-lg-5"
        >
          {{ 'Evaluate Existing Footbath' | translate }}
        </router-link>
        <router-link
          :to="
            '/users/' +
              $route.params.userId +
              '/evaluations/' +
              $route.params.evaluationId +
              '/groups/' +
              $route.params.groupId +
              '/assessors/' +
              $route.params.assessorType +
              '/footbaths/new/'
          "
          class="btn btn-primary btn-full--sm float-md-right float-lg-none mt-2 mt-lg-0 ml-0 ml-md-4 ml-lg-0"
        >
          {{ 'Design New Footbath' | translate }}
        </router-link>
      </div>
      <div class="col-lg-8">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          to="/reports"
          class="btn btn-primary btn-full--sm mt-2 mt-lg-0 float-lg-right"
        >
          {{ 'Reports' | translate }}
        </router-link>
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user._id]"
          class="btn btn-primary btn-full--sm mt-2 mt-lg-0 float-lg-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
      <div class="col-24">
        <div
          v-show="successMessage"
          class="alert alert-primary mt-3 mb-0"
          role="alert"
        >
          {{ 'Your request has been sent!' | translate }}
        </div>

        <div
          v-show="errorMessage"
          class="alert alert-danger mt-3 mb-0"
          role="alert"
        >
          {{ 'An error has occurred during your request!' | translate }}
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="skip_footbath_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="skip_footbath_modal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content edit-modal">
          <h2 class="h2 h2--modal text-center mt-3 mt-1 mt-md-0">
            {{ 'Skip To Footbath' | translate }}
          </h2>
          <div class="modal-body my-0">
            <router-link
              data-dismiss="modal"
              class="btn btn-full btn-full--modal mb-2"
              :to="
                '/users/' +
                  $route.params.userId +
                  '/evaluations/' +
                  $route.params.evaluationId +
                  '/groups/' +
                  $route.params.groupId +
                  '/assessors/' +
                  $route.params.assessorType +
                  '/footbaths/existing/'
              "
            >
              {{ 'Evaluate Existing Footbath' | translate }}
            </router-link>
            <router-link
              data-dismiss="modal"
              class="btn btn-full btn-full--modal"
              :to="
                '/users/' +
                  $route.params.userId +
                  '/evaluations/' +
                  $route.params.evaluationId +
                  '/groups/' +
                  $route.params.groupId +
                  '/assessors/' +
                  $route.params.assessorType +
                  '/footbaths/new/'
              "
            >
              {{ 'Design New Footbath' | translate }}
            </router-link>
            <div class="row mt-2">
              <div class="col-md-24 modal-bottom">
                <a href="#" class="btn-cancel-modal" data-dismiss="modal">
                  {{ 'Cancel' | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import _ from 'lodash';
import PresentationNotes from '../assessor_components/presentation-notes';
import PersonalNotes from '../assessor_components/personal-notes';
import HygieneHeader from '../assessor_components/hygiene-header.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import ModalInfo from '@/components/misc/modal-info.vue';
import Question from '../assessor_components/question';
import ModalScore from '@/components/misc/modal-score.vue';
import Status from '@/components/evaluations/status.vue';
import Evaluation from '@/libs/Classes/Evaluation.js';
import GroupClass from '@/libs/Classes/Group.js';
import requestReportMixin from '@/mixins/requestReport.js';
import { mapState } from 'vuex';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    PresentationNotes,
    PersonalNotes,
    ModalInfo,
    ModalScore,
    Status,
    Question,
    PhotoUpload,
    HygieneHeader,
    CurrencyInput
  },
  mixins: [requestReportMixin],
  data() {
    return {
      timeoutSave: null,
      showGuide: false,
      evaluationClass: null,
      groupClass: null,
    };
  },
  props: ['assessor', 'group', 'evaluation'],
  created() {
    setTimeout(() => {
      this.evaluationClass = new Evaluation(this, this.evaluation);
      this.groupClass = new GroupClass(this, this.evaluationClass, this.group);
    }, 1000);
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
    }),
    total_hygiene_score: function() {
      return (
        this.assessor.data.hygiene_score_1 +
        this.assessor.data.hygiene_score_2 +
        this.assessor.data.hygiene_score_3 +
        this.assessor.data.hygiene_score_4
      );
    },
  },
  methods: {
    getDate(timestamp) {
      let date = new Date(timestamp);
      var monthNames = [
        this.$t('Jan.'),
        this.$t('Feb.'),
        this.$t('Mar.'),
        this.$t('Apr.'),
        this.$t('May'),
        this.$t('Jun.'),
        this.$t('Jul.'),
        this.$t('Aug.'),
        this.$t('Sep.'),
        this.$t('Oct.'),
        this.$t('Nov.'),
        this.$t('Dec.'),
      ];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return day + ' ' + monthNames[monthIndex] + ' ' + year;
    },

    updateQuestion(value, key) {
      this.assessor.data[key] = value;
      this.$forceUpdate();

      // @TODO: Resetting values for conditional questions should be made consistent across assessors
      if (value === 0) {
        this.assessor.data.is_the_lot_dry = null;
      }
    },
  },

  watch: {
    group() {
      this.groupClass = new GroupClass(this, this.evaluationClass, this.group);
    },
  },
};
</script>
