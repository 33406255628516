import convert from 'convert-units'
const Table = require('../../Misc/Table');
import { getPageSize } from "@/constants";
import { LINE_HEIGHTS } from "@/reportLineHeights";
import { writeTableOnSlide } from '../BeddedPack/beddedPack';

const getAnswerYesNoNA = answer => {
  let result = 'N/A';

  if (answer === 0) {
    result = app.$t('No');
  } else if (answer === 1) {
    result = app.$t('Yes');
  }

  return result;
};

export const holdingYardMilkShed = (
  app,
  report,
  evaluation,
  group,
  assessor
) => {
  let slide;
  let slide2;

  let TITLE_Y_START = 2.38;
  let TABLE_Y_START = 2.7;

  let slideY = TABLE_Y_START;
  let titleY = TITLE_Y_START;
  const userLang = app.user.lang;
  const PAGE_SIZE = getPageSize(userLang, {
    ch: 11.5,
    es: 8.2,
    en: 9.5,
    fr: 11
  }); // we'll create slide after this

  const breakpoints = [0];
  const defaultValues = {
    tableY: TABLE_Y_START,
    titleY: TITLE_Y_START,
  };


    let yardSizeArray = [];
    let pipeworkArray = [];
    let yardShedEntranceArray = [];
    let milkingPlatformArray = [];
    let platformExitArray = [];
    let slopesStepsArray = [];
    let concreteCompositionSurfaceArray = [];
    let cleanlinessArray = [];
    let feedbinsInShedArray = [];
    let bullsDogsSocialArray = [];
    let milkerBehaviorArray = [];
    let lightArray = [];
    let strayVoltageArray = [];
    let gateArray = [];


  const {
    ONE_LINE,
    TWO_LINE,
    THREE_LINE,
    FOUR_LINE,
    FIVE_LINE,
    SIX_LINE,
    SEVEN_LINE,
    TEN_LINE,
  } = LINE_HEIGHTS[userLang] || LINE_HEIGHTS["en"];

  function makePageBreak(
    { tableY, tableValue, titleY, titleValue },
    defaultVal
  ) {
    if (tableY + tableValue > PAGE_SIZE) {
      const pageBreak = milkingPlatformArray.length - 1; /* because this value is greater the available space, 
        if break after this row our content get overflow, that's why need to break the slide before add this row the page */
      breakpoints.push(pageBreak);
      return {
        slideY: defaultVal.tableY + tableValue,
        titleY: defaultVal.titleY + titleValue,
      };
    }
    return {
      slideY: tableY + tableValue,
      titleY: titleY + titleValue,
    };
  }

  let obj = [
    {
      text: app.$t("Question"),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t("Answer"),
      options: report.styleOptions.tableHeader,
    },
    {
      text: app.$t("Comment"),
      options: report.styleOptions.tableHeader,
    },
  ];


  // Yard Size

  const yardShape = assessor.getYardShape();
  const isMeasurementsMetric =
    evaluation.getMeasurements() === "metric";
  if (yardShape !== null) {
    yardSizeArray.push([
      {
        text: app.$t("Yard Shape"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$t(
          `${yardShape.charAt(0).toUpperCase() + yardShape.slice(1)}`
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: yardShape === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const yardDiameter = assessor.getYardDiameter();

  if (yardShape === "circular") {
    yardSizeArray.push([
      {
        text: app.$t("Yard Diameter"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$t(`${yardDiameter}`),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: yardDiameter === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const yardSizeFtWide = assessor.getYardSizeFtWide();
  const yardSizeFtLong = assessor.getYardSizeFtLong();
  const jFCrossbreed = assessor.getJFCrossbreed();

  if (yardShape === "rectangular") {
    yardSizeArray.push([
      {
        text: `${app.$t("Yard Size")} (${app.$t(
          app.$getEquivalentUnit(evaluation.getMeasurements(), "m")
        )})`,
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: `${app.$numberFormat(yardSizeFtWide)} ${app.$t("by")} ${app.$numberFormat(yardSizeFtLong)}`,
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: jFCrossbreed === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  yardSizeArray.push([
    {
      text: app.$t("Yard Area"),
      options: {
        color: "000000",
        align: "left",
        fill: "ffffff",
        bold: false,
      },
    },
    {
      text:
        app.$numberFormat(assessor.getYardArea()) +
        " " +
        app.$t(
          app.$getEquivalentUnit(
            evaluation.getMeasurements(),
            app.$t("sq m")
          )
        ),
      options: {
        color: "000000",
        fill: "ffffff",
        bold: false,
      },
    },
    {
      text:
        app.$t(
          "At a minimum of 15 feet (4,6 m) per cow, the holding area is large enough to provide space for"
        ) +
        " " +
        app.$numberFormat(assessor.getCowAmountPerArea()) +
        " " +
        app.$t("cows"),
      options: {
        align: "left",
        color: "000000",
        fill: "efefef",
        bold: false,
      },
    },
  ]);
  slideY += 0.5;
  titleY += 0.5;

  const milkingShedType = assessor.getMilkingShedType();

  if (milkingShedType !== null) {
    yardSizeArray.push([
      {
        text: app.$t("Milking Shed Type"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$t(
          `${
            milkingShedType.charAt(0).toUpperCase() +
            milkingShedType.slice(1)
          }`
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: milkingShedType === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const fresians = assessor.getFresians();

  if (fresians !== null) {
    yardSizeArray.push([
      {
        text: app.$t("Friesians"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(fresians),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: fresians === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const jerseys = assessor.getJerseys();

  if (jerseys !== null) {
    yardSizeArray.push([
      {
        text: app.$t("Jerseys"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(jerseys),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: jerseys === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (jFCrossbreed !== null) {
    yardSizeArray.push([
      {
        text: app.$t("J/F Crossbreed"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(jFCrossbreed),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: jFCrossbreed === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (yardSizeArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Yard Size"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      yardSizeArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Pipework

  const areThereAnySharpProtrudingParts =
    assessor.getAreThereAnySharpProtrudingParts();

  if (areThereAnySharpProtrudingParts !== null) {
    pipeworkArray.push([
      {
        text: app.$t("Are there any sharp/protruding parts?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areThereAnySharpProtrudingParts),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areThereAnySharpProtrudingParts === 1
            ? app.$t(
                "Sharp parts can injure cows and make them reluctant to enter the yard and milk shed."
              )
            : areThereAnySharpProtrudingParts === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            areThereAnySharpProtrudingParts === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areThereAnyProjectionsInHipboneOrShoulderRange =
    assessor.getAreThereAnyProjectionsInHipboneOrShoulderRange();

  if (areThereAnyProjectionsInHipboneOrShoulderRange !== null) {
    pipeworkArray.push([
      {
        text: app.$t(
          "Are there any projections in hipbone or shoulder range?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          areThereAnyProjectionsInHipboneOrShoulderRange
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areThereAnyProjectionsInHipboneOrShoulderRange === 1
            ? app.$t(
                "Cows will try to avoid contact with any protruding objects especially in hipbone/shoulder area. Injuries can easily occur if cows are pressured by herdmates or handlers."
              )
            : areThereAnyProjectionsInHipboneOrShoulderRange === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            areThereAnyProjectionsInHipboneOrShoulderRange === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isThereProtectionAroundSolitaryPipesObjects =
    assessor.getIsThereProtectionAroundSolitaryPipesObjects();

  if (isThereProtectionAroundSolitaryPipesObjects !== null) {
    pipeworkArray.push([
      {
        text: app.$t(
          "Is there protection around solitary pipes/objects?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isThereProtectionAroundSolitaryPipesObjects
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isThereProtectionAroundSolitaryPipesObjects === 1
            ? app.$t("Good!")
            : isThereProtectionAroundSolitaryPipesObjects === 0
            ? app.$t(
                "Solitary pipes and objects in cow-flow areas may be inevitable. Attaching rounded pipework around these pipes and objects will minimize the risk of cows injuring themselves on these pipes and objects."
              )
            : "",
        options: {
          align: "left",
          color:
            isThereProtectionAroundSolitaryPipesObjects === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isThereAClearFieldOfViewForCowsExiting =
    assessor.getIsThereAClearFieldOfViewForCowsExiting();

  if (isThereAClearFieldOfViewForCowsExiting !== null) {
    pipeworkArray.push([
      {
        text: app.$t(
          "Is there a clear field of view for cows exiting?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isThereAClearFieldOfViewForCowsExiting
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isThereAClearFieldOfViewForCowsExiting === 1
            ? app.$t("Good!")
            : isThereAClearFieldOfViewForCowsExiting === 0
            ? app.$t(
                "Cow flow will be much better if they have an unobstructed view of the exit. Reducing turns and improving lighting will improve the cow's view of the exit path."
              )
            : "",
        options: {
          align: "left",
          color:
            isThereAClearFieldOfViewForCowsExiting === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (pipeworkArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Pipework"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      pipeworkArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Yard and Shed Entrance

  const isTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard =
    assessor.getIsTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard();

  if (
    isTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard !== null
  ) {
    yardShedEntranceArray.push([
      {
        text: app.$t(
          "Is the yard entrance at least as wide as the race leading to the yard?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard ===
          1
            ? app.$t("Good!")
            : isTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard ===
              0
            ? app.$t(
                "An entrance narrower than the race will slow cow flow into the yard, increasing urination and defecation. The end result is more wear and tear on the race."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isThereA4InchHighCurbInstalledInTheRaceToYardEntrance =
    assessor.getIsThereA4InchHighCurbInstalledInTheRaceToYardEntrance();

  if (
    isThereA4InchHighCurbInstalledInTheRaceToYardEntrance !== null
  ) {
    yardShedEntranceArray.push([
      {
        text:
          evaluation.getMeasurements() === "metric"
            ? app.$t(
                "Is there a 15 cm high curb installed in the race-to-yard entrance?"
              )
            : app.$t(
                "Is there a 6 inch high curb installed in the race-to-yard entrance?"
              ),
        // app.$t('Is there a ') + convert(15).from('cm').to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')).toFixed(0) + '-' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t('-high curb installed in the race-to-yard entrance?'),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isThereA4InchHighCurbInstalledInTheRaceToYardEntrance
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isThereA4InchHighCurbInstalledInTheRaceToYardEntrance === 1
            ? app.$t("Good!")
            : isThereA4InchHighCurbInstalledInTheRaceToYardEntrance ===
              0
            ? app.$t(
                "A curb will reduce the amount of debris carried onto the yard by the cows, decreasing trauma on cow's feet."
              )
            : "",
        options: {
          align: "left",
          color:
            isThereA4InchHighCurbInstalledInTheRaceToYardEntrance ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isARubberMatInstalledInTheYardEntrance =
    assessor.getIsARubberMatInstalledInTheYardEntrance();

  if (isARubberMatInstalledInTheYardEntrance !== null) {
    yardShedEntranceArray.push([
      {
        text: app.$t(
          "Is a rubber mat installed in the yard entrance?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isARubberMatInstalledInTheYardEntrance
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isARubberMatInstalledInTheYardEntrance === 1
            ? app.$t("Good!")
            : isARubberMatInstalledInTheYardEntrance === 0
            ? app.$t(
                "A rubber mat will improve cow flow and reduce claw wear, especially in locations where cows make sharp turns entering the yard."
              )
            : "",
        options: {
          align: "left",
          color:
            isARubberMatInstalledInTheYardEntrance === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheYardBailEntranceStraightLined =
    assessor.getIsTheYardBailEntranceStraightLined();

  if (isTheYardBailEntranceStraightLined !== null) {
    yardShedEntranceArray.push([
      {
        text: app.$t("Is the yard-bail entrance straight lined?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isTheYardBailEntranceStraightLined),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheYardBailEntranceStraightLined === 1
            ? app.$t("Good!")
            : isTheYardBailEntranceStraightLined === 0
            ? app.$t(
                "Eliminating turns at yard and bail entrances will decrease stress on cows' feet and will improve cow flow."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheYardBailEntranceStraightLined === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheYardBailEntranceCowSized3032Inches =
    assessor.getIsTheYardBailEntranceCowSized3032Inches();

  if (isTheYardBailEntranceCowSized3032Inches !== null) {
    const question = isMeasurementsMetric
      ? "Is the yard-bail entrance cow sized (76 – 81 cm)?"
      : "Is the yard-bail entrance cow sized (30-32 inches)?";
    yardShedEntranceArray.push([
      {
        text: app.$t(question),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheYardBailEntranceCowSized3032Inches
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheYardBailEntranceCowSized3032Inches === 1
            ? app.$t("Good!")
            : isTheYardBailEntranceCowSized3032Inches === 0
            ? app.$t(
                `Bail entrances narrower than ${
                  isMeasurementsMetric ? "76–81 cm" : "30-32 inches"
                } will restrict cow flow and increase risk of injury. If entrances are too wide, cows may try to enter two-at-a-time, increasing risk of injury and decreasing cow flow.`
              )
            : "",
        options: {
          align: "left",
          color:
            isTheYardBailEntranceCowSized3032Inches === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheEntranceFunneledWithAnAngleLessThan45And34MetersLong =
    assessor.getIsTheEntranceFunneledWithAnAngleLessThan45And34MetersLong();

  if (
    isTheEntranceFunneledWithAnAngleLessThan45And34MetersLong !== null
  ) {
    yardShedEntranceArray.push([
      {
        text: app.$t(
          `Is the entrance funneled with an angle less than 45° and ${
            isMeasurementsMetric ? "3-4 meters" : "10-13 feet"
          } long?`
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheEntranceFunneledWithAnAngleLessThan45And34MetersLong
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheEntranceFunneledWithAnAngleLessThan45And34MetersLong ===
          1
            ? app.$t("Good!")
            : isTheEntranceFunneledWithAnAngleLessThan45And34MetersLong ===
              0
            ? app.$t(
                "A funnelled entrance to the bail (herringbone or rotary), 3-4 cows long and at angle of less than 45°, minimizes double-ups, improving cow flow and minimizing the associated risk of injury."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheEntranceFunneledWithAnAngleLessThan45And34MetersLong ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh =
    assessor.getIsTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh();

  if (
    isTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh !==
    null
  ) {
    const question = isMeasurementsMetric
      ? app.$t(
          "Is the last 3-4 meters of surrounding yard fence made of solid material (140 cm high)?"
        )
      : app.$t(
          "Is the last 10-13 feet of surrounding yard fence made of solid material (55 inches high)?"
        );

    yardShedEntranceArray.push([
      {
        text: question,
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh ===
          1
            ? app.$t("Good!")
            : isTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh ===
              0
            ? app.$t(
                "Having solid material obscuring the cows' view out of the yard will help keep them from being distracted and keeps them focused on moving forward into the dairy shed."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const rotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide =
    assessor.getRotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide();

  if (
    rotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide !== null &&
    milkingShedType === "rotary"
  ) {
    yardShedEntranceArray.push([
      {
        text: app.$t(
          "Rotary only: Is the anti-jam gate installed on the entry side?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          rotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          rotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide === 1
            ? app.$t("Good!")
            : rotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide === 0
            ? app.$t(
                "This gate will stop the platform automatically if cows or people get trapped between the moving platform and the rumprail. Having this device installed will minimize risk of injury to both cattle and people."
              )
            : "",
        options: {
          align: "left",
          color:
            rotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const rotaryOnlyAreHingedDividersInstalled =
    assessor.getRotaryOnlyAreHingedDividersInstalled();

  if (
    rotaryOnlyAreHingedDividersInstalled !== null &&
    milkingShedType === "rotary"
  ) {
    yardShedEntranceArray.push([
      {
        text: app.$t("Rotary only: Are hinged dividers installed?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(rotaryOnlyAreHingedDividersInstalled),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          rotaryOnlyAreHingedDividersInstalled === 1
            ? app.$t("Good!")
            : rotaryOnlyAreHingedDividersInstalled === 0
            ? app.$t(
                "A spring-loaded, hinged divider (D gate) between entrance and exit helps prevent cows from being jammed between the moving platform and the gate centre-pole on exit."
              )
            : "",
        options: {
          align: "left",
          color:
            rotaryOnlyAreHingedDividersInstalled === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (yardShedEntranceArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Yard and Shed Entrance"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      yardShedEntranceArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Milking Platform

  const doesTheBreastrailMissTheShoulderJoints =
    assessor.getDoesTheBreastrailMissTheShoulderJoints();

  if (doesTheBreastrailMissTheShoulderJoints !== null) {
    const isMetric = isMeasurementsMetric ? true : false;

    const isTrue = doesTheBreastrailMissTheShoulderJoints === 1;

    milkingPlatformArray.push([
      {
        text: app.$t("Does the breastrail miss the shoulder joints?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          doesTheBreastrailMissTheShoulderJoints
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doesTheBreastrailMissTheShoulderJoints === 1
            ? app.$t("Good!")
            : doesTheBreastrailMissTheShoulderJoints === 0
            ? isMetric
              ? app.$t(
                  `Cows need to feel comfortable standing in the bail, without pipework pushing into sensitive areas. The breastrail needs to sit just under the shoulder joint; approximate rail height for Friesians, 76 cm and Jerseys, 70 cm. Actual fit to the cow is more important than the exact rail height. Having an adjustable breastrail, both height and width, is very useful especially in mixed breed herds.`
                )
              : app.$t(
                  `Cows need to feel comfortable standing in the bail, without pipework pushing into sensitive areas. The breastrail needs to sit just under the shoulder joint; approximate rail height for Friesians, 30 inches and Jerseys, 28 inches. Actual fit to the cow is more important than the exact rail height. Having an adjustable breastrail, both height and width, is very useful especially in mixed breed herds.`
                )
            : "",
        options: {
          align: "left",
          color:
            doesTheBreastrailMissTheShoulderJoints === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

      const value = isTrue ? ONE_LINE : SIX_LINE;
      ({ slideY, titleY } = makePageBreak(
        {
          tableY: defaultValues.tableY,
          tableValue: value,
          titleY: defaultValues.titleY,
          titleValue: value,
        },
        defaultValues
      ));

  }

  const doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva =
    assessor.getDoesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva();

  if (
    doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva !== null
  ) {
    const isTrue = doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva === 1;
    milkingPlatformArray.push([
      {
        text: app.$t(
          "Does the rumprail sit between the top of udder and bottom of the vulva?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva ===
          1
            ? app.$t("Good!")
            : doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva ===
              0
            ? app.$t(
                `The rumprail needs to sit between the bottom of the vulva and the top of the udder for maximizing cow comfort; approximate rumprail height for Friesians, ${
                  isMeasurementsMetric ? "97cm" : "38 inches"
                } and Jerseys, ${
                  isMeasurementsMetric ? "84cm" : "33 inches"
                }. Actual fit to the cow is more important than the exact rail height.`
              )
            : "",
        options: {
          align: "left",
          color:
            doesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);


    const value = isTrue ? TWO_LINE : FIVE_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));

    // slideY += 0.5;
    // titleY += 0.5;
  }

  const rumprails = assessor.getRumprails();

  if (rumprails !== null) {
    milkingPlatformArray.push([
      {
        text: app.$t("Rumprails?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          rumprails === "straight"
            ? app.$t("Straight")
            : app.$t("Zigzag"),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "", // rumprails === 1 ? app.$t('Good!') : rumprails === 0 ? app.$t('') : '',
        options: {
          align: "left",
          color: "000000", // rumprails === 1 ? '249824' : 'a40000',
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = ONE_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
    // slideY += 0.5;
    // titleY += 0.5;
  }

  const areRumprailsSetInBy2550mm =
    assessor.getAreRumprailsSetInBy2550mm();

  if (areRumprailsSetInBy2550mm !== null && rumprails !== "zigzag") {
    const isTrue = areRumprailsSetInBy2550mm === 1;
    const question = isMeasurementsMetric
      ? app.$t("Are rumprails set in by 25-50 mm?")
      : app.$t("Are rumprails set in by 1-2 inches?");
    milkingPlatformArray.push([
      {
        text: question,
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areRumprailsSetInBy2550mm),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areRumprailsSetInBy2550mm === 1
            ? app.$t("Good!")
            : areRumprailsSetInBy2550mm === 0
            ? app.$t(
                `A straight rumprail needs to be placed ${
                  isMeasurementsMetric ? "25-50 mm" : "1-2 inches"
                } inside the bail area, away from the pit-edge, to prevent cows\' feet from slipping into the pit.`
              )
            : "",
        options: {
          align: "left",
          color:
            areRumprailsSetInBy2550mm === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);


    const value = isTrue ? ONE_LINE : THREE_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
    // slideY += 0.5;
    // titleY += 0.5;
    
  }

  const areTheInsideCornersOfTheRumprailsOverThePitEdge =
    assessor.getAreTheInsideCornersOfTheRumprailsOverThePitEdge();

  if (
    areTheInsideCornersOfTheRumprailsOverThePitEdge !== null &&
    rumprails === "zigzag"
  ) {
    const isTrue =
      areTheInsideCornersOfTheRumprailsOverThePitEdge === 1;
    
    milkingPlatformArray.push([
      {
        text: app.$t(
          "Are the inside corners of the rumprails over the pit edge?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          areTheInsideCornersOfTheRumprailsOverThePitEdge
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areTheInsideCornersOfTheRumprailsOverThePitEdge === 1
            ? app.$t("Good!")
            : areTheInsideCornersOfTheRumprailsOverThePitEdge === 0
            ? app.$t(
                " Zigzag rumprails clearly define individual cow locations. They need to be wide enough to comfortably fit the width of the animal. The innermost point of the zigzag on the pit side needs to sit directly over the edge of the pit to prevent cows' feet from slipping into the pit."
              )
            : "",
        options: {
          align: "left",
          color:
            areTheInsideCornersOfTheRumprailsOverThePitEdge === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

     const value = isTrue ? ONE_LINE : FIVE_LINE;
     ({ slideY, titleY } = makePageBreak(
       { tableY: slideY, tableValue: value, titleY, titleValue: value },
       defaultValues
     ));
    // slideY += 0.5;
    // titleY += 0.5;
  }

  const doTheCowsFitComfortablyIntoTheBail =
    assessor.getDoTheCowsFitComfortablyIntoTheBail();

  if (doTheCowsFitComfortablyIntoTheBail !== null) {
    const isTrue = doTheCowsFitComfortablyIntoTheBail === 1  
    milkingPlatformArray.push([
      {
        text: app.$t("Do the cows fit comfortably into the bail?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(doTheCowsFitComfortablyIntoTheBail),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doTheCowsFitComfortablyIntoTheBail === 1
            ? app.$t("Good!")
            : doTheCowsFitComfortablyIntoTheBail === 0
            ? app.$t(
                "Cows should fit into the bail such that they can extend their necks and heads so that neck and heads are in line with their backbone. Cramped bails force cows to bend their necks/ heads, which is interpreted as an act of aggression by adjacent cows."
              )
            : "",
        options: {
          align: "left",
          color:
            doTheCowsFitComfortablyIntoTheBail === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isTrue ? ONE_LINE : SIX_LINE;
      ({ slideY, titleY } = makePageBreak(
        { tableY: slideY, tableValue: value, titleY, titleValue: value },
        defaultValues
      ));

  }

  const doCowsFitWellIntoTheFirstBail =
    assessor.getDoCowsFitWellIntoTheFirstBail();

  if (doCowsFitWellIntoTheFirstBail !== null) {

    const isTrue = doCowsFitWellIntoTheFirstBail === 1;
    milkingPlatformArray.push([
      {
        text: app.$t("Do cows fit well into the first bail?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(doCowsFitWellIntoTheFirstBail),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doCowsFitWellIntoTheFirstBail === 1
            ? app.$t("Good!")
            : doCowsFitWellIntoTheFirstBail === 0
            ? app.$t(
                "Good fit of cows into the first bail is critical. If cows are cramped into the first bail, they will be reluctant to use the first bail, resulting in poor cow flow into the bail."
              )
            : "",
        options: {
          align: "left",
          color:
            doCowsFitWellIntoTheFirstBail === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    // slideY += 0.5;
    // titleY += 0.5;

    const value = isTrue ? ONE_LINE : SIX_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
  }

  const isAnAntiJumpbarMounted = assessor.getIsAnAntiJumpbarMounted();

  if (isAnAntiJumpbarMounted !== null) {
    const isTrue = isAnAntiJumpbarMounted === 1;
    milkingPlatformArray.push([
      {
        text: app.$t("Is an anti-jumpbar mounted?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isAnAntiJumpbarMounted),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isAnAntiJumpbarMounted === 1
            ? app.$t("Good!")
            : isAnAntiJumpbarMounted === 0
            ? app.$t(
                "A correctly mounted anti-jumpbar will help prevent cows from jumping over the breastrail."
              )
            : "",
        options: {
          align: "left",
          color: isAnAntiJumpbarMounted === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    // slideY += 0.5;
    // titleY += 0.5;

    const value = isTrue ? ONE_LINE : SIX_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
  }

  const isTheAntiJumpbarInTheCorrectLocation =
    assessor.getIsTheAntiJumpbarInTheCorrectLocation();

  if (isTheAntiJumpbarInTheCorrectLocation !== null) {
    const isTrue = isTheAntiJumpbarInTheCorrectLocation === 1;
    milkingPlatformArray.push([
      {
        text: app.$t("Is the anti-jumpbar in the correct location?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isTheAntiJumpbarInTheCorrectLocation),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheAntiJumpbarInTheCorrectLocation === 1
            ? app.$t("Good!")
            : isTheAntiJumpbarInTheCorrectLocation === 0
            ? isMeasurementsMetric
              ? app.$t(
                  `The anti-jumpbar should be hung directly over the breastrail at 1350-1400 mm high. If an adjustable breastrail is used, the anti-jumpbar should be mounted 200 mm inside the breastrail.`
                )
              : app.$t(
                  `The anti-jumpbar should be hung directly over the breastrail at 53-55 inches high. If an adjustable breastrail is used, the anti-jumpbar should be mounted 8 inches inside the breastrail.`
                )
            : "",
        options: {
          align: "left",
          color:
            isTheAntiJumpbarInTheCorrectLocation === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);

    const value = isTrue ? ONE_LINE : THREE_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
    // slideY += 0.5;
    // titleY += 0.5;
  }

  const isASteelBarOrConcreteCurbAlongThePitEdge =
    assessor.getIsASteelBarOrConcreteCurbAlongThePitEdge();

  if (isASteelBarOrConcreteCurbAlongThePitEdge !== null) {

    const isTrue = isASteelBarOrConcreteCurbAlongThePitEdge === 1;
    milkingPlatformArray.push([
      {
        text: app.$t(
          "Is a steel bar or concrete curb along the pit edge?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isASteelBarOrConcreteCurbAlongThePitEdge
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isASteelBarOrConcreteCurbAlongThePitEdge === 1
            ? app.$t("Good!")
            : isASteelBarOrConcreteCurbAlongThePitEdge === 0
            ? app.$t(
                `A concrete curb, ${
                  isMeasurementsMetric ? "80 mm" : "3 1/8 inches"
                } high or a ${
                  isMeasurementsMetric ? "32 mm" : "1 1/4 inches"
                } steel pipe mounted ${
                  isMeasurementsMetric
                    ? "30-40 mm"
                    : "1 1/4 to 1 1/2 inches"
                } off the floor for easier washing of the shed, will help prevent cows from slipping into the pit.`
              )
            : "",
        options: {
          align: "left",
          color:
            isASteelBarOrConcreteCurbAlongThePitEdge === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    // slideY += 0.5;
    // titleY += 0.5;

    const value = isTrue ? ONE_LINE : FOUR_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
  }

  const isTheSurfaceMadeOfNonSlipMaterial =
    assessor.getIsTheSurfaceMadeOfNonSlipMaterial();

  if (isTheSurfaceMadeOfNonSlipMaterial !== null) {
    const isTrue = isTheSurfaceMadeOfNonSlipMaterial === 1;
    milkingPlatformArray.push([
      {
        text: app.$t("Is the surface made of non-slip material?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isTheSurfaceMadeOfNonSlipMaterial),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheSurfaceMadeOfNonSlipMaterial === 1
            ? app.$t(
                "Good, provided the floor is not excessively abrasive."
              )
            : isTheSurfaceMadeOfNonSlipMaterial === 0
            ? app.$t(
                "Bail surfaces need to be non-slip and non-abrasive. Slippery surfaces increase the risk of cows being injured."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheSurfaceMadeOfNonSlipMaterial === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    // slideY += 0.5;
    // titleY += 0.5;


    const value = isTrue ? ONE_LINE : THREE_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
  }

  const isThereAnExcessiveAmountOfDungInTheMilkingShed =
    assessor.getIsThereAnExcessiveAmountOfDungInTheMilkingShed();

  if (isThereAnExcessiveAmountOfDungInTheMilkingShed !== null) {
    const isTrue =
      isThereAnExcessiveAmountOfDungInTheMilkingShed === 0;
    milkingPlatformArray.push([
      {
        text: app.$t(
          "Is there an excessive amount of dung in the milking shed?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isThereAnExcessiveAmountOfDungInTheMilkingShed
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isThereAnExcessiveAmountOfDungInTheMilkingShed === 1
            ? app.$t(
                "Excess dung in the shed is a symptom of cow discomfort. Poor cow fit, milkers hitting cows or yelling at cows or dietary factors could be at fault. As a rough rule of thumb, more than 2-3 dung drops per 40-50 cows indicate cows are not comfortable."
              )
            : isThereAnExcessiveAmountOfDungInTheMilkingShed === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isThereAnExcessiveAmountOfDungInTheMilkingShed === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);


    const value = isTrue ? ONE_LINE : SEVEN_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));
    
    // slideY += 0.5;
    // titleY += 0.5;
  }

  const isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking =
    assessor.getIsTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking();

  if (
    isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking !== null
  ) {
    const isTrue = isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking === 1
    milkingPlatformArray.push([
      {
        text: app.$t(
          "Is the surface kept clean, free of stones and debris during milking?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking ===
          1
            ? app.$t("Good!")
            : isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking ===
              0
            ? app.$t(
                "There should be no sand, stones or other debris in the bail during milking with well constructed races, yards and sheds. Sand, stones or other debris, if present, should be immediately removed to minimize risk of injury or trauma to feet. Furthermore, the origin of the sand, stones and other debris should be determined and fixed."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    // slideY += 0.5;
    // titleY += 0.5;

    const value = isTrue ? ONE_LINE : TEN_LINE;
    ({ slideY, titleY } = makePageBreak(
      { tableY: slideY, tableValue: value, titleY, titleValue: value },
      defaultValues
    ));

  }

  if (milkingPlatformArray.length > 0) {
    if (userLang === "ch") {
      console.log({ userLang });

      writeTableOnSlide({
        app,
        Table,
        breakpoints,
        report,
        contentObj: milkingPlatformArray,
        group,
        assessorName: "Holding Yard & Milking Shed Assessor",
        prevSlide: slide,
        TITLE_Y_START,
        TABLE_Y_START,
        categoriesObj: obj,
        tableTitle: "Milking Platform",
        // prevTableEndAt,
        // prevTitleEndAt
      });
    } else {
      slide2 = report.pptx.addSlide("Slide master");

      slide2.addText(
        app.$t("Holding Yard & Milking Shed Assessor") +
          app.$t(" - ") +
          group.getName(),
        report.styleOptions.slideHeader
      );

      slide2.addText(app.$t("Milking Platform"), {
        x: 0.5,
        y: 2.38,
        ...report.styleOptions.subheaderBlue,
      });

      Table.create(
        slide2,
        obj,
        milkingPlatformArray,
        report,
        group,
        "Holding Yard & Milking Shed Assessor",
        {
          y: 2.7,
          colW: [3.25, 1.25, 3],
          ...report.styleOptions.tableOptions,
        }
      );
    }
  }

  // Platform Exit/Exit Race

  const exitRaceIsTheSurfaceMadeOfNonSlipMaterial =
    assessor.getExitRaceIsTheSurfaceMadeOfNonSlipMaterial();

  if (exitRaceIsTheSurfaceMadeOfNonSlipMaterial !== null) {
    platformExitArray.push([
      {
        text: app.$t("Is the surface made of non-slip material?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          exitRaceIsTheSurfaceMadeOfNonSlipMaterial
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          exitRaceIsTheSurfaceMadeOfNonSlipMaterial === 1
            ? app.$t(
                "Good, provided that the surface is not excessively abrasive."
              )
            : exitRaceIsTheSurfaceMadeOfNonSlipMaterial === 0
            ? app.$t(
                "Exit surface needs to be non-slip and non-abrasive, especially if cows need to make a turn to exit the bail. Slippery surfaces increase risk of cow injury."
              )
            : "",
        options: {
          align: "left",
          color:
            exitRaceIsTheSurfaceMadeOfNonSlipMaterial === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const herringboneDoCowsExitInAStraightLineFromBails =
    assessor.getHerringboneDoCowsExitInAStraightLineFromBails();

  if (
    herringboneDoCowsExitInAStraightLineFromBails !== null &&
    milkingShedType === "herringbone"
  ) {
    platformExitArray.push([
      {
        text: app.$t(
          "Herringbone: Do cows exit in a straight line from bails?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          herringboneDoCowsExitInAStraightLineFromBails
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          herringboneDoCowsExitInAStraightLineFromBails === 1
            ? app.$t("Good!")
            : herringboneDoCowsExitInAStraightLineFromBails === 0
            ? app.$t(
                "Ideally, the cow should exit the bail in a straight lane and should have an unobstructed view of the exit race. Eliminating turns and providing cows with an unobstructed view of the exit race, increases cows flow and decreases risk of injury to cows."
              )
            : "",
        options: {
          align: "left",
          color:
            herringboneDoCowsExitInAStraightLineFromBails === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const herringboneCanTheExitRaceContainAFullRowOfCows =
    assessor.getHerringboneCanTheExitRaceContainAFullRowOfCows();

  if (
    herringboneCanTheExitRaceContainAFullRowOfCows !== null &&
    milkingShedType === "herringbone"
  ) {
    platformExitArray.push([
      {
        text: app.$t(
          "Herringbone: Can the exit race contain a full row of cows?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          herringboneCanTheExitRaceContainAFullRowOfCows
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          herringboneCanTheExitRaceContainAFullRowOfCows === 1
            ? app.$t("Good!")
            : herringboneCanTheExitRaceContainAFullRowOfCows === 0
            ? app.$t(
                "If the exit race can hold a full row of cows, cows will exit the bail faster and there will be less pushing and fighting among cows as they exit, decreasing injury risk."
              )
            : "",
        options: {
          align: "left",
          color:
            herringboneCanTheExitRaceContainAFullRowOfCows === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const rotaryIsARubberMatInstalledAtTheCowsTurningPoint =
    assessor.getRotaryIsARubberMatInstalledAtTheCowsTurningPoint();

  if (
    rotaryIsARubberMatInstalledAtTheCowsTurningPoint !== null &&
    milkingShedType === "rotary"
  ) {
    platformExitArray.push([
      {
        text: app.$t(
          "Rotary: Is a rubber mat installed at the cows turning point?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          rotaryIsARubberMatInstalledAtTheCowsTurningPoint
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          rotaryIsARubberMatInstalledAtTheCowsTurningPoint === 1
            ? app.$t("Good, provided the rubber mat is not slippery.")
            : rotaryIsARubberMatInstalledAtTheCowsTurningPoint === 0
            ? app.$t(
                "A rubber mat installed at point of exit, reduces strain and wear on claws. The rubbermat should be big enough so that most cows can make a full turn on the mat."
              )
            : "",
        options: {
          align: "left",
          color:
            rotaryIsARubberMatInstalledAtTheCowsTurningPoint === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const rotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck =
    assessor.getRotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck();

  if (
    rotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck !==
      null &&
    milkingShedType === "rotary"
  ) {
    platformExitArray.push([
      {
        text: app.$t(
          "Rotary: Do cows turn freely without bending their backbone or neck?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          rotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          rotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck ===
          1
            ? app.$t("Good!")
            : rotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck ===
              0
            ? app.$t(
                "The exit is ideally at least 3 bails wide, and deep enough to allow cows to turn without having to bend their backbone and neck. Providing less than adequate space for cows to exit the bail, increases risk of cow injury and decreases cow flow."
              )
            : "",
        options: {
          align: "left",
          color:
            rotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (platformExitArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Platform Exit/Exit Race"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      platformExitArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Slopes/Steps

  const approximateYardSlope = assessor.getApproximateYardSlope();

  if (approximateYardSlope !== null && approximateYardSlope !== "") {
    slopesStepsArray.push([
      {
        text: app.$t("Approximate yard slope"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: app.$numberFormat(approximateYardSlope),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "", // approximateYardSlope === 1 ? app.$t('Good!') : approximateYardSlope === 0 ? app.$t('') : '',
        options: {
          align: "left",
          color: "000000", // approximateYardSlope === 1 ? '249824' : 'a40000',
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const drop = assessor.getDrop();

  if (drop !== null && drop !== "") {
    slopesStepsArray.push([
      {
        text: app.$t("Drop"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: drop,
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "", //drop === 1 ? app.$t('Good!') : drop === 0 ? app.$t('') : '',
        options: {
          align: "left",
          color: "000000", // drop === 1 ? '249824' : 'a40000',
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const length = assessor.getLength();

  if (length !== null && length !== "") {
    slopesStepsArray.push([
      {
        text: app.$t("Length"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: length,
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: "",
        options: {
          align: "left",
          color: "000000", // length === 1 ? '249824' : 'a40000',
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areThereCowRamps = assessor.getAreThereCowRamps();

  if (areThereCowRamps !== null) {
    slopesStepsArray.push([
      {
        text: app.$t("Are there cow ramps"), //Is algae present on any surface?'),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areThereCowRamps),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areThereCowRamps === 1
            ? app.$t(
                "While cow ramps are better than steps, slope of cow ramps should not be greater than 1:25 to minimize the amount slipping by cows."
              )
            : areThereCowRamps === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: areThereCowRamps === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areThereSteps = assessor.getAreThereSteps();

  if (areThereSteps !== null) {
    const unit =
      evaluation.getMeasurements() === "imperial" ? "inches" : "cm";
    slopesStepsArray.push([
      {
        text: app.$t("Are there steps?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areThereSteps),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areThereSteps === 1
            ? app.$t(
                "Steps decrease cow flow and require patience by the cow handler when moving cows up and down than the steps to prevent injury. Maximum height for each step should be "
              ) +
              convert(15)
                .from("cm")
                .to(
                  app.$getEquivalentUnit(
                    evaluation.getMeasurements(),
                    "cm"
                  )
                )
                .toDecimal(0) +
              " " +
              app.$t(unit)
            : areThereSteps === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: areThereSteps === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (slopesStepsArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Slopes/Steps"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      slopesStepsArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Concrete Composition, Surface

  const isTheSurfaceRoughWithAPronouncedAggregate =
    assessor.getIsTheSurfaceRoughWithAPronouncedAggregate();

  if (isTheSurfaceRoughWithAPronouncedAggregate !== null) {
    concreteCompositionSurfaceArray.push([
      {
        text: app.$t(
          "Is the surface rough with a pronounced aggregate?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheSurfaceRoughWithAPronouncedAggregate
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheSurfaceRoughWithAPronouncedAggregate === 1
            ? app.$t(
                "Exposed aggregate can increase abrasiveness of concrete, especially if crushed aggregate of hard parent material was used. Excessively abrasive surfaces can be re-coated with either a higher quality concrete or specialized epoxy coatings with fillers."
              )
            : isTheSurfaceRoughWithAPronouncedAggregate === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isTheSurfaceRoughWithAPronouncedAggregate === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheSurfaceSlippery = assessor.getIsTheSurfaceSlippery();

  if (isTheSurfaceSlippery !== null) {
    concreteCompositionSurfaceArray.push([
      {
        text: app.$t("Is the surface slippery?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isTheSurfaceSlippery),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheSurfaceSlippery === 1
            ? isMeasurementsMetric
              ? app.$t(
                  "Slippery surfaces can be remedied by grooving concrete 5 mm deep and 5 mm wide in approximately 10 by 10 cm square patterns. Care should be taken to prevent grooved surfaces from becoming excessively abrasive."
                )
              : app.$t(
                  "Slippery surfaces can be remedied by grooving concrete 3/16 inches deep and 3/16 inches wide in approximately 4 by 4 inches square patterns. Care should be taken to prevent grooved surfaces from becoming excessively abrasive."
                )
            : // app.$t(`Slippery surfaces can be remedied by grooving concrete ${isMeasurementsMetric ? `5 ${$t("mm")}` : `3/16 ${$t("inches")}`} deep and ${isMeasurementsMetric ? '5 mm' : '3/16 inches'} wide in approximately ${isMeasurementsMetric ? '10 by 10 cm' : '4 by 4 inches'} square patterns. Care should be taken to prevent grooved surfaces from becoming excessively abrasive.`)
            isTheSurfaceSlippery === 0
            ? app.$t(
                "Good, provided the surface is not excessively abrasive."
              )
            : "",
        options: {
          align: "left",
          color: isTheSurfaceSlippery === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isAlgaePresentOnAnySurface =
    assessor.getIsAlgaePresentOnAnySurface();

  if (isAlgaePresentOnAnySurface !== null) {
    concreteCompositionSurfaceArray.push([
      {
        text: app.$t("Is algae present on any surface?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isAlgaePresentOnAnySurface),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isAlgaePresentOnAnySurface === 1
            ? app.$t(
                "Presence of algae on floors makes the floors extremely slippery. Floors susceptible to algae growth should be routinely treated with a bleach mixture to prevent algae growth."
              )
            : isAlgaePresentOnAnySurface === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isAlgaePresentOnAnySurface === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (concreteCompositionSurfaceArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Concrete Composition, Surface"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      concreteCompositionSurfaceArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Cleanliness

  const isTheYardCleanWhenNotInUseEspeciallyFreeOfStones =
    assessor.getIsTheYardCleanWhenNotInUseEspeciallyFreeOfStones();

  if (isTheYardCleanWhenNotInUseEspeciallyFreeOfStones !== null) {
    cleanlinessArray.push([
      {
        text: app.$t(
          "Is the yard clean when not in use (especially free of stones)?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheYardCleanWhenNotInUseEspeciallyFreeOfStones
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheYardCleanWhenNotInUseEspeciallyFreeOfStones === 1
            ? app.$t("Good!")
            : isTheYardCleanWhenNotInUseEspeciallyFreeOfStones === 0
            ? app.$t(
                "Routine cleaning the yard removes stones and other debris that can cause injury to feet of cattle."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheYardCleanWhenNotInUseEspeciallyFreeOfStones === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (cleanlinessArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Cleanliness"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      cleanlinessArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Feedbins in Shed

  const areCowsFedInTheShed = assessor.getAreCowsFedInTheShed();

  if (areCowsFedInTheShed !== null) {
    feedbinsInShedArray.push([
      {
        text: app.$t("Are cows fed in the shed?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areCowsFedInTheShed),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areCowsFedInTheShed === 1
            ? app.$t(
                "Feeding cows in the shed generally increases cow flow into the shed and may slow exit of cows from the shed. Cows may fight to get into the shed, increasing risk of trauma to cow's feet."
              )
            : areCowsFedInTheShed === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: areCowsFedInTheShed === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (feedbinsInShedArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Feedbins in Shed"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      feedbinsInShedArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Bulls/Dogs/Social

  const areBullsKeptOutOfTheYardAtAllTimes =
    assessor.getAreBullsKeptOutOfTheYardAtAllTimes();

  if (areBullsKeptOutOfTheYardAtAllTimes !== null) {
    bullsDogsSocialArray.push([
      {
        text: app.$t("Are bulls kept out of the yard at all times?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areBullsKeptOutOfTheYardAtAllTimes),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areBullsKeptOutOfTheYardAtAllTimes === 1
            ? app.$t("Good!")
            : areBullsKeptOutOfTheYardAtAllTimes === 0
            ? app.$t(
                "Allowing bulls into the yard is a danger to people, bulls and cows. Allowing bulls into the yard increases the risk of trauma to bulls' and cows' feet and legs as bulls mount and dismount cows as well as attempt to assert their dominance."
              )
            : "",
        options: {
          align: "left",
          color:
            areBullsKeptOutOfTheYardAtAllTimes === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areAnyBitingDogsPresent =
    assessor.getAreAnyBitingDogsPresent();

  if (areAnyBitingDogsPresent !== null) {
    bullsDogsSocialArray.push([
      {
        text: app.$t("Are any biting dogs present?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areAnyBitingDogsPresent),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areAnyBitingDogsPresent === 1
            ? app.$t(
                "Biting dogs should not be permitted to be around cows as cows will scramble to get away from the dog. Dogs that don't bite need to be kept at sufficient distance from cows (outside the cow's flight zone)."
              )
            : areAnyBitingDogsPresent === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: areAnyBitingDogsPresent === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areNonBitingDogsKeptAtANoFlightDistanceFromCows =
    assessor.getAreNonBitingDogsKeptAtANoFlightDistanceFromCows();

  if (areNonBitingDogsKeptAtANoFlightDistanceFromCows !== null) {
    bullsDogsSocialArray.push([
      {
        text: app.$t(
          "Are non-biting dogs kept at a no-flight distance from cows?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          areNonBitingDogsKeptAtANoFlightDistanceFromCows
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areNonBitingDogsKeptAtANoFlightDistanceFromCows === 1
            ? app.$t("Good!")
            : areNonBitingDogsKeptAtANoFlightDistanceFromCows === 0
            ? app.$t(
                "Biting dogs should never be allowed near stock at all. Non-biting dogs can be helpful rounding up cows from the paddock and driving them forward on the races; as long as they are kept outside the no-flight distance, the cows will not experience undue pressure from them."
              )
            : "",
        options: {
          align: "left",
          color:
            areNonBitingDogsKeptAtANoFlightDistanceFromCows === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const doCowsHaveLowFlightDistance =
    assessor.getDoCowsHaveLowFlightDistance();

  if (doCowsHaveLowFlightDistance !== null) {
    bullsDogsSocialArray.push([
      {
        text: app.$t("Do cows have a low flight distance?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(doCowsHaveLowFlightDistance),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doCowsHaveLowFlightDistance === 1
            ? app.$t("Good!")
            : doCowsHaveLowFlightDistance === 0
            ? app.$t(
                `Well-treated cows are relatively unafraid of humans. They will allow people to come within ${
                  isMeasurementsMetric ? "2.3 meters" : "2.5 yards"
                } in the yard and ${
                  isMeasurementsMetric ? "3 meters" : "3.5 yards"
                } within the paddock before turning away; ${
                  isMeasurementsMetric ? "4.4 meters" : "4.8 yards"
                } in the yard and ${
                  isMeasurementsMetric ? "5.3 meters" : "5.8 yards"
                } in the paddock are acceptable. Greater flight distances than these indicate that handlers are treating cows roughly. Cows with large flight distance are at greater risk of injury as they attempt to flee the cow handler.`
              )
            : "",
        options: {
          align: "left",
          color:
            doCowsHaveLowFlightDistance === 1 ? "249824" : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areYoungCowsSeparatedFromMatureCows =
    assessor.getAreYoungCowsSeparatedFromMatureCows();

  if (areYoungCowsSeparatedFromMatureCows !== null) {
    bullsDogsSocialArray.push([
      {
        text: app.$t("Are young cows separated from mature cows?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areYoungCowsSeparatedFromMatureCows),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areYoungCowsSeparatedFromMatureCows === 1
            ? app.$t("Good!")
            : areYoungCowsSeparatedFromMatureCows === 0
            ? app.$t(
                "Older, bigger cows exert dominance over younger, smaller animals. Keeping the younger animals in a separate mob can help reduce social pressure and wear and tear on claws. Milker/herdsman behaviour can easily override any social patterns."
              )
            : "",
        options: {
          align: "left",
          color:
            areYoungCowsSeparatedFromMatureCows === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (bullsDogsSocialArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Bulls/Dogs/Social"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      bullsDogsSocialArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Milker Behavior

  const doesTheMilkerHitTheCows =
    assessor.getDoesTheMilkerHitTheCows();

  if (doesTheMilkerHitTheCows !== null) {
    milkerBehaviorArray.push([
      {
        text: app.$t("Does the milker hit the cows?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(doesTheMilkerHitTheCows),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doesTheMilkerHitTheCows === 1
            ? app.$t(
                "Hitting cows, even mildly, quickly decreases cows' confidence and increases flight distance. This in turn reduces cows' willingness to move into bails, past people, etc, which can result in the milker hitting the cows more. Cows need to be treated gently and calmly."
              )
            : doesTheMilkerHitTheCows === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: doesTheMilkerHitTheCows === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const doesTheMilkerMakeSuddenUnexpectedMovements =
    assessor.getDoesTheMilkerMakeSuddenUnexpectedMovements();

  if (doesTheMilkerMakeSuddenUnexpectedMovements !== null) {
    milkerBehaviorArray.push([
      {
        text: app.$t(
          "Does the milker make sudden, unexpected movements?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          doesTheMilkerMakeSuddenUnexpectedMovements
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doesTheMilkerMakeSuddenUnexpectedMovements === 1
            ? app.$t(
                "Loud, sudden noises and movements as well as unusual objects affect the cows in a similar manner as hitting cows. Loud sudden noises and movements decrease cows' confidence and increase cows' flight zone. Cows need to be treated gently and calmly and milkers should adhere to a routine."
              )
            : doesTheMilkerMakeSuddenUnexpectedMovements === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            doesTheMilkerMakeSuddenUnexpectedMovements === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areThereAnySuddenNoises =
    assessor.getAreThereAnySuddenNoises();

  if (areThereAnySuddenNoises !== null) {
    milkerBehaviorArray.push([
      {
        text: app.$t(
          "Are there any sudden noises (voice, radio, equipment, etc.)?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areThereAnySuddenNoises),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areThereAnySuddenNoises === 1
            ? app.$t(
                "Loud, sudden noises and movements as well as unusual objects affect the cows in a similar manner as hitting cows. Loud sudden noises and movements decrease cows' confidence and increase cows' flight zone. Cows need to be treated gently and calmly and milkers should adhere to a routine."
              )
            : areThereAnySuddenNoises === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: areThereAnySuddenNoises === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const doesTheMilkerLeaveTheShedToGetCows =
    assessor.getDoesTheMilkerLeaveTheShedToGetCows();

  if (doesTheMilkerLeaveTheShedToGetCows !== null) {
    milkerBehaviorArray.push([
      {
        text: app.$t("Does the milker leave the shed to get cows?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(doesTheMilkerLeaveTheShedToGetCows),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doesTheMilkerLeaveTheShedToGetCows === 1
            ? app.$t(
                "The milker should not leave the shed to get cows to enter the shed. In doing so, the cows quickly learn to not move, unless the milker comes out of the shed. This disrupts the routine, slowing down milking."
              )
            : doesTheMilkerLeaveTheShedToGetCows === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            doesTheMilkerLeaveTheShedToGetCows === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (milkerBehaviorArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Milker Behavior"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      milkerBehaviorArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Light
  const isTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource =
    assessor.getIsTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource();

  if (
    isTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource !==
    null
  ) {
    lightArray.push([
      {
        text: app.$t(
          "Is the shed well lit with roof panels, windows and/or an artificial light source?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource ===
          1
            ? app.$t("Good!")
            : isTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource ===
              0
            ? app.$t(
                "Plenty of natural or artificial light makes it easier for cows to see where they're going, improving cow flow. Cows are reluctant to move into dark, shaded areas."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects =
    assessor.getIsTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects();

  if (
    isTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects !== null
  ) {
    lightArray.push([
      {
        text: app.$t(
          "Is the exit path poorly lit or shaded by a wall, shrubs or other objects?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects ===
          1
            ? app.$t(
                "Any obstructions around the exit that create shade, or result in a poorly lit exit path will slow cow flow. Providing supplemental lighting to poorly lit or shaded exit races will improve cow flow."
              )
            : isTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects ===
              0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects ===
            1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (lightArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Light"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      lightArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  const isTheFenceUnitSwitchedOffDuringMilking =
    assessor.getIsTheFenceUnitSwitchedOffDuringMilking();

  if (isTheFenceUnitSwitchedOffDuringMilking !== null) {
    strayVoltageArray.push([
      {
        text: app.$t(
          "Is the fence unit switched off during milking?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheFenceUnitSwitchedOffDuringMilking
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheFenceUnitSwitchedOffDuringMilking === 1
            ? app.$t("Good!")
            : isTheFenceUnitSwitchedOffDuringMilking === 0
            ? app.$t(
                "The fence unit should be turned off during milking, removing a major potential source of stray voltage. Backing gates that use a fence unit are not desirable. They create fear and excess and erratic cow movement in the yard."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheFenceUnitSwitchedOffDuringMilking === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const theShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary =
    assessor.getTheShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary();

  if (
    theShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary !==
    null
  ) {
    strayVoltageArray.push([
      {
        text: app.$t(
          "The shed has been checked for stray voltage by a competent operator and remedied where necessary?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          theShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          theShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary ===
          1
            ? app.$t("Good!")
            : theShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary ===
              0
            ? app.$t(
                "Stray voltage slows down cow flow into the shed and creates fear in cows, resulting in erratic and excess movements."
              )
            : "",
        options: {
          align: "left",
          color:
            theShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas =
    assessor.getAreThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas();

  if (
    areThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas !== null
  ) {
    strayVoltageArray.push([
      {
        text: app.$t(
          "Are there any signs of cows that are reluctant to enter certain areas?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          areThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas ===
          1
            ? app.$t(
                "If no obvious reason can be found, stray voltage can be a problem if cows are reluctant to enter certain areas routinely."
              )
            : areThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas ===
              0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            areThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas ===
            1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (strayVoltageArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Stray Voltage"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      strayVoltageArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }

  // Backing Gate/Top Gate

  const isTheBackingGateElectrified =
    assessor.getIsTheBackingGateElectrified();

  if (isTheBackingGateElectrified !== null) {
    gateArray.push([
      {
        text: app.$t("Is the backing gate electrified?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isTheBackingGateElectrified),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheBackingGateElectrified === 1
            ? app.$t(
                "There should be no need for the backing gate to be electrified. The need to electrify the backing gate is a symptom of either poor gate design or poor milker behaviour."
              )
            : isTheBackingGateElectrified === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isTheBackingGateElectrified === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack =
    assessor.getIsTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack();

  if (
    isTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack !==
    null
  ) {
    gateArray.push([
      {
        text: app.$t(
          `Is the backing gate used to fill up space behind the cows? It is not pushing on them, and the cows are not leaning back?`
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack ===
          1
            ? app.$t("Good!")
            : isTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack ===
              0
            ? app.$t(
                "Backing gates should be used as a means to take up space that cows vacated. Pushing the whole herd from behind has very little effect on the cows at the bail entrance. In addition, it puts a lot of unnecessary stress on the cows closest to the backing gate, increasing risk of injury to cows' feet and legs. Cows leaning back on the gate and cows with their heads up are clear signs of excess backing gate pressure."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheBackingGateSpeedExcessive =
    assessor.getIsTheBackingGateSpeedExcessive();

  if (isTheBackingGateSpeedExcessive !== null) {
    gateArray.push([
      {
        text: app.$t("Is the backing gate speed excessive?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(isTheBackingGateSpeedExcessive),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheBackingGateSpeedExcessive === 1
            ? app.$t(
                `On rectangular yards, backing gate speed should be less than ${
                  isMeasurementsMetric ? "6 m/min" : "6.5 yd/min"
                }. On round yards, backing gate speed should be no more than ${
                  isMeasurementsMetric ? "12 m/min" : "13 yd/min"
                } at end of the boom. Excess backing gate speed puts a lot of unnecessary stress on cows closest to the backing gate, increasing risk of injury to cows\' feet and legs.`
              )
            : isTheBackingGateSpeedExcessive === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isTheBackingGateSpeedExcessive === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheBackingGateUsedInTheFirst15MinutesOfMilking =
    assessor.getIsTheBackingGateUsedInTheFirst15MinutesOfMilking();

  if (isTheBackingGateUsedInTheFirst15MinutesOfMilking !== null) {
    gateArray.push([
      {
        text: app.$t(
          "Is the backing gate used in the first 15 minutes of milking?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheBackingGateUsedInTheFirst15MinutesOfMilking
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheBackingGateUsedInTheFirst15MinutesOfMilking === 1
            ? app.$t(
                "In the first 15 minutes of milking, cows should be allowed to rearrange their position according to social status, without using the backing gate to disturb this process. Disturbing this process decreases cow flow into the shed and increases confrontations between cows."
              )
            : isTheBackingGateUsedInTheFirst15MinutesOfMilking === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color:
            isTheBackingGateUsedInTheFirst15MinutesOfMilking === 1
              ? "a40000"
              : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const areTheCowsHeadsUp = assessor.getAreTheCowsHeadsUp();

  if (areTheCowsHeadsUp !== null) {
    gateArray.push([
      {
        text: app.$t("Are the cows’ heads up?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(areTheCowsHeadsUp),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          areTheCowsHeadsUp === 1
            ? app.$t(
                "Cows leaning back on the gate and cows with their heads up are clear signs of excess backing gate pressure."
              )
            : areTheCowsHeadsUp === 0
            ? app.$t("Good!")
            : "",
        options: {
          align: "left",
          color: areTheCowsHeadsUp === 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheBuzzerBellWorkingInConjunctionWithBackingTopGate =
    assessor.getIsTheBuzzerBellWorkingInConjunctionWithBackingTopGate();

  if (
    isTheBuzzerBellWorkingInConjunctionWithBackingTopGate !== null
  ) {
    gateArray.push([
      {
        text: app.$t(
          "Is the buzzer/bell working in conjunction with backing/top gate?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheBuzzerBellWorkingInConjunctionWithBackingTopGate
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheBuzzerBellWorkingInConjunctionWithBackingTopGate === 1
            ? app.$t("Good!")
            : isTheBuzzerBellWorkingInConjunctionWithBackingTopGate ===
              0
            ? app.$t(
                "Cows will quickly learn to move forward on sound alone if a buzzer/bell sounds at the same time as the backing or top gate is moved. Sounding the buzzer/bell at the same time as the backing or top gate moves will improve cow flow and reduce pressure at the backing/top gate."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheBuzzerBellWorkingInConjunctionWithBackingTopGate ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const isTheTopGateUsedToBringUpSubgroupsOfCows =
    assessor.getIsTheTopGateUsedToBringUpSubgroupsOfCows();

  if (isTheTopGateUsedToBringUpSubgroupsOfCows !== null) {
    gateArray.push([
      {
        text: app.$t(
          "Is the top gate used to bring up subgroups of cows?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          isTheTopGateUsedToBringUpSubgroupsOfCows
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          isTheTopGateUsedToBringUpSubgroupsOfCows === 1
            ? app.$t("Good!")
            : isTheTopGateUsedToBringUpSubgroupsOfCows === 0
            ? app.$t(
                "With mobs greater than 200 cows, a top gate should be used to separate out and move a small group of cows towards the milk shed. The backing gate is not effective in promoting cow flow into the milk shed with mobs greater than 200 cows and tends to result in excess cow pressure at the backing gate."
              )
            : "",
        options: {
          align: "left",
          color:
            isTheTopGateUsedToBringUpSubgroupsOfCows === 1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const doCowsEscapeThroughTopGate =
    assessor.getDoCowsEscapeThroughTopGate();
  if (doCowsEscapeThroughTopGate !== null) {
    gateArray.push([
      {
        text: app.$t("Do cows escape through top gate?"),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(doCowsEscapeThroughTopGate),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doCowsEscapeThroughTopGate === 1
            ? app.$t(
                "Cows escaping through the top gate can result in milker frustration and aggression. If the top gate needs to be electrified to remedy this, the electrified gate needs to be used with extremely good judgement as careless use can quickly lead to abuse."
              )
            : doCowsEscapeThroughTopGate === 0
            ? app.$t(
                "Good! However, an electrified top gate, to prevent cows from escaping, should be used cautiously."
              )
            : "",
        options: {
          align: "left",
          color:
            doCowsEscapeThroughTopGate == 1 ? "a40000" : "249824",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  const doMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates =
    assessor.getDoMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates();

  if (
    doMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates !==
    null
  ) {
    gateArray.push([
      {
        text: app.$t(
          "Do milkers have an excellent view of the yard from the platform, enabling milkers to be aware of cow behavior and positions of the backing and top gates?"
        ),
        options: {
          color: "000000",
          align: "left",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text: getAnswerYesNoNA(
          doMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates
        ),
        options: {
          color: "000000",
          fill: "ffffff",
          bold: false,
        },
      },
      {
        text:
          doMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates ===
          1
            ? app.$t("Good!")
            : doMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates ===
              0
            ? app.$t(
                "Milkers need to always be aware of cows behaviour (heads up, leaning back) and positions of the backing and top gates. A convex mirror can be used to improve milkers' view of the yard, if there is not good overview of the yard from the milkers' positions."
              )
            : "",
        options: {
          align: "left",
          color:
            doMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates ===
            1
              ? "249824"
              : "a40000",
          fill: "efefef",
          bold: false,
        },
      },
    ]);
    slideY += 0.5;
    titleY += 0.5;
  }

  if (gateArray.length > 0) {
    slide2 = report.pptx.addSlide("Slide master");

    slide2.addText(
      app.$t("Holding Yard & Milking Shed Assessor") +
        app.$t(" - ") +
        group.getName(),
      report.styleOptions.slideHeader
    );

    slide2.addText(app.$t("Backing Gate/Top Gate"), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue,
    });

    Table.create(
      slide2,
      obj,
      gateArray,
      report,
      group,
      "Holding Yard & Milking Shed Assessor",
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions,
      }
    );
  }
};
