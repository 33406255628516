<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block mb-4 mr-1">{{ 'Tell Us About Your Freestall Barn' | translate }}</h2>
        <modal-info :value="'freestall'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ 'Tell Us About Your Freestall' | translate }}</h2>
            <p class="px-3">
              {{ 'For more information about Freestalls, click the link to learn more from' | translate }}
              <a
                :href="$t('https://library.zinprofirststep.com/freestall/')"
                @click="$goToLameness($t('/freestall/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <div class="row mx-0">
      <div class="form-group">
        <div class="form-check form-check-inline mr-4">
          <input
            v-model="assessor.data.freestall_type"
            type="radio"
            id="analyze_existing"
            name="analyze_existing"
            value="analyze_existing"
            class="form-check-input mr-2"
          />
          <label class="form-check-label mt-1" for="analyze_existing">
            {{ 'Analyze Existing Freestall' | translate }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            v-model="assessor.data.freestall_type"
            type="radio"
            id="build_ideal"
            name="build_ideal"
            value="build_ideal"
            class="form-check-input mr-2"
          />
          <label class="form-check-label mt-1" for="build_ideal">
            {{ 'Build Ideal Freestall' | translate }}
          </label>
        </div>
      </div>
    </div>

    <hr class="mb-4 mt-1 mt-lg-0" />

    <div class="row">
      <div class="col-lg-12">
        <label class="mb-1">{{ 'Select Your Stall Alignment' | translate }}</label>
        <select v-model="assessor.data.stall_alignment" class="form-control">
          <option value="one">{{ 'One Row' | translate }}</option>
          <option value="two">{{ 'Two Row Head to Head' | translate }}</option>
          <option value="two_row_head_to_tail">{{ 'Two Row Head to Tail' | translate }}</option>
          <option value="two_row_tail_to_tail">{{ 'Two Row Tail to Tail' | translate }}</option>
          <option value="three">{{ 'Three Row' | translate }}</option>
        </select>
        <img
          v-if="assessor.data.stall_alignment === 'one'"
          src="@/assets/img/Single_Row.jpg"
          alt="Single Row"
          class="w-100 mb-3 mb-lg-0 mt-3"
        />
        <img
          v-if="assessor.data.stall_alignment === 'two'"
          src="@/assets/img/Two_Row_Head_to_Head.jpg"
          alt="Two Row Head to Head"
          class="w-100 mb-3 mb-lg-0 mt-3"
        />
        <img
          v-if="assessor.data.stall_alignment === 'two_row_head_to_tail'"
          src="@/assets/img/Two_Row_Head_to_Tail.jpg"
          alt="Two Row Head to Tail"
          class="w-100 mb-3 mb-lg-0 mt-3"
        />
        <img
          v-if="assessor.data.stall_alignment === 'two_row_tail_to_tail'"
          src="@/assets/img/Two_Row_Tail_to_Tail.jpg"
          alt="Two Row Tail to Tail"
          class="w-100 mb-3 mb-lg-0 mt-3"
        />
        <img
          v-if="assessor.data.stall_alignment === 'three'"
          src="@/assets/img/Three_Row.jpg"
          alt="Three Row"
          class="w-100 mb-3 mb-lg-0 mt-3"
        />
      </div>
      <div class="col-lg-12">
        <label class="mb-1">{{ 'Select Your Divider Type' | translate }}</label>
        <select v-model="assessor.data.divider_type" class="form-control">
          <option value="cantilever">{{ 'Cantilever Divider' | translate }}</option>
          <option value="michigan">{{ 'Michigan Divider' | translate }}</option>
          <option value="multiple_blend">{{ 'Multiple Bend Divider' | translate }}</option>
          <option value="square">{{ 'Square Divider' | translate }}</option>
          <option value="sturdy_built">{{ 'Sturdy Built Divider' | translate }}</option>
        </select>
        <img
          v-if="assessor.data.divider_type === 'cantilever'"
          src="@/assets/img/Cantilever.jpg"
          alt="Cantilever divider"
          class="w-100 mt-3"
        />
        <img
          v-if="assessor.data.divider_type === 'michigan'"
          src="@/assets/img/Michigan.jpg"
          alt="Michigan divider"
          class="w-100 mt-3"
        />
        <img
          v-if="assessor.data.divider_type === 'multiple_blend'"
          src="@/assets/img/Multiple_Bend.jpg"
          alt="Multiple Bend divider"
          class="w-100 mt-3"
        />
        <img
          v-if="assessor.data.divider_type === 'square'"
          src="@/assets/img/Square.jpg"
          alt="Square divider"
          class="w-100 mt-3"
        />
        <img
          v-if="assessor.data.divider_type === 'sturdy_built'"
          src="@/assets/img/Sturdy_built.jpg"
          alt="Sturdy built divider"
          class="w-100 mt-3"
        />
      </div>
    </div>

    <hr class="mb-4 mt-5" />

    <div class="row">
      <div class="col-lg-12">
        <label class="mb-1">{{
          $t('Average Cow Size') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) + ')'
        }}</label>
        <input type="text" :value="groupClass.getCowSizeLabel()" class="form-control" readonly />
      </div>
      <div class="col-lg-12">
        <label class="mb-1">{{ 'Bedding Type' | translate }}</label>
        <select v-model="assessor.data.bedding_type" class="form-control">
          <option disabled :value="null">{{ 'Select' | translate }}</option>
          <option value="concrete">{{ 'Concrete' | translate }}</option>
          <option value="foam_mattress">{{ 'Foam Mattress' | translate }}</option>
          <option value="manure_solids">{{ 'Manure Solids' | translate }}</option>
          <option value="mats">{{ 'Mats' | translate }}</option>
          <option value="rubber_mattress">{{ 'Rubber Mattress' | translate }}</option>
          <option value="sand">{{ 'Sand' | translate }}</option>
          <option value="straw">{{ 'Straw' | translate }}</option>
					<option value="sawdust">{{ $t('Sawdust') }}</option>
          <option value="water_bed">{{ 'Water Bed' | translate }}</option>
          <option value="other">{{ 'Other' | translate }}</option>
        </select>
      </div>
      <div class="col-lg-12">
        <label class="mb-1">{{
          $t('Bedding Depth (') + $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) + ')'
        }}</label>
        <!-- set up conditional css class for this -->
        <currency-input                 
            v-model="converted_bedding_depth"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
      </div>
      <div v-if="assessor.data.bedding_type === 'other'" class="col-lg-12">
        <label class="mb-1">{{ 'Bedding Type Name' | translate }}</label>
        <input type="text" v-model="assessor.data.other_bedding_type" class="form-control" />
      </div>
    </div>

    <hr class="mt-4" />

    <question
      @change="
        assessor.data.surface_dry = $event.value;
        setCopyField('surface_dry');
      "
      :initial-value="assessor.data.surface_dry"
    >
      <label :class="['label d-inline-block', { 'text-danger': assessor.data.surface_dry === 0 }]">
        {{ 'Is the Stall Surface Dry?' | translate }}
      </label>
      <modal-info :value="'surface-dry'" class="d-inline">
        <template slot="modal-content">
          <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
            {{ 'Is the Stall Surface Dry?' | translate }}
          </h2>
          <p class="px-3 mb-2">
            {{
              'Excessive moisture on the lying surface may decrease lying time by about 1 hour per day. (Reich et al., 2010: JDS 93: 1561)'
                | translate
            }}
          </p>
          <div class="row modal-bottom pb-3">
            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
              'Cancel' | translate
            }}</a>
          </div>
        </template>
      </modal-info>
    </question>

    <hr />

    <question
      @change="
        assessor.data.hock_lesions = $event.value;
        setCopyField('hock_lesions');
      "
      :initial-value="assessor.data.hock_lesions"
    >
      <label :class="['label d-inline-block', { 'text-danger': assessor.data.hock_lesions === 1 }]">
        {{ 'Are hock lesions present?' | translate }}
      </label>
    </question>

    <hr class="mb-4" />

    <div class="row mb-4">
      <div class="col-lg-12">
        <img
          v-if="assessor.data.divider_type === 'cantilever'"
          src="@/assets/img/cantilever.png"
          alt="cantilever divider"
          class="w-100"
        />
        <img
          v-if="assessor.data.divider_type === 'michigan'"
          src="@/assets/img/michigan.png"
          alt="michigan divider"
          class="w-100"
        />
        <img
          v-if="assessor.data.divider_type === 'multiple_blend'"
          src="@/assets/img/multiplebend.png"
          alt="multiple bend divider"
          class="w-100"
        />
        <img
          v-if="assessor.data.divider_type === 'square'"
          src="@/assets/img/square.png"
          alt="square divider"
          class="w-100"
        />
        <img
          v-if="assessor.data.divider_type === 'sturdy_built'"
          src="@/assets/img/sturdybuilt.png"
          alt="sturdy built divider"
          class="w-100"
        />
        <img
          v-if="freestall.getWidth() < freestall.getMinWidth() || freestall.getWidth() > freestall.getMaxWidth()"
          :class="[
            'stallWidth',
            'pointer',
            { greyout: (copyField && 'stall_width' != copyField) || copyField === null },
          ]"
          @click="setCopyField('stall_width')"
          src="@/assets/img/arrow-stall-width-red.png"
        />
        <img
          v-else
          :class="[
            'stallWidth',
            'pointer',
            { greyout: (copyField && 'stall_width' != copyField) || copyField === null },
          ]"
          @click="setCopyField('stall_width')"
          src="@/assets/img/arrow-stall-width-green.png"
        />

        <img
          v-if="
            converted_rear_curb_height >= freestall.getMinRearCurbHeight() &&
              converted_rear_curb_height <= freestall.getMaxRearCurbHeight()
          "
          :class="[
            'rearCurbHeight',
            'pointer',
            { greyout: (copyField && 'rear_curb_height' != copyField) || copyField === null },
          ]"
          @click="setCopyField('rear_curb_height')"
          src="@/assets/img/arrow-rear-curb-height-green.png"
        />
        <img
          v-else
          :class="[
            'rearCurbHeight',
            'pointer',
            { greyout: (copyField && 'rear_curb_height' != copyField) || copyField === null },
          ]"
          @click="setCopyField('rear_curb_height')"
          src="@/assets/img/arrow-rear-curb-height-red.png"
        />

        <img
          v-if="
            converted_neckrail_height >= freestall.getMinNeckrailHeight() &&
              converted_neckrail_height <= freestall.getMaxNeckrailHeight()
          "
          :class="[
            'neckrailHeight',
            'pointer',
            { greyout: (copyField && 'neckrail_height' != copyField) || copyField === null },
          ]"
          @click="setCopyField('neckrail_height')"
          src="@/assets/img/arrow-neckrail-height-green.png"
        />
        <img
          v-else
          :class="['neckrailHeight', 'pointer', { greyout: copyField && 'neckrail_height' != copyField }]"
          @click="setCopyField('neckrail_height')"
          src="@/assets/img/arrow-neckrail-height-red.png"
        />

        <img
          v-if="converted_brisket_locator_height <= freestall.getIdealBrisketLocatorHeight()"
          :class="[
            'brisketLocatorHeight',
            'pointer',
            { greyout: (copyField && 'brisket_locator_height' != copyField) || copyField === null },
          ]"
          @click="setCopyField('brisket_locator_height')"
          src="@/assets/img/arrow-brisk-loc-height-green.png"
        />
        <img
          v-else
          :class="[
            'brisketLocatorHeight',
            'pointer',
            { greyout: (copyField && 'brisket_locator_height' != copyField) || copyField === null },
          ]"
          @click="setCopyField('brisket_locator_height')"
          src="@/assets/img/arrow-brisk-loc-height-red.png"
        />

        <img
          v-if="
            converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance() ||
              converted_curb_to_brisket_locator_distance > freestall.getMaxCurbToBrisketLocatorDistance()
          "
          :class="[
            'curbToBrisketLocatorDistance',
            'pointer',
            { greyout: (copyField && 'curb_to_brisket_locator_distance' != copyField) || copyField === null },
          ]"
          @click="setCopyField('curb_to_brisket_locator_distance')"
          src="@/assets/img/arrow-curb-brisk-dist-red.png"
        />
        <img
          v-else
          :class="[
            'curbToBrisketLocatorDistance',
            'pointer',
            { greyout: (copyField && 'curb_to_brisket_locator_distance' != copyField) || copyField === null },
          ]"
          @click="setCopyField('curb_to_brisket_locator_distance')"
          src="@/assets/img/arrow-curb-brisk-dist-green.png"
        />

        <img
          v-if="
            converted_neckrail_rear_curb < freestall.getMinNeckrailToRearCurbDistance() ||
              converted_neckrail_rear_curb > freestall.getMaxNeckrailToRearCurbDistance()
          "
          :class="[
            'neckrailToRearCurbDistance',
            'pointer',
            { greyout: (copyField && 'neckrail_to_rear_curb_distance' != copyField) || copyField === null },
          ]"
          @click="setCopyField('neckrail_to_rear_curb_distance')"
          src="@/assets/img/arrow-neck-curb-dist-red.png"
        />
        <img
          v-else
          :class="[
            'neckrailToRearCurbDistance',
            'pointer',
            { greyout: (copyField && 'neckrail_to_rear_curb_distance' != copyField) || copyField === null },
          ]"
          @click="setCopyField('neckrail_to_rear_curb_distance')"
          src="@/assets/img/arrow-neck-curb-dist-green.png"
        />

        <img
          v-if="converted_rear_curb <= freestall.getIdealRearCurbWidth()"
          :class="[
            'rearCurbWidth',
            'pointer',
            { greyout: (copyField && 'rear_curb' != copyField) || copyField === null },
          ]"
          v-show="
            assessor.data.bedding_type === 'sand' ||
              assessor.data.bedding_type === 'manure_solids' ||
              assessor.data.bedding_type === 'straw'
          "
          @click="setCopyField('rear_curb')"
          src="@/assets/img/arrow-rear-curb-width-green.png"
        />
        <img
          v-else
          :class="[
            'rearCurbWidth',
            'pointer',
            { greyout: (copyField && 'rear_curb' != copyField) || copyField === null },
          ]"
          v-show="
            assessor.data.bedding_type === 'sand' ||
              assessor.data.bedding_type === 'manure_solids' ||
              assessor.data.bedding_type === 'straw'
          "
          @click="setCopyField('rear_curb')"
          src="@/assets/img/arrow-rear-curb-width-red.png"
        />

        <!-- The stall length arrow is always the same, but the fields that are checked differ based on the freestall alignment -->
        <img
          v-if="converted_stall_length_single_row >= freestall.getMinSingleRowStallLength()"
          :class="[
            'stallLength',
            'pointer',
            { greyout: (copyField && 'stall_length_single_row' != copyField) || copyField === null },
          ]"
          v-show="
            assessor.data.stall_alignment === 'one' ||
              assessor.data.stall_alignment === 'two_row_head_to_tail' ||
              assessor.data.stall_alignment === 'two_row_tail_to_tail'
          "
          @click="setCopyField('stall_length_single_row')"
          src="@/assets/img/arrow-stall-length-green.png"
        />
        <img
          v-else
          :class="[
            'stallLength',
            'pointer',
            { greyout: (copyField && 'stall_length_single_row' != copyField) || copyField === null },
          ]"
          v-show="
            assessor.data.stall_alignment === 'one' ||
              assessor.data.stall_alignment === 'two_row_head_to_tail' ||
              assessor.data.stall_alignment === 'two_row_tail_to_tail'
          "
          @click="setCopyField('stall_length_single_row')"
          src="@/assets/img/arrow-stall-length-red.png"
        />

        <img
          v-if="converted_stall_length >= freestall.getMinDoubleRowStallLength()"
          :class="[
            'stallLength',
            'pointer',
            { greyout: (copyField && 'stall_length_double_row' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.stall_alignment === 'two'"
          @click="setCopyField('stall_length_double_row')"
          src="@/assets/img/arrow-stall-length-green.png"
        />
        <img
          v-else
          :class="[
            'stallLength',
            'pointer',
            { greyout: (copyField && 'stall_length_double_row' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.stall_alignment === 'two'"
          @click="setCopyField('stall_length_double_row')"
          src="@/assets/img/arrow-stall-length-red.png"
        />

        <!-- The Lower Divider Height arrow image changes based on the selected divider -->
        <img
          v-if="
            converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
              converted_lower_divider_height > freestall.getMaxLowerDividerHeight()
          "
          :class="[
            'lowerDividerHeight',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'multiple_blend' || assessor.data.divider_type === 'sturdy_built'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height1-red.png"
        />
        <img
          v-else
          :class="[
            'lowerDividerHeight',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'multiple_blend' || assessor.data.divider_type === 'sturdy_built'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height1-green.png"
        />

        <img
          v-if="
            converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
              converted_lower_divider_height > freestall.getMaxLowerDividerHeight()
          "
          :class="[
            'lowerDividerHeight',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'square' || assessor.data.divider_type === 'standard'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height2-red.png"
        />
        <img
          v-else
          :class="[
            'lowerDividerHeight',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'square' || assessor.data.divider_type === 'standard'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height2-green.png"
        />

        <img
          v-if="
            converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
              converted_lower_divider_height > freestall.getMaxLowerDividerHeight()
          "
          :class="[
            'lowerDividerHeight',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'cantilever'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height3-red.png"
        />
        <img
          v-else
          :class="[
            'lowerDividerHeight',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'cantilever'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height3-green.png"
        />

        <img
          v-if="
            converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
              converted_lower_divider_height > freestall.getMaxLowerDividerHeight()
          "
          :class="[
            'lowerDividerHeight2',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'michigan'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height4-red.png"
        />
        <img
          v-else
          :class="[
            'lowerDividerHeight2',
            'pointer',
            { greyout: (copyField && 'lower_divider_height' != copyField) || copyField === null },
          ]"
          v-show="assessor.data.divider_type === 'michigan'"
          @click="setCopyField('lower_divider_height')"
          src="@/assets/img/arrow-lower-div-height4-green.png"
        />

        <!--loop arrow image changes based on the selected divider type -->
        <img
          v-if="converted_inside_loop >= freestall.getIdealInsideLoopDividerDiameter()"
          :class="[
            'pointer',
            { insideLoopDividerDiameter: assessor.data.divider_type === 'square' },
            {
              insideLoopDividerDiameter2:
                assessor.data.divider_type === 'multiple_blend' ||
                assessor.data.divider_type === 'sturdy_built' ||
                assessor.data.divider_type === 'cantilever',
            },
            { insideLoopDividerDiameter3: assessor.data.divider_type === 'standard' },
            { greyout: (copyField && 'inside_loop_divider_diameter' != copyField) || copyField === null },
          ]"
          v-show="
            assessor.data.divider_type === 'square' ||
              assessor.data.divider_type === 'standard' ||
              assessor.data.divider_type === 'multiple_blend' ||
              assessor.data.divider_type === 'sturdy_built' ||
              assessor.data.divider_type === 'cantilever'
          "
          @click="setCopyField('inside_loop_divider_diameter')"
          src="@/assets/img/arrow-loop-div-dia1-green.png"
        />
        <img
          v-else
          :class="[
            'pointer',
            { insideLoopDividerDiameter: assessor.data.divider_type === 'square' },
            {
              insideLoopDividerDiameter2:
                assessor.data.divider_type === 'multiple_blend' ||
                assessor.data.divider_type === 'sturdy_built' ||
                assessor.data.divider_type === 'cantilever',
            },
            { insideLoopDividerDiameter3: assessor.data.divider_type === 'standard' },
            { greyout: (copyField && 'inside_loop_divider_diameter' != copyField) || copyField === null },
          ]"
          v-show="
            assessor.data.divider_type === 'square' ||
              assessor.data.divider_type === 'standard' ||
              assessor.data.divider_type === 'multiple_blend' ||
              assessor.data.divider_type === 'sturdy_built' ||
              assessor.data.divider_type === 'cantilever'
          "
          @click="setCopyField('inside_loop_divider_diameter')"
          src="@/assets/img/arrow-loop-div-dia1-red.png"
        />
      </div>

      <div class="col-24 col-lg-11 offset-lg-1 d-none d-lg-block">
        <div v-if="copyField !== null">
          <h2 class="h2 mb-3">{{ focusTitle | translate }}</h2>
          <p :class="['font-14', feedbackMood]">{{ feedbackTitle | translate }}</p>
          <p v-if="feedbackDescription !== undefined" class="font-14">{{ feedbackDescription | translate }}</p>
        </div>
      </div>
    </div>

    <div class="row mx-0 mb-3">
      <div class="col-24 pl-0">
        <h2 class="h2 mb-4">
          {{ 'Enter Stall Measurements' | translate }}
          <span>({{ $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) }})</span>
        </h2>
      </div>

      <div class="col-lg-12">
        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label
              :class="[
                'mb-0 mr-1',
                {
                  'text-danger':
                    freestall.getWidth(0) < freestall.getMinWidth() || freestall.getWidth(0) > freestall.getMaxWidth(),
                },
              ]"
            >
              {{ 'Stall Width' | translate }}
            </label>
            <modal-info :value="'stall-width'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Stall Width' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/stallWidth.png" alt="Stall Width" />
                <p class="px-3 mb-2">
                  {{
                    'The stall width should be measured from the center of the one loop divider to the center of the neighboring loop divider (center to center).'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="freestall.getWidth() < freestall.getMinWidth() || freestall.getWidth(0) > freestall.getMaxWidth()"
              :value="'stall-width-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Stall Width' | translate }}
                </h2>
                <p v-if="freestall.getWidth() < freestall.getMinWidth()" class="px-3 mb-2 text-danger">
                  {{ 'Too Narrow' | translate }}
                </p>
                <p v-else class="px-3 mb-2 text-danger">{{ 'Too Wide' | translate }}</p>

                <p v-if="freestall.getWidth() < freestall.getMinWidth()" class="px-3 mb-2">
                  {{
                    ($t('OUT OF RANGE! Stall width should be between ') +
                      freestall.getMinWidth() +
                      $t(' and ') +
                      freestall.getMaxWidth() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t(
                        '. Stalls that are too narrow will reduce lying time and increase disturbances between neighboring cows.'
                      ))
                      | translate
                  }}
                </p>
                <p v-if="freestall.getWidth() > freestall.getMaxWidth()" class="px-3 mb-2">
                  {{
                    ($t('OUT OF RANGE! Stall width should be between ') +
                      freestall.getMinWidth() +
                      $t(' and ') +
                      freestall.getMaxWidth() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t('. Stalls that are too wide may allow cows to lie diagonally and soil the stall.'))
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
            <label class="mb-0 pull-right">{{ 'Ideal' | translate }}</label>
          </div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('stall_width')"
              :class="[
                'slider',
                {
                  'slider--red':
                    freestall.getWidth(0) < freestall.getMinWidth() || freestall.getWidth(0) > freestall.getMaxWidth(),
                },
              ]"
              type="range"
              v-model.number="converted_stall_width"
              min="0"
              :max="freestall.getIdealWidth() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('stall_width')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_stall_width"
              :class="[
                'form-control form-control--freestall  mb-0',
                {
                  'is-invalid':
                    freestall.getWidth(0) < freestall.getMinWidth() || freestall.getWidth(0) > freestall.getMaxWidth(),
                },
                ''
              ]"
              :style="{paddingRight: '5px !important'}"
            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealWidth() }}
          </div>
        </div>

        <div v-if="assessor.data.stall_alignment === 'two' || assessor.data.stall_alignment === 'three'" class="row">
          <div class="col-22 pl-0">
            <label
              :class="['mb-0 mr-1', { 'text-danger': converted_stall_length < freestall.getMinDoubleRowStallLength() }]"
            >
              {{ 'Stall Length (Double Row)' | translate }}
            </label>
            <modal-info :value="'stall-length'" class="d-inline mr-1">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Stall Length (Double Row)' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/stallLength.jpg" alt="Stall Length" />
                <p class="px-3 mb-2">
                  {{
                    'The stall length is measured from the center divider to the alley in a two row barn.' | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="converted_stall_length < freestall.getMinDoubleRowStallLength()"
              :value="'stall-length-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Stall Length (Double Row)' | translate }}
                </h2>
                <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                <p class="px-3 mb-2">
                  {{
                    $t('OUT OF RANGE! Double row stall length should be at least ') +
                      freestall.getMinDoubleRowStallLength() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t('. Inadequate stall length prevents front lunge and forces cows to side lunge.')
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('stall_length_double_row')"
              :class="['slider', { 'slider--red': converted_stall_length < freestall.getMinDoubleRowStallLength() }]"
              type="range"
              v-model.number="converted_stall_length"
              min="0"
              :max="freestall.getIdealDoubleRowStallLength() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('stall_length_double_row')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_stall_length"
              :class="[
                'form-control form-control--freestall mb-0',
                { 'is-invalid': converted_stall_length < freestall.getMinDoubleRowStallLength() },
              ] "
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealDoubleRowStallLength() }}
          </div>
        </div>

        <div
          v-show="
            assessor.data.bedding_type === 'sand' ||
              assessor.data.bedding_type === 'manure_solids' ||
              assessor.data.bedding_type === 'straw' ||
              assessor.data.bedding_type === 'other'
          "
          class="row"
        >
          <div class="col-22 pl-0">
            <label :class="['mb-0 mr-1', { 'text-danger': converted_rear_curb > freestall.getIdealRearCurbWidth() }]">
              {{ 'Rear Curb Width' | translate }}
            </label>
            <modal-info :value="'rear-curb'" class="d-inline mr-1">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Rear Curb Width' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/curbWidth.jpg" alt="rear curb width" />
                <p class="px-3 mb-2">
                  {{
                    'The curb width is measured from the end of the bedding surface to the start of the alley.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="converted_rear_curb > freestall.getMaxRearCurbWidth()"
              :value="'rear-curb-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Rear Curb Width' | translate }}
                </h2>
                <p class="px-3 mb-2 text-danger">{{ 'Too Wide' | translate }}</p>
                <p class="px-3 mb-2">
                  {{
                    $t('OUT OF RANGE! Curb width should be less than ') +
                      freestall.getMaxRearCurbWidth() +
                      ' ' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t('. Wide curbs limit the standing and lying area of the stall.')
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('rear_curb')"
              :class="['slider', { 'slider--red': converted_rear_curb > freestall.getMaxRearCurbWidth() }]"
              type="range"
              v-model.number="converted_rear_curb"
              min="0"
              :max="freestall.getIdealRearCurbWidth() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('rear_curb')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_rear_curb"
              :class="[
                'form-control form-control--freestall mb-0',
                { 'is-invalid': converted_rear_curb > freestall.getMaxRearCurbWidth() },
              ] "
              :style="{paddingRight: '5px !important'}"
            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealRearCurbWidth() }}
          </div>
        </div>

        <div class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                {
                  'text-danger':
                    converted_rear_curb_height < freestall.getMinRearCurbHeight() ||
                    converted_rear_curb_height > freestall.getMaxRearCurbHeight(),
                },
              ]"
            >
              {{ 'Rear Curb Height' | translate }}
            </label>
            <modal-info :value="'rear-curb-height'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Rear Curb Height' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/curbHeight.jpg" alt="rear curb height" />
                <p class="px-3 mb-2">
                  {{ 'The curb height is measured from the top of the curb to the alley floor.' | translate }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="
                converted_rear_curb_height < freestall.getMinRearCurbHeight() ||
                  converted_rear_curb_height > freestall.getMaxRearCurbHeight()
              "
              :value="'rear-curb-height-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Rear Curb Height' | translate }}
                </h2>
                <div v-if="converted_rear_curb_height < freestall.getMinRearCurbHeight()">
                  <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      $t('OUT OF RANGE! The rear curb height should be at least ') +
                        freestall.getMinRearCurbHeight() +
                        $t(' ') +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t('. Low curbs may lead to soiling in the stall during scraping and flushing.')
                    }}
                  </p>
                </div>
                <div v-else-if="converted_rear_curb_height > freestall.getMaxRearCurbHeight()">
                  <p class="px-3 mb-2 text-danger">{{ 'Too Tall' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      $t('OUT OF RANGE! The rear curb should be no taller than ') +
                        freestall.getMaxRearCurbHeight() +
                        $t(' ') +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t('. High curbs make it difficult for lame cows to enter and leave the stall.')
                    }}
                  </p>
                </div>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('rear_curb_height')"
              :class="[
                'slider',
                {
                  'slider--red':
                    converted_rear_curb_height < freestall.getMinRearCurbHeight() ||
                    converted_rear_curb_height > freestall.getMaxRearCurbHeight(),
                },
              ]"
              type="range"
              v-model.number="converted_rear_curb_height"
              min="0"
              :max="freestall.getIdealRearCurbHeight() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('rear_curb_height')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_rear_curb_height"
              :class="[
                'form-control form-control--freestall mb-0',
                {
                  'is-invalid' :
                    converted_rear_curb_height < freestall.getMinRearCurbHeight() ||
                    converted_rear_curb_height > freestall.getMaxRearCurbHeight(),
                },
              ]"
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealRearCurbHeight() }}
          </div>
        </div>

        <div class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                {
                  'text-danger':
                    converted_neckrail_height < freestall.getMinNeckrailHeight() ||
                    converted_neckrail_height > freestall.getMaxNeckrailHeight(),
                },
              ]"
            >
              {{ 'Neckrail Height' | translate }}
            </label>
            <modal-info :value="'neckrail'" class="d-inline mr-1">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Neckrail Height' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/neckrailHeight.jpg" alt="Neckrail Height" />
                <p class="px-3 mb-2">
                  {{ 'Neckrail height is measured from the bottom of the neckrail to the stall surface.' | translate }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="
                converted_neckrail_height < freestall.getMinNeckrailHeight() ||
                  converted_neckrail_height > freestall.getMaxNeckrailHeight()
              "
              :value="'neckrail-height-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Neckrail Height' | translate }}
                </h2>
                <div v-if="converted_neckrail_height < freestall.getMinNeckrailHeight()">
                  <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      ($t('OUT OF RANGE! Neckrail height should be between ') +
                        freestall.getMinNeckrailHeight() +
                        $t(' and ') +
                        freestall.getMaxNeckrailHeight() +
                        $t(' ') +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t(
                          '. A low neckrail may increase time spent standing in the stall and make it more difficult for animals to rise.'
                        ))
                        | translate
                    }}
                  </p>
                </div>
                <div v-else>
                  <p class="px-3 mb-2 text-danger">{{ 'Too High' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      ($t('OUT OF RANGE! Neckrail height should be between ') +
                        freestall.getMinNeckrailHeight() +
                        $t(' and ') +
                        freestall.getMaxNeckrailHeight() +
                        $t(' ') +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t('. A neckrail that is too high may be ineffective at animal placement.'))
                        | translate
                    }}
                  </p>
                </div>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('neckrail_height')"
              :class="[
                'slider',
                {
                  'slider--red':
                    converted_neckrail_height < freestall.getMinNeckrailHeight() ||
                    converted_neckrail_height > freestall.getMaxNeckrailHeight(),
                },
              ]"
              type="range"
              v-model.number="converted_neckrail_height"
              min="0"
              :max="freestall.getIdealNeckrailHeight() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('neckrail_height')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_neckrail_height"
              :class="[
                'form-control form-control--freestall mb-0',
                {
                  'is-invalid' :
                    converted_neckrail_height < freestall.getMinNeckrailHeight() ||
                    converted_neckrail_height > freestall.getMaxNeckrailHeight(),
                },
              ]"
              :style="{paddingRight: '5px !important'}"
            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealNeckrailHeight() }}
          </div>
        </div>

        <div v-show="assessor.data.divider_type !== 'michigan'" class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                { 'text-danger': converted_inside_loop < freestall.getIdealInsideLoopDividerDiameter() },
              ]"
            >
              {{ 'Inside Loop Divider Diameter' | translate }}
            </label>
            <modal-info :value="'inside-loop'" class="d-inline mr-1">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Inside Loop Divider Diameter' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/loopDiameter.jpg" alt="Loop Diameter" />
                <p class="px-3 mb-2">
                  {{
                    'The inside loop divider diameter is the distance from the top of the lower divider rail to the bottom of the top divider rail, at the brisket locator.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="converted_inside_loop < freestall.getIdealInsideLoopDividerDiameter()"
              :value="'inside-loop-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Inside Loop Divider Diameter' | translate }}
                </h2>
                <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                <p class="px-3 mb-2">
                  {{
                    ($t('OUT OF RANGE! The diameter should be at least ') +
                      freestall.getMinInsideLoopDividerDiameter() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t(' to allow for the animal to side lunge.'))
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('inside_loop_divider_diameter')"
              :class="[
                'slider',
                { 'slider--red': converted_inside_loop < freestall.getIdealInsideLoopDividerDiameter() },
              ]"
              type="range"
              v-model.number="converted_inside_loop"
              min="0"
              :max="freestall.getIdealInsideLoopDividerDiameter() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('inside_loop_divider_diameter')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_inside_loop"
              :class="[
                'form-control form-control--freestall mb-0',
                { 'is-invalid': converted_inside_loop < freestall.getIdealInsideLoopDividerDiameter() },
              ] "
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealInsideLoopDividerDiameter() }}
          </div>
        </div>

        <div class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                {
                  'text-danger':
                    converted_neckrail_rear_curb < freestall.getMinNeckrailToRearCurbDistance() ||
                    converted_neckrail_rear_curb > freestall.getMaxNeckrailToRearCurbDistance(),
                },
              ]"
            >
              {{ 'Neckrail to Rear Curb Distance' | translate }}
            </label>
            <modal-info :value="'neckrail-rear-curb'" class="d-inline mr-1">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Neckrail to Rear Curb Distance' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/neckrailToCurb.jpg" alt="Neckrail To Curb" />
                <p class="px-3 mb-2">
                  {{
                    'Neckrail to curb distance is the distance from the neckrail to the outside edge of the curb.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="
                converted_neckrail_rear_curb < freestall.getMinNeckrailToRearCurbDistance() ||
                  converted_neckrail_rear_curb > freestall.getMaxNeckrailToRearCurbDistance()
              "
              :value="'neckrail-rear-curb-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Neckrail to Rear Curb Distance' | translate }}
                </h2>
                <div v-if="converted_neckrail_rear_curb > freestall.getMaxNeckrailToRearCurbDistance()">
                  <p class="px-3 mb-2 text-danger">{{ 'Too Long' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      $t('OUT OF RANGE! The neckrail to rear curb distance should be between ') +
                        freestall.getMinNeckrailToRearCurbDistance() +
                        $t(' and ') +
                        freestall.getMaxNeckrailToRearCurbDistance() +
                        $t(' ') +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t('. Neckrails too far forward increase soiling of the stall surface.')
                    }}
                  </p>
                </div>
                <div v-else>
                  <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      $t('OUT OF RANGE! The neckrail to rear curb distance should be between ') +
                        freestall.getMinNeckrailToRearCurbDistance() +
                        $t(' and ') +
                        freestall.getMaxNeckrailToRearCurbDistance() +
                        $t(' ') +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t('. Cattle should not hit the neckrail when rising and may have a tendency to perch.')
                    }}
                  </p>
                </div>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('neckrail_to_rear_curb_distance')"
              :class="[
                'slider',
                {
                  'slider--red':
                    converted_neckrail_rear_curb < freestall.getMinNeckrailToRearCurbDistance() ||
                    converted_neckrail_rear_curb > freestall.getMaxNeckrailToRearCurbDistance(),
                },
              ]"
              type="range"
              v-model.number="converted_neckrail_rear_curb"
              min="0"
              :max="freestall.getIdealNeckrailToRearCurbDistance() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('neckrail_to_rear_curb_distance')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_neckrail_rear_curb"
              :class="[
                'form-control form-control--freestall mb-0',
                {
                  'is-invalid' :
                    converted_neckrail_rear_curb < freestall.getMinNeckrailToRearCurbDistance() ||
                    converted_neckrail_rear_curb > freestall.getMaxNeckrailToRearCurbDistance(),
                },
              ]"
              :style="{paddingRight: '5px !important'}"
            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealNeckrailToRearCurbDistance() }}
          </div>
        </div>
      </div>

      <div class="offset-lg-1 col-lg-11">
        <div v-show="assessor.data.stall_alignment !== 'two'" class="row">
          <div class="col-24 pl-0 pr-1">
            <label
              :class="[
                'mb-0 mr-1',
                { 'text-danger': converted_stall_length_single_row < freestall.getMinSingleRowStallLength() },
              ]"
            >
              {{ 'Stall Length (Single Row)' | translate }}
            </label>
            <modal-info :value="'stall-length-single'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Stall Length (Single Row)' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/stallLength.jpg" alt="Stall Length Single Row" />
                <p class="px-3 mb-2">
                  {{ 'The stall length is measured from the wall to the alley in a one row barn.' | translate }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="converted_stall_length_single_row < freestall.getMinSingleRowStallLength()"
              :value="'stall-length-single-row-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Stall Length (Single Row)' | translate }}
                </h2>
                <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                <p class="px-3 mb-2">
                  {{
                    $t('OUT OF RANGE! Single row stall length should be at least ') +
                      freestall.getMinSingleRowStallLength() +
                      ' ' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t('. Inadequate stall length prevents front lunge and forces cows to side lunge.')
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
            <label class="mb-0 d-none d-lg-block pull-right">{{ 'Ideal' | translate }}</label>
          </div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('stall_length_single_row')"
              :class="[
                'slider',
                { 'slider--red': converted_stall_length_single_row < freestall.getMinSingleRowStallLength() },
              ]"
              type="range"
              v-model.number="converted_stall_length_single_row"
              min="0"
              :max="freestall.getIdealSingleRowStallLength() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('stall_length_single_row')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_stall_length_single_row"
              :class="[
                'form-control form-control--freestall mb-0',
                { 'is-invalid': converted_stall_length_single_row < freestall.getMinSingleRowStallLength() },
              ] "
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealSingleRowStallLength() }}
          </div>
        </div>

        <div class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                {
                  'text-danger':
                    converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
                    converted_lower_divider_height > freestall.getMaxLowerDividerHeight(),
                },
              ]"
            >
              {{ 'Lower Divider Height' | translate }}
            </label>
            <modal-info :value="'lower-divider-height'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Lower Divider Height' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/lowerDividerRailHeight.jpg" alt="Lower Divider Height" />
                <p class="px-3 mb-2">
                  {{
                    'Lower divider rail height is measured from the stall surface to the top of the lower divider rail, at the brisket locator.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="
                converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
                  converted_lower_divider_height > freestall.getMaxLowerDividerHeight()
              "
              :value="'lower-divider-height-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Lower Divider Height' | translate }}
                </h2>
                <div v-if="converted_lower_divider_height < freestall.getMinLowerDividerHeight()">
                  <p class="px-3 mb-2 text-danger">{{ 'Too Short' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{ 'OUT OF RANGE! Lower divider rail increases risk of leg entrapment.' | translate }}
                  </p>
                </div>
                <div v-else>
                  <p class="px-3 mb-2 text-danger">{{ 'Too Tall' | translate }}</p>
                  <p class="px-3 mb-2">
                    {{
                      $t('OUT OF RANGE! Lower divider rails over ') +
                        freestall.getMaxLowerDividerHeight() +
                        $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                        $t(' inhibit side lunging.')
                    }}
                  </p>
                </div>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('lower_divider_height')"
              :class="[
                'slider',
                {
                  'slider--red':
                    converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
                    converted_lower_divider_height > freestall.getMaxLowerDividerHeight(),
                },
              ]"
              type="range"
              v-model.number="converted_lower_divider_height"
              min="0"
              :max="freestall.getIdealLowerDividerHeight() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('lower_divider_height')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_lower_divider_height"
              :class="[
                'form-control form-control--freestall mb-0',
                {
                  'is-invalid' :
                    converted_lower_divider_height < freestall.getMinLowerDividerHeight() ||
                    converted_lower_divider_height > freestall.getMaxLowerDividerHeight(),
                },
              ]"
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealLowerDividerHeight() }}
          </div>
        </div>

        <div class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                { 'text-danger': converted_brisket_locator_height > freestall.getIdealBrisketLocatorHeight() },
              ]"
            >
              {{ 'Brisket Locator Height' | translate }}
            </label>
            <modal-info :value="'brisket-locator-height'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Brisket Locator Height' | translate }}
                </h2>
                <img class="w-100 px-3 mb-3" src="@/assets/img/brisketHeight.jpg" alt="Brisket Locator Height" />
                <p class="px-3 mb-2">
                  {{
                    'Brisket locator height is the distance from the stall surface to the top of the brisket locator.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="converted_brisket_locator_height > freestall.getIdealBrisketLocatorHeight()"
              :value="'brisket-locator-height-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Brisket Locator Height' | translate }}
                </h2>
                <p class="px-3 mb-2 text-danger">{{ 'Too High' | translate }}</p>
                <p class="px-3 mb-2">
                  {{
                    $t('OUT OF RANGE! Brisket locators that are taller than ') +
                      freestall.getMaxBrisketLocatorHeight() +
                      ' ' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t(
                        ' can prevent forward thrust of the forelimb when the cow rises, leading to reduced stall use and more diagonal lying.'
                      )
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('brisket_locator_height')"
              :class="[
                'slider',
                { 'slider--red': converted_brisket_locator_height > freestall.getIdealBrisketLocatorHeight() },
              ]"
              type="range"
              v-model.number="converted_brisket_locator_height"
              min="0"
              :max="freestall.getIdealBrisketLocatorHeight() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('brisket_locator_height')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_brisket_locator_height"
              :class="[
                'form-control form-control--freestall mb-0',
                { 'is-invalid': converted_brisket_locator_height > freestall.getIdealBrisketLocatorHeight() },
              ] "
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealBrisketLocatorHeight() }}
          </div>
        </div>

        <div class="row">
          <div class="col-22 pl-0">
            <label
              :class="[
                'mb-0 mr-1',
                {
                  'text-danger':
                    converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance() ||
                    converted_curb_to_brisket_locator_distance > freestall.getMaxCurbToBrisketLocatorDistance(),
                },
              ]"
            >
              {{ 'Curb to Brisket Locator Distance' | translate }}
            </label>
            <modal-info :value="'curb-to-brisket'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Curb to Brisket Locator Distance' | translate }}
                </h2>
                <img
                  class="w-100 px-3 mb-3"
                  src="@/assets/img/curbToBrisket.jpg"
                  alt="Curb to Brisket Locator Distance"
                />
                <p class="px-3 mb-2">
                  {{
                    'The curb to brisket locator distance is measured from the edge of the brisket locator to the far edge of the curb.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
            <modal-warning
              v-if="
                converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance() ||
                  converted_curb_to_brisket_locator_distance > freestall.getMaxCurbToBrisketLocatorDistance()
              "
              :value="'curb-to-brisket-warning'"
              class="d-inline"
            >
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Curb to Brisket Locator Distance' | translate }}
                </h2>
                <p
                  v-if="converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance()"
                  class="px-3 mb-2 text-danger"
                >
                  {{ 'Too Short' | translate }}
                </p>
                <p v-else class="px-3 mb-2 text-danger">{{ 'Too Long' | translate }}</p>

                <p
                  v-if="converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance()"
                  class="px-3 mb-2"
                >
                  {{
                    $t('OUT OF RANGE! The curb to brisket distance should be between ') +
                      freestall.getMinCurbToBrisketLocatorDistance() +
                      $t(' and ') +
                      freestall.getMaxCurbToBrisketLocatorDistance() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t(
                        '. Stalls with a resting length that is too short will force the cow to lie too far back in the stall.'
                      )
                  }}
                </p>
                <p
                  v-if="converted_curb_to_brisket_locator_distance > freestall.getMaxCurbToBrisketLocatorDistance()"
                  class="px-3 mb-2"
                >
                  {{
                    $t('OUT OF RANGE! The curb to brisket distance should be between ') +
                      freestall.getMinCurbToBrisketLocatorDistance() +
                      $t(' and ') +
                      freestall.getMaxCurbToBrisketLocatorDistance() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t('. Stalls with too long of a resting space may become soiled.')
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-warning>
          </div>
          <div class="col-2 d-none d-lg-block ideal"></div>
          <div class="col-15 col-md-17 col-lg-18 col-xl-19 pl-0 stall d-flex align-items-center">
            <input
              @click="setCopyField('curb_to_brisket_locator_distance')"
              :class="[
                'slider',
                {
                  'slider--red':
                    converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance() ||
                    converted_curb_to_brisket_locator_distance > freestall.getMaxCurbToBrisketLocatorDistance(),
                },
              ]"
              type="range"
              v-model.number="converted_curb_to_brisket_locator_distance"
              min="0"
              :max="freestall.getIdealCurbToBrisketLocatorDistance() * 2"
            />
          </div>
          <div class="col-6 col-md-4 col-xl-3">
            <input
              @click="setCopyField('curb_to_brisket_locator_distance')"
              type="number"
              inputmode="decimal"
              v-model.number="converted_curb_to_brisket_locator_distance"
              :class="[
                'form-control form-control--freestall mb-0',
                {
                  'is-invalid' :
                    converted_curb_to_brisket_locator_distance < freestall.getMinCurbToBrisketLocatorDistance() ||
                    converted_curb_to_brisket_locator_distance > freestall.getMaxCurbToBrisketLocatorDistance(),
                },
              ]"
              :style="{paddingRight: '5px !important'}"

            />
          </div>
          <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center ideal">
            {{ freestall.getIdealCurbToBrisketLocatorDistance() }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mx-0 d-none d-lg-flex justify-content-end pr-1">
      <label class="mb-0 pull-right">{{ 'Ideal' | translate }}</label>
    </div>

    <hr class="d-lg-none mt-4" />

    <div class="row mx-md-0">
      <div class="col-19 col-md-22 col-lg-23 pl-md-0">
        <hr class="d-none d-lg-block mt-0" />

        <question
          @change="
            assessor.data.bob_zone = $event.value;
            setCopyField('bob_zone');
          "
          :initial-value="assessor.data.bob_zone"
        >
          <div class="mb-1 mb-md-0">
            <label :class="['label d-inline', { 'text-danger': assessor.data.bob_zone === 1 }]">
              {{ 'Is there a bob-zone obstruction?' | translate }}
            </label>
            <modal-info :value="'bob-zone'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Is there a bob-zone obstruction?' | translate }}
                </h2>
                <div class="wistia_responsive_padding mb-3" style="padding:55.0% 0 0 0;position:relative;">
                  <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                    <div
                      class="wistia_embed wistia_async_5p17e3oy0k"
                      seo="false"
                      videoFoam="true"
                      style="height:100%;position:relative;width:100%"
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
                <p v-if="evaluation.data.measurements === 'metric'" class="px-3 mb-2">
                  {{
                    'Cows need about 1 meter of space beyond their lying area to lunge into as they stand up and lie down. This lunging space is called the bob-zone. If there is an obstruction to where the cow is lunging, the cow will have greater difficulty rising and lying down. A bob-zone obstruction is anything within 1 meter in front of the lying area and between 10 to 100 cm above the stall surface.'
                      | translate
                  }}
                </p>
                <p v-else class="px-3 mb-2">
                  {{
                    'Cows need about 3 feet of space beyond their lying area to lunge into as they stand up and lie down. This lunging space is called the bob-zone. If there is an obstruction to where the cow is lunging, the cow will have greater difficulty rising and lying down. A bob-zone obstruction is anything within 3 feet in front of the lying area and between 4 to 40 inches above the stall surface.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
          </div>
        </question>
      </div>

      <div
        class="col-5 col-md-2 col-lg-1 pt-3 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center"
      >
        <span class="d-none d-lg-block">{{ 'No' | translate }}</span>
        <div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
          <span>{{ ' No' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="row mx-md-0">
      <div class="col-24 col-lg-23 pl-md-0 d-none d-lg-block">
        <hr />
      </div>
      <div class="col-1 ideal d-none d-lg-block"></div>
    </div>

    <hr class="d-block d-lg-none" />

    <div class="row mx-md-0">
      <div class="col-19 col-md-22 col-lg-23 pl-md-0">
        <question
          @change="
            assessor.data.concrete_fill = $event.value;
            setCopyField('concrete_fill');
          "
          :initial-value="assessor.data.concrete_fill"
        >
          <div class="mb-1 mb-md-0">
            <label :class="['label d-inline', { 'text-danger': assessor.data.concrete_fill === 1 }]">
              {{ 'Is there a concrete fill behind brisket locator?' | translate }}
            </label>
            <modal-info :value="'concrete-fill'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Is there a concrete fill behind brisket locator?' | translate }}
                </h2>
                <div class="wistia_responsive_padding mb-3" style="padding:55.0% 0 0 0;position:relative;">
                  <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                    <div
                      class="wistia_embed wistia_async_3hvl0mj01x"
                      seo="false"
                      videoFoam="true"
                      style="height:100%;position:relative;width:100%"
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
                <p class="px-3 mb-2">
                  {{
                    $t(
                      'Cows need room to step forward in order to stand up properly. If there is a concrete fill behind the brisket locator that is more than '
                    ) +
                      freestall.getIdealConcreteFill() +
                      $t(' ') +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      $t(
                        ' above the stall surface, the cows are unable to step forward and will have a hard time standing up.'
                      )
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
          </div>
        </question>
      </div>

      <div
        class="col-5 col-md-2 col-lg-1 pt-1 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center"
      >
        <span class="d-none d-lg-block">{{ 'No' | translate }}</span>
        <div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
          <span>{{ 'No' | translate }}</span>
        </div>
      </div>
    </div>

    <hr class="d-lg-none" />

    <div class="row mx-0">
      <div class="col-24 col-lg-23 d-none d-lg-block pl-lg-0">
        <hr />
      </div>
      <div class="col-1 d-none d-lg-block ideal"></div>
    </div>

    <div class="row mx-md-0">
      <div class="col-19 col-md-22 col-lg-23 pl-md-0">
        <question
          @change="
            assessor.data.brisket_locator_properly_locate_cows = $event.value;
            setCopyField('brisket_locator_properly_locate_cows');
          "
          :initial-value="assessor.data.brisket_locator_properly_locate_cows"
        >
          <div class="mb-1 mb-md-0">
            <label
              :class="['label d-inline', { 'text-danger': assessor.data.brisket_locator_properly_locate_cows === 0 }]"
            >
              {{ 'Does the brisket locator properly locate the cows?' | translate }}
            </label>
            <modal-info :value="'brisket-locator-properly'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Does the brisket locator properly locate the cows?' | translate }}
                </h2>
                <div class="row px-3 mb-3">
                  <div class="col-md-12 mb-3 mb-lg-0">
                    <img src="@/assets/img/example-brisket-correct.jpg" alt="Correct brisket Example" class="w-100" />
                  </div>
                  <div class="col-md-12">
                    <img
                      src="@/assets/img/example-brisket-incorrect.jpg"
                      alt="Incorrect brisket Example"
                      class="w-100"
                    />
                  </div>
                </div>
                <p class="px-3 mb-2">
                  {{
                    'Brisket locators that improperly locate the cow will be ones where the cows lie on top of the brisket locator.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
          </div>
        </question>
      </div>

      <div
        class="col-5 col-md-2 col-lg-1 pt-1 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center"
      >
        <span class="d-none d-lg-block">{{ 'Yes' | translate }}</span>
        <div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
          <span>{{ 'Yes' | translate }}</span>
        </div>
      </div>
    </div>

    <hr class="d-block d-lg-none" />

    <div class="row mx-md-0">
      <div class="col-19 col-md-22 col-lg-23 pl-md-0">
        <hr class="d-none d-lg-block" />
        <question
          @change="
            assessor.data.knee_drop_test = $event.value;
            setCopyField('knee_drop_test');
          "
          :initial-value="assessor.data.knee_drop_test"
        >
          <div class="mb-1 mb-md-0">
            <label :class="['label d-inline', { 'text-danger': assessor.data.knee_drop_test === 0 }]">
              {{ 'Does the freestall pass the knee-drop test?' | translate }}
            </label>
            <modal-info :value="'knee-drop'" class="d-inline">
              <template slot="modal-content">
                <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                  {{ 'Does the freestall pass the knee-drop test?' | translate }}
                </h2>
                <div class="row px-3 mb-3">
                  <div class="col-md-12 mb-3 mb-lg-0">
                    <img src="@/assets/img/example-kneel-down-1.jpg" alt="Example Kneel Down 1" class="w-100" />
                  </div>
                  <div class="col-md-12">
                    <img src="@/assets/img/example-kneel-down-2.jpg" alt="Example Kneel Down 2" class="w-100" />
                  </div>
                </div>
                <p class="px-3 mb-2">
                  {{
                    'Crouch slightly and then drop to your knees in the stall. If you can do this comfortably and without feeling pain, then the stall has adequate bedding and passes the knee-drop test.'
                      | translate
                  }}
                </p>
                <div class="row modal-bottom pb-3">
                  <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                    'Cancel' | translate
                  }}</a>
                </div>
              </template>
            </modal-info>
          </div>
        </question>

        <hr class="mb-0 d-none d-lg-block" />
      </div>

      <div
        class="col-5 col-md-2 col-lg-1 pt-3 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center"
      >
        <span class="d-none d-lg-block">{{ 'Yes' | translate }}</span>
        <div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
          <span>{{ 'Yes' | translate }}</span>
        </div>
      </div>
    </div>

    <hr class="d-block d-lg-none" />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes v-model="assessor.data.presentation_notes"></presentation-notes>
      </div>
    </div>

    <div class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4">
      <photoUpload :assessorId="2" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <a class="btn btn-secondary btn-full--sm mb-2 mt-md-0" href="javascript:history.go(-1)">{{
          'Back' | translate
        }}</a>
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Reports' | translate }}
        </router-link>
        <button
          v-else-if="user && user.role === 'data_entry' && !$store.state.offline"
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user._id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div v-show="successMessage" class="alert alert-primary mt-3 mb-0" role="alert">
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div v-show="errorMessage" class="alert alert-danger mt-3 mb-0" role="alert">
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
  <!-- end container -->
</template>

<script>
import _ from 'lodash';
import PresentationNotes from '@/pages/assessors/assessor_components/presentation-notes';
import PersonalNotes from '@/pages/assessors/assessor_components/personal-notes';
import Question from '@/pages/assessors/assessor_components/question';
import ModalInfo from '@/components/misc/modal-info';
import ModalWarning from '@/components/misc/modal-warning';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from '@/pages/assessors/assessor_components/hygiene-header';
import convert from 'convert-units';
import FreeStall from '@/libs/Classes/FreeStall.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import { mapState } from 'vuex';
import requestReportMixin from '@/mixins/requestReport.js';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    ModalWarning,
    CurrencyInput
  },
  mixins: [requestReportMixin],
  props: ['assessor', 'group', 'evaluation'],
  data() {
    return {
      copyField: null,
      freestall: null,
      focusTitle: null,
      feedbackMood: null,
      feedbackTitle: null,
      feedbackDescription: null,
      initializing: true,
    };
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new Group(this, evaluationClass, this.group);
    this.freestall = new FreeStall(this, evaluationClass, this.groupClass, this.assessor);
    // this.user = this.$store.getters.getData("user");

    setTimeout(() => {
      this.initializing = false;
    }, 500);

    if (this.freestall.getWidth() === undefined) {
      this.freestall.setWidth(this.freestall.getIdealWidth());
    }
    if (this.freestall.getSingleRowStallLength() === undefined) {
      this.freestall.setSingleRowStallLength(this.freestall.getIdealSingleRowStallLength());
    }
    if (this.freestall.getDoubleRowStallLength() === undefined) {
      this.freestall.setDoubleRowStallLength(this.freestall.getIdealDoubleRowStallLength());
    }
    if (this.freestall.getRearCurbWidth() === undefined) {
      this.freestall.setRearCurbWidth(this.freestall.getIdealRearCurbWidth());
    }
    if (this.freestall.getRearCurbHeight() === undefined) {
      this.freestall.setRearCurbHeight(this.freestall.getIdealRearCurbHeight());
    }
    if (this.freestall.getLowerDividerHeight() === undefined) {
      this.freestall.setLowerDividerHeight(this.freestall.getIdealLowerDividerHeight());
    }
    if (this.freestall.getNeckrailHeight() === undefined) {
      this.freestall.setNeckrailHeight(this.freestall.getIdealNeckrailHeight());
    }
    if (this.freestall.getBrisketLocatorHeight() === undefined) {
      this.freestall.setBrisketLocatorHeight(this.freestall.getIdealBrisketLocatorHeight());
    }
    if (this.freestall.getInsideLoopDividerDiameter() === undefined) {
      this.freestall.setInsideLoopDividerDiameter(this.freestall.getIdealInsideLoopDividerDiameter());
    }
    if (this.freestall.getCurbToBrisketLocatorDistance() === undefined) {
      this.freestall.setCurbToBrisketLocatorDistance(this.freestall.getIdealCurbToBrisketLocatorDistance());
    }
    if (this.freestall.getNeckrailToRearCurbDistance() === undefined) {
      this.freestall.setNeckrailToRearCurbDistance(this.freestall.getIdealNeckrailToRearCurbDistance());
    }
    if (this.freestall.getConcreteFill() === undefined) {
      this.freestall.setNeckrailToRearCurbDistance(this.freestall.getIdealNeckrailToRearCurbDistance());
    }
  },
  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      user: state => state.users.user,
    }),
    cowSizes() {
      const cow_sizes_in_kg = [430.9, 521.6, 612.4, 703, 793.7];
      let converted_cow_sizes = [];

      for (let i = 0; i < cow_sizes_in_kg.length; i++) {
        if (i !== 0) {
          let low_converted_value = parseInt(
            convert(cow_sizes_in_kg[i - 1])
              .from('kg')
              .to(this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg'))
              .toFixed(0)
          );

          let high_converted_value = parseInt(
            convert(cow_sizes_in_kg[i])
              .from('kg')
              .to(this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg'))
              .toFixed(0)
          );

          converted_cow_sizes.push({
            value: cow_sizes_in_kg[i - 1] + '-' + cow_sizes_in_kg[i],
            label: low_converted_value + '-' + high_converted_value,
          });
        }
      }

      // if odd number of cow sizes, then add the last item to the list
      let last_index = i - 1;
      if (last_index % 2 === 0) {
        let last_converted_value = parseInt(
          convert(cow_sizes_in_kg[last_index])
            .from('kg')
            .to(this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg'))
            .toFixed(0)
        );

        converted_cow_sizes.push({
          value: '>' + cow_sizes_in_kg[last_index],
          label: '>' + last_converted_value,
        });
      }

      return converted_cow_sizes;
    },
    converted_stall_width: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getWidth(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setWidth(newValue);
      },
    },
    converted_stall_length: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getLength(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setLength(newValue);
      },
    },
    converted_rear_curb: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getRearCurbWidth(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setRearCurbWidth(newValue);
      },
    },
    converted_rear_curb_height: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getRearCurbHeight(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setRearCurbHeight(newValue);
      },
    },
    converted_neckrail_height: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getNeckrailHeight(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setNeckrailHeight(newValue);
      },
    },
    converted_inside_loop: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getInsideLoopDividerDiameter(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setInsideLoopDividerDiameter(newValue);
      },
    },
    converted_curb_to_brisket_locator_distance: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getCurbToBrisketLocatorDistance(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setCurbToBrisketLocatorDistance(newValue);
      },
    },
    converted_brisket_locator_height: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getBrisketLocatorHeight(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setBrisketLocatorHeight(newValue);
      },
    },
    converted_lower_divider_height: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getLowerDividerHeight(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setLowerDividerHeight(newValue);
      },
    },
    converted_stall_length_single_row: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getSingleRowStallLength(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setSingleRowStallLength(newValue);
      },
    },
    converted_neckrail_rear_curb: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getNeckrailToRearCurbDistance(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setNeckrailToRearCurbDistance(newValue);
      },
    },
    converted_bedding_depth: {
      cache: false,
      get: function() {
        return this.freestall !== null ? this.freestall.getBeddingDepth(0) : false;
      },
      set: function(newValue) {
        return this.freestall.setBeddingDepth(newValue);
      },
    },
  },
  methods: {
    // requestReport(evaluationName) {
    //   this.$store.dispatch('requestReport', {
    //       currentVueInstance: this,
    //       evaluationName: evaluationName
    //   })
    // },
    setCopyField: function(inputName) {
      this.copyField = inputName;
      let copyFeedback = this.freestall.getResultMessage(inputName);

      if (copyFeedback === false) return false;

      this.focusTitle = copyFeedback.title;
      if (copyFeedback.feedback.mood !== undefined) {
        this.feedbackMood = copyFeedback.feedback.mood;
      } else {
        this.feedbackMood = undefined;
      }

      if (copyFeedback.feedback.title !== undefined) {
        this.feedbackTitle = copyFeedback.feedback.title;
      } else {
        this.feedbackTitle = undefined;
      }

      if (copyFeedback.feedback.description !== undefined) {
        this.feedbackDescription = copyFeedback.feedback.description;
      } else {
        this.feedbackDescription = undefined;
      }
    },
    handleDivider() {
      this.freestall.setLowerDividerHeight(this.freestall.getIdealLowerDividerHeight());
    },
  },
  watch: {
    'assessor.data.bedding_type': {
      handler(value) {
        let evaluationClass = new Evaluation(this, this.evaluation);
        this.freestall = new FreeStall(this, evaluationClass, this.groupClass, this.assessor);
      },
      deep: true,
    },
    converted_stall_width: function() {
      if (this.initializing === false) {
        this.setCopyField('stall_width');
      }
    },
    converted_stall_length: function() {
      if (this.initializing === false) {
        this.setCopyField('stall_length_double_row');
      }
    },
    converted_rear_curb: function() {
      if (this.initializing === false) {
        this.setCopyField('rear_curb');
      }
    },
    converted_rear_curb_height: function() {
      if (this.initializing === false) {
        this.setCopyField('rear_curb_height');
      }
    },
    converted_neckrail_height: function() {
      if (this.initializing === false) {
        this.setCopyField('neckrail_height');
      }
    },
    converted_inside_loop: function() {
      if (this.initializing === false) {
        this.setCopyField('inside_loop_divider_diameter');
      }
    },
    converted_curb_to_brisket_locator_distance: function() {
      if (this.initializing === false) {
        this.setCopyField('curb_to_brisket_locator_distance');
      }
    },
    converted_brisket_locator_height: function() {
      if (this.initializing === false) {
        this.setCopyField('brisket_locator_height');
      }
    },
    converted_lower_divider_height: function() {
      if (this.initializing === false) {
        this.setCopyField('lower_divider_height');
      }
    },
    converted_stall_length_single_row: function() {
      if (this.initializing === false) {
        this.setCopyField('stall_length_single_row');
      }
    },
    converted_neckrail_rear_curb: function() {
      if (this.initializing === false) {
        this.setCopyField('neckrail_to_rear_curb_distance');
      }
    },
    converted_bedding_depth: function() {
      if (this.initializing === false) {
        this.setCopyField('bedding_depth');
      }
    },
    '$store.state.user.lang': function() {
      // update copyField in case of lang change
      this.setCopyField(this.copyField);
    },
    locale: {
      handler(){
        this.setCopyField(this.copyField);
      },
      deep: true
    },
  },
  mounted() {
    this.copyField = null;
    wistiaEmbeds.onFind(function(video) {
      video.bind('beforeremove', function() {
        return video.unbind;
      });
    });
  },
  beforeDestroy() {},
};
</script>
