const Table = require('../../Misc/Table');
const PieChart = require('../../Misc/PieChart');

export const group = (app, report, evaluation) => {

    let assessorType = "1";

    let groups = [];
    const selectedGroups = evaluation.getGroups().filter((item) => {
        item = item.group.id;
        return evaluation.getReport().groups.includes(item);
    });

    for ( let i = 0; i < selectedGroups.length; i++ ) {
        let group = selectedGroups[i];
        let locomotion = group.getAssessors().find(assessor => {
            return assessor.getType() === assessorType;
        });
        if ( locomotion !== undefined ) {
            groups.push(group);
        }
    }

    // if there are is less than 2 groups with locomotions, do not add this slide
    if(groups.length < 2) return false;

    let slide = report.pptx.addSlide('Slide master');

    slide.addText(
        app.$t('Hygiene/Footbath Assessor'), report.styleOptions.slideHeader
    ).addText(
        app.$t('Group Comparison'), {
            x: 0.5,
            y: 2.38,
            ...report.styleOptions.subheaderBlue
        }
    )

    let obj = [{
        text: app.$t('Hygiene Score'),
        options: report.styleOptions.tableHeader
    }, {
        text: '1',
        options: report.styleOptions.tableHeader
    }, {
        text: '2',
        options: report.styleOptions.tableHeader
    }, {
        text: '3',
        options: report.styleOptions.tableHeader
    }, {
        text: '4',
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Total Scored'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Percent Scored 3 or 4'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Recommended Footbath Frequency (days/week)'),
        options: report.styleOptions.tableHeader
    }];
    let obj2 = [];
    var total = 0;
    var score_1_total = 0;
    var score_2_total = 0;
    var score_3_total = 0;
    var score_4_total = 0;
    var rectangleHeight = 4.2;
    var pieHeight = 4.3;

    var hygieneFootbathAssessor;

    for ( let j = 0; j < groups.length; j++ ) {

        hygieneFootbathAssessor = groups[j].getAssessorByType("1");
        // console.log('hygieneFootbathAssessor', hygieneFootbathAssessor.isStarted())
        if (!hygieneFootbathAssessor.isStarted()) {
            continue
        }
        // console.log(groups[j].getName())

        let score1_2 = hygieneFootbathAssessor.getFirstScore() + hygieneFootbathAssessor.getSecondScore();
        let score3_4 = hygieneFootbathAssessor.getThirdScore() + hygieneFootbathAssessor.getFourthScore();
        let group_total = score1_2 + score3_4;

        total += group_total;
        score_1_total += hygieneFootbathAssessor.getFirstScore();
        score_2_total += hygieneFootbathAssessor.getSecondScore();
        score_3_total += hygieneFootbathAssessor.getThirdScore();
        score_4_total += hygieneFootbathAssessor.getFourthScore();

        let percent_score_1 = ((hygieneFootbathAssessor.getFirstScore() / group_total) * 100);
        let percent_score_2 = ((hygieneFootbathAssessor.getSecondScore() / group_total) * 100);
        let percent_score_3 = ((hygieneFootbathAssessor.getThirdScore() / group_total) * 100);
        let percent_score_4 = ((hygieneFootbathAssessor.getFourthScore() / group_total) * 100);

        let percent_arr = [percent_score_1, percent_score_2, percent_score_3, percent_score_4];

        obj2.push([{
                text: groups[j].getName() + ' (' + app.$numberFormat(groups[j].getGroupSize()) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat(percent_score_1.toFixed(2)) + '%',
                options: {
                    color: '000000',
                    align: 'center',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat(percent_score_2.toFixed(2)) + '%',
                options: {
                    color: '000000',
                    align: 'center',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat(percent_score_3.toFixed(2)) + '%',
                options: {
                    color: '000000',
                    align: 'center',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat(percent_score_4.toFixed(2)) + '%',
                options: {
                    color: '000000',
                    align: 'center',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat(group_total),
                options: {
                    color: '000000',
                    align: 'center',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat((percent_score_3 + percent_score_4).toFixed(2)) + '%',
                options: {
                    color: '000000',
                    align: 'center',
                    fill: 'ffffff',
                    bold: false
                }
            },{
                text: app.$numberFormat(hygieneFootbathAssessor.getFrequencySuggested(percent_score_3 + percent_score_4)),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'center'
                }
            }
        ]);

        rectangleHeight += .25;
        pieHeight += .25;

    };

    var total_percent_score_1 = ((score_1_total / total) * 100);
    var total_percent_score_2 = ((score_2_total / total) * 100);
    var total_percent_score_3 = ((score_3_total / total) * 100);
    var total_percent_score_4 = ((score_4_total / total) * 100);

    obj2.push([{
            text: app.$t('Average Score *:'),
            options: {
                color: '000000',
                align: 'right',
                fill: 'ffffff',
                bold: true
            }
        },
        {
            text: app.$numberFormat(total_percent_score_1.toFixed(2)) + '%',
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false,
                align: 'center'
            }
        },
        {
            text: app.$numberFormat(total_percent_score_2.toFixed(2)) + '%',
            options: {
                color: '000000',
                align: 'center',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(total_percent_score_3.toFixed(2)) + '%',
            options: {
                color: '000000',
                align: 'center',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(total_percent_score_4.toFixed(2)) + '%',
            options: {
                color: '000000',
                align: 'center',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(total),
            options: {
                color: '000000',
                align: 'center',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat((total_percent_score_3 + total_percent_score_4).toFixed(2)) + '%',
            options: {
                color: '000000',
                align: 'center',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$numberFormat(hygieneFootbathAssessor.getFrequencySuggested(total_percent_score_3 + total_percent_score_4)),
            options: {
                color: '000000',
                align: 'center',
                fill: 'ffffff',
                bold: false
            }
        },
    ]);

    obj2.push([{
        text: app.$t('* Hygiene score is weighted based on the number of animals in each group'),
        options: {
            color: 'ffffff',
            colspan: 8,
            align: 'c',
            valign: 'm',
            fill: '000000',
            bold: false
        }
    }]);

    rectangleHeight += 1.05;
    pieHeight += 1.05;

    let pieObj = [{
        name: app.$t('Percent of Cows scoring 3 or 4'),
        labels: [app.$t('Score 1'), app.$t('Score 2'), app.$t('Score 3'), app.$t('Score 4')],
        values: [score_1_total, score_2_total, score_3_total, score_4_total]
    }];

    Table.create(slide, obj, obj2, report, group, 'Hygiene/Footbath Assessor', {
        y: 2.7,
        colW: [1.45, .7, .7, .7, .7, .7, 1, 1.55],
        ...report.styleOptions.tableOptions
    });

    if (groups.length > 6) {
        slide = report.pptx.addSlide('Slide master');

        slide.addText(
            app.$t('Hygiene/Footbath Assessor'), report.styleOptions.slideHeader
        ).addText(
            app.$t('Group Comparison'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        )

        rectangleHeight = 2.7;
        pieHeight = 2.7;
    }

    slide.addShape(report.pptx.shapes.RECTANGLE, {
        x: 0.5,
        y: rectangleHeight + .2,
        w: 7.5,
        h: 3,
        line: '000000',
        line_size: 1
    });

    PieChart.create(report, slide, pieObj, {
        x: 0.75,
        y: pieHeight + .2,
        w: 7,
        h: 2.75,
    });

};
