<template>
  <div
    class="time-budget container main-container main-container--hygiene view"
  >
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ "Tell Us How Your Cows Spend Their Time" | translate }}
        </h2>

        <!-- MODAL INFO COMPONENT -->
        <modal-info class="d-inline" value="tell-us">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Time Budget" | translate }}
            </h2>
            <p class="px-3 mb-0">
              {{ "Stall Standing Index (%)" | translate }}:
            </p>
            <p class="px-3">
              {{
                "Stall standing index should be measured two hours before AM or PM milking. Stall Standing Index was adapted from Cook et al., 2005. JDP 88:3876-3885"
                  | translate
              }}
            </p>
            <p class="px-3 mb-0">{{ "Stocking Rate (%)" | translate }}:</p>
            <p class="px-3">
              {{
                "Stocking rates exceeding 125% will reduce lying times by approximately one hour per day."
                  | translate
              }}
            </p>
            <p class="px-3 mb-0">{{ "Grazing Time:" | translate }}</p>
            <p class="px-3">
              {{
                "The average grazing time numbers were sourced from Bargo et al., 2003. JDS 86:1-42"
                  | translate
              }}
            </p>
            <p class="px-3">
              {{
                "For more information about Time Budget, click the link to learn more from"
                  | translate
              }}
              <a
                :href="$t('https://library.zinprofirststep.com/time-budget/')"
                @click="$goToLameness($t('/time-budget/'), $event)"
                target="_blank"
                >{{ "FirstStep Resources" | translate }}</a
              >.
            </p>
            <p class="px-3">
              {{
                "Stall standing index should be measured two hours before AM or PM milking. Stall Standing Index was adapted from Cook et al., 2005. JDP 88:3876-3885"
                  | translate
              }}
            </p>
            <p class="px-3 mb-0">
              {{ "Stocking Rate (%)" | translate }}:
            </p>
            <p class="px-3">
              {{
                "Stocking rates exceeding 125% will reduce lying times by approximately one hour per day."
                  | translate
              }}
            </p>
            <p class="px-3 mb-0">
              {{ "Grazing Time:" | translate }}
            </p>
            <p class="px-3">
              {{
                "The average grazing time numbers were sourced from Bargo et al., 2003. JDS 86:1-42"
                  | translate
              }}
            </p>
            <p class="px-3">
              {{
                "For more information about Time Budget, click the link to learn more from"
                  | translate
              }}
              <a
                :href="$t('https://library.zinprofirststep.com/time-budget/')"
                target="_blank"
                @click="$goToLameness($t('/time-budget/'), $event)"
              >
                {{ "FirstStep Resources" | translate }} </a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                class="btn-cancel-modal d-flex justify-content-center"
                href="#"
                data-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </a>
            </div>
          </template>
        </modal-info>
      </div>
    </div>
    <hr class="mb-lg-4" />
    <div class="row">
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Select Your Type of Production" | translate }}
        </label>
        <select v-model="assessor.data.production_type" class="form-control">
          <option
            v-for="type in productionTypes"
            :key="type.value"
            :value="type.value"
          >
            {{ type.label | translate }}
          </option>
        </select>
      </div>
    </div>
    <div class="row my-0">
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Group/Pen Size" | translate }}
        </label>
        <currency-input
          v-model="group.data.size"
          readonly
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Cows Milked per Hour" | translate }}
        </label>
        <currency-input
          v-model="assessor.data.milked_per_hour"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Milkings per Day" | translate }}
        </label>
        <currency-input
          v-model="assessor.data.milkings_per_day"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
    </div>
    <div v-if="assessor.data.production_type === 'confined_cattle'" class="row">
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Stalls Available in a Pen" | translate }}
        </label>
        <currency-input
          v-model="assessor.data.stalls_available_in_pen"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Cows Standing or Lying in Stall" | translate }}
        </label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="stand-or-lying">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Cows Standing or Lying in Stall" | translate }}
            </h2>
            <p class="px-3">
              {{ "Any cow touching a stall." | translate }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model="assessor.data.cows_standing_or_lying_in_stall"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Cows Standing Only in Stall" | translate }}
        </label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="standing-only">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Cow Standing Only in Stall" | translate }}
            </h2>
            <p class="px-3">
              {{
                "Standing in a stall is defined as a cow that is standing and is touching the stall with at least one hoof."
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model="assessor.data.cows_only_standing"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
    </div>

    <hr v-if="assessor.data.production_type === 'confined_cattle'" />

    <div class="row" v-if="assessor.production_type === 'confined_cattle'">
      <div class="col-lg-12 d-md-flex align-self-center">
        <label class="label d-inline-block">
          <!-- {{ $t("Do freestalls have at least") + minSoftBedding + this.$getEquivalentUnit('cm') + $t(" of soft bedding?") }} -->
          {{
            evaluationClass.isImperial
              ? "Do freestalls have at least 8 in of soft bedding?"
              : "Do freestalls have at least 20 cm of soft bedding?" | translate
          }}
        </label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="cow-in-pen" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              <!-- {{ $t("Do freestalls have at least ") + minSoftBedding + this.$getEquivalentUnit('cm') + $t(" of soft bedding?") }} -->
              {{
                evaluationClass.isImperial
                  ? "Do freestalls have at least 8 in of soft bedding?"
                  : "Do freestalls have at least 20 cm of soft bedding?"
                    | translate
              }}
            </h2>
            <p class="px-3">
              {{
                "If the cows are in the pen when an automatic scraper pushes a wave of manure, leg hygiene may be poor and risk for digital dermatitis may be very high. Removal of manure when cows are in the parlor is preferred; this should occur at least three times per day."
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </a>
            </div>
          </template>
        </modal-info>
      </div>

      <div
        class="col-lg-12 d-flex align-self-center justify-content-end flex-row"
      >
        <div
          class="label_group label_group--width-sm label_group--inline mr-3 mr-sm-1"
        >
          <input
            class="label_group__input"
            type="radio"
            id="soft_bedding_no"
            v-model.number="assessor.soft_bedding"
            value="0"
          />
          <label class="label_group__label" for="soft_bedding_no">
            {{ "No" | translate }}
          </label>
        </div>

        <div class="label_group label_group--width-sm label_`group--inline">
          <input
            class="label_group__input"
            type="radio"
            id="soft_bedding_yes"
            v-model.number="assessor.soft_bedding"
            value="1"
          />
          <label class="label_group__label" for="soft_bedding_yes">
            {{ "Yes" | translate }}
          </label>
        </div>
      </div>
    </div>

    <!-- QUESTION COMPONENT -->
    <question
      v-if="assessor.data.production_type === 'confined_cattle'"
      :initial-value="assessor.data.soft_bedding"
      @change="handleSoftBedding"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            evaluationClass.isImperial
              ? "Do freestalls have at least 8 in of soft bedding?"
              : "Do freestalls have at least 20 cm of soft bedding?" | translate
          }}
        </label>
      </div>
    </question>

    <div
      v-if="assessor.data.production_type === 'grazing_cattle'"
      class="row mb-0"
    >
      <div class="col-lg-8">
        <label class="mb-1">{{
          "Walking Time to/from Paddock per Day (Mins)" | translate
        }}</label>
        <currency-input
          v-model="assessor.data.walking_time_per_day"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Time Held Away from Paddocks (hrs)" | translate }}
        </label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="paddocks" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ "Time Held Away from Paddocks (hrs)" | translate }}
            </h2>
            <p class="px-3">
              {{
                "Time away from paddock does not include time walking to and from paddock."
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </a>
            </div>
          </template>
        </modal-info>

        <currency-input
          v-model="assessor.data.time_held_from_paddocks"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1"
          >{{
            $t("Quantity of Grain Supplement") +
            " (" +
            $t($getEquivalentUnit(evaluation.data.measurements, "kg"))
          }})</label
        >
        <currency-input
          v-model="computed_quantity_of_grain_supplements"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0,
          }"
        />
      </div>
    </div>
    <div v-if="assessor.data.production_type === 'dirt_lot'" class="row mb-0">
      <div class="col-lg-8">
        <label class="mb-1">{{
          "Walking Time to/from Paddock per Day (Mins)" | translate
        }}</label>
        <currency-input
          v-model="assessor.data.walking_time_per_day"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">
          {{ "Time Held Away from Paddocks (hrs)" | translate }}
        </label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="paddocks" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ "Time Held Away from Paddocks (hrs)" | translate }}
            </h2>
            <p class="px-3">
              {{
                "Time away from paddock does not include time walking to and from paddock."
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </a>
            </div>
          </template>
        </modal-info>

        <currency-input
          v-model="assessor.data.time_held_from_paddocks"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-24">
        <hr />
      </div>
    </div>

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="4" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />
    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <button
          data-target="#areYouSure"
          data-toggle="modal"
          class="btn btn-secondary mb-2 mt-md-0"
        >
          {{ "Default Values" | translate }}
        </button>
        <div
          class="modal fade"
          id="areYouSure"
          tabindex="-1"
          role="dialog"
          aria-labelledby="areYouSure"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content edit-modal">
              <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                {{ "Return to Default Values" | translate }}
              </h2>
              <div class="px-3 pb-3">
                <p>
                  {{
                    "Are you sure that you would like to return values to default? Once completed, the data cannot be restored."
                      | translate
                  }}
                </p>
                <p>
                  <a
                    href="#"
                    data-dismiss="modal"
                    class="btn btn-full btn-full--modal mb-2 mb-md-0"
                    @click="resetDefaults"
                  >
                    {{ "Return to Defaults" | translate }}
                  </a>
                </p>
                <div class="row mt-2">
                  <div class="col-md-24 modal-bottom">
                    <a href="#" class="btn-cancel-modal" data-dismiss="modal">
                      {{ "Cancel" | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ "Reports" | translate }}
        </router-link>

        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          :data-target="['#requestSent-' + user._id]"
          class="btn btn-primary btn-full--sm pull-right"
          @click="requestReport(evaluation.name)"
        >
          {{ "Request Report" | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ "Your request has been sent!" | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ "An error has occurred during your request!" | translate }}
    </div>
  </div>
</template>

<script>
/** Components */
import PresentationNotes from "./assessor_components/presentation-notes";
import PersonalNotes from "./assessor_components/personal-notes";
import Question from "./assessor_components/question";
import HygieneHeader from "./assessor_components/hygiene-header.vue";

import ModalInfo from "@/components/misc/modal-info.vue";
import PhotoUpload from "@/components/misc/photoUpload";
import CurrencyInput from "@/components/common/CurrencyInput.vue";

/** Classes */
import TimeBudget from "@/libs/Classes/TimeBudget.js";
import Evaluation from "@/libs/Classes/Evaluation.js";
import Group from "@/libs/Classes/Group.js";
import requestReportMixin from "@/mixins/requestReport.js";
/** Helpers */
import convert from "convert-units";
import { mapState } from "vuex";

const productionTypes = [
  {
    value: "confined_cattle",
    label: "Confined Cattle",
  },
  {
    value: "dirt_lot",
    label: "Dirt Lot",
  },
  {
    value: "grazing_cattle",
    label: "Grazing Cattle",
  },
  {
    value: "closed_lot",
    label: "Closed Lot",
  },
  {
    value: "open_lot",
    label: "Open Lot",
  },
];

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    CurrencyInput,
  },
  mixins: [requestReportMixin],
  props: ["assessor", "group", "evaluation"],

  computed: {
    ...mapState({
      user: (state) => state.users.user,
    }),
    minSoftBedding() {
      const convertedValue =
        convert(20)
          .from("cm")
          .to(this.$getEquivalentUnit(this.evaluation.data.measurements, "cm"))
          .toFixed(0) || 0;

      return parseInt(convertedValue);
    },

    computed_quantity_of_grain_supplements: {
      get() {
        return this.timebudget.getQuantityOfGrainSupplements(0);
      },
      set(newValue) {
        this.timebudget.setQuantityOfGrainSupplements(newValue);
      },
    },
  },

  data() {
    return {
      timebudget: null,
      productionTypes,
      evaluationClass: null,
    };
  },

  created() {
    this.evaluationClass = new Evaluation(this, this.evaluation);
    const groupClass = new Group(this, this.evaluationClass, this.group);

    this.timebudget = new TimeBudget(
      this,
      this.evaluationClass,
      groupClass,
      this.assessor
    );

    if (this.assessor.data.milkings_per_day === undefined) {
      this.assessor.data.milkings_per_day =
        this.timebudget.getDefaultMilkingsPerDay();
    }
  },

  methods: {
    // requestReport(evaluationName) {
    //   this.$store.dispatch('requestReport', {
    //       currentVueInstance: this,
    //       evaluationName: evaluationName
    //   });
    // },

    handleSoftBedding($event) {
      console.log($event.value);
      this.assessor.data.soft_bedding = $event.value;
    },
    resetDefaults() {
      this.assessor.data = {
        cows_only_standing: 5,
        cows_standing_or_lying_in_stall: 95,
        milked_per_hour: 64,
        milkings_per_day: 2,
        other_bedding_type: null,
        production_type: "confined_cattle",
        quantity_of_grain_supplements: null,
        stalls_available_in_pen: 100,
        personal_notes: null,
        presentation_notes: null,
        time_held_from_paddocks: null,
        type_production: null,
        walking_time_per_day: null,
      };
    },
  },
};
</script>
