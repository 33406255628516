<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ 'Tell Us About Your Freestall Ventilation System' | translate }}
        </h2>
        <modal-info :value="'tell-us'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Freestall Ventilation' | translate }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Freestall Ventilation, click the link to learn more from'
                  | translate
              }}
              <a
                :href="$t('https://library.zinprofirststep.com/ventilation/')"
                @click="$goToLameness($t('/ventilation/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-lg-4" />

    <div class="row mt-0 mb-2">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ $t('Questions') }}</h2>
      </div>

      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1 d-block">{{
            $t('Summer Ventilation System')
          }}</label>
          <div class="form-check form-check-inline mr-4">
            <input
              v-model="assessor.data.summer_ventilation_system"
              type="radio"
              id="natural"
              name="natural"
              value="natural"
              class="form-check-input mr-2"
            />
            <label class="form-check-label mt-1" for="natural">
              {{ $t('Natural (open-sided)') }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="assessor.data.summer_ventilation_system"
              type="radio"
              id="tunnel"
              name="tunnel"
              value="tunnel"
              class="form-check-input mr-2"
            />
            <label class="form-check-label mt-1" for="tunnel">
              {{ $t('Tunnel') }}
            </label>
          </div>
        </div>
      </div>

      <div
        v-show="assessor.data.summer_ventilation_system === 'natural'"
        class="col-lg-8"
      >
        <label class="mb-1">{{
          $t('Long Axis Orientation of the Barn')
        }}</label>
        <select
          v-model="assessor.data.long_axis_orientation_of_the_barn"
          class="form-control"
        >
          <option :value="null" selected disabled>{{ $t('Select') }}</option>
          <option value="n_to_s">{{ $t('N to S') }}</option>
          <option value="ne_to_sw">{{ $t('NE to SW') }}</option>
          <option value="e_to_w">{{ $t('E to W') }}</option>
          <option value="se_to_nw">{{ $t('SE to NW') }}</option>
        </select>
      </div>

      <div
        v-show="assessor.data.summer_ventilation_system === 'natural'"
        class="col-lg-8"
      >
        <label class="mb-1">{{ $t('Prevailing Wind Direction') }}</label>
        <select
          v-model="assessor.data.prevailing_wind_direction"
          class="form-control"
        >
          <option :value="null" selected disabled>{{ $t('Select') }}</option>
          <option value="n_to_s">{{ $t('N to S') }}</option>
          <option value="ne_to_sw">{{ $t('NE to SW') }}</option>
          <option value="e_to_w">{{ $t('E to W') }}</option>
          <option value="w_to_e">{{ $t('W to E') }}</option>
          <option value="s_to_n">{{ $t('S to N') }}</option>
          <option value="se_to_nw">{{ $t('SE to NW') }}</option>
          <option value="nw_to_se">{{ $t('NW to SE') }}</option>
          <option value="sw_to_ne">{{ $t('SW to NE') }}</option>
        </select>
      </div>

      <div
        v-show="assessor.data.summer_ventilation_system === 'tunnel'"
        class="col-lg-8"
      >
        <div class="form-group">
          <label class="mb-1">{{
            $t('Barn Length') +
              ' (' +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <currency-input                 
            v-model.number="converted_barn_length"
            :options="{
              currency: 'USD',    
              currencyDisplay: 'hidden',   
              precision: {min: 0, max :2},       
            }"
          />
        </div>
      </div>

      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1">{{
            $t('Barn Width') +
              ' (' +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <currency-input                 
            v-model="converted_barn_width"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>

      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1">{{
            $t('Barn Eave Height') +
              ' (' +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <currency-input                 
            v-model="converted_barn_eave_height"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>

      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1">{{
            $t('Roof Height') +
              ' (' +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <currency-input                 
            v-model="converted_roof_height"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>

      <div class="col-lg-8">
        <div class="form-group">
          <label class="mb-1">{{
            $t('Cross-sectional area') +
              ' (' +
              $t(
                $getEquivalentUnit(
                  evaluation.data.measurements,
                  'square meters'
                )
              ) +
              ')'
          }}</label>
          <currency-input                 
            v-model="computed_cross_sectional_area"
            readonly
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          <span
            v-show="assessor.data.summer_ventilation_system === 'natural'"
            >{{ $t('Natural Summer Ventilation') + $t(' ') }}</span
          >
          <span v-show="assessor.data.summer_ventilation_system === 'tunnel'">{{
            $t('Tunnel Summer Ventilation') + $t(' ')
          }}</span>
        </h2>
      </div>

      <div
        v-show="assessor.data.summer_ventilation_system === 'natural'"
        class="col-lg-8"
      >
        <div class="form-group">
          <label class="mb-1">{{
            $t('Ridge Opening Width') +
              ' (' +
              $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
              ')'
          }}</label>
          <currency-input                 
            v-model="converted_ridge_opening_width"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>

      <div
        v-show="assessor.data.summer_ventilation_system === 'tunnel'"
        class="col-lg-12"
      >
        <div class="form-group">
          <label class="mb-1">{{
            $t('Inlet area') +
              ' (' +
              $t(
                $getEquivalentUnit(
                  evaluation.data.measurements,
                  'square meters'
                )
              ) +
              ')'
          }}</label>
          <currency-input                 
            v-model="converted_inlet_area"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>

      <!-- data collection row 1 -->
      <!-- <div
      v-show="assessor.data.summer_ventilation_system === 'tunnel'"
        class="col-lg-12"
      >
        <div class="form-group">
          <label class="mb-1">{{ $t('Number of Fans') }}</label>
          <input
            v-model.number="assessor.data.number_of_fans1"
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber($event)"
          />
        </div>
      </div>

      <div
      class="col-lg-12"
      v-show="assessor.data.summer_ventilation_system === 'tunnel'"
      >
        <label class="mb-1">{{
          $t('Fan Width') +
            ' (' +
            $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
            ')'
        }}</label>
        <select
          name="fan_width"
          v-model.number="assessor.data.fan_width1"
          class="form-control"
        >
          <option :value="null" disabled>{{ $t('Select') }}</option>
          <option
            v-for="(fanWidth, index) in fanWidths"
            :value="fanWidth.value"
            :key="index"
            >{{ fanWidth.label }}</option
          >
        </select>
      </div> -->
 
      <!-- ----------------------- Tunnel Summer Ventilation ------------------------------ -->
      <template v-if="assessor.data.summer_ventilation_system === 'tunnel'" >
        <Fans
          :evaluation="evaluation"
          :assessor="assessor"
          :fan-width.sync="converted_fan_width_1"
          no-of-fan="number_of_fans_1"
        />
        <Fans
          :evaluation="evaluation"
          :assessor="assessor"
          :fan-width.sync="converted_fan_width_2"
          no-of-fan="number_of_fans_2"
        />
        <Fans
          :evaluation="evaluation"
          :assessor="assessor"
          :fan-width.sync="converted_fan_width_3"
          no-of-fan="number_of_fans_3"
        />
      </template>

      <!-- ----------------------- Natural Summer Ventilation ------------------------------ -->
      <template  v-if="assessor.data.summer_ventilation_system === 'natural'">
        <Fans
          :evaluation="evaluation"
          :assessor="assessor"
          no-of-fan="number_of_fans_natural_1"
          :fan-width.sync="converted_fan_width_natural_1"
        />
        <Fans
          :evaluation="evaluation"
          :assessor="assessor"
          no-of-fan="number_of_fans_natural_2"
          :fan-width.sync="converted_fan_width_natural_2"
        />
        <Fans
          :evaluation="evaluation"
          :assessor="assessor"
          no-of-fan="number_of_fans_natural_3"
          :fan-width.sync="converted_fan_width_natural_3"
          />
      </template>


      <!-- data collection row 2 -->
      <!-- <div
      class="col-lg-12"
      v-show="assessor.data.summer_ventilation_system === 'tunnel'"
      >
        <div class="form-group">
          <label class="mb-1">{{ $t('Number of Fans') }}</label>
          <input
            v-model.number="assessor.data.number_of_fans2"
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber($event)"
          />
        </div>
      </div> -->

      <!-- <div
      class="col-lg-12"
      v-show="assessor.data.summer_ventilation_system === 'tunnel'"
      >
        <label class="mb-1">{{
          $t('Fan Width') +
            ' (' +
            $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
            ')'
        }}</label>
        <select
          name="fan_width"
          v-model.number="assessor.data.fan_width2"
          class="form-control"
        >
          <option :value="null" disabled>{{ $t('Select') }}</option>
          <option
            v-for="(fanWidth, index) in fanWidths"
            :value="fanWidth.value"
            :key="index"
            >{{ fanWidth.label }}</option
          >
        </select>
      </div> -->

         <!-- data collection row 3 -->
         <!-- <div
         v-show="assessor.data.summer_ventilation_system === 'tunnel'"
        class="col-lg-12"
      >
        <div class="form-group">
          <label class="mb-1">{{ $t('Number of Fans') }}</label>
          <input
            v-model.number="assessor.data.number_of_fans3"
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber($event)"
          />
        </div>
      </div>

      <div
        v-show="assessor.data.summer_ventilation_system === 'tunnel'"
        class="col-lg-12"
      >
        <label class="mb-1">{{
          $t('Fan Width') +
            ' (' +
            $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
            ')'
        }}</label>
        <select
          name="fan_width"
          v-model.number="assessor.data.fan_width3"
          class="form-control"
        >
          <option :value="null" disabled>{{ $t('Select') }}</option>
          <option
            v-for="(fanWidth, index) in fanWidths"
            :value="fanWidth.value"
            :key="index"
            >{{ fanWidth.label }}</option
          >
        </select>
      </div> -->

      <div
        v-show="assessor.data.summer_ventilation_system === 'tunnel'"
        class="col-lg-12"
      >
        <div class="form-group">
          <label class="mb-1"
            >{{
              $t('Total air movement capacity') +
                ' (' +
                $t($getEquivalentUnit(evaluation.data.measurements, 'cmh'))
            }})</label
          >
          <currency-input                 
            v-model="computed_air_movement_capacity"
            readonly
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
      
      <div
        v-show="assessor.data.summer_ventilation_system === 'natural'"
        class="col-lg-12"
      >
        <div class="form-group">
          <label class="mb-1"
            >{{
              $t('Total air movement capacity') +
                ' (' +
                $t($getEquivalentUnit(evaluation.data.measurements, 'cmh'))
            }})</label
          >
          <currency-input                 
            v-model="computed_air_movement_capacity_natural"
            readonly
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
    </div>

    <hr />

    <question-three
      v-show="assessor.data.summer_ventilation_system === 'natural'"
      @change="assessor.data.barn_is_free_of_wind_shadows = $event.value"
      :initial-value="assessor.data.barn_is_free_of_wind_shadows"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Barn is free of wind shadows') }}
        </label>
      </div>
    </question-three>

    <hr v-show="assessor.data.summer_ventilation_system === 'natural'" />

    <question-three
      v-show="assessor.data.summer_ventilation_system === 'natural'"
      @change="
        assessor.data.fifty_percent_of_side_wall_can_be_opened = $event.value
      "
      :initial-value="assessor.data.fifty_percent_of_side_wall_can_be_opened"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('50% of side wall can be opened') }}
        </label>
      </div>
    </question-three>

    <hr v-show="assessor.data.summer_ventilation_system === 'natural'" />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="12" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-24">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
          >{{ 'Reports' | translate }}</router-link
        >
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="$store.state.loader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import QuestionThree from './assessor_components/question-three.vue';
import ModalInfo from '@/components/misc/modal-info.vue';
import Fans from '@/components/misc/Fans.vue';

import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import FreestallVentilation from '@/libs/Classes/FreestallVentilation.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import requestReportMixin from '@/mixins/requestReport.js';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    QuestionThree,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    Fans,
    CurrencyInput
  },
  props: ['assessor', 'group', 'evaluation'],
  mixins: [requestReportMixin],

  data() {
    return {
      freestallventilation: null,
      evaluationClass:null,
    };
  },
  computed: {
    converted_barn_length: {
      get() {
        return this.freestallventilation.getBarnLength(false);
      },
      set(newValue) {
        return this.freestallventilation.setBarnLength(newValue);
      },
    },
    converted_barn_width: {
      get() {
        return this.freestallventilation.getBarnWidth(false);
      },
      set(newValue) {
        return this.freestallventilation.setBarnWidth(newValue);
      },
    },
    converted_barn_eave_height: {
      get() {
        return this.freestallventilation.getBarnEaveHeight(false);
      },
      set(newValue) {
        return this.freestallventilation.setBarnEaveHeight(newValue);
      },
    },
    converted_roof_height: {
      get() {
        return this.freestallventilation.getRoofHeight(false);
      },
      set(newValue) {
        return this.freestallventilation.setRoofHeight(newValue);
      },
    },
    converted_ridge_opening_width: {
      get() {
        return this.freestallventilation.getRidgeOpeningWidth(false);
      },
      set(newValue) {
        return this.freestallventilation.setRidgeOpeningWidth(newValue);
      },
    },
    converted_inlet_area: {
      get() {
        return this.freestallventilation.getInletArea(false);
      },
      set(newValue) {
        return this.freestallventilation.setInletArea(newValue);
      },
    },
    converted_fan_width_1: {
      get() {
       return this.freestallventilation.getFanWidth('fan_width_1');
      },
      set(newValue) {
        return this.freestallventilation.setFanWidth(newValue, 'fan_width_1');
      },
    },
    converted_fan_width_2: {
      get() {
        return this.freestallventilation.getFanWidth('fan_width_2');
      },
      set(newValue) {
       return this.freestallventilation.setFanWidth(newValue, 'fan_width_2');
      },
    },
    converted_fan_width_3: {
      get() {
        return this.freestallventilation.getFanWidth('fan_width_3');
      },
      set(newValue) {
       return this.freestallventilation.setFanWidth(newValue, 'fan_width_3');
      },
    },

    converted_fan_width_natural_1: {
      get() {
       return this.freestallventilation.getFanWidth('fan_width_natural_1');
      },
      set(newValue) {
        return this.freestallventilation.setFanWidth(newValue, 'fan_width_natural_1');
      },
    },
    converted_fan_width_natural_2: {
      get() {
        return this.freestallventilation.getFanWidth('fan_width_natural_2');
      },
      set(newValue) {
       return this.freestallventilation.setFanWidth(newValue, 'fan_width_natural_2');
      },
    },
    converted_fan_width_natural_3: {
      get() {
        return this.freestallventilation.getFanWidth('fan_width_natural_3');
      },
      set(newValue) {
       return this.freestallventilation.setFanWidth(newValue, 'fan_width_natural_3');
      },
    },
    computed_cross_sectional_area() {
      return this.freestallventilation.getCrossSectionalArea();
    },
    computed_air_movement_capacity() {
      return this.freestallventilation.getDisplayedAirMovementCapacity();
    },
    computed_air_movement_capacity_natural() {
      return this.freestallventilation.getDisplayedAirMovementCapacityNatural();
    },
    fanWidths() {
      const widths_in_cm = [
        35.56,
        40.64,
        45.72,
        60.96,
        91.44,
        121.92,
        127,
        129.54,
        137.16,
      ];
      let converted_widths = [];

      for (let i = 0; i < widths_in_cm.length; i++) {
        let converted_value = parseInt(
          convert(widths_in_cm[i])
            .from('cm')
            .to(
              this.$getEquivalentUnit(this.evaluation.data.measurements, 'cm')
            )
            .toFixed(0)
        );

        converted_widths.push({
          value: widths_in_cm[i],
          label: converted_value,
        });
      }

      return converted_widths;
    },
  },
  methods: {
    // requestReport(evaluationName) {
    //   this.$store.dispatch('requestReport', {
    //       currentVueInstance: this,
    //       evaluationName: evaluationName
    //   })
    // },
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, evaluationClass, this.group);
    this.evaluationClass = {...evaluationClass}
    // this.user = this.$store.getters.getData("user");
    this.freestallventilation = new FreestallVentilation(
      this,
      evaluationClass,
      groupClass,
      this.assessor
    );

    console.log('Freestall Ventilation data', this.assessor.data);
  },
};
</script>
