<template>
    <div>
        <div class="container main-container main-container--hygiene view">
            <div v-if="scoresFilledOut === false" class="row mx-0 mb-4 score-note score-note--border-orange">
                <div class="col-md-24">
                    <strong>{{"It is recommended to complete the hygiene assessor before analyzing your new footbath." | translate}}</strong>
                </div>
            </div>
            <div v-else class="row mx-0 mb-4 score-note score-note--border-blue">
                <div class="col-md-24">
                    <strong>{{ $t("Hygiene Assessor percent of cows scoring 3 or 4 is ") + percent3_4 + '%.' }}</strong> {{ $t("Based upon hygiene scores, your suggested footbath frequency is: ") + suggestedFrequency + $t(" days per week.") }}
                </div>
            </div>
            <div class="row">
                <div class="col-xl-18 col-lg-16 col-md-14 d-flex align-items-center">
                    <div>
                        <h2 class="h2 d-inline">
                            {{ 'Tell Us About Your Existing Footbath' | translate }}
                        </h2>
                        <modal-info :value="'design-new'">
                            <template slot="modal-content">
                                <h2 class="h2 text-center pb-2 pt-3 px-3">{{ 'Evaluate Existing Footbath' | translate }}</h2>
                                <p class="px-3">{{'Footbaths are a proven method of fighting lesions that can lead to lameness. Depending upon environmental factors and management practices, the amount of footbaths that are necessary can vary.' | translate}}</p>
                                <p class="px-3">{{'Make adjustments based on FirstStep® recommendations that arrive at a footbath program that works for your budget and your herd.' | translate}}</p>
                                <p class="px-3">{{'For more information about footbaths, click the link to learn more from' | translate}} <a :href="$t('https://library.zinprofirststep.com/footbath/')" @click="$goToLameness($t('/footbath/'), $event);" target="_blank"> {{'FirstStep Resources' | translate}}</a>.</p>
                                <div class="row modal-bottom my-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                </div>
                            </template>
                        </modal-info>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-8 col-md-10" v-if="assessor.footbaths && footbathsCount > 1">
                    <label class="mb-1">{{"Select a Footbath" | translate}}</label>
                    <select @change="changeFootbath()" class="form-control" v-model="selectedFootbathId">
                        <option v-for="(footbath, index) in formattedFootbaths" :key="index" :value="footbath.id">
                            {{ footbath.name }}
                        </option>
                    </select>
                </div>
            </div>

            <hr class="mt-2 mb-4 mt-md-3">

            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="mb-1">{{"Footbath Location/Name" | translate}}</label>
                        <input type="text" class="form-control" name="name" v-model="footbath.name" data-vv-as="Footbath Location/Name" v-validate="'required'" />
                        <span v-show="errors.has( 'name' )" class="text-danger">
                            <small>{{ 'The Footbath Location/Name field is required.' | translate }}</small>
                        </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="mb-1">{{"Evaluation Date" | translate}}</label>
						<input type="text" class="form-control date-picker date-picker--disabled" name="evaluation_date" :value="getDate(evaluation.data.consultation_date)" data-vv-as="Evaluation Date" v-validate="'required'" disabled />
                        <span v-show="errors.has( 'evaluation' )" class="text-danger">
                            <small>{{ 'The Evaluation Date field is required.' | translate }}</small>
                        </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="mb-1">{{"Group/Pen Size" | translate}}</label>
                        <currency-input
                          v-model.number="group.data.size"
                          :class="'form-control'"
                          disabled
                          :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            precision: 0,
                          }"
                        />
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <!-- desktop -->
                <div class="col-lg-7 measurements d-none d-lg-block">
                    <volume-existing :footbath="footbathClass" v-model="footbath.volume"></volume-existing>
                </div>
                <div class="col-lg-17">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="mb-1">{{ 'Cow Passes / Bath' | translate }}</label>
                                <modal-info :value="'cow-passes'">
                                    <template slot="modal-content">
                                        <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Cow Passes/Bath' | translate}}</h2>
                                        <p class="px-3">{{'The number of cow passes per footbath is a measure of the efficiency of the footbath. The higher the number, the more efficient the footbath is.' | translate}}</p>
                                        <div class="row modal-bottom pb-3">
                                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                        </div>
                                    </template>
                                </modal-info>
                                <currency-input
                                  v-model.number="footbath.data.cow_passes_bath"
                                  :class="'form-control'"
                                  name="cow_passes_bath"
                                  data-vv-as="Cow Passes/Bath"
                                  v-validate="'required'"
                                  :options="{
                                    currency: 'USD',
                                    currencyDisplay: 'hidden',
                                    precision: {min: 0, max :2},
                                  }"
                                />
                                <span v-show="errors.has('cow_passes_bath')" class="text-danger">
                                  <small>{{ 'The Cow Passes/Bath field is required.' | translate }}</small>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="mb-1">{{
                                  $t('Labor Cost (' + evaluation.data.currency + '/Footbath)')
                                }}</label>
                                <modal-info :value="'labor-cost'">
                                    <template slot="modal-content">
                                        <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Labor Cost' | translate}}</h2>
                                        <p class="px-3">{{'The labor cost per footbath is a measure of the cost of labor per footbath. The lower the cost, the more efficient the footbath is.' | translate}}</p>
                                        <div class="row modal-bottom pb-3">
                                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                        </div>
                                    </template>
                                </modal-info>
                                <currency-input
                                  v-model.number="footbath.data.labor_cost"
                                  :class="'form-control'"
                                  :options="{
                                    currency: 'USD',
                                    currencyDisplay: 'hidden',
                                    precision: {min: 0, max :2},
                                  }"
                                />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="mb-1">{{ 'pH' | translate }}</label>
                                <modal-info :value="'pH'">
                                    <template slot="modal-content">
                                        <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'pH' | translate}}</h2>
                                        <p class="px-3">{{'The pH of the footbath is a measure of the acidity or basicity of the footbath. The ideal pH for a footbath is between 6.5 and 7.5.' | translate}}</p>
                                        <div class="row modal-bottom pb-3">
                                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                        </div>
                                    </template>
                                </modal-info>
                                <currency-input
                                  v-model.number="footbath.data.ph"
                                  :class="'form-control'"
                                  :options="{
                                    currency: 'USD',
                                    currencyDisplay: 'hidden',
                                    precision: {min: 0, max :2},
                                  }"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- mobile -->
                    <div class="col-24 px-0 measurements d-lg-none">
                        <volume-existing :footbath="footbathClass" v-model="footbath.volume"></volume-existing>
                    </div>

                    <hr>

                    <question @change="footbath.data.properly_located = $event.value" :initial-value="footbath.data.properly_located">
                        <div class="mb-1 mb-md-0">
                            <label class="label d-inline">
                                {{"Is the footbath properly located?" | translate}}
                            </label>
                            <modal-info :value="'located'" class="d-inline-block">
                                <template slot="modal-content">
                                    <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Is the footbath properly located?' | translate}}</h2>
                                    <p class="px-3">{{'The bath should be located so that it minimizes obstruction to flow of traffic, is in a place where it may easily be filled and emptied, and where cows do not immediately enter a manure contaminated area.' | translate}}</p>
                                    <p class="px-3">{{'It should not be positioned immediately adjacent to a wash bath – as this allows for transfer of wash water into the treatment bath, diluting the product concentration.' | translate}}</p>
                                    <div class="row modal-bottom pb-3">
                                        <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                    </div>
                                </template>
                            </modal-info>
                        </div>
                    </question>

                    <hr>

                    <question @change="footbath.data.stones_bottom = $event.value" :initial-value="footbath.data.stones_bottom">
                        <div class="mb-1 mb-md-0">
                            <label class="label d-inline">
                                {{"Are there stones at the bottom?" | translate}}
                            </label>
                        </div>
                    </question>

                    <hr>

                    <question @change="footbath.data.floor_deteriorated = $event.value" :initial-value="footbath.data.floor_deteriorated">
                        <label class="label d-inline-block">
                            {{"Is the floor deteriorated?" | translate}}
                        </label>
                        <modal-info :value="'deteriorated'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ "Is the floor deteriorated?" | translate }}</h2>
                                <p class="px-3">{{'The flooring surface of the bath should not be traumatic (excessively rough or filled with stones and other debris) as this will inhibit the throughput of cows and potentially create claw lesions.' | translate}}</p>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                </div>
                            </template>
                        </modal-info>
                    </question>

                    <hr>

                    <question @change="footbath.data.avoid_stepping = $event.value" :initial-value="footbath.data.avoid_stepping">
                        <label class="label d-inline-block">
                            {{"Is there a way the cow can avoid stepping into the footbath?" | translate}}
                        </label>
                    </question>

                    <hr>

                    <question @change="footbath.data.bypass_footbath = $event.value" :initial-value="footbath.data.bypass_footbath">
                        <div class="mb-1 mb-md-0">
                            <label class="label d-inline">
                                {{"Is it possible to bypass the footbath when not in use?" | translate}}
                            </label>
                            <modal-info :value="'bypass'" class="d-inline">
                                <template slot="modal-content">
                                    <h2 class="h2 h2--line-sm pb-2 pt-3 px-3 text-center">{{"Is it possible to bypass the footbath when not in use?" | translate}}</h2>
                                    <p class="px-3">{{'On days when the bath is not in use, the cows should be able to walk around the bath, not through a bath that progressively fills with manure during the course of the day.' | translate}}</p>
                                    <div class="row modal-bottom pb-3">
                                        <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                                    </div>
                                </template>
                            </modal-info>
                        </div>
                    </question>

                    <hr>

                    <question @change="footbath.data.clean_path = $event.value" :initial-value="footbath.data.clean_path">
                        <div class="mb-1 mb-md-0">
                            <label class="label d-inline">
                                {{"Do cows walk from the footbath to the freestalls in a clean path?" | translate}}
                            </label>
                        </div>
                    </question>

                    <hr>

                </div> <!-- end col-md-17 -->
            </div> <!-- end row -->

            <div class="row d-none d-lg-block">
                <div class="col-md-24 mb-3">
                    <!-- show desktop -->
                    <div class="row">
                        <div class="col-md-24">
                            <label class="mb-1 mr-1">{{ 'Personal Notes' | translate }}</label>
                            <modal-info :value="'personal-notes'">
                                <template slot="modal-content">
                                    <h2 class="h2 pt-3 pb-2 px-3 text-center">
                                        {{'Personal Notes: Not included when outputting reports.' | translate}}
                                    </h2>
                                    <div class="row modal-bottom pb-3">
                                        <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">Cancel</a>
                                    </div>
                                </template>
                            </modal-info>
                        </div>
                        <div class="col-md-24">
                            <textarea v-model="footbath.personal_notes" rows="7" class="form-control d-none d-lg-block"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- show mobile -->
            <div>
                <div class="row order-1 order-lg-2 mx-0 d-lg-none mt-3">
                    <label class="mb-1 mr-1">{{"Personal Notes" | translate}}</label>
                    <modal-info :value="'personal-notes-sm'">
                        <template slot="modal-content">
                            <h3 class="h3 px-3 py-3">
                                {{'Personal Notes: Not included when outputting reports.' | translate}}
                            </h3>
                            <div class="row modal-bottom">
                                <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">Cancel</a>
                            </div>
                        </template>
                    </modal-info>
                    <textarea :value="footbath.personal_notes" @input="$emit('input', $event.target.value)" rows="4" class="form-control"></textarea>
                    <div v-show="errorMessage === true" class="alert alert-danger mt-4 mb-0" role="alert">
                        {{ 'Please fill out the required fields!' | translate }}
                    </div>
                </div>

                <div class="row order-2 order-lg-1 pb-2">
                    <div class="col-md-8 col-lg-12">
                        <photoUpload :assessorId="1" :assessor="assessor" class="mb-2 mb-md-0"></photoUpload>
                    </div>
                    <div class="col-md-16 col-lg-12">
                        <button v-if="!newFootbath" @click="createNewFootbath()" class="btn btn-primary btn-full--sm pull-right">{{ 'New Footbath' | translate }}</button>
                        <button @click="saveFootbath" :class="['btn btn-primary btn-full--sm pull-right', {'mr-md-3 mt-2 mt-md-0':!newFootbath}]">{{ 'Save Footbath' | translate }}</button>
                    </div>
                </div>
            </div>

            <hr :class="['mb-4', 'mt-4', {'mb-md-5' : successMessage === false && errorMessage === false}, {'mb-md-4' : successMessage === true || errorMessage === true}]">

            <div v-show="errorMessage" class="alert alert-danger mb-4 mt-0" role="alert">
                {{ 'Please fill out the required fields!' | translate }}
            </div>

            <div v-if="successMessage" class="alert alert-primary mb-4 mt-0" role="alert">
                {{ "Footbath has been saved!" | translate }}
            </div>

            <modal-confirm v-if="showModal" @close="showModal = false" :link="url" :changeFootbathCall="willFootbathChange" :revertFunction="revertFootbath" :onClick="propFunction"></modal-confirm>

            <div class="row misc-bottom d-flex">
                <div class="col-md-7 order-2 order-md-1">
                    <button @click="checkIfDataHasChanged('/users/' + $route.params.userId + '/evaluations/' + $route.params.evaluationId + '/groups/' + group.id + '/assessors/' + assessor.type)" class="btn btn-secondary btn-full--sm mb-0 mt-0">{{"Back" | translate}}</button>
                </div>
                <div class="col-md-17 d-flex flex-column flex-md-row justify-content-end order-1 order-md-2">
                    <button data-target="#areYouSure" data-toggle="modal" class="order-2 order-md-1 btn btn-secondary btn-full--sm mr-md-3 mb-2 mb-md-0 mt-0">{{"Default Values" | translate}}</button>
                    <div class="modal fade" id="areYouSure"  tabindex="-1" role="dialog" aria-labelledby="areYouSure" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content edit-modal">
                                <h2 class="h2 h2--modal text-center mt-3 mt-md-0">{{"Return to Default Values" | translate}}</h2>
                                <div class="px-3 pb-3">
                                    <p>{{ "Are you sure that you would like to return values to default? Once completed, the data cannot be restored." | translate }}</p>
                                    <p>
                                        <a @click="resetDefaults" href="#" data-dismiss="modal" class="btn btn-full btn-full--modal mb-2 mb-md-0">{{ "Return to Defaults" | translate }}</a>
                                    </p>
                                    <div class="row mt-2">
                                        <div class="col-md-24 modal-bottom">
                                            <a href="#" class="btn-cancel-modal" data-dismiss="modal">{{ "Cancel" | translate }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click="goToMixes()" :class="['btn btn-primary btn-full--sm d-block mb-2 mb-md-0 order-1 order-md-2', {'disabled':newFootbath}]">
                        {{ 'Add Mix' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PersonalNotes from '../assessor_components/personal-notes';
    import VolumeExisting from '../assessor_components/volume-existing';
    import Question from '../assessor_components/question';
    import ModalInfo from '@/components/misc/modal-info.vue';
    import ModalConfirm from '@/components/misc/modal-confirm.vue';
    import HygieneHeader from '../assessor_components/hygiene-header.vue';
    import CreateNewMix from './create-new-mix';
    import PhotoUpload from '@/components/misc/photoUpload';
    import Footbath from '@/libs/Classes/Footbath.js';
    import Group from '@/libs/Classes/Group.js';
    import Evaluation from '@/libs/Classes/Evaluation.js';
import { mapActions, mapState } from 'vuex';
import { GET_UPDATE_ASSESSOR } from '../../../store/types';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

    export default {
        components: {
            PersonalNotes,
            VolumeExisting,
            CreateNewMix,
            ModalInfo,
            ModalConfirm,
            Question,
            PhotoUpload,
            HygieneHeader,
            CurrencyInput
        },
        data() {
            return {
                default_footbath: {
                    data: {
                        avoid_stepping: null,
                        bypass_footbath: null,
                        clean_path: null,
                        cow_passes_bath: null,
                        floor_deteriorated: null,
                        labor_cost: 50,
                        ph: null,
                        properly_located: null,
                        stones_bottom: null
                    },
                    id: null,
                    name: null,
                    personal_notes: null,
                    volume: {
                        measurements_length: null,
                        measurements_width: null,
                        measurements_step_in_depth: null,
                        measurements_step_out_depth: null,
                        measurements_volume: null
                    },
                },
                assessor: null,
                confirmFootbathModal: false,
                errorMessage: false,
                // evaluation: null,
                evaluationClass: null,
                footbath: null,
                footbathClass: null,
                group: null,
                groupClass: null,
                newFootbath:false,
                percent3_4: null,
                propFunction: null,
                scoresFilledOut: false,
                selectedFootbathId: null,
                showModal: false,
                successMessage: false,
                suggestedFrequency: null,
                willFootbathChange: false
            }
        },
        methods: {
            ...mapActions('assessors', [GET_UPDATE_ASSESSOR]),

            revertFootbath() {
                this.selectedFootbathId = this.footbath.id;
                this.willFootbathChange = false;
            },
            checkIfDataHasChanged(url) {
                let clonedDefault = this.getClonedDefaultFootbath();
                clonedDefault.date_created = this.footbath.date_created;
                //if current footbath doesn't equal footbath in state or current footbath is new and doesn't equal default footbath
                //including this extra conditional because the function breaks if a footbath hasn't been created yet.
                if ( this.assessor.footbaths !== undefined ) {
                    if ( ( this.getCurrentFootbathInState() !== undefined && ! _.isEqual(this.footbath, this.getCurrentFootbathInState()) ) || ( this.getCurrentFootbathInState() === undefined && this.newFootbath === true && !_.isEqual(this.footbath, clonedDefault) ) ) {
                        this.url = url;
                        this.showModal = true;
                    } else {
                        this.$router.push(url);
                    }
                } else {
                    if( !_.isEqual(this.footbath, clonedDefault) ) {
                        this.url = url;
                        this.showModal = true;
                    } else {
                        this.$router.push(url);
                    }
                }
            },
            getFirstAssessorTypeByGroup: function(group) {
                return this.evaluation.order.find(assessorType => {
                    return group.assessor_list.find(groupAssessorType => {
                        return groupAssessorType == assessorType;
                    });
                });
            },
            propNewFootbath() {
                this.footbath = _.cloneDeep(this.default_footbath);
                this.footbath.id = this.$generateId();
                this.footbath.date_created = new Date().getTime();
                this.footbathClass = new Footbath(this, this.evaluationClass, this.groupClass, this.assessor, this.footbath);
                this.footbath.volume.measurements_length = 0, //this.footbathClass.getIdealLength();
                this.footbath.volume.measurements_width = 0, //this.footbathClass.getIdealWidth();
                this.footbath.volume.measurements_step_in_depth = 0, //this.footbathClass.getIdealStepInDepth();
                this.footbath.volume.measurements_step_out_depth = 0, //this.footbathClass.getIdealStepOutDepth();
                this.newFootbath = true;
                this.selectedFootbathId = this.footbath.id;
                this.showModal = false;
                this.$validator.reset();
                this.$scrollTop();
            },
            createNewFootbath() {
                if(this.assessor.footbaths) {
                    if ( this.getCurrentFootbathInState() !== undefined && ! _.isEqual(this.footbath, this.getCurrentFootbathInState()) ) {
                        this.propFunction = this.propNewFootbath;
                        this.showModal = true;
                        return false;
                    }
                }
                this.propNewFootbath();
            },
            saveFootbath() {
                // Prevent save if Footbath has not been given a name
                this.$validator.validateAll()
                    .then((result) => {
                        if(result === true) {

                            if (!this.assessor.footbaths) {
                                this.assessor.footbaths = {};
                            }

                            if ( Array.isArray(this.assessor.footbaths) ) {

                                let footbathExists = this.assessor.footbaths.findIndex(footbath => {
                                    return footbath.id === this.footbath.id;
                                });

                                if(footbathExists !== -1) {
                                    this.assessor.footbaths[footbathExists] = _.merge(this.assessor.footbaths[footbathExists], this.footbath);
                                } else {
                                    this.assessor.footbaths.push(this.footbath);
                                }

                            } else {
                                this.assessor.footbaths[this.footbath.id] = this.footbath;
                            }

                            this.newFootbath = false;

                            this.successMessage = true;

                            // this.$store.dispatch('setData', {
                            //     currentVueInstance: this,
                            //     endpointUrl: "users/" + this.$route.params.userId + "/evaluations/" + this.$route.params.evaluationId + "/groups/" + this.$route.params.groupId + "/assessors/" + this.assessor.id + "/footbaths/" + this.footbath.id,
                            //     objectBeingUpdated: this.footbath
                            // });

                            this[GET_UPDATE_ASSESSOR]({
                                evaluationId: this.evaluation._id,
                                groupId: this.group.id,
                                assessor:this.assessor
                            })

                            setTimeout(() => {
                                this.successMessage = false;
                            }, 5000);


                        } else {
                            this.errorMessage = true;
                            setTimeout(() => {
                                this.errorMessage = false;
                            }, 5000)
                        }
                    })
            },
            confirmGoToMixes() {
                let footbathId = this.footbath.id;

                if ( this.newFootbath ) {
                    let existingFootbath = this.assessor.footbaths[this.assessor.footbaths.length - 1];
                    footbathId = existingFootbath.id;
                }

                this.$router.push('/users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId + '/groups/' + this.$route.params.groupId + '/assessors/' + this.$route.params.assessorType + '/footbaths/existing/' + footbathId + '/mixes/');
            },
            // cancelGoToMixes() {
            //     this.$validator.validateAll();
            // },
            goToMixes() {
                /**
                 * Check to see if this footbath is different than the one in state.
                 * If it is different, we want to warn the user of unsaved changes
                 */
                let footbathId = this.footbath.id;

                let currentFootbathInState;
                if(Array.isArray(currentFootbathInState)) {
                    currentFootbathInState = this.assessor.footbaths.find(footbath => footbath.id === this.footbath.id);
                } else {
                    currentFootbathInState = this.assessor.footbaths[this.footbath.id];
                }

                if ( this.getCurrentFootbathInState() !== undefined && ! _.isEqual(this.footbath, this.getCurrentFootbathInState()) ) {
                    this.url = '/users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId + '/groups/' + this.$route.params.groupId + '/assessors/' + this.$route.params.assessorType + '/footbaths/existing/' + footbathId + '/mixes/';
                    this.showModal = true;
                } else {
                    this.confirmGoToMixes();
                }
            },
            resetDefaults() {
                this.footbath = _.cloneDeep(this.getClonedDefaultFootbath());
                this.footbathClass.footbath = this.footbath;
                this.$validator.reset();
            },
            getFrequencySuggested(percent3and4) {
                if (percent3and4 >= 75) {
                    this.suggestedFrequency = 7;
                } else if (percent3and4 <= 75 && percent3and4 >= 51) {
                    this.suggestedFrequency = 5;
                } else if (percent3and4 <= 50 && percent3and4 >= 25) {
                    this.suggestedFrequency = 2;
                } else {
                    this.suggestedFrequency = 0;
                }
            },
            getPercent3_4() {
                var total = this.assessor.data.hygiene_score_1 + this.assessor.data.hygiene_score_2 + this.assessor.data.hygiene_score_3 + this.assessor.data.hygiene_score_4;
                var percent_score_1 = (this.assessor.data.hygiene_score_1 / total) * 100;
                var percent_score_2 = (this.assessor.data.hygiene_score_2 / total) * 100;
                var percent_score_3 = (this.assessor.data.hygiene_score_3 / total) * 100;
                var percent_score_4 = (this.assessor.data.hygiene_score_4 / total) * 100;
                var percent_arr = [percent_score_1, percent_score_2, percent_score_3, percent_score_4];
                var new_percents = this.$store.getters.getPercentagesRoundedTo100(percent_arr, 100);

                this.percent3_4 = new_percents[2] + new_percents[3];
            },
            scoresEntered() {
                let total = this.assessor.data.hygiene_score_1 + this.assessor.data.hygiene_score_2 + this.assessor.data.hygiene_score_3 + this.assessor.data.hygiene_score_4;
                if(total !== 0) {
                    this.getPercent3_4();
                    this.getFrequencySuggested(this.percent3_4);
                    this.scoresFilledOut = true;
                } else {
                    this.scoresFilledOut = false;
                }
            },
            propChangeFootbath() {
                this.footbath = _.cloneDeep(this.assessor.footbaths.find(footbath => footbath.id === this.selectedFootbathId));
                this.footbathClass = new Footbath(this, this.evaluationClass, this.groupClass, this.assessor, this.footbath);
                this.newFootbath = false;
                this.showModal = false;
            },
            getCurrentFootbathInState() {
                if(Array.isArray(this.assessor.footbaths)) {
                    return this.assessor.footbaths.find(footbath => footbath.id === this.footbath.id);
                } else {
                    return this.assessor.footbaths[this.footbath.id];
                }
            },
            getDate(timestamp) {
                let date = new Date(timestamp);
                var monthNames = [
                    this.$t("Jan."), this.$t("Feb."), this.$t("Mar."),
                    this.$t("Apr."), this.$t("May"), this.$t("Jun."),
                    this.$t("Jul."), this.$t("Aug."), this.$t("Sep."),
                    this.$t("Oct."), this.$t("Nov."), this.$t("Dec.")
                ];
                var day = date.getDate();
                var monthIndex = date.getMonth();
                var year = date.getFullYear();
                return day + ' ' + monthNames[monthIndex] + ' ' + year;
            },
            getClonedDefaultFootbath() {
                let clonedDefault = _.cloneDeep(this.default_footbath);
                clonedDefault.id = this.footbath.id;
                clonedDefault.volume = {
                    measurements_length: this.footbathClass.idealLength,
                    measurements_width: this.footbathClass.idealWidth,
                    measurements_step_in_depth: this.footbathClass.idealStepInDepth,
                    measurements_step_out_depth: this.footbathClass.idealStepOutDepth,
                    measurements_volume: null
                }
                return clonedDefault;
            },
            changeFootbath() {
                let clonedDefault = this.getClonedDefaultFootbath();
                clonedDefault.date_created = this.footbath.date_created;

                if ( ( this.getCurrentFootbathInState() && ! _.isEqual(this.footbath, this.getCurrentFootbathInState()) ) ||  ( this.getCurrentFootbathInState() === undefined && this.newFootbath === true && !_.isEqual(this.footbath, clonedDefault) ) ) {
                    this.willFootbathChange = true;
                    this.propFunction = this.propChangeFootbath;
                    this.showModal = true;
                } else {
                    this.footbath = _.cloneDeep(this.assessor.footbaths[this.selectedFootbathId]);
                    this.footbathClass = new Footbath(this, this.evaluationClass, this.groupClass, this.assessor, this.footbath);
                    this.newFootbath = false;
                }
            },
            initializeData() {
                this.isNewFootbath = false;
                // this.evaluation = this.$store.getters.getData("users/" + this.$route.params.userId + "/evaluations/" + this.$route.params.evaluationId);
                // this.group = this.$store.getters.getData("users/" + this.$route.params.userId + "/evaluations/" + this.$route.params.evaluationId + "/groups/" + this.$route.params.groupId);
                this.group = this.evaluation.groups[this.$route.params.groupId]
                this.evaluationClass = new Evaluation(this, this.evaluation);
                this.groupClass = new Evaluation(this, this.evaluationClass, this.group);

                if ( Array.isArray(this.group.assessors) ) {
                    this.assessor = this.group.assessors.find(assessor => {
                        return Number(assessor.type) === 1;
                    });
                } else {
                    for (let assessorId in this.group.assessors) {
                        if(Number(this.group.assessors[assessorId].type) === 1) {
                            this.assessor = this.group.assessors[assessorId];
                        };
                    }
                }

                if (this.assessor.footbaths === undefined || this.assessor.footbaths.length === 0) {
                    this.createNewFootbath();
                } else {

                    if ( Array.isArray(this.assessor.footbaths) ) {
                        if(this.$route.query.footbathId === undefined) {
                            this.footbath = _.cloneDeep(this.assessor.footbaths[0]);
                        } else {
                            this.footbath = this.assessor.footbaths.find(footbath => footbath.id === this.$route.query.footbathId);
                        }
                    } else {
                        if(this.$route.query.footbathId === undefined) {
                            for ( let footbathId in this.assessor.footbaths ) {
                                this.footbath = this.assessor.footbaths[footbathId];
                                break;
                            }
                        } else {
                            this.footbath = this.assessor.footbaths[this.$route.query.footbathId];
                        }
                    }
                    this.footbathClass = new Footbath(this, this.evaluationClass, this.groupClass, this.assessor, this.footbath);
                }
                this.selectedFootbathId = this.footbath.id;
            }
        },
        watch: {
            '$route' (to, from) {
                this.initializeData();
                this.$validator.reset();
            }
        },
        computed: {
            ...mapState({
                evaluation: state => state.evaluations.evaluation
            }),
            footbathsCount: {
                cache: false,
                get: function () {
                    return Object.keys(this.assessor.footbaths).length;
                }
            },
            formattedFootbaths: {
                cache: false,
                get: function () {
                    if ( this.assessor.footbaths ) {
                        let footbaths = _.cloneDeep(this.assessor.footbaths);

                        if ( this.newFootbath ) {
                            let currentFootbath = _.cloneDeep(this.footbath);
                            currentFootbath.name = this.$t("New Footbath");
                            footbaths[currentFootbath.id] = currentFootbath;
                        }

                        return footbaths;
                    }

                    return {};
                }
            },
            nextGroup: function() {
                let foundCurrentGroup = false;
                // return the next group id if it exists

                if ( Array.isArray(this.evaluation.groups) ) {
                    return this.evaluation.groups.find(group => {
                        if (foundCurrentGroup === false) {
                            if (group.id === this.$route.params.groupId) {
                                foundCurrentGroup = true;
                            }
                            return false;
                        }
                        return true;
                    });
                } else {
                    for (let groupId in this.evaluation.groups) {
                        if (foundCurrentGroup === false) {
                            if ( this.$route.params.groupId === groupId ) {
                                foundCurrentGroup = true;
                            }
                            return false;
                        }

                        return false;

                    }
                }


            },
            previousGroup: function() {
                let foundCurrentGroup = false;
                let previousGroup = undefined;
                // return the next group id if it exists

                if ( Array.isArray(this.evaluation.groups) ) {

                    for (let i = 0; i < this.evaluation.groups.length; i++) {
                        if (this.evaluation.groups[i].id === this.$route.params.groupId) {
                            return previousGroup;
                        }

                        previousGroup = this.evaluation.groups[i];
                    }

                } else {
                    for (let groupId in this.evaluation.groups) {
                        if (this.evaluation.groups[groupId].id === this.$route.params.groupId) {
                            return previousGroup;
                        }

                        previousGroup = this.evaluation.groups[groupId];
                    }
                }

                return undefined;
            }
        },
        created() {
            this.initializeData();
        },
        beforeMount() {
            this.scoresEntered();
        }
    }
</script>
