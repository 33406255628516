<template>
  <div
    class="transition-main container main-container main-container--hygiene view"
  >
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ 'Tell Us About Your Transition Area and Procedures' | translate }}
        </h2>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="tell-us" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Transition' | translate }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Transition Areas, click the link to learn more from'
                  | translate
              }}
              <a
                :href="
                  $t('https://library.zinprofirststep.com/transition-heifers/')
                "
                target="_blank"
                @click="$goToLameness($t('/transition-heifers/'), $event)"
              >
                {{ 'FirstStep Resources' | translate }} </a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ 'Cancel' | translate }}
              </a>
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-lg-4" />

    <div class="row" v-if="assessor.data">
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Type of Animal' | translate }}</label>
        <select
          v-model="group.data.type_of_animal"
          class="form-control"
          disabled
        >
          <option :value="undefined" hidden selected disabled>
            {{ 'Not selected' | translate }}
          </option>
          <option
            v-for="type in typesOfAnimal"
            :key="type.value"
            :value="type.value"
          >
            {{ type.label | translate }}
          </option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1">{{ 'Group/Pen Size' | translate }}</label>
          <currency-input
            v-model="group.data.size"
            :class="'form-control'"
            disabled
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1"
            >{{ 'Total Bedded Pack Lying Space (' | translate
            }}{{
              $t(
                $getEquivalentUnit(evaluation.data.measurements, 'sq m')
              ).replace('sq m', 'm²')
            }})</label
          >
          <currency-input
            v-model="assessor.data.lying_space"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1">{{
            'Water Troughs per Group' | translate
          }}</label>
          <currency-input
            v-model="assessor.data.troughs_per_group"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not traslated whole block -->
        <div class="form-group">
          <label class="mb-1"
            >{{ 'Total Drinking Space' | translate }} ({{
              $getEquivalentUnit(evaluation.data.measurements, 'm') | translate
            }})</label
          >
          <currency-input
            v-model="assessor.data.drinking_space"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1">{{ 'Total Freestalls' | translate }}</label>
          <currency-input
            v-model="assessor.data.stalls_per_cow"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{
          'Feeding Space Divider Type' | translate
        }}</label>
        <select v-model="assessor.data.bunk_divider_type" class="form-control">
          <option :value="null" hidden selected disabled>{{
            'Select' | translate
          }}</option>
          <option value="no-bunk">{{ 'Feed rail' | translate }}</option>
          <option value="slant-bar">{{
            'Slant bar dividers' | translate
          }}</option>
          <option value="headlocks">{{ 'Headlocks' | translate }}</option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1"
            >{{ 'Total Feeding Space' | translate }} ({{
              $getEquivalentUnit(evaluation.data.measurements, 'm') | translate
            }})</label
          >
          <currency-input
            v-model="assessor.data.feed_bunk_space"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not traslated whole block -->
        <div class="form-group">
          <label class="mb-1">{{ 'Total Headlocks' | translate }}</label>
          <currency-input
            v-model="assessor.data.total_headlocks"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <template v-if="typeIsCloseUp">
        <div class="col-lg-8 col-md-12">
          <!-- b4dPotato not traslated whole block -->
          <label class="mb-1">{{ 'Maternity Pen?' | translate }}</label>
          <select v-model="assessor.data.is_maternity_pen" class="form-control">
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>
      <template v-if="typeIsCloseUp">
        <div class="col-lg-8 col-md-12">
          <!-- b4dPotato not traslated whole block -->
          <div class="form-group">
            <label class="mb-1">
              {{ 'Total Maternity Pen Space' | translate }}
              ({{
                $t(
                  $getEquivalentUnit(evaluation.data.measurements, 'sq m')
                ).replace('sq m', 'm²')
              }})
            </label>
            <currency-input
              v-model="assessor.data.total_maternity_pen_space"
              :class="'form-control'"
              :disabled="!isMaternityPen"
              :options="{
                currency: 'USD',
                currencyDisplay: 'hidden',
                
              }"
            />
          </div>
        </div>
      </template>
      <template v-if="typeIsCloseUp">
        <div class="col-lg-8 col-md-12">
          <!-- b4dPotato not traslated whole block -->
          <label class="mb-1">{{ 'Maternity Undisturbed?' | translate }}</label>
          <modal-info class="d-inline">
            <template slot="modal-content">
              <h2 class="h2 pb-2 pt-3 px-3 text-center">
                {{ 'Maternity Undisturbed?' | translate }}
              </h2>
              <p class="px-3">
                {{
                  'Is the maternity pen located in a high traffic area or is rather in a calm and undisturbed environment?'
                    | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
          <select
            v-model="assessor.data.maternity_undisturbed"
            class="form-control"
          >
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>

      <template v-if="isMaternityPen">
        <div class="col-lg-8 col-md-12 d-flex justify-content-end flex-column">
          <!-- b4dPotato not traslated whole block -->
          <label class="mb-1">{{
            'Are Cows Hydrated Immediately Post Calving?' | translate
          }}</label>
          <select
            v-model="assessor.data.cows_hydrated_immediately"
            class="form-control"
          >
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>

      <template v-if="isMaternityPen">
        <div class="col-lg-8 col-md-12 d-flex justify-content-end flex-column">
          <!-- b4dPotato not traslated whole block -->
          <label class="mb-1">{{
            'Feed Available in Maternity Pen?' | translate
          }}</label>
          <select
            v-model="assessor.data.is_feed_available"
            class="form-control"
          >
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>

      <div class="col-lg-8 col-md-12 d-flex justify-content-end flex-column">
        <label class="mb-1">{{
          'Freestall Surface Cushion' | translate
        }}</label>
        <select
          v-model="assessor.data.freestall_surface_cushion"
          class="form-control"
        >
          <option :value="null" hidden selected disabled>{{
            'Select' | translate
          }}</option>
          <option value="firm-bed">{{
            'Packed or firm bed' | translate
          }}</option>
          <option value="mattress">{{
            'Cushioned mattress with thin bedding' | translate
          }}</option>
          <option value="deep-loose-bedding">{{
            'Deep loose bedding >3"/8 cm depth' | translate
          }}</option>
        </select>
      </div>

      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{
          'Freestall Occupation Area' | translate
        }}</label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="freestall-occupation-area" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Freestall Occupation Area' | translate }}
            </h2>
            <p class="px-3">
              {{
                'When lying down, cows could occupy a portion of the neighboring freestall when the freestall does not have the right configuration. How much is this overlap in most of the cows?'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>

        <select
          v-model="assessor.data.freestall_occupation_area"
          class="form-control"
        >
          <option :value="null" hidden selected disabled>{{
            'Select' | translate
          }}</option>
          <option value="large-overlap">{{
            'Large overlap (> 3 in/8 cm)' | translate
          }}</option>
          <option value="small-overlap">{{
            'Small overlap (1-3 in/3-8 cm)' | translate
          }}</option>
          <option value="no-overlap">{{ 'No overlap' | translate }}</option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{
          'Freestall Lunge Obstruction' | translate
        }}</label>
        <select
          v-model="assessor.data.freestalls_lunge_obstruction"
          class="form-control"
        >
          <option :value="null" hidden selected disabled>{{
            'Select' | translate
          }}</option>
          <option value="front-side">{{
            'Front and side lunge obstruction' | translate
          }}</option>
          <option value="front">{{
            'Front lunge obstruction only' | translate
          }}</option>
          <option value="none">{{ 'No obstructions' | translate }}</option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{
          'Heat Abatement - Freestall' | translate
        }}</label>
        <select v-model="assessor.data.heat_abatement" class="form-control">
          <option :value="null" hidden selected disabled>{{
            'Select' | translate
          }}</option>
          <option value="no-fans">{{
            'No fans or water cooling' | translate
          }}</option>
          <option value="fans">{{
            'Fans over stalls, no water cooling' | translate
          }}</option>
          <option value="fans-water">{{
            'Fans over freestalls, water cooling' | translate
          }}</option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not traslated whole block -->
        <label class="mb-1">
          {{ 'Avg Air Speed at Cow Level' | translate }}
        </label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="avg-air-speed" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Avg Air Speed at Cow Level' | translate }}
            </h2>
            <p class="px-3">
              {{
                'On average, how much air speed is found at cow level when measured with an anemometer?'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>

        <select v-model="assessor.data.air_speed_all_cows" class="form-control">
          <option :value="undefined" hidden selected disabled>
            {{ 'Select' | translate }}
          </option>
          <option
            v-for="value in airSpeedAllCowsValues"
            :key="value"
            :value="value"
          >
            {{
              comparativeValues.airSpeedAllCows(
                value,
                evaluation.data.measurements,
                $t
              ) | translate
            }}
          </option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not traslated whole block -->
        <label class="mb-1">{{ 'Avg Air Volume per Cow' | translate }}</label>

        <!-- MODAL INFO COMPONENT -->
        <modal-info value="avg-air-volume" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Avg Air Volume per Cow' | translate }}
            </h2>
            <p class="px-3">
              {{
                'What is the average volume per cow? (Total fan capacity (cmh or cfm) / number of cows)'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ 'Cancel' | translate }}
              </a>
            </div>
          </template>
        </modal-info>

        <select v-model="assessor.data.air_speed_cow" class="form-control">
          <option :value="undefined" hidden selected disabled>
            {{ 'Select' | translate }}
          </option>
          <option
            v-for="value in airSpeedCowsValues"
            :key="value"
            :value="value"
          >
            {{
              comparativeValues.airSpeedCow(
                value,
                evaluation.data.measurements,
                $t
              ) | translate
            }}
          </option>
        </select>
      </div>
    </div>

    <hr class="mt-2 mb-4 mt-md-3" />
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 mb-3 mb-sm-2">
          {{ 'Feeding & Body Condition' | translate }}
        </h2>
      </div>
    </div>

    <div class="row" v-if="assessor.data">
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">
          {{
            typeIsFresh
              ? '% Cows Rumen Fill < 3'
              : '% Cows Rumen Fill < 4' | translate
          }}
        </label>
        <modal-info value="cows-rumen-fill" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{
                typeIsFresh
                  ? '% Cows Rumen Fill < 3'
                  : '% Cows Rumen Fill < 4' | translate
              }}
            </h2>
            <p class="px-3">
              {{
                typeIsFresh
                  ? 'Slight dip visible in left flank, after last rib. Skin under lumbar vertebrae runs vertically down for one hand’s width before bulging out slightly. Skin fold from hook bone is hardly visible. This is the desired score for milking cows having sufficient intakes.'
                  : 'No dip is visible in left flank, after last rib. Skin under lumbar vertebrae curves outwards. Skin fold from hook bone is not visible. This is the correct score for milking cows at the end of lactation and through the dry period. It is the target minimum score for pre-calvers.'
                    | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model="assessor.data.cows_rumen_fill"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ '% of Cows with Manure Score < 3' | translate }}</label>
        <modal-info value="cows-with-manure-score" class="d-inline" fullscreen>
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ $t(' ') }}
            </h2>
            <div class="bp-table">
              <div class="bp-table--prev">
                <span @click="tablePrev">&nbsp;&lt;&nbsp;</span>
              </div>
              <div class="bp-table--content">
                <table class="table-bedded-pack-ibutton">
                  <tbody>
                    <tr>
                      <td class="bp-table-cell">
                        <b>{{ $t('Score 1') }}</b>
                      </td>
                      <td class="bp-table-cell">
                        <b>{{ $t('Score 2') }}</b>
                      </td>
                      <td class="bp-table-cell">
                        <b>{{ $t('Score 3') }}</b>
                      </td>
                      <td class="bp-table-cell">
                        <b>{{ $t('Score 4') }}</b>
                      </td>
                      <td class="bp-table-cell">
                        <b>{{ $t('Score 5') }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="@/assets/img/ibutton/cows_with_manure_score/image1.jpeg"
                          class="bp-table-image"
                        />
                      </td>
                      <td>
                        <img
                          src="@/assets/img/ibutton/cows_with_manure_score/image2.jpeg"
                          class="bp-table-image"
                        />
                      </td>
                      <td>
                        <img
                          src="@/assets/img/ibutton/cows_with_manure_score/image3.jpeg"
                          class="bp-table-image"
                        />
                      </td>
                      <td>
                        <img
                          src="@/assets/img/ibutton/cows_with_manure_score/image4.jpeg"
                          class="bp-table-image"
                        />
                      </td>
                      <td>
                        <img
                          src="@/assets/img/ibutton/cows_with_manure_score/image5.jpeg"
                          class="bp-table-image"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span></span></p>
                        <p>
                          <span>{{
                            $t(
                              'Feces are watery thin and not truly recognizable as feces.'
                            )
                          }}</span>
                        </p>
                        <p><span></span></p>
                      </td>
                      <td>
                        <p><span></span></p>
                        <p>
                          <span>{{
                            $t(
                              'Feces are thin custard-like. Fecal structure can be recognized. At dropping, they splash wide out on the floor.'
                            )
                          }}</span>
                        </p>
                        <p><span></span></p>
                      </td>
                      <td>
                        <p><span></span></p>
                        <p>
                          <span>{{
                            $t(
                              'Feces are thick and custard-like. They make a light plopping sound when being dropped on the floor. They make a well-circumscribed pad that spreads and has a thickness of about 2 cm (1").'
                            )
                          }}</span>
                        </p>
                        <p><span></span></p>
                      </td>
                      <td>
                        <p><span></span></p>
                        <p>
                          <span>{{
                            $t(
                              'Feces is stiff. They make a heavy plopping sound when being dropped. They make a well-circumscribed pad that piles in rings and spreads out very little.'
                            )
                          }}</span>
                        </p>
                        <p><span></span></p>
                      </td>
                      <td>
                        <p><span></span></p>
                        <p>
                          <span>{{
                            $t(
                              'Stiff feces in balls, looks like horse feces. A boot sole profile is left when stepped in.'
                            )
                          }}</span>
                        </p>
                        <p><span></span></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bp-table--next">
                <span @click="tableNext"> > </span>
              </div>
            </div>

            <div class="row modal-bottom mt-3 pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model="assessor.data.cows_manure_score"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1">{{ '% of Cows with BCS ≤2.5 or ≥3.75, 1st lact' | translate }}</label>
          <currency-input
            v-model="assessor.data.body_condition_score_1"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1">{{ '% of Cows with BCS ≤2.5 or ≥3.75, ≥2nd lact' | translate }}</label>
          <currency-input
            v-model="assessor.data.body_condition_score_2"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Refusal %' | translate }}</label>
        <modal-info value="refusal" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Refusal %' | translate }}
            </h2>
            <p class="px-3">
              {{
                'Refusal % is percentage feed left over per day.' | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                {{ 'Cancel' | translate }}
              </a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model="assessor.data.refusal"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'TMR Sorting' | translate }}</label>
        <modal-info value="tmr-sorting" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ 'TMR Sorting' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{
                'Difference in % points in Top Penn State Screen between offered and refusals'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
        <select v-model="assessor.data.tmr_sorting" class="form-control">
          <option :value="null" hidden selected disabled>{{
            'Select' | translate
          }}</option>
          <option value="more-10">{{ '≥10' | translate }}</option>
          <option value="5-9">{{ '5-9' | translate }}</option>
          <option value="less-5">{{ '≤5' | translate }}</option>
        </select>
      </div>
      <template v-if="typeIsFresh">
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{ 'Feed Available after Milking?' | translate }}</label>
          <select
            v-model="assessor.data.is_feed_available_af_milking"
            class="form-control"
          >
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>
      <template v-if="typeIsFresh">
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{ 'Checks Done After Milking?' | translate }}</label>
          <select
            v-model="assessor.data.checks_af_milking"
            class="form-control"
          >
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>
      <template v-if="typeIsFresh">
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{ 'Time Spent Checking Fresh Cows (hr)' | translate }}</label>
          <modal-info value="time-spent-checking-fresh-cows" class="d-inline">
            <template slot="modal-content">
              <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                {{ 'Time Spent Checking Fresh Cows (hr)' | translate }}
              </h2>
              <p class="px-3 mb-2">
                {{
                  'How much time is used everyday to check fresh cows? This is the time fresh cow checks are being performed on the fresh pen (it should account for time cows are locked up + any extra time related work is performed in the pen).'
                    | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
          <currency-input
            v-model="assessor.data.time_checking_hr"
            :class="'form-control'"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              
            }"
          />
        </div>
      </template>
      <template v-if="typeIsFresh">
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{ 'Check Appetite?' | translate }}</label>
          <select
            v-model="assessor.data.is_appetite_checked"
            class="form-control"
          >
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option value="yes">{{ 'Yes' | translate }}</option>
            <option value="no">{{ 'No' | translate }}</option>
          </select>
        </div>
      </template>
      <template v-if="typeIsFresh">
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{ 'Screening Method Used' | translate }}</label>
          <select v-model="assessor.data.screening_method" class="form-control">
            <option :value="undefined" hidden selected disabled>{{
              'Select' | translate
            }}</option>
            <option :value="transitionConstants['screening_method_min']">{{
              'No program' | translate
            }}</option>
            <option :value="transitionConstants['screening_method_mid']">{{
              'Routine temping, multiple staff' | translate
            }}</option>
            <option :value="transitionConstants['screening_method_mid2']">{{
              'Daily milk wt, rumination activity' | translate
            }}</option>
            <option :value="transitionConstants['screening_method_max']">{{
              'Dedicated staff, appetite, front & rear visual exam' | translate
            }}</option>
          </select>
        </div>
      </template>
    </div>

    <!-- COMMON -->

    <hr class="mt-2 mb-4 mt-md-3" />

    <div class="row my-0" v-if="assessor">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4"
    >
      <photoUpload :assessorId="2" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div
        class="col-md-12 flex-column flex-lg-row justify-content-md-between justify-content-lg-start d-flex"
      >
        <a
          class="btn btn-secondary btn-full--sm mb-2 mt-md-0"
          href="javascript:history.go(-1)"
          >{{ 'Back' | translate }}</a
        >
        <router-link
          :to="
            '/users/' +
              $route.params.userId +
              '/evaluations/' +
              $route.params.evaluationId +
              '/groups/' +
              $route.params.groupId +
              '/assessors/' +
              $route.params.assessorType
          "
          class="btn btn-primary btn-full--sm mb-2 mt-md-0 ml-lg-4"
        >
          {{ 'Setup' | translate }}
        </router-link>
      </div>
      <div
        class="col-md-12 flex-column-reverse flex-lg-row-reverse justify-content-md-between justify-content-lg-start d-flex"
      >
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm mb-md-2"
        >
          {{ 'Reports' | translate }}
        </router-link>
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user._id]"
          class="btn btn-primary btn-full--sm pull-right mb-md-2"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
        <router-link
          :to="
            '/users/' +
              $route.params.userId +
              '/evaluations/' +
              $route.params.evaluationId +
              '/groups/' +
              $route.params.groupId +
              '/assessors/' +
              $route.params.assessorType +
              '/summary'
          "
          class="btn btn-primary btn-full--sm mr-lg-4 mb-2"
        >
          {{ 'Summary' | translate }}
        </router-link>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="requestReportLoader"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
/** Assessor Components */
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import HygieneHeader from './assessor_components/hygiene-header.vue';

/** Components */
import ModalInfo from '@/components/misc/modal-info.vue';
import ModalWarning from '@/components/misc/modal-warning';
import PhotoUpload from '@/components/misc/photoUpload';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

/** Classes */
import {
  comparativeValues,
  transitionConstants,
} from '@/libs/Classes/constants/transition';
import requestReportMixin from '@/mixins/requestReport.js';

/** Libs */
import convert from 'convert-units';
import { mapState } from 'vuex';

/** Component Variables */
const typesOfAnimal = [
  {
    value: 'close-up',
    label: 'Close-up',
  },
  {
    value: 'far-off',
    label: 'Far-off',
  },
  {
    value: 'fresh',
    label: 'Fresh',
  },
  {
    value: 'lactating',
    label: 'Lactating',
  },
  {
    value: 'heifers-first-lactation',
    label: 'Heifers (First Lactation)',
  },
  {
    value: 'heifers-growing',
    label: 'Heifers (Growing)',
  },
  {
    value: 'mixed',
    label: 'Mixed',
  },
];
const airSpeedAllCowsValues = ['no-vent', 'less-5', '5-8', 'more-5'];
const airSpeedCowsValues = ['no-vent', 'less-300', '300-1000', 'more-1000'];

export default {
  mixins: [requestReportMixin],
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    ModalWarning,
    CurrencyInput,
  },

  props: ['assessor', 'group', 'evaluation'],

  data() {
    return {
      comparativeValues,
      transitionConstants,

      typesOfAnimal,
      airSpeedAllCowsValues,
      airSpeedCowsValues,
    };
  },

  computed: {
    // user() {
    //   return this.$store.getters.getData('user') || null;
    // },

    ...mapState({
      user: state => state.users.user,
    }),

    typeIsCloseUp() {
      return this.group.data.type_of_animal === 'close-up';
    },

    typeIsFresh() {
      return this.group.data.type_of_animal === 'fresh';
    },

    isMaternityPen() {
      return (
        this.assessor.data.is_maternity_pen &&
        this.assessor.data.is_maternity_pen === 'yes'
      );
    },
  },

  watch: {
    'assessor.data.is_maternity_pen'() {
      if (this.isMaternityPen) {
        this.assessor.data.total_maternity_pen_space = '';
      }
    },
  },

  methods: {
    tableGetEl() {
      return this.$el.querySelector('.bp-table--content');
    },

    tableGetCellWidth() {
      return this.$el.querySelector('.bp-table-cell').clientWidth;
    },

    tableGetPosition() {
      return this.tableGetEl().scrollLeft;
    },

    tableGetActiveSlideN() {
      return Math.round(this.tableGetPosition() / this.tableGetCellWidth());
    },

    tableScrollToPosition(position) {
      this.tableGetEl().scroll({
        left: position,
        behavior: 'smooth',
      });
    },

    tableSlidesNumber() {
      return this.$el.querySelectorAll('.bp-table-cell').length;
    },

    tableNext() {
      let slide = this.tableGetActiveSlideN() + 1;
      if (slide >= this.tableSlidesNumber()) {
        slide = 0;
      }
      this.tableScrollToPosition(slide * (this.tableGetCellWidth() + 1));
    },

    tablePrev() {
      let slide = this.tableGetActiveSlideN() - 1;
      if (slide < 0) {
        slide = this.tableSlidesNumber();
      }
      this.tableScrollToPosition(slide * (this.tableGetCellWidth() + 1));
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bedded-pack-ibutton {
  table-layout: fixed;
  margin: 0 4%;
  td {
    border: 1px solid black;
    width: 20%;
    text-align: center;
    //width:100%;
  }
}

.bp-table-wrapper {
  &--content {
    overflow: auto;
    .table-bedded-pack-ibutton {
      td {
        @media (max-width: 767px) {
          min-width: 240px;
        }
      }
    }
  }
}
.table-bedded-pack-ibutton-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.bp-table-image {
  width: 200px;
  height: 250px;
}
@media (min-width: 768px) and (max-width: 1100px) {
  .bp-table-image {
    width: 150px;
    height: 188px;
  }
}
@media (min-width: 768px) {
  .bp-table {
    display: flex;
    justify-content: center;
    overflow: auto;
    &--next,
    &--prev {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .bp-table {
    display: flex;
    align-items: center;
    &--next,
    &--prev {
      span {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding: 7px;
        cursor: pointer;
        &.inactive {
          opacity: 0.5;
        }
      }
    }
    &--content {
      overflow: auto;
      td {
        min-width: 261px;
      }
    }
  }
}
</style>
