var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card--measurements card--measurements--small"},[_c('div',{staticClass:"row card--measurements__first-row"},[_c('div',{staticClass:"col-md-24 measurements_heading"},[_c('p',{staticClass:"p--measurements-heading mb-0 d-inline-block"},[_vm._v(" "+_vm._s(_vm._f("translate")('Measurements'))+" ")])])]),(_vm.assessor.data.summer_ventilation_system === 'cross')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$t('Barn Length') + ' (' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'm')) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_barn_length),callback:function ($$v) {_vm.computed_barn_length=$$v},expression:"computed_barn_length"}})],1)])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{class:['form-group', { 'pt-4': _vm.assessor.data.summer_ventilation_system === 'tunnel' }]},[_c('label',[_vm._v(_vm._s(_vm.$t('Barn Width') + ' (' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'm')) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_barn_width),callback:function ($$v) {_vm.computed_barn_width=$$v},expression:"computed_barn_width"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('Barn Ceiling Height') + ' (' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'm')) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_ceiling_height),callback:function ($$v) {_vm.computed_ceiling_height=$$v},expression:"computed_ceiling_height"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('Inlet area') + ' (' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'sq m')) + ')'))]),_c('currency-input',{attrs:{"options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }},model:{value:(_vm.computed_inlet_area),callback:function ($$v) {_vm.computed_inlet_area=$$v},expression:"computed_inlet_area"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('Cross-Sectional Area') + ' (' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'sq m')) + ')'))]),_c('currency-input',{attrs:{"value":_vm.computed_cross_sectional_area,"readonly":"","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }}})],1)])]),(_vm.assessor.data.summer_ventilation_system === 'cross')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-24"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('Barn Volume') + ' (' + _vm.$t(_vm.$getEquivalentUnit(_vm.evaluation.data.measurements, 'cu meters')) + ')'))]),_c('currency-input',{attrs:{"value":_vm.volume,"readonly":"","options":{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }