 
const Table = require('../../Misc/Table');

export const hygiene2 = (app, report, evaluation, group, assessor) => {

    let tableObject = [];

    report.slideHeight = 2.2;

    if (assessor.getFrequencyOfManureRemoval() !== undefined) {
        tableObject.push([{
                text: app.$t('Frequency of Manure Removal') + ' ' + app.$t('(times per day)'),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFrequencyOfManureRemoval()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false

                }
            },
            {
                text: assessor.getFrequencyOfManureRemoval() < assessor.getMinimumFrequencyOfManureRemoval() ? app.$t('Frequencies less than 3 times a day lead to poor leg results.') : app.$t('Good!'),
                options: {
                    color: assessor.getFrequencyOfManureRemoval() < assessor.getMinimumFrequencyOfManureRemoval() ? '000000' : '249824',
                    align: 'left',
                    fill: 'efefef',
                    fontFace: 'Arial',
                    bold: false
                }

            },
        ]);
    }

    if ( assessor.getSummerManureRemovalMethod() !== undefined ) {
        tableObject.push([{
                text: app.$t('Summer Manure Removal Method') + ':',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getSummerManureRemovalMethod(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if ( assessor.getWinterManureRemovalMethod() !== undefined ) {
        tableObject.push([{
                text: app.$t('Winter Manure Removal Method') + ':',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getWinterManureRemovalMethod(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if ( assessor.getBeddingType() !== undefined ) {
        tableObject.push([{
                text: app.$t('Bedding Type'),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getBeddingType(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getCowsInPen() !== undefined) {
        tableObject.push([{
                text: app.$t('Are cows in the pen when manure is scraped / flushed?'),
                options: {
                    color: '000000',
                    align: 'left',
                    fontFace: 'Arial',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getCowsInPen() === 1 ? app.$t("Yes") : app.$t("No"),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getCowsInPen() === 1 ? app.$t('Coating cows feet with manure is a risk factor for infectious claw lesions.') : app.$t('Good!'),
                options: {
                    color: assessor.getCowsInPen() === 1 ? 'a40000' : '249824',
                    align: 'left',
                    fill: 'efefef',
                    fontFace: 'Arial',
                    bold: false
                }
            },
        ]);
    }

    if ( assessor.getCowsDryLot() !== undefined ) {
        tableObject.push([{
                text: app.$t('Do cows have access to a dry lot?'),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getCowsDryLot() === 1 ? app.$t("Yes") : app.$t("No"),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getCowsDryLot() === 1 ? app.$t('Good!') : app.$t('Excessive mud and manure in dirt lots may worsen cow hygiene and increase the incidence of infectious claw lesions.'),
                options: {
                    color: assessor.getCowsDryLot() === 1 ? '249824' : 'a40000',
                    align: 'left',
                    fill: 'efefef',
                    fontFace: 'Arial',
                    bold: false
                }
            },
        ]);
    }

    if ( assessor.getIsTheLotDry() !== undefined && assessor.getCowsDryLot() !== 0 ) {
        tableObject.push([{
                text: app.$t('Is the lot dry and regularly groomed?'),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheLotDry() === 1 ? app.$t("Yes") : app.$t("No"),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheLotDry() === 1 ? app.$t('Good!') : app.$t('Excessive mud and manure in dirt lots may worsen cow hygiene and increase the incidence of infectious claw lesions.'),
                options: {
                    color: assessor.getIsTheLotDry() === 1 ? '249824' : 'a40000',
                    align: 'left',
                    fill: 'efefef',
                    fontFace: 'Arial',
                    bold: false
                }
            },
        ]);
    }


    if (tableObject.length === 0) {
        return true;
    }

    let slidePage2 = report.pptx.addSlide('Slide master');
    let objHeading2 = [
        {
            text: app.$t('Question'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Answer'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Comment'),
            options: report.styleOptions.tableHeader
        }
    ]

    slidePage2.addText(
        app.$t('Hygiene/Footbath Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    );

    slidePage2.addText(
        app.$t('Your Operation'), {
            x: 0.5,
            y: report.slideHeight + .2,
            ...report.styleOptions.subheaderBlue
        }
    );

    report.slideHeight += 0.4;

    Table.create(slidePage2, objHeading2, tableObject, report, group, 'Hygiene/Footbath Assessor', {
        y: report.slideHeight,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions
    });

    report.slideHeight += (0.54 * (tableObject.length + 1));

    return true;

}
