<template>
	<div class="container main-container main-container--hygiene view">
		<div class="row">
			<div class="col-lg-24">
				<h2 class="h2 d-inline d-sm-inline-block mb-4 mr-1">{{ $t('Tell Us About Your Tie Stall / Stanchion Barn') }}</h2>
				<modal-info :value="'tss'" class="d-inline">
					<template slot="modal-content">
						<h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Tie Stall/Stanchion') }}</h2>
						<p class="px-3">{{'For more information about Tie Stall/Stanchions, click the link to learn more from' | translate}}
							<a :href="$t('https://library.zinprofirststep.com/tie-stall/')" @click="$goToLameness($t('/tie-stall/'), $event);" target="_blank">{{'FirstStep Resources' | translate}}</a>.
						</p>
						<div class="row modal-bottom pb-3">
							<a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
						</div>
					</template>
				</modal-info>
			</div>
		</div>

		<hr class="mb-4 mt-sm-0">

		<div class="row">
			<div :class="top_column">
				<label class="mb-1">{{ $t('Average Cow Size') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements,'kg')) + ')' }}</label>
				<input type="text" :value="groupClass.getCowSizeLabel()" class="form-control" readonly>
			</div>

			<div :class="top_column">
				<label class="mb-1">{{ $t('Stall Surface') }}</label>
				<select v-model="assessor.data.stall_surface" class="form-control">
					<option disabled :value="null">{{ $t("Select") }}</option>
					<option value="concrete">{{ $t('Concrete') }}</option>
					<option value="foam_mattress">{{ $t('Foam Mattress') }}</option>
					<option value="manure_solids">{{ $t('Manure Solids') }}</option>
					<option value="mats">{{ $t('Mats') }}</option>
					<option value="rubber_mattress">{{ $t('Rubber Mattress') }}</option>
					<option value="sand">{{ $t('Sand') }}</option>
					<option value="sawdust">{{ $t('Sawdust') }}</option>
					<option value="straw">{{ $t('Straw') }}</option>
					<option value="water_bed">{{ $t('Waterbed') }}</option>
					<option value="other">{{ $t('Other') }}</option>
				</select>
			</div>

			<div v-show="assessor.data.stall_surface !== 'manure_solids' && assessor.data.stall_surface !== 'other' && assessor.data.stall_surface !== 'mats'" class="col-lg-8">
				<label class="mb-1">{{ $t('Bedding Depth (') + $t($getEquivalentUnit(evaluation.data.measurements,'cm')) + ')' }}</label>
				<currency-input
					v-model="converted_bedding_depth"
					:options="{
						currency: 'USD',
						currencyDisplay: 'hidden',
						precision: 0,
					}"
				/>
			</div>
		</div>

		<hr class="mt-lg-4">

		<div class="row mx-0 mb-3">
			<div class="col-24 pl-0">
				<h2 class="h2 mb-lg-4">{{ $t('Enter Tie Stall/Stanchion Dimensions') + " (" + $t($getEquivalentUnit(evaluation.data.measurements,'cm')) + ')' }}</h2>
			</div>

			<div class="col-lg-12">
                <div class="row">
					<div class="col-24 pl-0 pr-1">
						<label :class="['mb-0 mr-1', {'text-danger':(converted_stall_length < tiestallstanchion.getIdealStallLength() - rangeIndex || converted_stall_length > tiestallstanchion.getIdealStallLength() + rangeIndex) && converted_stall_length !== null}]">
							{{ $t("Stall Length") }}
						</label>
						<modal-info :value="'stall-length-single'" class="d-inline">
							<template slot="modal-content">
								<h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
									{{ "Stall Length" | translate }}
								</h2>
								<img class="w-100 px-3 mb-3" src="/images/stallLength.jpg" alt="">
								<p class="px-3 mb-2">{{ 'The stall length is measured from the wall to the alley in a one row barn.' | translate }}</p>
								<div class="row modal-bottom pb-3">
									<a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{"Cancel" | translate}}</a>
								</div>
							</template>
						</modal-info>
						<label class="mb-0 mr-2 pull-right">{{ $t("Ideal") }}</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_stall_length"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_stall_length < tiestallstanchion.getIdealStallLength() - rangeIndex || converted_stall_length > tiestallstanchion.getIdealStallLength() + rangeIndex) && converted_stall_length !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealStallLength() }}
					</div>
				</div>

				<div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_stall_width < tiestallstanchion.getIdealStallWidth() - rangeIndex || converted_stall_width > tiestallstanchion.getIdealStallWidth() + rangeIndex) && converted_stall_width !== null}]">
							{{ $t("Stall Width") }}
						</label>
						<modal-info :value="'stall-width'" class="d-inline">
							<template slot="modal-content">
								<h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
									{{ "Stall Width" | translate }}
								</h2>
								<img class="w-100 px-3 mb-3" src="/images/stallWidth.png" alt="Stall Width">
								<p class="px-3 mb-2">{{ 'The stall width should be measured from the center of the one loop divider to the center of the neighboring loop divider (center to center).' | translate }}</p>
								<div class="row modal-bottom pb-3">
									<a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{"Cancel" | translate}}</a>
								</div>
							</template>
						</modal-info>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_stall_width"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_stall_width < tiestallstanchion.getIdealStallWidth() - rangeIndex || converted_stall_width > tiestallstanchion.getIdealStallWidth() + rangeIndex) && converted_stall_width !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealStallWidth() }}
					</div>
				</div>

				<div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_height_of_feed_curb_bunk_side < tiestallstanchion.getIdealHeightOfFeedCurbBunkSide() - rangeIndex || converted_height_of_feed_curb_bunk_side > tiestallstanchion.getIdealHeightOfFeedCurbBunkSide() + rangeIndex) && converted_height_of_feed_curb_bunk_side !== null}]">
							{{ $t("Height of Feed Curb - Bunk Side") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_height_of_feed_curb_bunk_side"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_height_of_feed_curb_bunk_side < tiestallstanchion.getIdealHeightOfFeedCurbBunkSide() - rangeIndex || converted_height_of_feed_curb_bunk_side > tiestallstanchion.getIdealHeightOfFeedCurbBunkSide() + rangeIndex) && converted_height_of_feed_curb_bunk_side !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealHeightOfFeedCurbBunkSide() }}
					</div>
				</div>

				<div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_height_of_feed_curb_stall_side > tiestallstanchion.getMaxHeightOfFeedCurbStallSide()) && converted_height_of_feed_curb_stall_side !== null}]">
							{{ $t("Height of Feed Curb - Stall Side") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_height_of_feed_curb_stall_side"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_height_of_feed_curb_stall_side > tiestallstanchion.getMaxHeightOfFeedCurbStallSide()) && converted_height_of_feed_curb_stall_side !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ $t('≤') + tiestallstanchion.getMaxHeightOfFeedCurbStallSide() }}
					</div>
				</div>

                <div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_height_of_headrail_above_stall_surface < tiestallstanchion.getIdealHeightOfHeadrailAboveStallSurface() - rangeIndex || converted_height_of_headrail_above_stall_surface > tiestallstanchion.getIdealHeightOfHeadrailAboveStallSurface() + rangeIndex) && converted_height_of_headrail_above_stall_surface !== null}]">
							{{ $t("Height of Headrail Above Stall Surface") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_height_of_headrail_above_stall_surface"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_height_of_headrail_above_stall_surface < tiestallstanchion.getIdealHeightOfHeadrailAboveStallSurface() - rangeIndex || converted_height_of_headrail_above_stall_surface > tiestallstanchion.getIdealHeightOfHeadrailAboveStallSurface() + rangeIndex) && converted_height_of_headrail_above_stall_surface !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealHeightOfHeadrailAboveStallSurface() }}
					</div>
				</div>

                <div class="row mb-3 mb-lg-0">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_length_of_chain < tiestallstanchion.getIdealLengthOfChain() - rangeIndex || converted_length_of_chain > tiestallstanchion.getIdealLengthOfChain() + rangeIndex) && converted_length_of_chain !== null}]">
							{{ $t("Length of Chain") }}
						</label>
					</div>
					<div class="col-20 col-md-21  pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_length_of_chain"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_length_of_chain < tiestallstanchion.getIdealLengthOfChain() - rangeIndex || converted_length_of_chain > tiestallstanchion.getIdealLengthOfChain() + rangeIndex) && converted_length_of_chain !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealLengthOfChain() }}
					</div>
				</div>
            </div>
            <!-- end left column -->

            <!-- right column -->
			<div class="offset-lg-1 col-lg-11">
                <div class="row">
					<div class="col-24 pl-0 pr-1">
						<label :class="['mb-0 mr-1', {'text-danger':(converted_height_of_drinking_cup_floor_above_stall_surface < tiestallstanchion.getIdealHeightOfDrinkingCupFloorAboveStallSurface() - rangeIndex || converted_height_of_drinking_cup_floor_above_stall_surface > tiestallstanchion.getIdealHeightOfDrinkingCupFloorAboveStallSurface() + rangeIndex) && converted_height_of_drinking_cup_floor_above_stall_surface !== null}]">
							{{ $t("Height of Drinking Cup Above Stall Surface") }}
						</label>
						<label class="mb-0 mr-2 pull-right d-none d-lg-block">{{ $t("Ideal") }}</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_height_of_drinking_cup_floor_above_stall_surface"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_height_of_drinking_cup_floor_above_stall_surface < tiestallstanchion.getIdealHeightOfDrinkingCupFloorAboveStallSurface() - rangeIndex || converted_height_of_drinking_cup_floor_above_stall_surface > tiestallstanchion.getIdealHeightOfDrinkingCupFloorAboveStallSurface() + rangeIndex) && converted_height_of_drinking_cup_floor_above_stall_surface !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealHeightOfDrinkingCupFloorAboveStallSurface() }}
					</div>
				</div>

				<div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_headrail_to_rear_curb < tiestallstanchion.getIdealHeadrailToRearCurb() - rangeIndex || converted_headrail_to_rear_curb > tiestallstanchion.getIdealHeadrailToRearCurb() + rangeIndex) && converted_headrail_to_rear_curb !== null}]">
							{{ $t("Headrail to Rear Curb") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_headrail_to_rear_curb"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_headrail_to_rear_curb < tiestallstanchion.getIdealHeadrailToRearCurb() - rangeIndex || converted_headrail_to_rear_curb > tiestallstanchion.getIdealHeadrailToRearCurb() + rangeIndex) && converted_headrail_to_rear_curb !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealHeadrailToRearCurb() }}
					</div>
				</div>

				<div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_rear_edge_of_divider_to_curb < tiestallstanchion.getIdealRearEdgeOfDividerToCurb() - rangeIndex || converted_rear_edge_of_divider_to_curb > tiestallstanchion.getIdealRearEdgeOfDividerToCurb() + rangeIndex) && converted_rear_edge_of_divider_to_curb !== null}]">
							{{ $t("Rear Edge of Divider to Curb") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_rear_edge_of_divider_to_curb"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_rear_edge_of_divider_to_curb < tiestallstanchion.getIdealRearEdgeOfDividerToCurb() - rangeIndex || converted_rear_edge_of_divider_to_curb > tiestallstanchion.getIdealRearEdgeOfDividerToCurb() + rangeIndex) && converted_rear_edge_of_divider_to_curb !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealRearEdgeOfDividerToCurb() }}
					</div>
				</div>

				<div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_distance_of_trainers_from_rear_curb < tiestallstanchion.getIdealDistanceOfTrainersFromRearCurb() - rangeIndex || converted_distance_of_trainers_from_rear_curb > tiestallstanchion.getIdealDistanceOfTrainersFromRearCurb() + rangeIndex) && converted_distance_of_trainers_from_rear_curb !== null}]">
							{{ $t("Distance of Trainers From Rear Curb") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_distance_of_trainers_from_rear_curb"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_distance_of_trainers_from_rear_curb < tiestallstanchion.getIdealDistanceOfTrainersFromRearCurb() - rangeIndex || converted_distance_of_trainers_from_rear_curb > tiestallstanchion.getIdealDistanceOfTrainersFromRearCurb() + rangeIndex) && converted_distance_of_trainers_from_rear_curb !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealDistanceOfTrainersFromRearCurb() }}
					</div>
				</div>

                <div class="row">
					<div class="col-20 pl-0">
						<label :class="['mb-0 mt-3 mr-1', {'text-danger':(converted_height_of_trainer_above_wither < tiestallstanchion.getIdealHeightOfTrainerAboveWither() || converted_height_of_trainer_above_wither > tiestallstanchion.getIdealHeightOfTrainerAboveWither()) && converted_height_of_trainer_above_wither !== null}]">
							{{ $t("Height of Trainer Above Wither") }}
						</label>
					</div>
					<div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
						<currency-input
							v-model="converted_height_of_trainer_above_wither"
							:class="['form-control form-control--freestall mb-0', {'is-invalid':(converted_height_of_trainer_above_wither < tiestallstanchion.getIdealHeightOfTrainerAboveWither() || converted_height_of_trainer_above_wither > tiestallstanchion.getIdealHeightOfTrainerAboveWither()) && converted_height_of_trainer_above_wither !== null}]"
							:options="{
								currency: 'USD',
								currencyDisplay: 'hidden',
								precision: 0,
							}"
						/>
					</div>
					<div class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal">
						{{ tiestallstanchion.getIdealHeightOfTrainerAboveWither() }}
					</div>
				</div>
			</div>
            <!-- end right column -->
		</div>
        <!-- end dimensions -->

		<div class="row mx-0 d-none d-lg-flex justify-content-end pr-1">
			<label class="mb-0 pull-right">{{ $t("Ideal") }}</label>
		</div>

		<hr class="d-lg-none mt-4">

		<div class="row mx-md-0">

			<div class="col-19 col-md-22 col-lg-23 pl-md-0 pr-md-0 pr-lg-2 z-1">

				<hr class="d-none d-lg-block mt-0">
				<question class="text-nowrap" @change="assessor.data.does_the_stall_pass_the_knee_drop_test = $event.value;" :initial-value="assessor.data.does_the_stall_pass_the_knee_drop_test">
					<label :class="['label d-inline-block',{'text-danger':assessor.data.does_the_stall_pass_the_knee_drop_test !== 1 && assessor.data.does_the_stall_pass_the_knee_drop_test !== null}]">
						{{ $t("Does the stall pass the knee-drop test?") }}
					</label>
					<modal-info :value="'knee-drop'" class="d-inline text-wrap">
						<template slot="modal-content">
							<h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
								{{"Does the freestall pass the knee-drop test?" | translate}}
							</h2>
							<div class="row px-3 mb-3">
								<div class="col-md-12 mb-3 mb-lg-0">
									<img src="/images/example-kneel-down-1.jpg" alt="Example Kneel Down 1" class="w-100">
								</div>
								<div class="col-md-12">
									<img src="/images/example-kneel-down-2.jpg" alt="Example Kneel Down 2" class="w-100">
								</div>
							</div>
							<p class="px-3 mb-2">{{ 'Crouch slightly and then drop to your knees in the stall. If you can do this comfortably and without feeling pain, then the stall has adequate bedding and passes the knee-drop test.' | translate }}</p>
							<div class="row modal-bottom pb-3">
								<a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t("Cancel") }}</a>
							</div>
						</template>
					</modal-info>
				</question>
			</div>

			<div class="col-5 col-md-2 col-lg-1 pt-1 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center">
				<span class="d-none d-lg-block">{{ $t("Yes") }}</span>
				<div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
					<span>{{ $t("Yes") }}</span>
				</div>
			</div>
		</div>

		<hr class="d-lg-none">

		<div class="row mx-0">
			<div class="col-24 col-lg-23 d-none d-lg-block pl-lg-0">
				<hr>
			</div>
			<div class="col-1 d-none d-lg-block ideal"></div>
		</div>

		<div class="row mx-md-0">
			<div class="col-19 col-md-22 col-lg-23 pl-md-0 pr-md-0 pr-lg-2 z-1">
				<question @change="assessor.data.is_there_an_obstruction_above_drinking_cups = $event.value;" :initial-value="assessor.data.is_there_an_obstruction_above_drinking_cups">
					<label :class="['label d-inline-block',{'text-danger':assessor.data.is_there_an_obstruction_above_drinking_cups !== 0 && assessor.data.is_there_an_obstruction_above_drinking_cups !== null}]">
						{{ $t("Is there an obstruction above drinking cups?") }}
					</label>
				</question>
			</div>

			<div class="col-5 col-md-2 col-lg-1 pt-1 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center">
				<span class="d-none d-lg-block">{{ $t("No") }}</span>
				<div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
					<span>{{ $t("No") }}</span>
				</div>
			</div>
		</div>

		<hr class="d-block d-lg-none">

		<div class="row mx-md-0">
			<div class="col-19 col-md-22 col-lg-23 pl-md-0 pr-md-0 pr-lg-2 z-1">
				<hr class="d-none d-lg-block">
				<question class="text-nowrap" @change="assessor.data.is_there_a_bob_zone_obstruction = $event.value;" :initial-value="assessor.data.is_there_a_bob_zone_obstruction">
					<label :class="['label d-inline-block',{'text-danger':assessor.data.is_there_a_bob_zone_obstruction !== 0 && assessor.data.is_there_a_bob_zone_obstruction !== null}]">
						{{ $t("Is there a bob-zone obstruction?") }}
					</label>
					<modal-info :value="'bob-zone'" class="d-inline text-wrap">
						<template slot="modal-content" class="">
							<h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
								{{ "Is there a bob-zone obstruction?" | translate }}
							</h2>
							<div class="wistia_responsive_padding mb-3" style="padding:55.0% 0 0 0;position:relative;">
								<div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
									<div class="wistia_embed wistia_async_5p17e3oy0k seo=false videoFoam=true" style="height:100%;position:relative;width:100%">&nbsp;</div>
								</div>
							</div>
							<p v-if="evaluation.data.measurements === 'metric'" class="px-3 mb-2">{{ 'Cows need about 1 meter of space beyond their lying area to lunge into as they stand up and lie down. This lunging space is called the bob-zone. If there is an obstruction to where the cow is lunging, the cow will have greater difficulty rising and lying down. A bob-zone obstruction is anything within 1 meter in front of the lying area and between 10 to 100 cm above the stall surface.' | translate }}</p>
							<p v-else class="px-3 mb-2">{{ 'Cows need about 3 feet of space beyond their lying area to lunge into as they stand up and lie down. This lunging space is called the bob-zone. If there is an obstruction to where the cow is lunging, the cow will have greater difficulty rising and lying down. A bob-zone obstruction is anything within 3 feet in front of the lying area and between 4 to 40 inches above the stall surface.' | translate }}</p>
							<div class="row modal-bottom pb-3">
								<a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{"Cancel" | translate}}</a>
							</div>
						</template>
					</modal-info>
				</question>
				<hr class="d-none d-lg-block mb-0">
			</div>

			<div class="col-5 col-md-2 col-lg-1 pt-3 ideal ideal--question d-flex justify-content-center align-items-end align-items-lg-center">
				<span class="d-none d-lg-block">{{ $t("No") }}</span>
				<div class="ideal--sm d-flex justify-content-center align-items-center d-lg-none">
					<span>{{ $t("No") }}</span>
				</div>
			</div>
		</div>

		<hr class="d-block d-lg-none">

		<div class="row mt-3 mb-0">
			<div class="col-md-12">
				<personal-notes v-model="assessor.data.personal_notes"></personal-notes>
			</div>
			<div class="col-md-12">
				<presentation-notes v-model="assessor.data.presentation_notes"></presentation-notes>
			</div>
		</div>

		<div class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4">
			<photoUpload :assessorId="10"  :assessor="assessor" ></photoUpload>
		</div>

		<hr class="mb-3 mb-md-5 mt-3 mt-md-5">

		<div class="row misc-bottom">
			<div class="col-md-12 flex-sm-row-reverse">
				<a class="btn btn-secondary btn-full--sm mb-2 mt-md-0" href="javascript:history.go(-1)">{{ "Back" | translate }}</a>
			</div>
			<div class="col-md-12">
				<router-link v-if="user && user.role !== 'data_entry'" :to="'/reports'" class="btn btn-primary btn-full--sm pull-right">
					{{"Reports" | translate}}
				</router-link>
				<button v-else-if="user && user.role === 'data_entry' && !$store.state.offline" data-toggle="modal" @click="requestReport(evaluation.name)" :data-target="['#requestSent-' + user.id]" class="btn btn-primary btn-full--sm pull-right">
					{{ 'Request Report' | translate }}
					<span v-show="requestReportLoader" class="loader ml-1"></span>
				</button>
			</div>
		</div>

		<div v-show="successMessage" class="alert alert-primary mt-3 mb-0" role="alert">
			{{ 'Your request has been sent!' | translate }}
		</div>

		<div v-show="errorMessage" class="alert alert-danger mt-3 mb-0" role="alert">
			{{ 'An error has occurred during your request!' | translate }}
		</div>

	</div>
	<!-- end container -->
</template>

<script>
	import _ from 'lodash';
	import PresentationNotes from '@/pages/assessors/assessor_components/presentation-notes';
	import PersonalNotes from '@/pages/assessors/assessor_components/personal-notes';
    import Question from '@/pages/assessors/assessor_components/question';
	import QuestionThree from '@/pages/assessors/assessor_components/question-three';
	import ModalInfo from '@/components/misc/modal-info';
	import PhotoUpload from '@/components/misc/photoUpload';
	import HygieneHeader from '@/pages/assessors/assessor_components/hygiene-header';
	import convert from 'convert-units';
    import TieStallStanchion from '@/libs/Classes/TieStallStanchion.js';
	import Evaluation from '@/libs/Classes/Evaluation.js';
	import Group from '@/libs/Classes/Group.js';
	import requestReportMixin from '@/mixins/requestReport.js';
	import CurrencyInput from "@/components/common/CurrencyInput.vue";



	export default {
		components: {
			PersonalNotes,
			PresentationNotes,
            Question,
			QuestionThree,
			HygieneHeader,
			PhotoUpload,
			ModalInfo,
			CurrencyInput,
		},
		mixins: [requestReportMixin],
		props: ['assessor', 'group', 'evaluation'],
		data() {
			return {
				tiestallstanchion: null
			}
		},
		created() {
            let evaluationClass = new Evaluation(this, this.evaluation);
			this.groupClass = new Group(this, evaluationClass, this.group);
          //   this.user = this.$store.getters.getData("user");
			this.tiestallstanchion = new TieStallStanchion(this, evaluationClass, this.groupClass, this.assessor);

			console.log('tie stall stanchion data', this.assessor.data);
		},
		computed: {
			rangeIndex() {
				return this.evaluation.data.measurements === 'imperial' ? 1 : 2
			},
            converted_bedding_depth: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getBeddingDepth(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setBeddingDepth(newValue)}
			},
            converted_stall_length: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getStallLength(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setStallLength(newValue)}
			},
			converted_stall_width: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getStallWidth(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setStallWidth(newValue)}
			},
			converted_height_of_feed_curb_bunk_side: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getHeightOfFeedCurbBunkSide(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setHeightOfFeedCurbBunkSide(newValue)}
			},
			converted_height_of_feed_curb_stall_side: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getHeightOfFeedCurbStallSide(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setHeightOfFeedCurbStallSide(newValue)}
			},
			converted_height_of_headrail_above_stall_surface: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getHeightOfHeadrailAboveStallSurface(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setHeightOfHeadrailAboveStallSurface(newValue)}
			},
			converted_length_of_chain: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getLengthOfChain(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setLengthOfChain(newValue)}
			},
			converted_height_of_drinking_cup_floor_above_stall_surface: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getHeightOfDrinkingCupFloorAboveStallSurface(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setHeightOfDrinkingCupFloorAboveStallSurface(newValue)}
			},
			converted_headrail_to_rear_curb: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getHeadrailToRearCurb(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setHeadrailToRearCurb(newValue)}
			},
			converted_rear_edge_of_divider_to_curb: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getRearEdgeOfDividerToCurb(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setRearEdgeOfDividerToCurb(newValue)}
			},
			converted_distance_of_trainers_from_rear_curb: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getDistanceOfTrainersFromRearCurb(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setDistanceOfTrainersFromRearCurb(newValue)}
			},
			converted_height_of_trainer_above_wither: {
				get: function () {return (this.tiestallstanchion !== null ) ? this.tiestallstanchion.getHeightOfTrainerAboveWither(0) : false},
			    set: function (newValue) {return this.tiestallstanchion.setHeightOfTrainerAboveWither(newValue)}
			},
			top_column() {
				if(this.tiestallstanchion.getStallSurfaceValue() !== 'manure_solids' && this.tiestallstanchion.getStallSurfaceValue() !== 'other' && this.tiestallstanchion.getStallSurfaceValue() !== 'mats') {
					return 'col-lg-8';
				} else {
					return 'col-lg-12';
				}
			}
		},
	}
</script>
