<template>
    <div class="card-body mix-card-body">
        <h5 class="card-title text-center" style="min-height: 48px" >{{ product.product.title | translate }}</h5>
        <modal-mixes :index="index" class="d-inline">
            <template slot="modal-content">
                <button type="button" class="mb-3 btn btn-primary w-100" data-dismiss="modal" @click="removeProduct(product.getTitle())">{{"Yes" | translate}}</button>
                <button type="button" class="btn btn-secondary w-100" data-dismiss="modal">{{"No" | translate}}</button>
            </template>
        </modal-mixes>
        <div class="col-md-24">
            <label>{{"Products" | translate}}</label>
            <input v-if="productData.productType == 'Other'" type="text" :value="productData.title | translate" @input="handleTitleChange" class="form-control" :readonly="productData.productType !== 'Other'" />
            <input v-if="productData.productType !== 'Other'" type="text" v-model="translatedTitle" class="form-control" :readonly="productData.productType !== 'Other'" />
        </div>
        <div class="col-md-24">
            <label>{{"Classification" | translate}}</label>
            <select v-if="product.productType == 'Other'" class="form-control" v-model="productData.classification">
                <option value="liquid" selected>{{"Liquid" | translate}}</option>
                <option value="solid">{{"Solid" | translate}}</option>
            </select>
            <input v-else type="text" v-model="translatedClassification" class="form-control" readonly />
        </div>
        <div class="col-md-24" :key="index + measurements + 'converted_inclusion_rate'">
            <label>{{"Inclusion/Footbath (" | translate }}{{ getProductUnit(product.getClassification()) | translate }}{{ ")" | translate}}</label>
            <currency-input
                v-model.number="converted_inclusion_rate"
                :class="'form-control decimal'"
                readonly
                :options="{
                    currency: 'USD',
                    currencyDisplay: 'hidden',
                    precision: 0,
                }"
            />
        </div>
        <div class="col-md-24">
            <label>{{"Concentration (%)" | translate}}</label>
            <currency-input
                v-model.number="convertedConcentration"
                :class="'form-control decimal'"
                v-validate="'required'"
                :options="{
                    currency: 'USD',
                    currencyDisplay: 'hidden',
                    precision: {min: 0, max :2},
                }"
            />
        </div>
        <div class="col-md-24" :key="index + measurements + 'converted_price'">
            <label>{{"Price (" | translate }}{{ evaluation.data.currency }}{{ "/" | translate }}{{ getProductUnit(product.getClassification()) | translate }}{{ ")" | translate}}</label>
            <currency-input
                v-model.number="convertedPrice"
                name="price"
                data-vv-as="Price"
                :class="'form-control decimal'"
                v-validate="'required'"
                :options="{
                    currency: 'USD',
                    currencyDisplay: 'hidden',
                    precision: {min: 0, max :2},
                }"
            />
            <span v-show="errors.has('price')" class="text-danger">
                <small>{{ 'The Product field is required.' | translate }}</small>
            </span>
        </div>
    </div>
</template>

<script>
/*  eslint-disable */

    import ModalInfo from '@/components/misc/modal-info.vue';
    import Footbath from '@/libs/Classes/Footbath.js';
    import ModalMixes from '@/components/misc/modal-mixes.vue';
    import CurrencyInput from "@/components/common/CurrencyInput.vue";
    import { mapState } from 'vuex';

    export default {
        components: {
            ModalInfo,
            ModalMixes,
            CurrencyInput
        },
        props: ['index', 'product','measurements'],
        inject: ['$validator'],
        data() {
            return {
                convertedConcentration: this.product.getConcentrationPercentage(),
                convertedPrice: this.product.getPricePerUnit(false),
                // evaluation: null,
                group: null,
                assessor: null
            }
        },
        created() {
            this.group = this.evaluation.groups[this.$route.params.groupId]
            // this.evaluation = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId);
            // this.group = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId + "/groups/" + this.$route.params.groupId);
            let assessorType = this.$route.params.assessorType;
            if (this.group.assessors) {
              if(Array.isArray(this.group.assessors)) {
                this.assessor = this.group.assessors.find(assessor => {
                    return Number(assessor.type) === Number(assessorType);
                });
              } else {
                for(let assessorId in this.group.assessors) {
                    if(Number(this.group.assessors[assessorId].type) === Number(assessorType)) {
                        this.assessor = this.group.assessors[assessorId];
                    }
                }
              }
            }
        },
        methods: {
            handleTitleChange(input) {
                this.$set(this.product.product, 'title', this.$t(input.target.value))

                this.$nextTick(() => {
                    this.$forceUpdate()
                })
            },

            removeProduct: function(name) {
                this.$emit('removed', name);
            },
            getProductUnit(productClassification) {
                const { mixMeasurements , measurements: evaluationMeasurement } = this.evaluation.data
                let unit  = (productClassification === 'Liquid') ? 'ml/L' : 'g/L';
                const measurements = mixMeasurements || evaluationMeasurement
                return this.$getEquivalentUnit(measurements, unit);
            }
        },
        computed: {
                ...mapState({
                evaluation: state => state.evaluations.evaluation
            }),
            converted_inclusion_rate() {
                return this.product.getInclusionRate(this.evaluation.data.mixMeasurements);
            },
            productData() {
                return this.product.product;
            },
            translatedClassification() {
                return this.$t(this.productData.classification);
            },
            translatedTitle() {
                return this.$t(this.productData.title);
            }
        },
        watch: {
            value: function(newValue) {
                if (newValue.measurements_length === null &&
                    newValue.measurements_width === null &&
                    newValue.measurements_volume === null &&
                    newValue.measurements_depth === null) {
                    this.$validator.reset();
                }
            },
            convertedConcentration: function (newValue) {
                this.product.setConcentrationPercentage(newValue);
            },
            convertedPrice: function (newValue) {
                this.product.setPricePerUnit(newValue);
            }
        }
    }
</script>
