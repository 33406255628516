<!-- @format -->

<template>
  <div>
    <div class="loading" v-show="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div
      class="background-white container main-container"
      v-show="isLoaded"
    >
      <h1 class="h1">{{ "Quick Start" | translate }}</h1>
      <div class="card mt-4 mb-0">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Evaluation Name" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="evaluation_name"
                  v-model="evaluation.name"
                  data-vv-as="Evaluation Name"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('evaluation_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Evaluation Name field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Group/Pen Name" | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="evaluation.groups[groupId].name"
                  name="pen_name"
                  data-vv-as="Group/Pen Name"
                  v-validate="'required'"
                />
                <span
                  v-show="errors.has('pen_name')"
                  class="text-danger"
                >
                  <small>{{
                    "The Group/Pen Name is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Group/Pen Size" | translate }}
                  <span class="required">*</span>
                </label>
                <currency-input
                key="pen_size"
                  name="pen_size"                  
                  v-model="evaluation.groups[groupId].data.size"
                  data-vv-as="Group/Pen Size"
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: 0,
                  }"
                />
                <span
                  v-show="errors.has('pen_size')"
                  class="text-danger"
                >
                  <small>{{
                    "The Group/Pen Size field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Type of Animal" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  name="group_type_of_animal"
                  v-model="
                    evaluation.groups[groupId].data.type_of_animal
                  "
                  class="form-control"
                  data-vv-as="Type of Animal"
                  v-validate="'required'"
                >
                  <option :value="null" selected disabled>
                    {{ "Select" | translate }}
                  </option>
                  <option value="close-up">
                    {{ "Close-up" | translate }}
                  </option>
                  <option value="far-off">
                    {{ "Far-off" | translate }}
                  </option>
                  <option value="fresh">
                    {{ "Fresh" | translate }}
                  </option>
                  <option value="lactating">
                    {{ "Lactating" | translate }}
                  </option>
                  <option value="heifers-first-lactation">
                    {{ "Heifers (First Lactation)" | translate }}
                  </option>
                  <option value="heifers-growing">
                    {{ "Heifers (Growing)" | translate }}
                  </option>
                  <option value="mixed">
                    {{ "Mixed" | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('group_type_of_animal')"
                  class="text-danger"
                >
                  <small>{{
                    "The Type of Animal field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Consultation Date" | translate }}
                  <span class="required">*</span>
                </label>
                 <vuejs-datepicker
                  v-on:input="convertDate"
                  :value="evaluation.data.consultation_date"
                  :typeable="true"
                  input-class="form-control date-picker"
                  data-vv-as="Consultation Date"
                  name="consultation_date"
                  v-model="evaluation.data.consultation_date"
                  v-validate="'required'"
                  :format="formatDate"
                  :language="selectedLanguage"
                ></vuejs-datepicker>

                <span
                  v-show="errors.has('consultation_date')"
                  class="text-danger"
                >
                  <small>{{
                    "The Consultation Date field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Average Cow Size" | translate }}
                  {{ averageCowSizeMeasurement() }}
                  <span class="required">*</span>
                </label>
                <select
                  name="cow_size"
                  class="form-control"
                  v-model="evaluation.groups[groupId].data.cow_size"
                  data-vv-as="Average Cow Size"
                  v-validate="'required'"
                >
                  <option disabled :value="null">
                    {{ "Select" | translate }}
                  </option>
                  <option
                    v-for="(cowSize, index) in cowSizes"
                    :value="cowSize.value"
                    :key="index"
                  >
                    {{ cowSize.label }}
                  </option>
                </select>
                <span
                  v-show="errors.has('cow_size')"
                  class="text-danger"
                >
                  <small>{{
                    "The Average Cow Size field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="mb-1">
                  {{ "Currency" | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  name="currency_qs"
                  v-model="evaluation.data.currency"
                  class="form-control"
                  data-vv-as="Currency"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>
                    {{ "Select" | translate }}
                  </option>
                  <option
                    v-for="(currency, index) in $store.state.misc
                      .currencies"
                    :key="index"
                    :value="index"
                  >
                    {{ currency | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('currency_qs')"
                  class="text-danger"
                >
                  <small>{{
                    "The Currency field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="d-block">
                  {{ "Measurements" | translate }}
                  <span class="required">*</span>
                </label>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="measurements"
                      value="metric"
                      data-vv-as="Measurements"
                      v-validate="'required'"
                      v-model="evaluation.data.measurements"
                    />
                    {{ "Metric" | translate }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="measurements"
                      value="imperial"
                      data-vv-as="Measurements"
                      v-validate="'required'"
                      v-model="evaluation.data.measurements"
                    />
                    {{ "Imperial" | translate }}
                  </label>
                </div>
                <span
                  v-show="errors.has('measurements')"
                  class="text-danger d-block"
                >
                  <!-- <small>{{ 'The Measurements field is required.' | translate }}</small> -->
                  <small>{{ errors.first("measurements") }}</small>
                </span>
              </div>
            </div>

              <div class="col-lg-6">
                <label for="mineral" class="mb-1">
                  {{ "Zinpro Products Used?" | translate }}
                                     <modal-info :value="'zinpro-products'">
                       <template slot="modal-content">
                         <h2 class="h2 pt-3 pb-2 px-3 text-center">
                           {{
                             `Please indicate if any Zinpro products are fed within this pen.
                              If yes, select “Details” to select which products are used. 
                              You can select multiple products and indicate the inclusion level if known. 
                              Inclusion level is not required.`
                               | translate
                           }}
                         </h2>
                         <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ "Cancel" | translate }}</a
                      >
                    </div>
                       </template>
                   </modal-info>
                </label>
                <div
                  style="
                    display: flex;
                    gap: 10px;
                    align-items: flex-start;
                  "
                >
                  <select
                    name="mineral"
                    v-model="evaluation.groups[groupId].data.isUsedZinproProduct"
                    class="form-control"
                    data-vv-as="Avg. Cow Size"
                  ><option :value="null" selected disabled>
                    {{ "Select" | translate }}
                  </option>
                    <option :value="true">
                      {{ "Yes" | translate }}
                    </option>
                    <option :value="false" :disabled="!!Object.keys(evaluation.groups[groupId].data.selectedMinerals).length">
                      {{ "No" | translate }}
                    </option>
                  </select>
                  <button
                    v-if="evaluation.groups[groupId].data.isUsedZinproProduct"
                    type="button"
                    class="btn btn-sm mr-4 btn-full--sm"
                    style="padding: 8px 10px"
                    @click="showMineralModal = true"
                  >
                    {{ "Details" | translate }}
                  </button>
                </div>
              </div>

              <mineral-detail-modal
                :is-open="showMineralModal"
                @close="showMineralModal = false"
                @delete-mineral="
                  (minerals) =>
                    (evaluation.groups[groupId].data.selectedMinerals = minerals)
                "
                :selected-minerals="evaluation.groups[groupId].data.selectedMinerals"
              >
              </mineral-detail-modal>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="d-block"
                  >{{
                    "Does this evaluation contain false data and not actual real-world information?"
                      | translate
                  }}
                  <span class="required">*</span>
                </label>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="is_for_testing"
                      :value="true"
                      data-vv-as="Is For Testing"
                      v-validate="'required'"
                      v-model="evaluation.isForTesting"
                    />
                    {{ "Yes" | translate }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="is_for_testing"
                      :value="false"
                      data-vv-as="Is For Testing"
                      v-validate="'required'"
                      v-model="evaluation.isForTesting"
                    />
                    {{ "No" | translate }}
                  </label>
                </div>
                <span
                  v-if="errors.has('is_for_testing')"
                  class="text-danger d-block"
                >
                  <small>{{
                    "Please fill in required fields"
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group" v-if="showMilkProductionField">
                <label class="mb-1"
                  >{{
                    $t("Milk Price") +
                    " (" +
                    computed_currency +
                    $t(") / ") +
                    $t(
                      $getEquivalentUnit(
                        evaluation.data.measurements,
                        "kg"
                      )
                    )
                  }}
                  *</label
                >
                <currency-input
                key="milk_price_qt"
                  name="milk_price_qt"                  
                  v-model="computed_milk_price"
                  :data-vv-as="$t('Milk Price')"
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: {min: 0, max :2},
                  }"
                />
                <span
                  v-show="errors.has('milk_price_qt')"
                  class="text-danger"
                >
                  <small>{{
                    "The Milk Price field is required." | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6" v-if="showMilkProductionField">
              <div class="form-group">
                <label class="mb-1"
                  >{{
                    $t("Milk Production") +
                    " (" +
                    $t(
                      $getEquivalentUnit(
                        evaluation.data.measurements,
                        "kg"
                      )
                    ) +
                    $t("/day)")
                  }}
                  *</label
                >
                <currency-input
                key="group_milk_prod"
                  name="group_milk_prod"                  
                  v-model="computed_milk_production"
                  :data-vv-as="$t('Milk Production') +
                    ' (' +
                    $t(
                      $getEquivalentUnit(
                        evaluation.data.measurements,
                        'kg'
                      )
                    ) +
                    $t('/day)')"
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: {min: 0, max :2},
                  }"
                />
                <span
                  v-show="errors.has('group_milk_prod')"
                  class="text-danger"
                >
                  <small>{{
                    "The Milk Production field is required."
                      | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-24">
              <label class="mb-1 d-lg-none"
                >{{ "Personal Notes" | translate }}
                <modal-info :value="'personal-notes'">
                  <template slot="modal-content">
                    <h2 class="h2 pt-3 pb-2 px-3 text-center">
                      {{
                        "Personal Notes: Not included when outputting reports."
                          | translate
                      }}
                    </h2>
                    <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ "Cancel" | translate }}</a
                      >
                    </div>
                  </template>
                </modal-info>
              </label>
              <label class="d-none d-lg-block mb-1"
                >{{ "Personal Notes" | translate }}
                <modal-info :value="'personal-notes-2'">
                  <template slot="modal-content">
                    <h2 class="h2 pt-3 pb-2 px-3 text-center">
                      {{
                        "Personal Notes: Not included when outputting reports."
                          | translate
                      }}
                    </h2>
                    <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ "Cancel" | translate }}</a
                      >
                    </div>
                  </template>
                </modal-info>
              </label>
            </div>
            <div class="col-md-24">
              <textarea
                class="form-control"
                name="group_personal_notes"
                v-model="evaluation.groups[groupId].personal_notes"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-24">
              <div class="form-group">
                <label class="mr-3"
                  >{{ "Select Assessors" | translate }}
                  <span class="required">*</span>
                </label>
                <label
                  class="label-checkbox label-checkbox--select-all"
                >
                  <input
                    id="CheckAll"
                    type="checkbox"
                    :checked="check_all"
                    value="1"
                    @click="checkAll()"
                  />
                  <span class="checkbox"></span>
                </label>
                <label for="CheckAll" class="label--font-thin">{{
                  "Select/Deselect All" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div
            class="modal fade show modal-overlay"
            tabindex="-1"
            id="info-transition-alert"
            role="dialog"
            style="display: block"
            v-if="showTransitionAlert"
          >
            <div
              class="modal-dialog modal-dialog--edit"
              role="document"
            >
              <div class="modal-content edit-modal pt-0 px-0">
                <div class="modal-body my-0 py-0 px-0">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    style="margin: 0 5px"
                    @click="showTransitionAlert = false"
                  >
                    &times;
                  </button>
                  <h2 class="h2 pb-2 pt-3 px-3 text-center">
                    {{
                      "If you want to export data for the Transition assessor you must first"
                        | translate
                    }}:
                  </h2>
                  <ul>
                    <li>
                      <p class="p--md px-3">
                        •
                        {{
                          "Complete the full Dairy Characteristics page."
                            | translate
                        }}
                      </p>
                    </li>
                    <li>
                      <p class="p--md px-3">
                        •
                        {{
                          "Add multiple groups for a single Evaluation to allow for comparison reporting."
                            | translate
                        }}
                      </p>
                    </li>
                    <li>
                      <p class="p--md px-3">
                        •
                        {{
                          "It is advised to complete the Locomotion assessor before beginning the Transition assessor."
                            | translate
                        }}
                      </p>
                    </li>
                  </ul>
                  <div class="btn-container">
                    <a
                      href="/evaluations/new"
                      class="btn btn-primary btn-full"
                    >
                      {{ "Start New Evaluation" | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row checkbox-grid">
            <div
              v-for="(assessor, index) in $store.state.assessorTypes"
              :key="index"
            >
              <div class="form-group">
                <label class="label-checkbox">
                  <input
                    type="checkbox"
                    name="assessors"
                    :id="assessor.name"
                    key="checkbox-input"
                    :value="assessor.id"
                    v-model="evaluation.groups[groupId].assessor_list"
                  />
                  <!-- @change="checkIndividual()" -->
                  <span class="checkbox"></span>
                </label>
                <label
                  :for="assessor.name"
                  class="label--font-thin word-wrap d-inline"
                >
                  {{ assessor.name | translate }}
                </label>
                <modal-info :value="assessor.id">
                  <template slot="modal-content">
                    <h2 class="h2 pb-2 pt-3 px-3 text-center">
                      {{ assessor.name | translate }}
                    </h2>
                    <p
                      class="p--md px-3"
                      v-for="(
                        description, index
                      ) in assessor.description"
                      :key="index"
                    >
                      {{ description | translate }}
                    </p>
                    <div class="row modal-bottom pb-3 mt-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ "Cancel" | translate }}</a
                      >
                    </div>
                  </template>
                </modal-info>
              </div>
            </div>
          </div>

          <span
            v-show="checkboxPermission === true"
            class="text-danger"
          >
            <small>{{
              "You need to click at least one of the checkboxes!"
                | translate
            }}</small>
          </span>
        </div>

        <div
          v-if="errorMessage === true"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          {{ "Please fill out the required fields!" | translate }}
        </div>

        <div class="row d-flex">
          <div class="order-2 order-md-1 col-md-12">
            <router-link
              to="/"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4"
              >{{ "Back" | translate }}</router-link
            >
          </div>
          <div class="order-1 order-md-2 col-md-12">
            <button
              @click="saveData()"
              class="btn btn-primary btn-full--sm mt-4 float-right"
              :disabled="isSaving || disableNextButton"
            >
              {{ "Next" | translate }}
              <span v-if="loader" class="loader ml-1"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @TODO disable next button until user fills out an assessor
// @EVAN should we disable the button until all required fields are filled out?
import ModalInfo from "@/components/misc/modal-info.vue";
import Evaluation from "@/libs/Classes/Evaluation.js";
import GroupClass from "@/libs/Classes/Group.js";
import convert from "convert-units";
import vuejsDatepicker from "vuejs-datepicker";
import {
  GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER,
  GET_ADD_EVALUATION_IN_DB,
  GET_DELETE_EVALUATION,
  GET_EVALUATION_BY_ID,
} from "@/store/types/index.js";
import { mapActions, mapGetters, mapState } from "vuex";
import { cloneDeep } from "lodash";
import { initializeAssessorDataByType } from "../../helperFunctions";
import { newObjectId } from "@/helperFunctions.js";
import { GET_EVALUATION_BY_ID_GETTER } from "../../store/types";
import { zh, fr, es, en, de, ptBR, pl, it, ru, nl } from "vuejs-datepicker/dist/locale";
import MineralDetailModal from "@/components/misc/mineral-detail-modal.vue";
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    vuejsDatepicker,
    ModalInfo,
    MineralDetailModal,
    CurrencyInput
  },
  data() {
    const groupId = this.$generateId();

    return {
      groupId: groupId,
      ch:zh,
      fr,
      es,
      en,
      de,
      pt:ptBR,
      pl,
      it,
      ru,
      nl,
      groupName: null,
      isSaving : false ,
      loaded: false,
      errorMessage: false,
      checkboxPermission: false,
      typeOfAnimal: null,
      cowSize: null,
      loader: false,
      isLoaded: true,
      units: null,
      groupClass: null,
      evaluationClass: null,
      showMineralModal: false,
      evaluation: {
        id: this.$generateId(),
        name: null,
        isForTesting: null,
        userId: null,
        dairyLocationId: null,
        clientId: null,
        contacts: [],
        order: [],
        data: {
          currency: null,
          measurements: "metric", // default value
        },
        report: {
          assessors: [],
          groups: [],
          file_name: "Report 1", // Not sure if needed
          file_size: 234444, // Not sure if needed
          executive_summary_title: null,
          executive_summary_content: null,
          output_data: {}, // object
          date_created: 1527795454,
        },
        groups: {
          [groupId]: {
            id: groupId,
            assessor_list: [],
            data: {
              cow_size: null,
              type_of_animal: null,
              milkProduction: null,
              showMilkProduction: null,
              date_created: null,
              selectedMinerals: {},
              isUsedZinproProduct: null
            },
          },
        },
        date_created: null,
        date_updated: null,
      },

      disableNextButton: true,
      showTransitionAlert: false,
      showedTransitionAlert: false,
      imperialMilkProduction: null,
      metricMilkProduction: null,
      hiddenImperialMilkProduction: null,
      hiddenMetricMilkProduction: null,
      originalMeasurement: null,
      userEnteredDecimal: false,
    };
  },
  watch: {
    'evaluation.data.measurements': {
      handler() {
        this.updateMilkProduction();
      },
    },
    groupName(val) {
      this.evaluation.groups[this.groupId].name = val;
    },
    evaluation: {
      handler(newValue) {
        this.checkboxPermission = false;
        if (
          newValue.groups[this.groupId].assessor_list.includes("19") &&
          !newValue.groups[this.groupId].assessor_list.includes("3") &&
          !this.showedTransitionAlert
        ) {
          this.showTransitionAlert = true;
          this.showedTransitionAlert = true;
        }
        if (this.evaluation.groups[this.groupId].assessor_list.length === 0) {
          
          this.checkboxPermission = true;
          this.disableNextButton = true;
          return;
        }
        this.$validator.validateAll().then((result) => {  
          if (!result) {
            this.errorMessage = true;
            setTimeout(() => {
              this.errorMessage = false;
            }, 4000);
          }
          this.disableNextButton = !result;
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
      userId: (state) => state.users.user._id,
      lang: (state) => state.i18n.locale,
    }),
    ...mapGetters("evaluations", [GET_EVALUATION_BY_ID_GETTER]),

    selectedLanguage() {
      return this[this.lang];
    },
    showMilkProductionField() {
      return (
        //this.evaluation.groups[this.groupId].assessor_list.includes("3") &&
        [
          "fresh",
          "lactating",
          "heifers-first-lactation",
          "mixed",
        ].includes(
          this.evaluation.groups[this.groupId].data.type_of_animal
        )
      );
    },
    computed_currency() {
      if (this.evaluation.data.currency === null) {
        return "currency";
      } else {
        return this.evaluation.data.currency;
      }
    },
    computed_milk_price: {
      cache: false,
      get() {
        return this.evaluationClass.getMilkPrice(false);
      },
      set(newValue) {
        return this.evaluationClass.setMilkPrice(newValue);
      },
    },
    cowSizes: {
      cache: false,
      get() {
        const cow_sizes_in_kg = [
          408.2, 498.9, 589.6, 680.3, 771, 861.8, 952.5,
        ];
        let converted_cow_sizes = [];

        for (let i = 0; i < cow_sizes_in_kg.length; i++) {
          if (i !== 0) {
            let low_converted_value = parseInt(
              convert(cow_sizes_in_kg[i - 1])
                .from("kg")
                .to(
                  this.$getEquivalentUnit(
                    this.evaluation.data.measurements,
                    "kg"
                  )
                )
                .toFixed(0)
            );

            let high_converted_value = parseInt(
              convert(cow_sizes_in_kg[i])
                .from("kg")
                .to(
                  this.$getEquivalentUnit(
                    this.evaluation.data.measurements,
                    "kg"
                  )
                )
                .toFixed(0)
            );

            converted_cow_sizes.push({
              value:
                cow_sizes_in_kg[i - 1] + "-" + cow_sizes_in_kg[i],
              label: low_converted_value + "-" + high_converted_value,
            });
          }
        }

        return converted_cow_sizes;
      },
    },
    check_all() {
      return (
        this.evaluation.groups[this.groupId].assessor_list.length ===
        this.$store.state.assessorTypes.length
      );
    },
    displayMilkProduction: {
      get() {
        if (this.evaluation.data.measurements === 'imperial') {
          return this.imperialMilkProduction !== null ? this.imperialMilkProduction : this.hiddenImperialMilkProduction;
        } else {
          return this.metricMilkProduction !== null ? this.metricMilkProduction : this.hiddenMetricMilkProduction;
        }
      },
      set(value) {
        this.userEnteredDecimal = value % 1 !== 0;
        if (this.evaluation.data.measurements === 'imperial') {
          this.imperialMilkProduction = value;
          this.hiddenImperialMilkProduction = value;
          this.hiddenMetricMilkProduction = value * 0.45359237;
          this.metricMilkProduction = Math.round(this.hiddenMetricMilkProduction);
        } else {
          this.metricMilkProduction = value;
          this.hiddenMetricMilkProduction = value;
          this.hiddenImperialMilkProduction = value / 0.45359237;
          this.imperialMilkProduction = Math.round(this.hiddenImperialMilkProduction);
        }
        this.originalMeasurement = this.evaluation.data.measurements;
        this.groupClass.setMilkProduction(value);
      }
    },
  },
  methods: {
     formatDate() {
      return this.$store.getters.getDate(
        this.evaluation.data.consultation_date
      );
    },

    initializeGroupData() {
      for (const groupId in this.evaluation.groups) {
        const group = this.evaluation.groups[groupId];
        group.assessor_list.forEach((type) => {
          const newAssessor = initializeAssessorDataByType(
            this,
            type
          );
          group.assessors = {
            ...(group.assessors || {}),
            [newAssessor.id]: newAssessor,
          };
        });
      }
    },
    checkAll() {
      if (!this.check_all) {
        this.evaluation.groups[this.groupId].assessor_list =
          this.$store.state.assessorTypes.map(
            (assessorType) => assessorType.id
          );
      } else {
        this.evaluation.groups[this.groupId].assessor_list = [];
      }
    },
    averageCowSizeMeasurement() {
      if (this.evaluation.data.measurements === undefined)
        return null;
      if (this.evaluation.data.measurements === "metric") {
        return `(${this.$t("kg")})`;
      } else {
        return `(${this.$t("lbs")})`;
      }
    },
    convertDate() {
      this.evaluation.data.consultation_date = new Date(
        this.evaluation.data.consultation_date
      ).getTime();
    },
    updateMilkProduction() {
      if (this.evaluation.data.measurements === 'imperial') {
        if (this.originalMeasurement === 'metric' || !this.userEnteredDecimal) {
          this.imperialMilkProduction = Math.round(this.hiddenImperialMilkProduction);
        } else {
          this.imperialMilkProduction = this.hiddenImperialMilkProduction;
        }
        this.metricMilkProduction = Math.round(this.hiddenMetricMilkProduction);
      } else {
        if (this.originalMeasurement === 'imperial' || !this.userEnteredDecimal) {
          this.metricMilkProduction = Math.round(this.hiddenMetricMilkProduction);
        } else {
          this.metricMilkProduction = this.hiddenMetricMilkProduction;
        }
        this.imperialMilkProduction = Math.round(this.hiddenImperialMilkProduction);
      }
    },
    saveData() {
      if(this.isSaving) return
      this.isSaving= true
      this.disableNextButton = true;
      
      this.evaluation.groups[this.groupId].assessor_list.length === 0
        ? (this.checkboxPermission = true)
        : (this.checkboxPermission = false);
        this.$validator.validateAll().then((result) => {
        if (this.checkboxPermission === true) {
          result = false;
        }
        if (result === true) {
          this.checkboxPermission = false;

          this.evaluation.order = _.clone(
            this.evaluation.groups[this.groupId].assessor_list
          );

          this.evaluation.date_created = new Date().getTime();
          this.evaluation.date_updated = new Date().getTime();

          if (!this.evaluation.data.mixMeasurements) { 
            this.evaluation.data.mixMeasurements = this.evaluation.data.measurements;
          }


          this.evaluation.userId = this.userId;
          
          this.evaluation.data.milkProduction =
          this.evaluation.data.measurements === 'imperial'
          ? this.imperialMilkProduction
          : this.metricMilkProduction;

          this.evaluation.data.showMilkProduction = this.displayMilkProduction
          this.evaluation.groups[this.groupId].data.showMilkProduction = this.displayMilkProduction
          this.loader = true;

          // TODO: need to add evaluation in user
          // if (!this.user.evaluations) {
          //   this[GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER]();
          // }

          // let evaluationIndex = this.user.evaluations.findIndex(evaluation => {
          //   return evaluation._id === this.evaluation._id;
          // });

          // if (evaluationIndex === -1) {
          //   this.user.evaluations.push(this.evaluation);
          // }

          // initialize the assessor data in groups
          this.initializeGroupData();
          this.evaluation = {
            _id: newObjectId(),
            ...this.evaluation,
          };
          this[GET_ADD_EVALUATION_IN_DB](this.evaluation);
          // .then(evaluation => {
          // console.log({ evaluation });
          this.$router.push(
            `/users/${this.userId}/evaluations/${this.evaluation._id}/order`
          );
          this.loader = false;
          // })
          // .catch(error => {
          //   this[GET_DELETE_EVALUATION](this.evaluation._id).then(() => {
          //     this.$router.push(
          //       `/users/${this.userId}/evaluations?errorCode=evaluation/not-saved`
          //     );
          //   });
          // });
        } else {
          this.disableNextButton = false;
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 4000);
        }
      });
    },

    addEvaluationCreatedDate() {
      this.evaluation.data.consultation_date = new Date().getTime();
      this.evaluation.date_created = new Date().getTime();
      this.evaluation.groups[this.groupId].date_created =
        new Date().getTime();
    },
    async getEvaluation() {
      try {
        const { evaluationId } = this.$route.params;

        if (evaluationId) {
          const evaluation =
            this[GET_EVALUATION_BY_ID_GETTER](evaluationId);
          if (evaluation) return evaluation;
        } else this.addEvaluationCreatedDate();

        return false;
      } catch (error) {
        // this.$router.push(
        //   `/users/${this.userId}/evaluations?errorCode=evaluation/not-found`
        // );
      }
    },

    handleMilkProductionInput(event) {
      const inputValue = event.target.value;
      this.milkProductionInput = inputValue;
      
      // Only update the actual value when the input is complete
      if (inputValue !== '') {
        const numericValue = parseFloat(inputValue);
        if (!isNaN(numericValue)) {
          this.groupClass.setMilkProduction(numericValue);
        }
      }
    },

    getMilkProduction() {
      return this.milkProductionInput !== null ? this.milkProductionInput : this.groupClass.getMilkProduction(false);
    },

    ...mapActions("evaluations", [
      GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER,
      GET_ADD_EVALUATION_IN_DB,
      GET_DELETE_EVALUATION,
      GET_EVALUATION_BY_ID,
    ]),
  },
  async created() {
    // this.$db.then(database => {
    // if user comeup along with evaluationId for edit evaluation.
    // if (this.$route.params.evaluationId !== undefined) {
    //   let evaluation = this.$store.getters.getData(
    //     'users/' +
    //       this.$store.state.user.id +
    //       '/evaluations/' +
    //       this.$route.params.evaluationId
    //   );

    //   if (evaluation === undefined) {
    //     this.$router.push(
    //       '/users/' +
    //         this.$store.state.user.id +
    //         '/evaluations?errorCode=evaluation/not-found'
    //     );
    //     return false;
    //   }

    const { evaluationId } = this.$route.params || {};

    const evaluation = await this.getEvaluation();

    if (evaluation) {
      this.evaluation = cloneDeep(evaluation);
      this.groupId = Object.keys(this.evaluation.groups)[0];
      this.groupName = this.evaluation.groups[this.groupId].name;
      setTimeout(() => {
        this.$validator.validateAll().then((result) => {
          this.errorMessage = !result;
          this.disableNextButton = !result;
        });
      }, 10);
    }

    this.evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new GroupClass(
      this,
      this.evaluationClass,
      this.evaluation.groups[this.groupId]
    );

    this.evaluation.data.measurements = this.user.measurements;
    this.evaluation.data.currency = this.user.currency;
    this.isLoaded = true;
    // });
  },
};
</script>
<style lang="scss" scoped>
.QuickStart {
  label {
    font-size: 13px;
  }
  .col-md-8 {
    padding-left: 0;
    padding-right: 0;
  }
}

.checkbox-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(1, auto);
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, auto);
  }
}
</style>
