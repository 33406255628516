const Table = require('../../Misc/Table');

export const holdingArea = (app, report, evaluation, group, assessor) => {

    let slide;
    let slide2;

    let titleY = 2.6;
    let slideY = 3;

    let titleY2;
    let slideY2;

    let obj = [
        {
            text: app.$t('Question'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Answer'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Comment'),
            options: report.styleOptions.tableHeader
        }
    ]
    let generalObj = [];
    let fanObj = [];
    let soakerObj = [];
    let shadeObj = [];

    if (assessor.getHoldingAreaSize() !== null) {
        generalObj.push([{
                text: app.$t("Holding Area Size") + " (" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + ")?",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getHoldingAreaSize()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t('At a minimum of ') + app.$numberFormat(assessor.getMinHoldingAreaSize()) + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'square meters')) + app.$t(' per cow, the holding area is large enough to provide space for ') + app.$numberFormat(assessor.getIdealHoldingAreaSize()) + ' ' + app.$t('cows') + '.',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() !== null) {
        generalObj.push([{
                text: app.$t("Do cows spend more than 3 hours/day, on average, in the holding area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() === 1 ? app.$t('Yes') : assessor.getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() === 1 ? app.$t('Prolonged time spent standing waiting to be milked can exacerbate lameness problems.') : assessor.getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() !== null) {
        generalObj.push([{
                text: app.$t("Are first lactation heifers mixed with mature cows in the holding area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() === 1 ? app.$t('Yes') : assessor.getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() === 1 ? app.$t('Mixing age groups means that younger animals will gravitate to the back of the group and stand longer each milking.') : assessor.getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsTheCrowdGateUsedAppropriately() !== null) {
        generalObj.push([{
                text: app.$t('Is the crowd gate used appropriately (used to fill up space behind cows, not used to push cows)?'),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheCrowdGateUsedAppropriately() === 1 ? app.$t('Yes') : assessor.getIsTheCrowdGateUsedAppropriately() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheCrowdGateUsedAppropriately() === 1 ? app.$t('Good!') : assessor.getIsTheCrowdGateUsedAppropriately() === 0 ? app.$t('Contact between the gate and the cows is unacceptable. It should only be used to reduce the area available to the remaining cows in the group.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheCrowdGateUsedAppropriately() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() !== null) {
        generalObj.push([{
                text: app.$t("Is a large proportion (more than 30%) of cows with their heads up, rather than down looking at the ground?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() === 1 ? app.$t('Yes') : assessor.getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() === 1 ? app.$t('Heads up behavior is abnormal and suggests that the cows have insufficient space to be comfortable.') : assessor.getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsTheHoldingAreaFloorExcessivelySloped() !== null) {
        generalObj.push([{
                text: app.$t("Is the holding area floor excessively sloped (greater than 2%)?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheHoldingAreaFloorExcessivelySloped() === 1 ? app.$t('Yes') : assessor.getIsTheHoldingAreaFloorExcessivelySloped() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheHoldingAreaFloorExcessivelySloped() === 1 ? app.$t('Excessive slope puts extra stresses on the feet while standing and may increase the risk of slipping.') : assessor.getIsTheHoldingAreaFloorExcessivelySloped() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheHoldingAreaFloorExcessivelySloped() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() !== null) {
        generalObj.push([{
                text: app.$t("Is there any slipping as the cows move between each other that may traumatize the hoof?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() === 1 ? app.$t('Yes') : assessor.getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() === 1 ? app.$t('Improve the floor surface to reduce the risk of trauma.') : assessor.getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() === 0 ? app.$t('Good!') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (generalObj.length > 0) {

        slide = report.pptx.addSlide('Slide master');

        slide.addText(
            app.$t('Holding Area Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('General'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, obj, generalObj, report, group, 'Holding Area Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });

    }

    if (assessor.getAreRecirculatingFansInTheHoldingArea() !== null) {
        fanObj.push([{
                text: app.$t("Are recirculating fans in the holding area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreRecirculatingFansInTheHoldingArea() === 1 ? app.$t('Yes') : assessor.getAreRecirculatingFansInTheHoldingArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: assessor.getAreRecirculatingFansInTheHoldingArea() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
        slideY += 1;
        titleY += 1;
    }

    if (assessor.getGroupPenSizeInHoldingArea() !== null) {
        fanObj.push([{
                text: app.$t("Group/Pen Size in Holding Area"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getGroupPenSizeInHoldingArea()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    if (assessor.getTemperatureThatTheFansActivate() !== undefined && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {
        console.log('assessor.getTemperatureThatTheFansActivate() ', assessor.getTemperatureThatTheFansActivate())
        fanObj.push([{
                text: app.$t("Temperature that the fans activate (°") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTemperatureThatTheFansActivate()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getTemperatureThatTheFansActivate() > assessor.getMaxTemperatureThatTheSoakersActivate() ? app.$t('Target activation temperature is ') + assessor.getMaxTemperatureThatTheSoakersActivate() + app.$t('°') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + app.$t(' maximum.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getTemperatureThatTheFansActivate() <= assessor.getMaxTemperatureThatTheSoakersActivate() ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    // fan width row 1 
    if (assessor.getFanWidth('fan_width_1') && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {

        fanObj.push([{
                text: app.$t('Fan Width 1') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidth('fan_width_1')),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    if (assessor.getNumberOfFans("number_of_fans_1") && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {
        fanObj.push([{
                text: app.$t("Number of Fans 1"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFans("number_of_fans_1")),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    // fan width row 2
    if (assessor.getFanWidth('fan_width_2') && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {

        fanObj.push([{
                text: app.$t('Fan Width 2') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidth('fan_width_2')),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    if (assessor.getNumberOfFans("number_of_fans_2") && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {
        fanObj.push([{
                text: app.$t("Number of Fans 2"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFans("number_of_fans_2")),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

      // fan width row 3
    if (assessor.getFanWidth('fan_width_3') && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {

        fanObj.push([{
                text: app.$t('Fan Width 3') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getFanWidth('fan_width_3')),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    if (assessor.getNumberOfFans("number_of_fans_3") && assessor.getAreRecirculatingFansInTheHoldingArea() === 1) {
        fanObj.push([{
                text: app.$t("Number of Fans 3"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfFans("number_of_fans_3")),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += .49;
        titleY += .49;
    }

    if(assessor.isNumberOfFansAvailable("number_of_fans") ) {
        
        const condition =  assessor.getCurrentCFM() > assessor.getRecommendedCFM();

        fanObj.push([{
            text: app.$t("Is there adequate air flow?"),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: condition ? app.$t('Yes') : app.$t('No'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: app.$t('Calculated ') + assessor.getCurrentCFM() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + app.$t(' per cow in holding area. Target is ') + assessor.getRecommendedCFM() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cmh')) + app.$t(' per cow.'),
            options: {
                align: 'left',
                color: condition ? '249824' : 'a40000',
                fill: 'efefef',
                bold: false
            }
        },
    ]);
    slideY += .49;
    titleY += .49;
    }

    if (fanObj.length > 0) {

        slide2 = report.pptx.addSlide('Slide master');

        slide2.addText(
            app.$t('Holding Area Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide2.addText(
            app.$t('Fans'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide2, obj, fanObj, report, group, 'Holding Area Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    slideY2 = slideY;
    titleY2 = titleY;

    if (assessor.getAreSoakersMistersInTheHoldingArea() !== null) {
        soakerObj.push([{
                text: app.$t("Are soakers/misters in the holding area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreSoakersMistersInTheHoldingArea() === 1 ? app.$t('Yes') : assessor.getAreSoakersMistersInTheHoldingArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: '000000',
                fill: 'efefef',
                    bold: false
                }
            },
        ]);
        slideY += 1;
        titleY += 1;
    }

    if (assessor.getSoakersTemperatureThatTheSoakersActivate() !== null && assessor.getAreSoakersMistersInTheHoldingArea() === 1) {
        soakerObj.push([{
                text: app.$t("Temperature that the soakers activate (°") + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + ')',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getSoakersTemperatureThatTheSoakersActivate()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$t('Target activation temperature is ') + app.$numberFormat(assessor.getMaxTemperatureThatTheSoakersActivate()) + app.$t('°') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'C')) + app.$t(' maximum.'),
                options: {
                    align: 'left',
                    color: assessor.getSoakersTemperatureThatTheSoakersActivate() <= assessor.getMaxTemperatureThatTheSoakersActivate() ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .5;
        titleY += .5;
    }

    if (assessor.getSoakersDuration() !== null && assessor.getAreSoakersMistersInTheHoldingArea() === 1) {
        soakerObj.push([{
                text: app.$t("Duration (minutes)?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getSoakersDuration()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    color: 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .5;
        titleY += .5;
    }

    if (assessor.getSoakersFrequency() !== null && assessor.getAreSoakersMistersInTheHoldingArea() === 1) {
        soakerObj.push([{
                text: app.$t("Frequency (minutes)?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getSoakersFrequency()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: evaluation.getMeasurements() === 'metric' ? app.$t('For Temperatures between 21˚ - 26˚, the duration should be 1-2 min, with a frequency of every 15 min. For Temperatures between 27˚ - 32˚, the duration should be 1-2 min, with a frequency of every 10 min. For Temperatures > 32˚, the duration should be 1-2 min, with a frequency of every 5 min.') : app.$t('For Temperatures between 70˚ - 80˚, the duration should be 1-2 min, with a frequency of every 15 min. For Temperatures between 81˚ - 90˚, the duration should be 1 - 2 min, with a frequency of every 10 min. For Temperatures > 90˚, the duration should be 1 - 2 min, with a frequency of every 5 min.'),
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += 1.7;
        titleY += 1.7;
    }

    if (soakerObj.length > 0) {
        slide2.addText(
            app.$t('Soakers/Misters'), {
                x: 0.5,
                y: titleY2 + .3,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide2, obj, soakerObj, report, group, 'Holding Area Assessor', {
            y: slideY2 + .3,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    if (assessor.getIsThereShadeOverTheHoldingArea() !== null) {
        shadeObj.push([{
                text: app.$t("Is there shade over the holding area?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverTheHoldingArea() === 1 ? app.$t('Yes') : assessor.getIsThereShadeOverTheHoldingArea() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsThereShadeOverTheHoldingArea() === 1 ? app.$t('Good!') : assessor.getIsThereShadeOverTheHoldingArea() === 0 ? app.$t('Consider constructing a shade.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsThereShadeOverTheHoldingArea() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slideY += .5;
        titleY += .5;
    }

    if (shadeObj.length > 0) {
        let slide3 = report.pptx.addSlide('Slide master');

        slide3.addText(
          app.$t('Holding Area Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide3.addText(
            app.$t('Shade'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide3, obj, shadeObj, report, group, 'Holding Area Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

}
