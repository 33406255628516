import { getPageSize } from '@/constants';
import { LINE_HEIGHTS } from '@/reportLineHeights';
import { writeTableOnSlide } from '../BeddedPack/beddedPack';

const Table = require('../../Misc/Table');

export const hoofTrimming = (app, report, evaluation, group, assessor) => {

    let slide, prevTableEndAt, prevTitleEndAt;
    const TITLE_Y_START = 2.3;
    const TABLE_Y_START = 2.52;


    // let titleY = 2.9;
    // let tableY = 3.3;

    let tableY = TABLE_Y_START;
    let titleY = TITLE_Y_START;
    const userLang = app.user.lang
    const PAGE_SIZE = getPageSize(userLang, {ch: 11.5, es: 9.7, en: 10.2}); // we'll create slide after this 

    const breakpoints = [0];
    const defaultValues = {tableY:TABLE_Y_START, titleY: TITLE_Y_START}
    let capacityObj = [];
    let techniqueObj = [];
    const {ONE_LINE, TWO_LINE, THREE_LINE, FOUR_LINE, FIVE_LINE, SIX_LINE, SEVEN_LINE, TEN_LINE} = LINE_HEIGHTS[userLang] || LINE_HEIGHTS['en']
 
    function makePageBreak({tableY, tableValue, titleY, titleValue}, defaultVal) {
    if(tableY + tableValue > PAGE_SIZE){
        const pageBreak = techniqueObj.length -1 /* because this value is greater the available space, 
        if break after this row our content get overflow, that's why need to break the slide before add this row the page */
        breakpoints.push(pageBreak);
        return {tableY: defaultVal.tableY  + tableValue, titleY: defaultVal.titleY + titleValue}
    }
        return {tableY: tableY+ tableValue , titleY: titleY + titleValue}
    };
    
    const obj = [
        {
            text: app.$t('Question'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Answer'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Comment'),
            options: report.styleOptions.tableHeader
        }
    ]


    if (group.getGroupSize() !== null) {

        capacityObj.push([{
                text: app.$t("Group/Pen Size"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(group.getGroupSize()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef',
                }
            }
        ]);
   
        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});
    }

    if (assessor.getPrimaryHoofTrimmer() !== null) {

        capacityObj.push([{
                text: app.$t("Primary Hoof Trimmer"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getPrimaryHoofTrimmer() === 'employee' ? app.$t('Employee') : assessor.getPrimaryHoofTrimmer() === 'family_member' ? app.$t('Family Member') : assessor.getPrimaryHoofTrimmer() === 'outside_contractor' ? app.$t('Outside Contractor') : app.$t('Owner'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef'
                }
            }
        ]);
      
        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getTrimmingFrequency() !== null) {

        capacityObj.push([{
                text: app.$t("Trimming Frequency (times/lactation)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTrimmingFrequency()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef'
                }
            }
        ]);
        

        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});


    }

    if (assessor.getTrimmerVisitFrequency() !== null) {

        capacityObj.push([{
                text: app.$t("Trimmer Visit Frequency (times/year)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getTrimmerVisitFrequency()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef'
                }
            }
        ]);
        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getNumberOfTrimsRecordedPerYear() !== null) {

        capacityObj.push([{
                text: app.$t("Number of Trims Recorded per Year (Lame Included)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getNumberOfTrimsRecordedPerYear()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef',
                }
            }
        ]);
        const value = TWO_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getPercentageOfCowsInHerdWithLocomotionScoresOf4And5() !== null) {
        capacityObj.push([{
                text: app.$t("Percentage of Cows in Herd with Locomotion Scores of 4 and 5"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getPercentageOfCowsInHerdWithLocomotionScoresOf4And5()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef',
                }
            }
        ]);
        const value = TWO_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});


    }

    if (assessor.getAreHeifersTrimmedPriorToCalving() !== null) {
        capacityObj.push([{
                text: app.$t("Are heifers trimmed prior to calving?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreHeifersTrimmedPriorToCalving() === 1 ? app.$t('Yes') : assessor.getAreHeifersTrimmedPriorToCalving() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef',
                }
            }
        ]);
        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getAreHeifersTrimmedPriorToCalving() === 1 && assessor.getHeifersInGroupNumber() !== null && assessor.getHeifersInGroupPercent() === null) {
        capacityObj.push([{
                text: app.$t("Heifers in group (# of heifers)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getHeifersInGroupNumber()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef',
                }
            }
        ]);
        

        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getAreHeifersTrimmedPriorToCalving() === 1 && assessor.getHeifersInGroupPercent() !== null && assessor.getHeifersInGroupNumber() === null) {
        capacityObj.push([{
                text: app.$t("Heifers in group (% of group)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getHeifersInGroupPercent()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef',
                }
            }
        ]);
        

        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getAreLameCowsTreatedByHoofTrimmer() !== null) {
        capacityObj.push([{
                text: app.$t("Are lame cows treated by hoof trimmer?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreLameCowsTreatedByHoofTrimmer() === 1 ? app.$t('Yes') : assessor.getAreLameCowsTreatedByHoofTrimmer() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    fill: 'efefef'
                }
            }
        ]);
        const value = ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getEstimatedTrimmedPerVisit() !== 0 || assessor.getEstimatedTrimmedPerVisit() !== null) {
        console.log('getEstimatedTrimmedPerVisit', assessor.getEstimatedTrimmedPerVisit());
        capacityObj.push([{
                text: app.$t("Estimated number of cows trimmed per visit"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getEstimatedTrimmedPerVisit()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getEstimatedTrimmedPerVisit() > 50 ? app.$t('Trimmer may be trimming cows too fast.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getEstimatedTrimmedPerVisit() > 50 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        const value = TWO_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (assessor.getEstimatedTrimmedPerYear() !== 0.0) {
        const isTrue = assessor.getEstimatedTrimmedPerYear() > assessor.getEstimatedTrimmedPerVisit();
        console.log('getEstimatedTrimmedPerYear', assessor.getEstimatedTrimmedPerYear());
        capacityObj.push([{
                text: app.$t("Estimated number of trims per year to meet goal"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getEstimatedTrimmedPerYear()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: isTrue ? app.$t('Trimmer may not be visiting frequently enough to meet the need of routine trimming.') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: isTrue ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        const value = isTrue ? THREE_LINE : TWO_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) );
        console.log({value, tableY, titleY});

    }

    if (capacityObj.length > 0) {

        // prevTableEndAt = tableY;
        // prevTitleEndAt = titleY;

         tableY = TABLE_Y_START;
         titleY = TITLE_Y_START;

        slide = report.pptx.addSlide('Slide master');

        slide.addText(
            app.$t('Hoof Trimming Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('Capacity'), {
                x: 0.5,
                y: 2.3,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, obj, capacityObj, report, group, 'Hoof Trimming Assessor', {
            y: 2.52,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });

    }

    if (assessor.getDoesDairyPersonnelActivelyIdentifyLameCowsForTreatmentAsSoonAsTheyOccur() !== null) {
        const isTrue  = assessor.getDoesDairyPersonnelActivelyIdentifyLameCowsForTreatmentAsSoonAsTheyOccur() === 1;

        techniqueObj.push([{
                text: app.$t("Do dairy personnel actively identify lame cows for treatment as soon as they occur?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: isTrue ? app.$t('Yes') : assessor.getDoesDairyPersonnelActivelyIdentifyLameCowsForTreatmentAsSoonAsTheyOccur() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: isTrue ? app.$t('Good!') : assessor.getDoesDairyPersonnelActivelyIdentifyLameCowsForTreatmentAsSoonAsTheyOccur() === 0 ? app.$t('Delay in treatment reduces the chances of cure and exaggerates herd lameness problems.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: isTrue ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

        const value = isTrue ? TWO_LINE : THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) ) 
        console.log({value, tableY, titleY});
    }

    if (assessor.getDoesTheHoofTrimmerLikeWorkingWithCows() !== null) {
        const isTrue = assessor.getDoesTheHoofTrimmerLikeWorkingWithCows() === 1
        techniqueObj.push([{
                text: app.$t("Does the hoof trimmer like working with cows?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: isTrue ? app.$t('Yes') : assessor.getDoesTheHoofTrimmerLikeWorkingWithCows() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: isTrue ? app.$t('Good!') : assessor.getDoesTheHoofTrimmerLikeWorkingWithCows() === 0 ? app.$t('People who enjoy working with cows tend to be more willing to take a few extra minutes to make sure the cow is treated properly.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: isTrue ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

        const value = isTrue ? ONE_LINE : FOUR_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues) ) 
        console.log({value, tableY, titleY});
    }

    if (assessor.getDoesTheHoofTrimmerHandleCowsGently() !== null) {
        const isTrue = assessor.getDoesTheHoofTrimmerHandleCowsGently() !== 1 && assessor.getDoesTheHoofTrimmerHandleCowsGently() === 0
        techniqueObj.push([{
                text: app.$t("Does the hoof trimmer handle cows gently?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheHoofTrimmerHandleCowsGently() === 1 ? app.$t('Yes') : assessor.getDoesTheHoofTrimmerHandleCowsGently() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheHoofTrimmerHandleCowsGently() === 1 ? app.$t('Good!') : assessor.getDoesTheHoofTrimmerHandleCowsGently() === 0 ? app.$t('Hoof trimmers that do not handle cows gently increase the risk of cows being injured and may not take the extra time to make sure the cow receives the proper treatment for claw lesions.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getDoesTheHoofTrimmerHandleCowsGently() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        
        const value = isTrue ?  FIVE_LINE : ONE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues)) 
        console.log({value, tableY, titleY});
    }

    if (assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() !== null) {
        const isTrue  = assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() !== 1 && assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() === 0;

        techniqueObj.push([{
                text: app.$t("Is the trimming area a pleasant place to work in all weather conditions?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() === 1 ? app.$t('Yes') : assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() === 1 ? app.$t('Good!') : assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() === 0 ? app.$t("A safe, clean area with adequate lighting, adequate ventilation, protection from the elements, good flooring, good cow flow, proper restraint and good electrical supply helps minimize both mental and physical fatigue on the trimmer. This allows the trimmer to properly trim cows' feet and treat lesions present on the feet.") : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

        // from here
        const value =  isTrue ? TEN_LINE : TWO_LINE;
        console.log({tenLIne: value});
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues)) 
        console.log({value, tableY, titleY});

    }

    if (assessor.getIsShadeAvailable() !== null) {
        const isTrue =   assessor.getIsShadeAvailable() !== 1 &&  assessor.getIsShadeAvailable() === 0;
        techniqueObj.push([{
                text: app.$t("Is shade available for animals waiting to be trimmed or animals that have been trimmed?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsShadeAvailable() === 1 ? app.$t('Yes') : assessor.getIsShadeAvailable() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsShadeAvailable() === 1 ? app.$t('Good!') : assessor.getIsShadeAvailable() === 0 ? app.$t('It is recommended that animals in trimming areas have access to shade to decrease severity of heat stress during trimming in summer months.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsShadeAvailable() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        const value = isTrue ? FOUR_LINE :  THREE_LINE; 
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues)) 
        console.log({value, tableY, titleY});
        
    }

    if (assessor.getIsWaterAvailable() !== null) {
        const isTrue = assessor.getIsWaterAvailable() !== 1 && assessor.getIsWaterAvailable() === 0
        techniqueObj.push([{
                text: app.$t("Is water available for animals waiting to be trimmed or animals that have been trimmed?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsWaterAvailable() === 1 ? app.$t('Yes') : assessor.getIsWaterAvailable() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsWaterAvailable() === 1 ? app.$t('Good!') : assessor.getIsWaterAvailable() === 0 ? app.$t('It is recommended that animals in trimming areas have access to water to decrease severity of heat stress during trimming in summer months.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsWaterAvailable() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

        const value =  isTrue ? FIVE_LINE : THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues)) 
        console.log({value, tableY, titleY});
    }

    if (assessor.getIsFeedAvailable() !== null) {
        const isTrue = assessor.getIsFeedAvailable() !== 1 && assessor.getIsFeedAvailable() === 0
        techniqueObj.push([{
                text: app.$t("Is feed available for animals waiting to be trimmed or animals that have been trimmed?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsFeedAvailable() === 1 ? app.$t('Yes') : assessor.getIsFeedAvailable() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsFeedAvailable() === 1 ? app.$t('Good!') : assessor.getIsFeedAvailable() === 0 ? app.$t('Animals should have access to feed for at least 21 hours per day (assuming 3 hours of milking time per day).') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsFeedAvailable() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

        const value = isTrue ? FOUR_LINE : THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});
    }

    if (assessor.getHasTheHoofTrimmerReceivedFormalTraining() !== null) {
        const isTrue = assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 1 && assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 0
        techniqueObj.push([{
                text: app.$t("Has the hoof trimmer received formal training?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 1 ? app.$t('Yes') : assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 1 ? app.$t('Good!') : assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 0 ? app.$t('Trimmers that have not undergone formal training may not be using the correct technique.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getHasTheHoofTrimmerReceivedFormalTraining() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value =  isTrue ? THREE_LINE : TWO_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() !== null) {
        const isTrue = assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() !== 1 && assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() === 0
        techniqueObj.push([{
                text: app.$t("Does the hoof trimmer use a lesion recording system with standard definitions for hoof lesions?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() === 1 ? app.$t('Yes') : assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() === 1 ? app.$t('Good!') : assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() === 0 ? app.$t('Records should be collected to enable further investigation as to why these lesions occur and to monitor reoccurring individual events.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value =  isTrue ?  FOUR_LINE : THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() !== null) {
        techniqueObj.push([{
                text: app.$t("Is there a noticeable increase in new cases of lameness within a week of routine trimming?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() === 1 ? app.$t('Yes') : assessor.geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() === 0 ? app.$t('Good!') : assessor.geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() === 1 ? app.$t('If these cases are related to the removal of too much horn, trimming technique must be investigated.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value = THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() !== null) {

        techniqueObj.push([{
                text: app.$t("Are toes trimmed to maintain the top-front (dorsal) hoof wall at a length of ") + assessor.getIdealAreToesTrimmed() + ' ' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(" or greater?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() === 1 ? app.$t('Yes') : assessor.getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() === 1 ? app.$t('Good!') : assessor.getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() === 0 ? app.$t('Toes that are too short will put the cows at risk of becoming lame.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value = THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() !== null) {
        techniqueObj.push([{
                text: app.$t("Does the hoof trimmer spare the height of the medial claw heel (inside claw) of the hind foot, in particular to the heel?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() === 1 ? app.$t('Yes') : assessor.getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() === 1 ? app.$t('Good!') : assessor.getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() === 0 ? app.$t('Removal of too much horn on the inner claw will lead to hoof balance problems.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value = THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() !== null) {
        techniqueObj.push([{
                text: app.$t("Is the inside wall (axial wall) or toe of the inner or outer wall removed?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() === 1 ? app.$t('Yes') : assessor.getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() === 0 ? app.$t('Good!') : assessor.getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() === 1 ? app.$t('The side wall is a weight bearing surface and should not be removed.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value = TWO_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() !== null) {
        techniqueObj.push([{
                text: app.$t("Are claws trimmed so that the sole surface is flat rather than concave? (Do the toes splay?)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() === 1 ? app.$t('Yes') : assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() === 1 ? app.$t('Good!') : assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() === 0 ? app.$t('Claws should be trimmed flat, so that they are not forced apart when the animal bears weight.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() === 1 ? '249824' : assessor.getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() === 0 ? 'a40000' : '000000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value = THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() !== null) {
        const isTrue =  assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() === 0;
        techniqueObj.push([{
                text: app.$t("Is the outer wall (abaxial wall) removed from the toe and side wall excessively?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() === 1 ? app.$t('Yes') : assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() === 0 ? app.$t('Good!') : assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() === 1 ? app.$t('This horn is part of the weight bearing surface and should not be removed.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

     
        const value = isTrue ? TWO_LINE : THREE_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getIsTheSoleTrimmedExcessively() !== null) {
        techniqueObj.push([{
                text: app.$t("Is the sole trimmed excessively? Does the sole spring under thumb pressure after trimming? (This conveys that the soles have been trimmed too thin.)"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheSoleTrimmedExcessively() === 1 ? app.$t('Yes') : assessor.getIsTheSoleTrimmedExcessively() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheSoleTrimmedExcessively() === 0 ? app.$t('Good!') : assessor.getIsTheSoleTrimmedExcessively() === 1 ? app.$t('The sole has been trimmed too thin and the hoof trimming technique needs to be corrected.') : app.$t('N/A'),
                options: {
                    align: 'left',
                    color: assessor.getIsTheSoleTrimmedExcessively() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);

        const value = FOUR_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment() !== null) {
        console.log('getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment', assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment());
        const isTrue = assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment() < 3;
        techniqueObj.push([{
                text: app.$t("At what locomotion score are the dairy personnel trained to identify cows in need of treatment?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: app.$numberFormat(assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment()),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment() < 3 ? app.$t('Over-treating cows may increase the incidence of lameness.') : assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment() > 3 ? app.$t('Identifying cows at the first sign of lameness (Score 3) will decrease the odds of cows becoming severely lame (Scores 4 or 5).') : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment() == 3 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

      
        const value = isTrue ?THREE_LINE : FOUR_LINE;
        ({tableY, titleY} = makePageBreak({tableY, tableValue:value, titleY,titleValue:value }, defaultValues));
        console.log({value, tableY, titleY});

    }

    if (techniqueObj.length > 0) {

        // slide.addText(
        //     app.$t('Hoof Trimming Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        // );

        // slide.addText(
        //     app.$t('Technique'), {
        //         x: 0.5,
        //         y: 2.3,
        //         ...report.styleOptions.subheaderBlue
        //     }
        // );

        // Table.create(slide, obj, techniqueObj, report, group, 'Hoof Trimming Assessor', {
        //     y: 2.52,
        //     autoPageLineWeight: .2,
        //     colW: [3.25, 1.25, 3],
        //     ...report.styleOptions.tableOptions
        // });

        writeTableOnSlide({
        app,
        Table,
        breakpoints,
        report,
        contentObj: techniqueObj,
        group,
        assessorName: "Hoof Trimming Assessor",
        prevSlide: slide,
        TITLE_Y_START,
        TABLE_Y_START,
        categoriesObj: obj,
        tableTitle: "Technique",
        // prevTableEndAt,
        // prevTitleEndAt
    })

    }

}
